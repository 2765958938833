/**
 * Static class to check roles from an array with roles
 */
class AvatarRoleCheck {
  static enums = {
    // Bacteriologist, Site Bacteriologist, Universal Bacteriologist
    bacteriologist: [
      '5a8409ad-ee29-4ca9-90ef-5517415449a3',
      '83b35c59-85cc-4270-afd3-7fbcd5cd1f68',
      '50674216-32a3-4f9e-956f-86414231eda5',
    ],
    // Junior Bacteriologist, Agricam Bacticam Junior Bacteriologist
    juniorBacteriologist: [
      'a700529c-c10f-4d80-ab7f-c99d084cfed3',
      '50f34d96-6ebb-4c4c-8555-d479fe7ff773',
    ],
  };

  /**
   * Checks if the given role exists in the users role array
   * @returns {boolean} True if the role exists, false otherwise.
   * @param {Array} userRoles The array with roles
   * @param {String} roleToCheck The role to check for
   */
  static hasRole(roles, rolesToCheck) {
    if (!roles) {
      return false;
    }

    return roles.some((role) => {
      return rolesToCheck.includes(role?.roleid);
    });
  }

  static isBacteriologist(roles) {
    return this.hasRole(roles, [...this.enums.bacteriologist]);
  }

  static isVeterinarian(roles) {
    return this.hasRole(roles, [...this.enums.juniorBacteriologist]);
  }
}

export default AvatarRoleCheck;
