import React, { useEffect, useState, useCallback, useRef } from 'react';
import { toRGB } from '../../Services/Utility/toRGB';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Chart = require('chart.js');

const useStyles = makeStyles({
  canvasWrapper: {
    maxHeight: '500px',
    width: '100% !important',
  },
});

export default function PieChart(props) {
  const { data } = props;
  const { t } = useTranslation(['bacterias']);
  const classes = useStyles();
  const canvasRef = useRef();
  const [chartInstance, setChartInstance] = useState(null);

  const generateColors = useCallback(() => {
    const defColors = [
      'rgb(66,142,51)',
      'rgb(225,95,15)',
      'rgb(218,44,56)',
      'rgb(7,30,34)',
      'rgb(8,103,136)',
      'rgb(134,203,146)',
      'rgb(234,191,203)',
      'rgb(9,4,70)',
      'rgb(192,189,165)',
      'rgb(79,0,11)',
      'rgb(240,162,2)',
      'rgb(168,8,116)',
      'rgb(67,146,241)',
      'rgb(239,118,116)',
      'rgb(84,13,110)',
      'rgb(86,65,56)',
      'rgb(23,190,187)',
    ];
    const colors = [];

    data.forEach((d) => {
      if (defColors > 0) {
        let hash = 0;
        if (d.name.length === 0) return hash;
        for (let i = 0; i < d.name.length; i++) {
          hash = d.name.charCodeAt(i) + ((hash << 5) - hash);
          hash = hash & hash;
        }
        const index = Math.abs(hash) % defColors.length;
        const rgb = defColors[index];
        colors.push(rgb);
        defColors.splice(index, 1);
      } else {
        colors.push(toRGB(d.name));
      }
    });
    return colors;
  }, [data]);

  useEffect(() => {
    const myChart = new Chart(canvasRef.current, {
      type: 'pie',
      options: {
        maintainAspectRatio: false,
      },
      data: {
        labels: null,
        datasets: [
          {
            data: null,
            backgroundColor: null,
          },
        ],
      },
    });
    setChartInstance(myChart);
  }, []);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.data.labels = data.map((d) => t(`bacterias:${d.name}`));
      chartInstance.data.datasets[0].data = data.map((d) => d.amount);
      chartInstance.data.datasets[0].backgroundColor = generateColors();
      chartInstance.update();
    }
  }, [data, chartInstance, generateColors, t]);

  return <canvas className={classes.canvasWrapper} ref={canvasRef} />;
}

PieChart.propTypes = {
  data: PropTypes.array.isRequired,
};
