import Backend from 'i18next-chained-backend';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Locize from 'i18next-locize-backend';
import HttpApi from 'i18next-http-backend';
import packageJson from '../package.json';
const isProduction = process.env.NODE_ENV === 'production';

// 'Bacticam' locize project
const locizeOptionsPrimary = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID_PRIMARY,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY_PRIMARY, // The API key should only be used in development, not in production. You should not =expose your apps API key to production!!!
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: process.env.REACT_APP_LOCIZE_VERSION,
};

// 'Shared' locize project
const locizeOptionsSecondary = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID_SECONDARY,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY_SECONDARY, // The API key should only be used in development, not in production. You should not =expose your apps API key to production!!!
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: process.env.REACT_APP_LOCIZE_VERSION,
};

i18n
  // i18next-locize-backend
  // https://github.com/locize/i18next-locize-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    keySeparator: ':',
    fallbackLng: 'en',
    debug: !isProduction,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      backends: [Locize, Locize, HttpApi],
      backendOptions: [
        locizeOptionsSecondary,
        locizeOptionsPrimary,
        { loadPath: `/locales/{{lng}}/{{ns}}.json?${packageJson.version}` },
      ],
    },
  });

export default i18n;
