import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import robotIcon from '../../../../../icons/robot.png';
import agricamIcon from '../../../../../icons/agricam-logo-symbol.png';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  isResultSetByAI,
  isResultSetByVeterinarian,
  isResultSetByLab,
} from '../../../../../Services/Utility/SampleInformation';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  whoSetThisResultIcon: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.2),
    height: theme.spacing(1.25),
    width: theme.spacing(1.25),
  },
  circleIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
}));

export default function ResultIcon(props) {
  const { result } = props;
  const classes = useStyles();
  const [icon, setIcon] = useState(<></>);

  useEffect(() => {
    if (result === 'preliminaryResultMissing' || isResultSetByAI(result)) {
      setIcon(
        <div className={classes.whoSetThisResultIcon}>
          <img alt='Result icon' src={robotIcon} height='100%' />
        </div>,
      );
    } else if (isResultSetByVeterinarian(result)) {
      setIcon(
        <div className={classes.whoSetThisResultIcon}>
          <img alt='Result icon' src={agricamIcon} height='100%' />
        </div>,
      );
    } else if (isResultSetByLab(result)) {
      setIcon(<CheckCircleIcon className={classes.circleIcon} />);
    }
  }, [classes, result]);

  return icon || <></>;
}

ResultIcon.propTypes = {
  result: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
