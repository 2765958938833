import { enums } from '../Enumerations';
import FlEmpty from '../../../icons/FL-empty.png';
import FrEmpty from '../../../icons/FR-empty.png';
import BlEmpty from '../../../icons/BL-empty.png';
import BrEmpty from '../../../icons/BR-empty.png';
import logo from '../../../icons/favicon.ico';
import FlIconGray from '../../../icons/udderIcon-FL-gray.png';
import FrIconGray from '../../../icons/udderIcon-FR-gray.png';
import BlIconGray from '../../../icons/udderIcon-BL-gray.png';
import BrIconGray from '../../../icons/udderIcon-BR-gray.png';
import FlIcon from '../../../icons/udderIcon-FL.png';
import FrIcon from '../../../icons/udderIcon-FR.png';
import BlIcon from '../../../icons/udderIcon-BL.png';
import BrIcon from '../../../icons/udderIcon-BR.png';
import i18n from '../../../i18n';

export function getEmptyImage(udderId) {
  switch (udderId) {
    case enums.udderPart.frontLeft:
      return FlEmpty;
    case enums.udderPart.frontRight:
      return FrEmpty;
    case enums.udderPart.backLeft:
      return BlEmpty;
    case enums.udderPart.backRight:
      return BrEmpty;
    default:
      return logo;
  }
}

export function getCmt(cmt) {
  switch (cmt) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return cmt;
    case 6:
      return '-';
    case 7:
      return 'x';
    default:
      return '-';
  }
}

export function getUdderPart(
  udderId,
  frontLeftLabel,
  frontRightLabel,
  backLeftLabel,
  backRightLabel,
  udderPartUnknownLabel,
) {
  udderId = parseInt(udderId);
  switch (udderId) {
    case enums.udderPart.frontLeft:
      return i18n.t(frontLeftLabel);
    case enums.udderPart.frontRight:
      return i18n.t(frontRightLabel);
    case enums.udderPart.backLeft:
      return i18n.t(backLeftLabel);
    case enums.udderPart.backRight:
      return i18n.t(backRightLabel);
    default:
      if (udderPartUnknownLabel === null) return '-';
      else return i18n.t(udderPartUnknownLabel);
  }
}

export function getSmallUdderIcon(udderId) {
  udderId = parseInt(udderId);
  switch (udderId) {
    case enums.udderPart.frontLeft:
      return FlIcon;
    case enums.udderPart.frontRight:
      return FrIcon;
    case enums.udderPart.backLeft:
      return BlIcon;
    case enums.udderPart.backRight:
      return BrIcon;
    default:
      return '';
  }
}

export function getSmallGrayUdderIcon(udderId) {
  udderId = parseInt(udderId);
  switch (udderId) {
    case enums.udderPart.frontLeft:
      return FlIconGray;
    case enums.udderPart.frontRight:
      return FrIconGray;
    case enums.udderPart.backLeft:
      return BlIconGray;
    case enums.udderPart.backRight:
      return BrIconGray;
    default:
      return '';
  }
}
