import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    backgroundColor: theme.palette.complementColors.blackBlue,
    color: theme.palette.common.white,
  },
  dialogHeader: {
    color: theme.palette.complementColors.blackBlue,
    marginLeft: '24px',
    marginTop: '16px',
  },
}));

export default function StatisticsDialog(props) {
  const { closeDialog } = props;
  const classes = useStyles();
  const { t } = useTranslation(['main']);

  return (
    <Dialog open onClose={closeDialog}>
      <Typography variant='h4' className={classes.dialogHeader}>
        {t('navigation:statisticsDialogHeader')}
      </Typography>
      <DialogContent>
        <DialogContentText variant='body1'>
          <Trans
            i18nKey={t('navigation:statisticsDialogBody')}
            components={{
              br: (
                <>
                  <br />
                  <br />
                </>
              ),
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => closeDialog()} className={classes.closeButton}>
          {t('navigation:statisticsDialogClose')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

StatisticsDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};
