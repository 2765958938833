import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { openModal } from '../../../../../actions/modalActions';
import { useDispatch } from 'react-redux';
import { getUdderPart } from '../../../../../Services/Utility/Udder';
import {
  getSampleLocation,
  getShortSampleDate,
  getBactSampleStatus,
  getBactSampleInfo,
  hasFinalBacteriaResult,
  hasPreliminaryBacteriaResult,
  hasEarlyResult,
  getBacteriaAmount,
  getCultureTypeLabel,
  hasFinalResult,
  hasPreliminaryResult,
  hasEarlyBacteriaResult,
} from '../../../../../Services/Utility/SampleInformation';

import {
  Button,
  Grid,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  panelSummary: {
    marginTop: '0.3rem',
  },
  mobilePanelSummary: {
    marginTop: '0.3rem',
    padding: '0 0.5rem',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    minWidth: 64,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
  },
  locationHeading: {
    maxHeight: '24px',
    overflow: 'hidden',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    padding: '0 0.5rem',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  date: {
    minWidth: '100px',
  },
}));

export default function BactSampleTableRow(props) {
  const classes = useStyles();
  const { sample } = props;
  const { t } = useTranslation(['samplesBacteriologist', 'bacterias']);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const bacteriaResult = hasFinalBacteriaResult(sample)
    ? `${t(`bacterias:${sample.finalResult.bacterias[0]?.name}`)}${getBacteriaAmount(
        sample.finalResult.cultureType.bacteriaAmount,
      )}`
    : hasFinalResult(sample)
    ? `${getCultureTypeLabel(sample.finalResult)}${getBacteriaAmount(
        sample.finalResult.cultureType.bacteriaAmount,
      )}`
    : hasPreliminaryBacteriaResult(sample)
    ? `${t(`bacterias:${sample.preliminaryResult.bacterias[0]?.name}`)}${getBacteriaAmount(
        sample.preliminaryResult.cultureType.bacteriaAmount,
      )}`
    : hasPreliminaryResult(sample)
    ? `${getCultureTypeLabel(sample.preliminaryResult)}${getBacteriaAmount(
        sample.preliminaryResult.cultureType.bacteriaAmount,
      )}`
    : hasEarlyBacteriaResult(sample)
    ? `${t(`bacterias:${sample.earlyResult.bacterias[0]?.name}`)}${getBacteriaAmount(
        sample.earlyResult.cultureType.bacteriaAmount,
      )}`
    : hasEarlyResult(sample)
    ? `${getCultureTypeLabel(sample.earlyResult)}${getBacteriaAmount(
        sample.earlyResult.cultureType.bacteriaAmount,
      )}`
    : '';

  // Makes sure no doubles are displayed
  function getUnique(arr, comp) {
    const unique = arr
      .map((e) => e[comp])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  function getUdderLabels() {
    return getUdderPart(
      sample.udderId,
      'samplesBacteriologist:tableRow:udderPartFrontLeft',
      'samplesBacteriologist:tableRow:udderPartFrontRight',
      'samplesBacteriologist:tableRow:udderPartBackLeft',
      'samplesBacteriologist:tableRow:udderPartBackRight',
      'samplesBacteriologist:tableRow:udderPartUnknown',
    );
  }

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          className={isMobile ? classes.mobilePanelSummary : classes.panelSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid item xs={isMobile ? 2 : 1}>
            <Typography className={classes.heading}>{sample.subject.number}</Typography>
          </Grid>
          <Grid item xs={isMobile ? 2 : 1}>
            <Typography className={classes.desc}>{getUdderLabels()}</Typography>
          </Grid>
          <Grid item xs={isMobile ? 4 : 2} className={classes.date}>
            <Typography className={classes.secondaryHeading}>
              {getShortSampleDate(sample)}
            </Typography>
          </Grid>
          {!isMobile ? (
            <Grid item xs={5}>
              <Typography className={classes.secondaryHeading}>
                {getBactSampleInfo(sample)}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={isMobile ? 4 : 3}>
            <Typography className={classes.secondaryHeading}>
              {getSampleLocation(sample)}
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div className={classes.column}>
            {t('tableRow:actionsHeader')}
            {sample.sampleActions !== null && sample.sampleActions.length > 0
              ? getUnique(sample.sampleActions, 'name').map((action) => {
                  return (
                    <React.Fragment key={action.sampleActionId}>
                      <Typography variant={'caption'}>{action.name}</Typography>
                    </React.Fragment>
                  );
                })
              : null}
          </div>
          <div className={classes.column}>
            {t('tableRow:resultHeader')}
            <Typography variant='caption'>{bacteriaResult}</Typography>
          </div>
          <div className={classes.column}>
            {t('tableRow:statusHeader')}
            <Typography variant='caption'>{getBactSampleStatus(sample)}</Typography>
          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Link to={`/samples/${sample.sampleId}`}>
            <Button
              onClick={() => {
                dispatch(openModal(sample, 'bacteriologist', false));
              }}
              size='small'
            >
              {t('tableRow:detailsButtonLabel')}
            </Button>
          </Link>
        </AccordionActions>
      </Accordion>
    </div>
  );
}

BactSampleTableRow.propTypes = {
  sample: PropTypes.object.isRequired,
};
