const initialState = {
  isOpen: false,
  sample: null,
};

function PDFreducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_PDF_DIALOG':
      return { ...state, isOpen: true, sample: action.sample };
    case 'CLOSE_PDF_DIALOG':
      return { ...state, isOpen: false };
    default:
      return state;
  }
}
export default PDFreducer;
