import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getAvatar } from '../../../Services/Utility/User';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import SettingsIcon from '@material-ui/icons/Settings';
import InputIcon from '@material-ui/icons/Input';
import {
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  Input,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { selectSite } from '../../../actions/siteActions';
import { useCookies } from 'react-cookie';
import SelectLanguageFlag from '../SelectLanguageFlag';
import PermissionCheck from '../../../Services/PermissionCheck';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    right: '0',
    display: 'flex',
    width: '100%',
    height: 'var(--topbar-height-desktop)',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#ffff',
    marginBottom: '32px',
    zIndex: '1240',
    boxShadow: '0px -3px 10px #888888',
  },
  content: {
    display: 'flex',
    padding: '0 32px',
    '&> div': {
      margin: '0 20px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  select: {
    backgroundColor: '#ffff',
    '&:focus': {
      backgroundColor: '#ffff',
    },
  },
  formControl: {
    flexDirection: 'row',
  },
  menuItemUser: {
    padding: '0',
    width: '100%',
    minHeight: '3rem',
    backgroundColor: '#ffffff',
    '&>a': {
      textDecoration: 'none',
      color: '#000000',
      '&.active': {
        backgroundColor: 'unset',
      },
      '&:hover': {
        backgroundColor: 'unset',
        color: 'black',
      },
    },
  },
  avatar: {
    width: '24px',
    height: 'auto',
    marginRight: '8px',
    marginBottom: '4px',
  },
  avatarAndName: {
    display: 'flex',
    alignItems: 'center',
  },
  chosenSite: {
    fontSize: theme.typography.h5.fontSize,
    lineHeight: theme.typography.h5.lineHeight,
    fontWeight: theme.typography.h5.fontWeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    '&:focus': {
      backgroundColor: 'white',
    },
  },
}));

export default function TopBar(props) {
  const classes = useStyles();
  const { t } = useTranslation(['main']);
  const { onSignout, signedInState } = props;
  const dispatch = useDispatch();

  const [roles, setRoles] = useState(null);

  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['site']); //'cookies' is never used, but needs to be declared to access the setCookie function

  const view = useSelector((state) => state.viewReducer.selectedView);
  const availableSites = useSelector((state) => state.siteReducer.availableSites);
  const selectedSiteId = useSelector((state) => state.siteReducer.selectedSiteId);

  useEffect(() => {
    const userGroups = PermissionCheck.getUserGroups();

    if (userGroups) {
      setRoles(userGroups.map((role) => role?.usergrouproles[0]?.role));
    }
  }, []);

  function ListItemLink(item) {
    const { icon, primary, to } = item;

    const renderLink = React.useMemo(
      () => React.forwardRef((itemProps, ref) => <NavLink to={to} innerRef={ref} {...itemProps} />),
      [to],
    );

    return (
      <ListItem component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    );
  }

  const handleChangeSite = (event) => {
    setCookie('site', event.target.value);
    dispatch(selectSite(event.target.value));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        {view === 'farmer' && signedInState === 'signedIn' && (
          <div>
            {availableSites.length === 1 && (
              <Typography variant='h5'>{availableSites[0].name}</Typography>
            )}
            {availableSites.length > 1 && selectedSiteId && (
              <FormControl>
                <Select
                  classes={{ select: classes.chosenSite }}
                  value={selectedSiteId}
                  onChange={handleChangeSite}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  input={<Input disableUnderline={true} name='site' />}
                  IconComponent={KeyboardArrowDown}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                >
                  {availableSites.map((site) => {
                    return (
                      <MenuItem key={site.siteid} value={site.siteid}>
                        {site.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </div>
        )}
        {signedInState === 'signedIn' && (
          <div>
            <FormControl classes={{ root: classes.formControl }}>
              <Select
                value={localStorage.getItem('name')}
                name='user'
                renderValue={() => (
                  <div className={classes.avatarAndName}>
                    {roles && (
                      <img src={getAvatar(roles)} alt='avatar' className={classes.avatar} />
                    )}
                    <Typography variant='h5'>{localStorage.getItem('name')}</Typography>
                  </div>
                )}
                input={<Input disableUnderline={true} name='name' />}
                IconComponent={KeyboardArrowDown}
                classes={{
                  select: classes.select,
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem
                  disableGutters={true}
                  value={localStorage.getItem('name')}
                  className={classes.menuItemUser}
                  classes={{
                    selected: classes.select,
                  }}
                >
                  <ListItemLink
                    color='secondary'
                    to='/settings'
                    primary={t('navigation:Settings')}
                    icon={<SettingsIcon />}
                  />
                </MenuItem>
                <MenuItem className={classes.menuItemUser}>
                  <NavLink to={'/login'} className={classes.menuItemUser}>
                    <ListItem
                      button
                      key={'logout'}
                      onClick={() => {
                        onSignout();
                      }}
                    >
                      <ListItemIcon>
                        <InputIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('navigation:Logout')} />
                    </ListItem>
                  </NavLink>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        )}

        <div>
          <SelectLanguageFlag />
        </div>
      </div>
    </div>
  );
}

TopBar.propTypes = {
  onSignout: PropTypes.func,
  signedInState: PropTypes.string.isRequired,
};
