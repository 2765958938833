import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { openSnackbar } from '../../../../../actions/mainActions';
import { useDispatch } from 'react-redux';

import { Accordion, AccordionSummary, AccordionDetails, Typography, Card } from '@material-ui/core';
import PieChart from '../../../../PieChart';
import { BacteriaDataClient } from '../../../../../Services/Utility/WebApiClient';

const useStyles = makeStyles({
  bactOnSiteWrapper: {
    border: '1px solid #979797',
  },
  expanPanel: {
    padding: '0 1rem 0 1rem',
  },
  headerText: {
    float: 'left',
    marginRight: '0.4rem',
  },
  expanPanelContent: {
    padding: '0 1rem 1rem 1rem',
  },
  pieWrapper: {
    width: '100%',
    minHeight: '400px',
  },
});

export default function SiteBacteriasCard(props) {
  const { t } = useTranslation(['samplesDetails']);
  const classes = useStyles();
  const { siteId } = props;
  const [siteBacterias, setSiteBacterias] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const siteObject = {
      siteId: siteId,
    };
    async function fetchSiteBacterias() {
      const fetchBacterias = await BacteriaDataClient.GetBacteriaData(siteObject);
      if (fetchBacterias instanceof Error) {
        dispatch(openSnackbar('warning', 'fetchSiteBacteriasError'));
      } else {
        setSiteBacterias(fetchBacterias);
      }
    }
    fetchSiteBacterias();
  }, [dispatch, siteId]);

  return (
    <Card className={classes.bactOnSiteWrapper}>
      <Accordion>
        <AccordionSummary className={classes.expanPanel} expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.headerText} variant='subtitle1'>
            {t('siteBacteriasCard:bacteriasOnSite')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expanPanelContent}>
          <div className={classes.pieWrapper}>
            <PieChart data={siteBacterias}></PieChart>
          </div>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}

SiteBacteriasCard.propTypes = {
  siteId: PropTypes.string.isRequired,
};
