import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../../actions/mainActions';
import { BacticamSiteSettingsClient } from '../../Services/Utility/WebApiClient';
import { Paper, Typography, TextField, Button, makeStyles, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '1rem 0',
  },
  titlePaper: {
    textAlign: 'center',
    marginBottom: '0.6rem',
  },
  siteForm: {
    display: 'flex',
    flexDirection: 'column',
    '&>div': {
      marginTop: '1.5rem',
    },
  },
  siteFormWrapper: {
    padding: '2rem',
    marginBottom: '2rem',
  },
  siteFormWrapperMobile: {
    padding: '1rem',
  },
  addressForm: {
    display: 'flex',
    flexDirection: 'column',
    '&>div+div': {
      marginTop: '1rem',
    },
  },
  addressLabel: {
    position: 'absolute',
    background: 'white',
    border: '4px solid white',
    top: '-1rem',
    left: '1.3rem',
  },
  forms: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '5px',
    padding: '1.5rem',
    position: 'relative',
    marginTop: '1rem',
  },
  saveSiteSettings: {
    marginTop: '2rem',
    alignSelf: 'baseline',
  },
  subtitle: {
    marginBottom: theme.spacing(0.75),
  },
}));

function mapEmailsToRequestBody(mailObject) {
  let mailString = '';

  Object.values(mailObject).forEach((mail) => {
    if (mail) {
      mailString += `${mailString.length ? ';' : ''}${mail}`;
    }
  });

  return mailString;
}

export default function SiteSettings() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(['settings']);
  const isMobile = useMediaQuery('(max-width:1160px)');

  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [PPN, setPPN] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [farmerEmail, setFarmerEmail] = useState({ mail1: '', mail2: '' });
  const [vetEmail, setVetEmail] = useState({ mail1: '', mail2: '' });
  const [currentSiteSettings, setCurrentSiteSettings] = useState(null);
  const [isLoadingSiteSettings, setIsLoadingSiteSettings] = useState(false);

  const siteId = useSelector((state) => state.siteReducer.selectedSiteId);

  const fetchSiteSettings = useCallback(
    async (siteId) => {
      const siteSettings = await BacticamSiteSettingsClient.GetSiteSettings({ siteId: siteId });

      if (siteSettings instanceof Error) {
        dispatch(openSnackbar('warning', 'fetchSiteSettingsError'));
      } else {
        setCurrentSiteSettings({
          mail1: '',
          mail2: '',
          veterinarianEmail1: '',
          veterinarianEmail2: '',
          addressLine1: siteSettings.addressLine1,
          addressLine2: siteSettings.addressLine2,
          registrationNumber: siteSettings.registrationNumber || '',
          zipCode: siteSettings.zipCode,
          city: siteSettings.city,
        });
        setAddress1(siteSettings.addressLine1);
        setAddress2(siteSettings.addressLine2);
        setPPN(siteSettings.registrationNumber || '');
        setZipCode(siteSettings.zipCode);
        setCity(siteSettings.city);

        const farmerEmails = siteSettings.mail.split(';');
        const vetEmails = siteSettings.veterinarianEmail.split(';');

        for (let i = 1; i <= farmerEmails.length; i++) {
          setFarmerEmail((prev) => ({
            ...prev,
            [`mail${i}`]: farmerEmails[i - 1],
          }));
          setCurrentSiteSettings((prev) => ({
            ...prev,
            [`mail${i}`]: farmerEmails[i - 1],
          }));
        }

        for (let i = 1; i <= vetEmails.length; i++) {
          setVetEmail((prev) => ({
            ...prev,
            [`mail${i}`]: vetEmails[i - 1],
          }));
          setCurrentSiteSettings((prev) => ({
            ...prev,
            [`veterinarianEmail${i}`]: vetEmails[i - 1],
          }));
        }
      }
      setIsLoadingSiteSettings(false);
    },
    [dispatch],
  );

  const resetForm = useCallback(() => {
    setAddress1('');
    setAddress2('');
    setPPN('');
    setZipCode('');
    setCity('');
    setFarmerEmail({ mail1: '', mail2: '' });
    setVetEmail({ mail1: '', mail2: '' });
  }, []);

  useEffect(() => {
    if (siteId) {
      setIsLoadingSiteSettings(true);
      fetchSiteSettings(siteId);
    }
    return () => {
      resetForm();
    };
  }, [siteId, dispatch, fetchSiteSettings, resetForm]);

  const formIsEdited = useCallback(() => {
    if (currentSiteSettings) {
      if (
        currentSiteSettings.addressLine1 !== address1 ||
        currentSiteSettings.addressLine2 !== address2 ||
        (PPN && currentSiteSettings.registrationNumber !== parseInt(PPN)) ||
        (!PPN && currentSiteSettings.registrationNumber) ||
        currentSiteSettings.zipCode !== zipCode ||
        currentSiteSettings.city !== city ||
        currentSiteSettings.mail1 !== farmerEmail.mail1 ||
        currentSiteSettings.mail2 !== farmerEmail.mail2 ||
        currentSiteSettings.veterinarianEmail1 !== vetEmail.mail1 ||
        currentSiteSettings.veterinarianEmail2 !== vetEmail.mail2
      ) {
        return true;
      } else {
        return false;
      }
    }
  }, [currentSiteSettings, address1, address2, PPN, zipCode, city, farmerEmail, vetEmail]);

  const handleSaveSiteData = async (event) => {
    event.preventDefault();

    const requestBody = {
      siteId: siteId,
      addressLine1: address1,
      addressLine2: address2,
      mail: mapEmailsToRequestBody(farmerEmail),
      registrationNumber: PPN,
      veterinarianEmail: mapEmailsToRequestBody(vetEmail),
      zipCode: zipCode,
      city: city,
      country: currentSiteSettings.country || 'Sweden',
    };

    await BacticamSiteSettingsClient.PutSiteSettings(requestBody).then((response) => {
      if (response instanceof Error) {
        dispatch(openSnackbar('warning', 'editFailed'));
      } else {
        fetchSiteSettings(siteId);
        dispatch(openSnackbar('success', 'siteSettingsSaved'));
      }
    });
  };

  return (
    <>
      <Paper className={classes.titlePaper} elevation={2}>
        <Typography variant='h4' className={classes.title}>
          {t('siteSettings')}
        </Typography>
      </Paper>
      <Paper
        className={isMobile ? classes.siteFormWrapperMobile : classes.siteFormWrapper}
        elevation={2}
      >
        <form className={classes.siteForm} onSubmit={handleSaveSiteData}>
          <div className={classes.forms}>
            <Typography className={classes.addressLabel}>Adress</Typography>
            <div className={classes.addressForm}>
              <TextField
                label={t('addressRow1')}
                value={address1}
                onChange={(e) => {
                  setAddress1(e.target.value);
                }}
              />
              <TextField
                label={t('addressRow2')}
                value={address2}
                onChange={(e) => {
                  setAddress2(e.target.value);
                }}
              />
              <TextField
                label={t('zipCode')}
                value={zipCode}
                onChange={(e) => {
                  setZipCode(e.target.value);
                }}
              />
              <TextField
                label={t('city')}
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </div>
          </div>
          <TextField
            label={t('ppn')}
            type='number'
            value={PPN}
            onChange={(e) => {
              setPPN(e.target.value);
            }}
          />
          <TextField
            label={`${t('emailFarmer')} 1`}
            value={farmerEmail.mail1}
            type='email'
            onChange={(e) => {
              setFarmerEmail((prev) => ({ ...prev, mail1: e.target.value }));
            }}
          />
          <TextField
            label={`${t('emailFarmer')} 2`}
            value={farmerEmail.mail2}
            type='email'
            onChange={(e) => {
              setFarmerEmail((prev) => ({ ...prev, mail2: e.target.value }));
            }}
          />
          <TextField
            label={`${t('emailVet')} (${t('optionally')})`}
            value={vetEmail.mail1}
            type='email'
            onChange={(e) => {
              setVetEmail((prev) => ({ ...prev, mail1: e.target.value }));
            }}
          />
          <TextField
            label={`${t('emailExtra')} (${t('optionally')})`}
            value={vetEmail.mail2}
            type='email'
            onChange={(e) => {
              setVetEmail((prev) => ({ ...prev, mail2: e.target.value }));
            }}
          />
          <Button
            variant='contained'
            color='primary'
            disableElevation
            size='large'
            disabled={!formIsEdited() || isLoadingSiteSettings}
            className={classes.saveSiteSettings}
            type='submit'
          >
            {t('saveSettings')}
          </Button>
        </form>
      </Paper>
    </>
  );
}
