import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, makeStyles, Typography, CircularProgress, Fade } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import icon from '../../../../../icons/missing-image.png';
import { enums } from '../../../../../Services/Utility/Enumerations';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { differenceInHours } from 'date-fns';

const useStyles = makeStyles({
  wrapper: {
    borderRadius: '7px',
  },
  card: {
    borderRight: '1px solid #979797',
    borderBottom: '1px solid #979797',
    position: 'relative',
    borderRadius: '0 0 7px 0',
  },
  cardMobile: {
    borderRadius: '7px',
  },
  imagePair: {
    display: 'flex',
  },
  imageRowMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageContainer: {
    position: 'relative',
    width: '50%',
    minHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  isLoading: {
    backgroundColor: '#000000',
  },
  imageContainerMobile: {
    position: 'relative',
    width: '100%',
    maxWidth: '650px',
    minHeight: '300px',
    backgroundColor: '#000000',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyImage: {
    padding: '0.3rem',
    cursor: 'not-allowed',
    backgroundColor: '#ffffff !important',
  },
  emptyImageTextContainer: {
    position: 'absolute',
    top: '0',
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyImageText: {
    fontSize: 'calc(1rem + 1vmin)',
    color: '#7e7e7e',
  },
  buttonContainer: {
    position: 'absolute',
    bottom: '0',
    margin: '0.6rem',
    backgroundColor: '#ffffff',
    border: '3px solid #009D59',
    borderRadius: '7px',
  },
  buttonContainerMobile: {
    border: '3px solid #009D59',
    borderRadius: '7px',
  },
  buttonWrapperMobile: {
    position: 'relative',
    height: '2.3rem',
    margin: '0.5rem 0 1rem',
    display: 'flex',
    justifyContent: 'center',
  },
  imagesTitle: {
    padding: '1rem 1rem 0 1rem',
  },
  imageButtonNotSelected: {
    backgroundColor: 'inherit',
    color: '#bdbdbd',
  },
});

export default function BactImageCard(props) {
  const { imagePairs, sampleCreatedAt } = props;
  const { t } = useTranslation(['samplesDetails']);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const classes = useStyles();
  const sampleCreated = new Date(sampleCreatedAt);
  const [imagePairIndexDisplayed, setImagePairIndexDisplayed] = useState(0);
  const [loadingImages, setLoadingImages] = useState([true, true]);

  const changeLoadingState = useCallback((index, loadingBool) => {
    setLoadingImages((loadingImages) => {
      const newState = loadingImages.map((currentValue, arrayIndex) =>
        arrayIndex === index ? loadingBool : currentValue,
      );
      return newState;
    });
  }, []);

  useEffect(() => {
    setImagePairIndexDisplayed(imagePairs.length - 1);
  }, [imagePairs]);

  useEffect(() => {
    function checkIfImageAlreadyIsDone(index) {
      if (
        document.getElementById(`image${index}`) &&
        document.getElementById(`image${index}`).complete
      ) {
        changeLoadingState(index, false);
      } else {
        changeLoadingState(index, true);
      }
    }
    checkIfImageAlreadyIsDone(0);
    checkIfImageAlreadyIsDone(1);
  }, [imagePairs, imagePairIndexDisplayed, changeLoadingState]);

  function calculatePhotoShoot(imagePair) {
    const photoShoot = new Date(imagePair.createdAt);
    return differenceInHours(photoShoot, sampleCreated);
  }

  function singleImage(image, index) {
    const isLoading = loadingImages[index];

    return (
      <div
        className={
          isMobile
            ? classes.imageContainerMobile
            : isLoading
            ? `${classes.isLoading} ${classes.imageContainer}`
            : classes.imageContainer
        }
      >
        <img
          id={`image${index}`}
          onLoad={() => changeLoadingState(index, false)}
          src={image.large ? image.large : icon}
          alt='sampleImage'
          width='100%'
          height='auto'
          className={image === icon ? classes.emptyImage : ''}
          style={{
            filter: isLoading ? 'blur(1.5rem)' : '',
            display: isLoading ? 'none' : 'block',
          }}
        ></img>

        {isLoading && (
          <Fade
            in={isLoading}
            style={{
              transitionDelay: isLoading ? '600ms' : '0ms',
            }}
            unmountOnExit
          >
            <CircularProgress color='primary' size='5rem' />
          </Fade>
        )}

        {image === icon && (
          <div className={classes.emptyImageTextContainer}>
            <Typography className={classes.emptyImageText}>
              {t('bactImageCard:missingImage')}
            </Typography>
          </div>
        )}
      </div>
    );
  }

  function twoPictures() {
    if (!imagePairs[imagePairIndexDisplayed]) {
      return <></>;
    }

    const pictures = imagePairs[imagePairIndexDisplayed].images;

    const firstPicture = pictures.find((pic) => pic.lightMode === enums.lightMode.under);
    const secondPicture = pictures.find((pic) => pic.lightMode === enums.lightMode.over);

    return (
      <div className={isMobile ? classes.imageRowMobile : classes.imagePair}>
        {singleImage(firstPicture || icon, 0)}
        {singleImage(secondPicture || icon, 1)}
      </div>
    );
  }

  const buttons = imagePairs.map((imagePair) => {
    const thisButtonChosen = imagePairIndexDisplayed === imagePairs.indexOf(imagePair);
    return (
      <Button
        variant='contained'
        size='small'
        disableElevation={true}
        color={thisButtonChosen ? 'primary' : 'inherit'}
        style={{ zIndex: thisButtonChosen ? '2' : '1', outline: 'none' }}
        className={thisButtonChosen ? '' : classes.imageButtonNotSelected}
        key={imagePair.createdAt}
        onClick={() => setImagePairIndexDisplayed(imagePairs.indexOf(imagePair))}
      >
        {t('bactImageCard:afterHours', { hours: calculatePhotoShoot(imagePair) })}
      </Button>
    );
  });

  return (
    <div className={classes.wrapper}>
      {!isMobile && (
        <Card className={classes.card}>
          {twoPictures()}
          <div className={classes.buttonContainer}>{buttons}</div>
        </Card>
      )}

      {isMobile && (
        <Card className={classes.cardMobile}>
          <div>
            <Typography variant='subtitle1' className={classes.imagesTitle}>
              {t('bactImageCard:images')}
            </Typography>
            <div className={classes.buttonWrapperMobile}>
              <div className={classes.buttonContainerMobile}>{buttons}</div>
            </div>
          </div>
          {twoPictures()}
        </Card>
      )}
    </div>
  );
}

BactImageCard.propTypes = {
  imagePairs: PropTypes.array.isRequired,
  sampleCreatedAt: PropTypes.string.isRequired,
};
