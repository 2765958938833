import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip, useMediaQuery, ClickAwayListener } from '@material-ui/core';
import { differenceInHours } from 'date-fns';
import {
  arePreliminaryImagesCorrect,
  areFinalImagesCorrect,
  isSampleFailedOnFinal,
  isSampleFailedOnPreliminary,
  hasPreliminaryResult,
  hasFinalResult,
} from '../../../../../../Services/Utility/SampleInformation';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import GroupedTooltip from '../GroupedTooltip';
import StatusIcon from '../StatusIcon';

const useStyles = makeStyles({
  hover: {
    '&:hover': { opacity: '.8' },
  },
  cameraContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '3.5rem',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '3.5rem',
    padding: '0 0.24rem',
  },
  photoshootWaiting: {
    color: '#DADADA',
  },
  photoshootExpected: {
    color: '#2C98F0',
  },
  photoshootReceived: {
    color: '#8D8D8D',
  },
  photoshootNotReceived: {
    color: '#D10505',
  },
  photoshootIncorrect: {
    color: '#FFC672',
  },
  tooltipLabel: {
    fontSize: '0.8rem',
  },
  cameraWrapper: {
    marginLeft: '1.4rem',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '3rem',
    marginTop: '1.5rem',
  },
  mobileCameraWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '4rem',
  },
  groupedTooltipStyle: {
    color: '#7A7A7A',
    backgroundColor: '#F4F4F4',
    boxShadow: '0px 0px 22px #888888',
    minWidth: '20rem',
  },
  groupedTooltipArrowStyle: {
    color: '#F4F4F4',
  },
  tooltipzIndex: {
    zIndex: 1000,
  },
  tooltipPosition: {
    margin: '0.8rem -0.25rem',
  },
});

export function PhotoStatus(props) {
  const { sample } = props;
  const { t } = useTranslation(['samplesFarmer']);
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const sampleCreated = new Date(sample.createdAt);
  const now = new Date();
  const cultivationHours = differenceInHours(now, sampleCreated);
  const dayOnePhotoReady = cultivationHours >= 18 && cultivationHours < 36;
  const dayTwoPhotoReady = cultivationHours >= 36 && cultivationHours < 72;
  const [showTooltip, setShowTooltip] = useState(false);

  const firstPhotoShootStatus = getFirstPhotoshootStatus();
  const secondPhotoShootStatus = getSecondPhotoshootStatus();
  const firstPhotoShootInfo = getFirstPhotoShootInfo();
  const secondPhotoShootInfo = getSecondPhotoShootInfo();

  /**
   * Decide which camera icon and informative text that belong to the first photoshoot.
   */
  function getFirstPhotoshootStatus() {
    let text = '';

    // If preliminary images have been created, check if they were created in time and if they are correct.
    if (sample.preliminaryImagesCreatedAt) {
      if (arePreliminaryImagesCorrect(sample)) {
        text = t('photoStatusTooltips:photoshootOneReceived');

        return { camera: getCamera(classes.photoshootReceived), text: text };
      } else {
        text = t('photoStatusTooltips:photoshootIncorrect');

        return { camera: getCamera(classes.photoshootIncorrect), text: text };
      }
    } else {
      // If no preliminary images has been created, check the cultivation status of the sample.
      if (cultivationHours <= 18) {
        text = t('photoStatusTooltips:photoshootOneWaiting');
        return { camera: getCamera(classes.photoshootWaiting), text: text };
      }
      if (dayOnePhotoReady) {
        text = t('photoStatusTooltips:photoshootOneExpected');

        return { camera: getCamera(classes.photoshootExpected), text: text };
      } else {
        text = t('photoStatusTooltips:photoshootOneNotReceived');

        return { camera: getCamera(classes.photoshootNotReceived), text: text };
      }
    }
  }

  /**
   * Decide which camera icon and informative text that belong to the second photoshoot.
   */
  function getSecondPhotoshootStatus() {
    let text = '';

    // If final images have been created, check if they were created in time and if they are correct.
    if (sample.finalImagesCreatedAt) {
      const finalImagesCreatedAfterSampleRegistration = differenceInHours(
        new Date(sample.finalImagesCreatedAt),
        new Date(sample.createdAt),
      );
      if (
        finalImagesCreatedAfterSampleRegistration >= 36 &&
        finalImagesCreatedAfterSampleRegistration <= 72
      ) {
        if (areFinalImagesCorrect(sample)) {
          text = t('photoStatusTooltips:photoshootTwoReceived');

          return { camera: getCamera(classes.photoshootReceived), text: text };
        } else {
          text = t('photoStatusTooltips:photoshootIncorrect');

          return { camera: getCamera(classes.photoshootIncorrect), text: text };
        }
      } else if (finalImagesCreatedAfterSampleRegistration > 72) {
        text = t('photoStatusTooltips:photoshootTwoLate');

        return { camera: getCamera(classes.photoshootReceived), text: text };
      }
    } else {
      // If no final images has been created, check the cultivation status of the sample.
      if (cultivationHours < 36) {
        text = t('photoStatusTooltips:photoshootTwoWaiting');

        return { camera: getCamera(classes.photoshootWaiting), text: text };
      }
      if (dayTwoPhotoReady) {
        text = t('photoStatusTooltips:photoshootTwoExpected');

        return { camera: getCamera(classes.photoshootExpected), text: text };
      } else {
        text = t('photoStatusTooltips:photoshootTwoNotReceived');

        return { camera: getCamera(classes.photoshootNotReceived), text: text };
      }
    }

    return { camera: null, text: null };
  }

  /**
   * Decide if a symbol w/ tooltip should be displayed under the first camera.
   */
  function getFirstPhotoShootInfo() {
    let text = '';

    if (hasPreliminaryResult(sample)) {
      text = t('photoStatusTooltips:preliminaryResultAvailable');

      return { icon: getStatusIcon('resultsAvailable'), text: text };
    } else if (isSampleFailedOnPreliminary(sample) || cultivationHours >= 36) {
      text = t('photoStatusTooltips:preliminaryResultCanNotBeSet');

      return { icon: getStatusIcon('error'), text: text };
    }

    return { icon: <div></div>, text: text };
  }

  /**
   * Decide if a symbol w/ tooltip should be displayed under the second camera.
   */
  function getSecondPhotoShootInfo() {
    let text = '';
    if (hasFinalResult(sample)) {
      text = t('photoStatusTooltips:finalResultAvailable');

      return { icon: getStatusIcon('resultsAvailable'), text: text };
    } else if (isSampleFailedOnFinal(sample) || cultivationHours > 72) {
      text = t('photoStatusTooltips:finalResultCanNotBeSet');

      return { icon: getStatusIcon('error'), text: text };
    }

    return { icon: <></>, text: text };
  }

  /**
   * Returns the requested camera icon.
   * @param {object} cameraType - Style class to decide color of the camera icon
   */
  function getCamera(cameraType) {
    const camera = (
      <div>
        <CameraAltIcon className={cameraType} />
      </div>
    );

    return camera;
  }

  /**
   * Returns the requested status icon.
   * @param {object} iconType - Style class to decide color and shape of the status icon
   */
  function getStatusIcon(iconType) {
    const icon = (
      <div>
        <StatusIcon iconType={iconType} />
      </div>
    );

    return icon;
  }

  /**
   * Creates a tooltip for a given icon/element
   * @param {Object} icon - Icon/element we want to assign a tooltip
   * @param {string} text - Text to be displayed in the tooltip
   * @param {string} placement - Placement of tooltip relative to the icon
   */
  function createTooltip(icon, text, placement) {
    return (
      <Tooltip
        title={text}
        placement={placement}
        classes={{ tooltip: classes.tooltipLabel }}
        arrow
        PopperProps={{
          disablePortal: true,
          popperOptions: {
            positionFixed: true,
            modifiers: {
              preventOverflow: {
                enabled: true,
                boundariesElement: 'window',
              },
            },
          },
        }}
      >
        <div className={classes.hover}>{icon}</div>
      </Tooltip>
    );
  }

  if (isMobile) {
    return (
      <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
        <Tooltip
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={showTooltip}
          onClick={() => setShowTooltip(!showTooltip)}
          title={
            <GroupedTooltip
              firstPhotoShootStatus={firstPhotoShootStatus}
              secondPhotoShootStatus={secondPhotoShootStatus}
              firstPhotoShootInfo={firstPhotoShootInfo}
              secondPhotoShootInfo={secondPhotoShootInfo}
            />
          }
          PopperProps={{
            disablePortal: true,
          }}
          placement='top'
          arrow
          classes={{
            tooltip: classes.groupedTooltipStyle,
            arrow: classes.groupedTooltipArrowStyle,
            popper: classes.tooltipzIndex,
            tooltipPlacementTop: classes.tooltipPosition,
            tooltipPlacementBottom: classes.tooltipPosition,
          }}
        >
          <div className={classes.mobileCameraWrapper}>
            <div className={classes.cameraContainer}>
              {firstPhotoShootStatus.camera}
              {secondPhotoShootStatus.camera}
            </div>
            <div className={classes.iconContainer}>
              {firstPhotoShootInfo.icon}
              {secondPhotoShootInfo.icon}
            </div>
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  } else
    return (
      <div className={classes.cameraWrapper}>
        <div className={classes.cameraContainer}>
          {createTooltip(firstPhotoShootStatus.camera, firstPhotoShootStatus.text, 'top-end')}
          {createTooltip(secondPhotoShootStatus.camera, secondPhotoShootStatus.text, 'top-start')}
        </div>
        <div className={classes.iconContainer}>
          {createTooltip(firstPhotoShootInfo.icon, firstPhotoShootInfo.text, 'bottom-end')}
          {createTooltip(secondPhotoShootInfo.icon, secondPhotoShootInfo.text, 'bottom-start')}
        </div>
      </div>
    );
}

PhotoStatus.propTypes = {
  sample: PropTypes.object.isRequired,
};
