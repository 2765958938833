import React, { useCallback, useEffect, useState } from 'react';
import FarmerSampleView from './FarmerSampleView';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../Modal';
import FarmerDetails from '../Details/FarmerDetails';
import MobileFarmerDetails from '../Details/MobileFarmerDetails';
import { openSnackbar } from '../../../actions/mainActions';
import {
  filterActiveSamples,
  getSamplesToVerify,
} from '../../../Services/Utility/SampleInformation';
import { useFarmerSamples } from '../../../Services/Utility/CustomHooks/useFetch';
import InfoBar from '../../Main/InfoBar';
import FarmerInfoDialog from './FarmerInfoDialog';
import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import ExportCowsDialog from '../../ExportCows/ExportCowsDialog';
import { SamplesClient } from '../../../Services/Utility/WebApiClient';

export default function FarmerSampleContainer(props) {
  const { selectedSiteId } = props;
  const isMobile = useMediaQuery('(max-width:1160px)');
  const [loadingActiveSamples, setLoadingActiveSamples] = useState(true);
  const [allActiveSamples, setAllActiveSamples] = useState([]);
  const [activeSamples, setActiveSamples] = useState([]);
  const [filteredActiveSamples, setFilteredActiveSamples] = useState([]);
  const [samplesToVerify, setSamplesToVerify] = useState([]);
  const [page, setPage] = useState(1);
  const [cowNumber, setCowNumber] = useState('');
  const [orderBy, setOrderBy] = useState('latestSample');
  const [order, setOrder] = useState('descending');
  const [queryParams, setQueryParams] = useState({
    page: page,
    cowNumber: cowNumber,
    orderBy: orderBy,
    order: order,
  });
  const [isUpdatingFarmerVerificationCard, setIsUpdatingFarmerVerificationCard] = useState(false);

  const modalOpen = useSelector((state) => state.modalReducer.isOpen);
  const view = useSelector((state) => state.modalReducer.view);
  const exportDialogIsOpen = useSelector((state) => state.exportCowsReducer.isOpen);
  const dispatch = useDispatch();

  const { samples, isLoadingSamples } = useFarmerSamples({
    siteId: selectedSiteId,
    page: queryParams.page,
    cowNumber: queryParams.cowNumber,
    orderBy: queryParams.orderBy,
    order: queryParams.order,
  });

  /**
   * When the user browse 'All samples' using the forward and backward button in
   * FarmerSampleview, the page state is updated.
   */
  const onPageUpdated = useCallback((pageNumber) => {
    setPage(pageNumber);
  }, []);

  /**
   * Callback to be called by the status filter component when the filter selection changes
   * and in turn let this component update the statusId state.
   */
  const onStatusFilterUpdated = useCallback(
    (newStatuses) => {
      const filteredActiveSamples = filterActiveSamples(activeSamples, newStatuses);
      setFilteredActiveSamples(filteredActiveSamples);
    },
    [activeSamples],
  );

  /**
   * Callback to be called by FarmerSampleView when sorting options are applied by the user,
   * which updates the states orderBy and order, found in queryParams.
   */
  const onOrderOptionUpdated = useCallback((orderBy, order) => {
    setPage(1); // switch to page 1 before applying the sorting option
    setOrderBy(orderBy);
    setOrder(order ? 'descending' : 'ascending');
  }, []);

  /**
   * Callback to be called by the search bar component when the query search changes
   * to let us update our cowNumber state
   */
  const onSearchTextUpdated = useCallback((query) => {
    setPage(1); // switch to page 1 before applying the search filter
    setCowNumber(query);
  }, []);

  useEffect(() => {
    setQueryParams({ page: page, cowNumber: cowNumber, orderBy: orderBy, order: order });
  }, [page, cowNumber, orderBy, order]);

  useEffect(() => {
    async function fetchActiveSamples() {
      const fetchedSamples = await SamplesClient.GetActiveSamplesForSite({
        siteId: selectedSiteId,
      });

      if (fetchedSamples instanceof Error) {
        dispatch(openSnackbar('warning', 'somethingWentWrong'));
      } else {
        setAllActiveSamples(fetchedSamples);

        // Find active samples that need to be verified
        setSamplesToVerify(getSamplesToVerify(fetchedSamples));

        // If there are active samples that need to be verified, make sure they are not also in the list of active samples.
        // Also remove subjects that end up with no samples after filtering samples.
        setActiveSamples(
          fetchedSamples
            .map((cow) => {
              return {
                subject: cow.subject,
                lastActivity: cow.lastActivity,
                samples: cow.samples.filter(
                  (sample) => !getSamplesToVerify(fetchedSamples).includes(sample),
                ),
              };
            })
            .filter((cow) => cow.samples.length > 0),
        );
      }
      setLoadingActiveSamples(false);
    }
    setLoadingActiveSamples(true);

    fetchActiveSamples();
  }, [selectedSiteId, dispatch]);

  // Refetch the sample after a change has been made on the sample and also update the list of active samples
  async function updateFarmerSample(component, sampleId) {
    const fetchSample = await SamplesClient.GetSample(sampleId);

    if (component === 'farmerVerificationCard') {
      setIsUpdatingFarmerVerificationCard(true);
    }

    if (fetchSample instanceof Error) {
      dispatch(openSnackbar('warning', 'somethingWentWrong'));
      setIsUpdatingFarmerVerificationCard(false);
    } else if (fetchSample) {
      const samplesCopy = [].concat(allActiveSamples);

      for (const samplesPerCow of samplesCopy) {
        const index = samplesPerCow.samples.findIndex((sample) => sample.sampleId === sampleId);

        if (index !== -1) {
          samplesPerCow.samples.splice(index, 1, fetchSample);
          break;
        }
      }
      setSamplesToVerify(getSamplesToVerify(samplesCopy));

      setActiveSamples(
        samplesCopy
          .map((cow) => {
            return {
              subject: cow.subject,
              lastActivity: cow.lastActivity,
              samples: cow.samples.filter(
                (sample) => !getSamplesToVerify(samplesCopy).includes(sample),
              ),
            };
          })
          .filter((cow) => cow.samples.length > 0),
      );
      setIsUpdatingFarmerVerificationCard(false);
    }
  }

  return (
    <>
      {false && <InfoBar />}
      <FarmerSampleView
        activeSamples={filteredActiveSamples}
        loadingActiveSamples={loadingActiveSamples}
        samplesToVerify={samplesToVerify}
        samples={samples}
        loadingSamples={isLoadingSamples}
        onStatusFilterUpdated={onStatusFilterUpdated}
        onOrderOptionUpdated={onOrderOptionUpdated}
        onSearchTextUpdated={onSearchTextUpdated}
        onPageUpdated={onPageUpdated}
        cowNumber={cowNumber}
        noActiveSamples={activeSamples?.length === 0}
      />
      {modalOpen && view === 'farmer' && (
        <Modal>
          {isMobile ? (
            <MobileFarmerDetails
              updateFarmerSample={updateFarmerSample}
              isUpdatingFarmerVerificationCard={isUpdatingFarmerVerificationCard}
            />
          ) : (
            <FarmerDetails
              updateFarmerSample={updateFarmerSample}
              isUpdatingFarmerVerificationCard={isUpdatingFarmerVerificationCard}
            />
          )}
        </Modal>
      )}
      <FarmerInfoDialog />
      {!isMobile && exportDialogIsOpen && <ExportCowsDialog />}
    </>
  );
}

FarmerSampleContainer.propTypes = {
  selectedSiteId: PropTypes.string.isRequired,
};
