import farmer from '../../../icons/farmer.png';
import vet from '../../../icons/Vet.png';
import bacteriologist from '../../../icons/bacteriologist.png';
import AvatarRoleCheck from '../../../Services/AvatarRoleCheck';

export function getAvatar(roles) {
  const avatar = AvatarRoleCheck.isBacteriologist(roles)
    ? bacteriologist
    : AvatarRoleCheck.isVeterinarian(roles)
    ? vet
    : farmer;
  return avatar;
}
