import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Card,
  Typography,
  Button,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { openPDFDialog, openSnackbar } from '../../../../../actions/mainActions';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PropTypes from 'prop-types';
import { enums } from '../../../../../Services/Utility/Enumerations';
import { SampleLabFlagClient } from '../../../../../Services/Utility/WebApiClient';

const useStyles = makeStyles((theme) => ({
  verifyWrapper: {
    padding: '1rem',
  },
  verifyWrapperDesktop: {
    border: '1px solid #E8E8E8',
  },
  markedAsDoneWrapper: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.35em',
    '&>svg': {
      marginRight: '0.25rem',
    },
  },
  notMarkedAsDoneColor: {
    color: theme.palette.secondary.main,
  },
  markedAsDoneColor: {
    color: theme.palette.primary.main,
  },
  infoText: {
    color: '#8B8B8B',
  },
  mobileButtonWrapper: {
    display: 'flex',
  },
  button: {
    '&>span': {
      whiteSpace: 'noWrap',
    },
  },
  leftButton: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
  rightButton: {
    marginTop: theme.spacing(1),
  },
}));

export default function FarmerVerificationCard(props) {
  const { updateSampleOnMarkAsManaged, isUpdating } = props;
  const classes = useStyles();
  const { t } = useTranslation(['samplesDetails']);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const [markedAsManaged, setMarkedAsManaged] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const view = useSelector((state) => state.viewReducer.selectedView);
  const sample = useSelector((state) => state.modalReducer.sample);

  useEffect(() => {
    if (sample && sample.labFlag === enums.labFlag.sendLocked) {
      setMarkedAsManaged(false);
    }
  }, [sample]);

  async function manageSample(lagFlagTrigger) {
    setIsLoading(true);
    const response = await SampleLabFlagClient.PutSampleLabFlag(
      sample.sampleId,
      lagFlagTrigger,
    ).then((response) => {
      setIsLoading(false);
      return response;
    });

    if (response instanceof Error) {
      dispatch(openSnackbar('warning', 'somethingWentWrong'));
    } else {
      updateSampleOnMarkAsManaged();
      setMarkedAsManaged(!markedAsManaged);
    }
  }

  function handleClick() {
    if (!markedAsManaged) {
      manageSample(enums.labFlagTrigger.farmerSetsSentToLaboratory);
    } else if (markedAsManaged) {
      manageSample(enums.labFlagTrigger.farmerUnsetsSentToLaboratory);
    }
  }

  return (
    <Card
      className={
        markedAsManaged
          ? isMobile
            ? classes.verifyWrapper
            : `${classes.verifyWrapper} ${classes.verifyWrapperDesktop}`
          : isMobile
          ? `${classes.verifyWrapper} ${classes.markedAsDoneWrapper}`
          : `${classes.verifyWrapper} ${classes.markedAsDoneWrapper} ${classes.verifyWrapperDesktop}`
      }
      elevation={isMobile ? 0 : 1}
    >
      <div
        className={
          markedAsManaged
            ? `${classes.header} ${classes.markedAsDoneColor}`
            : `${classes.header} ${classes.notMarkedAsDoneColor}`
        }
      >
        {markedAsManaged ? (
          <CheckCircleOutlineIcon size='small' />
        ) : (
          <ErrorOutlineIcon size='small' />
        )}
        <Typography variant='h5'>
          {markedAsManaged
            ? t('farmerVerificationCard:cultivationSent')
            : t('farmerVerificationCard:verificationRequired')}
        </Typography>
      </div>
      <Typography className={isMobile ? '' : classes.infoText}>
        {markedAsManaged
          ? t('farmerVerificationCard:youSentTheCultivation')
          : t('farmerVerificationCard:sendToLab')}
      </Typography>
      <div className={isMobile ? classes.mobileButtonWrapper : ''}>
        {!markedAsManaged && (
          <Button
            variant='contained'
            color='primary'
            size='small'
            disableElevation
            className={`${classes.button} ${classes.leftButton}`}
            disabled={view === 'bacteriologist'}
            onClick={() => dispatch(openPDFDialog(sample))}
          >
            {t('farmerVerificationCard:getRemittance')}
          </Button>
        )}
        <Button
          startIcon={
            (isUpdating || isLoading) && <CircularProgress color='primary' size='0.8rem' />
          }
          variant='outlined'
          color='primary'
          size='small'
          className={`${classes.button} ${classes.rightButton}`}
          disabled={isUpdating || isLoading || view === 'bacteriologist'}
          onClick={() => handleClick()}
        >
          {markedAsManaged
            ? t('farmerVerificationCard:markAsUnmanaged')
            : t('farmerVerificationCard:markAsManaged')}
        </Button>
      </div>
    </Card>
  );
}

FarmerVerificationCard.propTypes = {
  updateSampleOnMarkAsManaged: PropTypes.func,
  isUpdating: PropTypes.bool,
};
