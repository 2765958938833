import MaterialTable from '@material-table/core';
import { Paper, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BacteriaGroupsClient } from '../../../../Services/Utility/WebApiClient';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../../actions/mainActions';
import { v4 as uuidv4 } from 'uuid';
import { mapBacteriaGroupToTableData } from './helper';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '1rem',
  },
  contentWrapper: {
    overflowX: 'auto',
  },
});

export default function AddBacteriaGroup() {
  const { t } = useTranslation(['admin']);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        title: t('admin:addBacteriaGroup:name'),
        field: 'name',
      },
      {
        title: t('admin:addBacteriaGroup:nameKey'),
        field: 'nameKey',
      },
    ],
    [t],
  );

  const handleRowAdd = async (newData) => {
    if (newData.name === undefined || newData.nameKey === undefined) {
      return;
    }

    setIsLoading(true);

    await BacteriaGroupsClient.Post({
      id: uuidv4(),
      name: newData.name,
      nameKey: newData.nameKey,
    }).then((result) => {
      if (result instanceof Error) {
        setIsError(true);
      } else {
        setIsError(false);
        setData((value) => [
          ...value,
          mapBacteriaGroupToTableData({
            id: result.id,
            name: result.name,
            nameKey: result.nameKey,
          }),
        ]);

        setIsLoading(false);
      }
    });
  };

  const handleRowUpdate = async (newData, oldData) => {
    if (
      newData.name === undefined ||
      newData.nameKey === undefined ||
      (newData.name === oldData.name && newData.nameKey === oldData.nameKey)
    ) {
      return;
    }

    setIsLoading(true);

    await BacteriaGroupsClient.Put(oldData.id, {
      id: oldData.id,
      name: newData.name,
      nameKey: newData.nameKey,
    }).then((result) => {
      if (result instanceof Error) {
        setIsError(true);
      } else {
        setIsError(false);
        setData((value) => {
          const newValue = [...value];
          const index = newValue.findIndex((data) => data.id === oldData.id);

          newValue[index] = mapBacteriaGroupToTableData({
            id: oldData.id,
            name: newData.name,
            nameKey: newData.nameKey,
          });

          return newValue;
        });

        setIsLoading(false);
      }
    });
  };

  const handleRowDelete = async (oldData) => {
    if (!data.some((data) => data.id === oldData.id)) {
      return;
    }

    setIsLoading(true);

    await BacteriaGroupsClient.Delete(oldData.id).then((result) => {
      if (result instanceof Error) {
        setIsError(true);
      } else {
        setIsError(false);
        setData((value) => {
          const newValue = [...value];
          const index = newValue.findIndex((data) => data.id === oldData.id);
          newValue.splice(index, 1);

          return newValue;
        });

        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);

    BacteriaGroupsClient.GetBacteriaGroups({ orderBy: 'asc' }).then((result) => {
      if (result instanceof Error) {
        setIsError(true);
      } else {
        setIsError(false);
        setData(
          result.map((bacteriaGroup) =>
            mapBacteriaGroupToTableData({
              id: bacteriaGroup.bacteriagroupid,
              name: bacteriaGroup.name,
              nameKey: bacteriaGroup.namekey,
            }),
          ),
        );
      }

      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (isError) {
      setIsError(false);
      dispatch(openSnackbar('warning', 'somethingWentWrong'));
    }
  }, [dispatch, isError]);

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.contentWrapper}>
        <MaterialTable
          isLoading={isLoading}
          title={t('bacteriaGroup')}
          columns={columns}
          data={data}
          options={{
            addRowPosition: 'first',
          }}
          localization={{
            header: {
              actions: t('tableHeaderActions'),
            },
            body: {
              addTooltip: t('addTooltip'),
              editTooltip: t('editTooltip'),
              editRow: {
                deleteText: t('deleteText'),
                cancelTooltip: t('cancelTooltip'),
                saveTooltip: t('saveTooltip'),
              },
              emptyDataSourceMessage: t('admin:addBacteriaGroup:emptyDataSource'),
            },
            toolbar: {
              searchTooltip: t('searchTooltip'),
              searchPlaceholder: t('searchPlaceholder'),
            },
            pagination: {
              labelDisplayedRows: t('labelDisplayedRows'),
              labelRowsSelect: t('labelRowsSelect'),
              firstTooltip: t('firstTooltip'),
              previousTooltip: t('previousTooltip'),
              nextTooltip: t('nextTooltip'),
              lastTooltip: t('lastTooltip'),
            },
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                handleRowAdd(newData);
                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                handleRowUpdate(newData, oldData);
                resolve();
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                handleRowDelete(oldData);
                resolve();
              }),
          }}
        ></MaterialTable>
      </Paper>
    </div>
  );
}
