import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Button, useMediaQuery } from '@material-ui/core';
import UdderIcon from '../UdderIcon';
import { getUdderPart } from '../../../../../Services/Utility/Udder';
import {
  getSampleReason,
  getSampleInformation,
} from '../../../../../Services/Utility/SampleInformation';
import { yyyymmddhhmm } from '../../../../../Services/Utility/DateUtils';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../../actions/modalActions';
import { openPDFDialog } from '../../../../../actions/mainActions';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 1.5rem',
  },
  mobileCardContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    padding: '1rem',
    flexWrap: 'wrap',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '70%',
    minWidth: '60%',
  },
  mobileInfo: {
    minWidth: '50%',
    maxWidth: 'inherit',
  },
  buttonWrapper: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    height: '6rem',
    alignContent: 'space-evenly',
  },
  button: {
    width: theme.spacing(10.25),
    maxHeight: '2.5rem',
    '&>span': {
      whiteSpace: 'nowrap',
    },
  },
  mobileButtonWrapper: {
    padding: '1rem 0 0',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row-reverse',
  },
  mobileButton: {
    maxHeight: '1.75rem',
    maxWidth: '9.5rem',
    '&>span': {
      whiteSpace: 'nowrap',
    },
  },
  sampleInfo: {
    margin: '0 2rem 0 2rem',
    '&>p:first-child': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(0.5),
    },
  },
  mobileSampleInfo: {
    margin: '0 0.5rem 0 1rem',
    '&>p:first-child': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(0.5),
    },
  },
  clockIcon: {
    marginRight: theme.spacing(0.25),
  },
}));

export default function VerifySampleCard(props) {
  const { sample } = props;
  const { t } = useTranslation(['samplesFarmer']);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const classes = useStyles();
  const dispatch = useDispatch();

  function getResult(sample) {
    const result = getSampleInformation(sample);

    if (result === 'somethingWentWrong') {
      return `, ${t('cowCard:somethingWentWrong')}`;
    } else if (result) {
      return `, ${result}`;
    }

    return '';
  }

  return (
    <div className={isMobile ? classes.mobileCardContent : classes.cardContent}>
      <div className={isMobile ? `${classes.info} ${classes.mobileInfo}` : classes.info}>
        <UdderIcon sample={sample} />
        <div className={isMobile ? classes.mobileSampleInfo : classes.sampleInfo}>
          <Typography variant='body2'>
            <AccessTimeIcon className={classes.clockIcon} />{' '}
            {`${t('verifySampleCard:sampled')}: ${yyyymmddhhmm(new Date(sample.createdAt))}`}
          </Typography>
          <Typography variant='body2'>{t('verifySampleCard:summary')}</Typography>
          <Typography variant='h5'>{`${t('verifySampleCard:cow', {
            cowNumber: sample.subject.name,
          })}, ${getUdderPart(
            sample.udderId,
            'samplesFarmer:cowCard:FL',
            'samplesFarmer:cowCard:FR',
            'samplesFarmer:cowCard:BL',
            'samplesFarmer:cowCard:BR',
            'samplesFarmer:cowCard:udderPartUnknown',
          )}, ${getSampleReason(sample.sampleReason)}${getResult(sample)}`}</Typography>
        </div>
      </div>
      <div className={isMobile ? classes.mobileButtonWrapper : classes.buttonWrapper}>
        <Link to={`/samples/${sample.sampleId}`}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            size='small'
            style={{ marginLeft: isMobile ? '0.6rem' : '' }}
            className={isMobile ? classes.mobileButton : classes.button}
            onClick={() => dispatch(openPDFDialog(sample))}
          >
            {t('verifySampleCard:getRemittance')}
          </Button>
        </Link>
        <Link to={`/samples/${sample.sampleId}`}>
          <Button
            id='show-sample-info'
            variant='outlined'
            color='primary'
            size='small'
            style={{ marginLeft: isMobile ? '' : '1rem' }}
            className={isMobile ? classes.mobileButton : classes.button}
            onClick={() => {
              dispatch(openModal(sample, 'farmer'));
            }}
          >
            {t('verifySampleCard:showSampleInfo')}
          </Button>
        </Link>
      </div>
    </div>
  );
}

VerifySampleCard.propTypes = {
  sample: PropTypes.object.isRequired,
};
