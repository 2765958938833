import React, { useState, useEffect } from 'react';
import {
  Card,
  Typography,
  makeStyles,
  Tooltip,
  ClickAwayListener,
  Fade,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { enums } from '../../../../../Services/Utility/Enumerations';
import PermissionCheck from '../../../../../Services/PermissionCheck';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { sampleIsMarkedAsSendInForVerification } from '../../../../../Services/Utility/SampleInformation';
import { SampleLabFlagClient, SamplesClient } from '../../../../../Services/Utility/WebApiClient';

const useStyles = makeStyles((theme) => ({
  verificationCard: {
    border: '1px solid #979797',
  },
  tooltipLabel: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    lineHeight: theme.typography.body2.lineHeight,
    letterSpacing: theme.typography.body2.letterSpacing,
  },
  expanPanel: {
    padding: theme.spacing(0, 1, 0, 1),
  },
  expanPanelDetails: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.lightGrays.gray20}`,
    display: 'flex',
    flexDirection: 'column',
    '&>div+div': {
      marginTop: theme.spacing(1),
    },
  },
  option: {
    display: 'flex',
  },
  iconButton: {
    padding: 0,
  },
  optionText: {
    marginLeft: theme.spacing(1),
  },
  disabled: {
    opacity: '50%',
  },
}));

export default function VerificationCard(props) {
  const {
    onCheckSendInForVerification,
    onCheckSendToBacteriologist,
    onCheckSendToFinal,
    isLoadingUpdateAfterSendInForVerification,
    isLoadingUpdateAfterCheckSendToBacteriologist,
    isLoadingUpdateAfterSendToFinal,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation(['samplesDetails']);
  const sample = useSelector((state) => state.modalReducer.sample);
  const isJuniorBacteriologist = PermissionCheck.canClassifyAsJuniorBacteriologist();
  const canFlagLab = PermissionCheck.canFlagLab();
  const isAdmin = PermissionCheck.isAdmin();

  const [isCheckedSendToLab, setIsCheckedSendToLab] = useState(false);
  const [isCheckedSendToBacteriologist, setIsCheckedSendToBacteriologist] = useState(false);
  const [isLoadingSendInForVerification, setIsLoadingSendInForVerification] = useState(false);
  const [isLoadingSendToBacteriologist, setIsLoadingSendToBacteriologist] = useState(false);
  const [isLoadingSendToFinal, setIsLoadingSendToFinal] = useState(false);
  const [tooltip, setTooltip] = useState(null);

  useEffect(() => {
    if (sample && sampleIsMarkedAsSendInForVerification(sample)) {
      setIsCheckedSendToLab(true);
    } else {
      setIsCheckedSendToLab(false);
    }
  }, [sample]);

  useEffect(() => {
    if (sample.status.statusId === enums.status.awaitingSeniorBac) {
      setIsCheckedSendToBacteriologist(true);
    } else {
      setIsCheckedSendToBacteriologist(false);
    }
  }, [sample]);

  /**
   * If any of the send to lab tooltips are displayed, hide it.
   */
  function hideAllSendToLabTooltips() {
    if (tooltip === 'sendToLabSupport') {
      setTooltip(null);
    }
  }

  /**
   * If any of the send to bacteriologist tooltips are displayed, hide it.
   */
  function hideAllSendToBacteriologistTooltips() {
    if (tooltip === 'sendToBacteriologistWarning' || tooltip === 'sendToBacteriologistSupport') {
      setTooltip(null);
    }
  }

  async function updateSendToLabCheckbox(labFlagTrigger) {
    setIsLoadingSendInForVerification(true);

    const response = await SampleLabFlagClient.PutSampleLabFlag(
      sample.sampleId,
      labFlagTrigger,
    ).then((response) => {
      return response;
    });

    if (response instanceof Error) {
      if (response.response.status === 401) {
        onCheckSendInForVerification('unauthorized');
      } else {
        onCheckSendInForVerification('error');
      }
    } else {
      onCheckSendInForVerification();
    }
    setIsLoadingSendInForVerification(false);
  }

  function handleCheckSendToLab() {
    if (!isCheckedSendToLab) {
      hideAllSendToLabTooltips();
      updateSendToLabCheckbox(enums.labFlagTrigger.bacSetsSendToLaboratory);
    } else {
      updateSendToLabCheckbox(enums.labFlagTrigger.bacUnsetsSendToLaboratory);
    }
  }

  async function handleCheckSendToFinal() {
    setIsLoadingSendToFinal(true);

    const response = await SamplesClient.FireTrigger(
      sample.sampleId,
      enums.sampleStateTrigger.complete,
    ).then((r) => r);

    if (response instanceof Error) {
      onCheckSendToFinal('error');
    } else {
      onCheckSendToFinal();
    }

    setIsLoadingSendToFinal(false);
  }

  async function handleCheckSendToBacteriologist() {
    if (!isCheckedSendToBacteriologist && !tooltip) {
      setTooltip('sendToBacteriologistWarning');
    } else if (!isCheckedSendToBacteriologist && tooltip === 'sendToBacteriologistWarning') {
      setIsLoadingSendToBacteriologist(true);
      hideAllSendToBacteriologistTooltips();

      const markAsSendToBacteriologistResponse = await SamplesClient.FireTrigger(
        sample.sampleId,
        enums.sampleStateTrigger.sendToSeniorBak,
      ).then((response) => {
        return response;
      });

      if (markAsSendToBacteriologistResponse instanceof Error) {
        if (markAsSendToBacteriologistResponse.response.status === 401) {
          onCheckSendToBacteriologist('unauthorized');
        } else {
          onCheckSendToBacteriologist('error');
        }
      } else {
        onCheckSendToBacteriologist();
      }

      setIsLoadingSendToBacteriologist(false);
    }
  }

  const Spinner = () => {
    return (
      <div>
        <Fade
          in={
            isLoadingUpdateAfterSendInForVerification ||
            isLoadingUpdateAfterCheckSendToBacteriologist ||
            isLoadingUpdateAfterSendToFinal ||
            isLoadingSendInForVerification ||
            isLoadingSendToBacteriologist ||
            isLoadingSendToFinal
          }
          style={{
            transitionDelay: '300ms',
          }}
          unmountOnExit
        >
          <CircularProgress color='primary' size='1.5rem' />
        </Fade>
      </div>
    );
  };

  const CheckSendToLab = () => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          hideAllSendToLabTooltips();
        }}
      >
        <Tooltip
          title={
            tooltip === 'sendToLabSupport'
              ? t('verificationCard:supportTooltip')
              : t('verificationCard:warningTooltip')
          }
          open={tooltip === 'sendToLabSupport'}
          arrow
          placement={'top'}
          classes={{ tooltip: classes.tooltipLabel }}
        >
          <div
            onClick={() => {
              if (
                [
                  enums.labFlag.hasBeenSent,
                  enums.labFlag.requestedResultAvailable,
                  enums.labFlag.sendLocked,
                ].includes(sample.labFlag)
              ) {
                setTooltip('sendToLabSupport');
              }
            }}
          >
            {isLoadingUpdateAfterSendInForVerification || isLoadingSendInForVerification ? (
              <Spinner />
            ) : (
              <Checkbox
                classes={{ root: classes.iconButton }}
                checked={isCheckedSendToLab}
                onChange={handleCheckSendToLab}
                color='primary'
                disabled={
                  isLoadingUpdateAfterCheckSendToBacteriologist ||
                  isLoadingSendToBacteriologist ||
                  [
                    enums.labFlag.hasBeenSent,
                    enums.labFlag.requestedResultAvailable,
                    enums.labFlag.sendLocked,
                  ].includes(sample.labFlag) ||
                  sample.labFlag === enums.labFlag.notRequestedResultAvailable
                }
              />
            )}
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  };

  const CheckSendToBacteriologist = () => {
    return (
      <ClickAwayListener
        onClickAway={() => {
          hideAllSendToBacteriologistTooltips();
        }}
      >
        <Tooltip
          title={
            tooltip === 'sendToBacteriologistSupport'
              ? t('verificationCard:supportTooltip')
              : t('verificationCard:warningTooltip')
          }
          open={
            tooltip === 'sendToBacteriologistSupport' || tooltip === 'sendToBacteriologistWarning'
          }
          arrow
          placement={'top'}
          classes={{ tooltip: classes.tooltipLabel }}
        >
          <div
            onClick={() => {
              if (isCheckedSendToBacteriologist) {
                setTooltip('sendToBacteriologistSupport');
              }
            }}
          >
            {isLoadingUpdateAfterCheckSendToBacteriologist || isLoadingSendToBacteriologist ? (
              <Spinner />
            ) : (
              <Checkbox
                classes={{ root: classes.iconButton }}
                checked={isCheckedSendToBacteriologist}
                onChange={handleCheckSendToBacteriologist}
                color='primary'
                disabled={
                  isLoadingUpdateAfterSendInForVerification ||
                  isLoadingSendInForVerification ||
                  isCheckedSendToBacteriologist
                }
              />
            )}
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  };

  const CheckSendToFinal = () => {
    return isLoadingUpdateAfterSendToFinal || isLoadingUpdateAfterSendToFinal ? (
      <Spinner />
    ) : (
      <Checkbox
        classes={{ root: classes.iconButton }}
        checked={sample.status.statusId === enums.status.finalResultAvailable}
        onChange={handleCheckSendToFinal}
        color='primary'
        disabled={sample.status.statusId === enums.status.finalResultAvailable}
      />
    );
  };

  return (
    <Card className={classes.verificationCard}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.expanPanel }}>
          <Typography variant='subtitle1'>{t('verificationCard:header')}</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.expanPanelDetails }}>
          {canFlagLab && (
            <div className={classes.option}>
              <CheckSendToLab />
              <Typography
                className={
                  [
                    enums.labFlag.hasBeenSent,
                    enums.labFlag.requestedResultAvailable,
                    enums.labFlag.sendLocked,
                    enums.labFlag.notRequestedResultAvailable,
                  ].includes(sample.labFlag)
                    ? `${classes.optionText} ${classes.disabled}`
                    : classes.optionText
                }
              >
                {t('verificationCard:sendToLab')}
              </Typography>
            </div>
          )}
          {(isJuniorBacteriologist || isAdmin) && (
            <div className={classes.option}>
              <CheckSendToBacteriologist />
              <Typography
                className={
                  isCheckedSendToBacteriologist
                    ? `${classes.optionText} ${classes.disabled}`
                    : classes.optionText
                }
              >
                {t('verificationCard:sendToBacteriologist')}
              </Typography>
            </div>
          )}
          {(isJuniorBacteriologist || isAdmin) && (
            <div className={classes.option}>
              <CheckSendToFinal />
              <Typography
                className={
                  sample.sampleId === enums.status.finalResultAvailable
                    ? `${classes.optionText} ${classes.disabled}`
                    : classes.optionText
                }
              >
                {t('verificationCard:sendToFinal')}
              </Typography>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}

VerificationCard.propTypes = {
  onCheckSendInForVerification: PropTypes.func.isRequired,
  onCheckSendToBacteriologist: PropTypes.func.isRequired,
  onCheckSendToFinal: PropTypes.func.isRequired,
  isLoadingUpdateAfterSendInForVerification: PropTypes.bool.isRequired,
  isLoadingUpdateAfterCheckSendToBacteriologist: PropTypes.bool.isRequired,
  isLoadingUpdateAfterSendToFinal: PropTypes.bool.isRequired,
};
