import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { enums } from '../../../../../Services/Utility/Enumerations';
import { yyyymmddhhmm } from '../../../../../Services/Utility/DateUtils';
import {
  hasFinalResult,
  hasFinalInconclusiveResult,
  hasPreliminaryResult,
  hasPreliminaryInconclusiveResult,
  displayPreliminaryResultInTimeline,
  displayFinalResultInTimeline,
} from '../../../../../Services/Utility/SampleInformation';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '1rem',
    border: '1px solid #979797',
    '@media (max-width: 1160px)': {
      border: 'none',
    },
  },
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',

    '@media (max-width: 1160px)': {
      flexDirection: 'row-reverse',
    },
  },
  slider: {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    marginLeft: '16px',

    '@media (max-width: 1160px)': {
      marginRight: '16px',
      marginLeft: 0,
    },
  },
  sliderLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0',
    position: 'relative',
  },
  active: {
    '&::before': {
      content: '""',
      display: 'inline-block',
      borderRadius: '50%',
      height: '10px',
      width: '10px',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      right: '-22px',

      '@media (max-width: 1160px)': {
        left: '-22px',
        right: '0x',
      },
    },
  },
  disabled: {
    color: '#CECECE',
  },
  sliderLabels: {
    flexGrow: 1,
    '&> div:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  date: {
    whiteSpace: 'nowrap',
  },
}));

export default function TimelineCard(props) {
  const { sample } = props;
  const classes = useStyles();
  const { t } = useTranslation(['samplesDetails']);
  const isMobile = useMediaQuery('(max-width:1160px)');

  const [sliderValue, setSliderValue] = useState(enums.steps.registered);

  useEffect(() => {
    if (sample.finalResult) {
      setSliderValue(enums.steps.finalResult);
    } else if (sample.finalImagesCreatedAt) {
      setSliderValue(enums.steps.finalPhotos);
    } else if (sample.preliminaryResult) {
      setSliderValue(enums.steps.preliminaryResult);
    } else if (sample.preliminaryImagesCreatedAt) {
      setSliderValue(enums.steps.preliminaryPhotos);
    } else if (sample.earlyResult) {
      setSliderValue(enums.steps.earlyResult);
    } else if (sample.earlyImagesCreatedAt) {
      setSliderValue(enums.steps.earlyPhotos);
    }
  }, [sample]);

  /* Returns a date in format YYYY-MM-DD - HH:MM for a specific event
  if the event's creation date is not null or undefined. */
  function dateStamp(createdAt) {
    return createdAt ? yyyymmddhhmm(new Date(createdAt)) : '-';
  }

  const Slider = () => {
    return <div className={classes.slider}></div>;
  };

  const SliderLabels = () => {
    return (
      <div className={classes.sliderLabels}>
        <SliderLabel
          label={t('timelineCard:sampleRegistered')}
          date={dateStamp(sample.createdAt)}
          current={sliderValue === enums.steps.registered}
        />
        {sample.earlyImagesCreatedAt && (
          <SliderLabel
            label={t('timelineCard:earlyPhotos')}
            date={dateStamp(sample.earlyImagesCreatedAt)}
            current={sliderValue === enums.steps.earlyPhotos}
            disabled={sliderValue < enums.steps.earlyPhotos}
          />
        )}
        {sample.earlyResult && (
          <SliderLabel
            label={t('timelineCard:earlyResult')}
            date={dateStamp(sample.earlyResult?.createdAt)}
            current={sliderValue === enums.steps.earlyResult}
            disabled={sliderValue < enums.steps.earlyResult}
          />
        )}
        <SliderLabel
          label={t('timelineCard:photoShoot1')}
          date={dateStamp(sample.preliminaryImagesCreatedAt)}
          current={sliderValue === enums.steps.preliminaryPhotos}
          disabled={
            sliderValue < enums.steps.preliminaryPhotos || !sample.preliminaryImagesCreatedAt
          }
        />
        <SliderLabel
          label={t('timelineCard:testResultDay1')}
          date={
            hasPreliminaryResult(sample) ||
            (hasPreliminaryInconclusiveResult(sample) && displayPreliminaryResultInTimeline(sample))
              ? dateStamp(sample.preliminaryResult.createdAt)
              : dateStamp(null)
          }
          current={sliderValue === enums.steps.preliminaryResult}
          disabled={
            sliderValue < enums.steps.preliminaryResult ||
            !sample.preliminaryResult ||
            (hasPreliminaryInconclusiveResult(sample) && !displayFinalResultInTimeline(sample))
          }
        />
        <SliderLabel
          label={t('timelineCard:photoShoot2')}
          date={dateStamp(sample.finalImagesCreatedAt)}
          current={sliderValue === enums.steps.finalPhotos}
          disabled={sliderValue < enums.steps.finalPhotos || !sample.finalImagesCreatedAt}
        />
        <SliderLabel
          label={t('timelineCard:testResult')}
          date={
            hasFinalResult(sample) ||
            (hasFinalInconclusiveResult(sample) && displayFinalResultInTimeline(sample))
              ? dateStamp(sample.finalResult.createdAt)
              : dateStamp(null)
          }
          current={sliderValue === enums.steps.finalResult}
          disabled={
            sliderValue < enums.steps.finalResult ||
            !sample.finalResult ||
            !displayFinalResultInTimeline(sample)
          }
        />
      </div>
    );
  };

  return (
    <Card className={classes.card} elevation={isMobile ? 0 : 1}>
      <Typography variant='subtitle1' gutterBottom>
        {t('timelineCard:timeline')}
      </Typography>
      <div className={classes.container}>
        <SliderLabels />
        <Slider />
      </div>
    </Card>
  );
}

const SliderLabel = ({ label, date, current, disabled }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');

  return (
    <div
      className={
        current
          ? `${classes.sliderLabel} ${classes.active} ${disabled ? classes.disabled : ''}`
          : `${classes.sliderLabel} ${disabled ? classes.disabled : ''}`
      }
    >
      <Typography variant={isMobile ? 'body2' : 'body1'}>{label}</Typography>
      <Typography variant='body2' className={classes.date}>
        {date}
      </Typography>
    </div>
  );
};

TimelineCard.propTypes = {
  sample: PropTypes.object.isRequired,
};

SliderLabel.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  current: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};
