import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useMediaQuery, Menu, MenuItem, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const useStyles = makeStyles({
  popOver: {
    overflowX: 'unset',
    overflowY: 'unset',
    boxShadow: 'none',
    backgroundColor: '#F4F4F4',
    marginTop: '0.5rem',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-9px',
      width: '10px',
      left: '45%',
      borderBottom: '10px solid #F4F4F4',
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
    },
  },
  mobilePopOver: {
    '&::before': {
      left: '83%',
    },
  },
  menuList: {
    padding: 0,
    '&>li': {
      padding: '1rem',
      width: '12rem',
      display: 'flex',
      alignItems: 'center',
      '&>svg': {
        marginRight: '1rem',
      },
    },
    '&>li+li': {
      borderTop: '1px solid #DDDDDE',
    },
    '&>li:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  deleteColor: {
    color: '#D10505',
  },
});

export default function EditMenu(props) {
  const { anchorEl, close, openDeleteDialog, startEditing, comment } = props;
  const { t } = useTranslation(['samplesDetails']);
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');

  return (
    <Menu
      id='edit-menu'
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      keepMounted
      onClose={close}
      classes={{
        paper: isMobile ? `${classes.popOver} ${classes.mobilePopOver}` : classes.popOver,
        list: classes.menuList,
      }}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MenuItem
        disableRipple
        // prevent highlight of first menu item (built-in MUI feature)
        style={{ backgroundColor: '#F4F4F4' }}
        data-cy='edit-comment-option'
        onClick={() => {
          startEditing(comment);
          close();
        }}
      >
        <CreateOutlinedIcon />
        <Typography>{t('commentCard:edit')}</Typography>
      </MenuItem>
      <MenuItem
        disableRipple
        data-cy='delete-comment-option'
        onClick={() => {
          openDeleteDialog();
          close();
        }}
      >
        <DeleteOutlinedIcon className={classes.deleteColor} />
        <Typography className={classes.deleteColor}>{t('commentCard:delete')}</Typography>
      </MenuItem>
    </Menu>
  );
}

EditMenu.propTypes = {
  anchorEl: PropTypes.object,
  close: PropTypes.func.isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
  startEditing: PropTypes.func.isRequired,
  comment: PropTypes.object,
};
