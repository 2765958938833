import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from './Main';
import Login from './Login';
import DataService from '../Services/DataService';
import CacheBuster from '../components/CacheBuster';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { selectSite, setAvailableSites } from '../actions/siteActions';
import { openSnackbar } from '../actions/mainActions';
import { farmerOrBacteriologist } from '../actions/sampleActions';
import PermissionCheck from '../Services/PermissionCheck';
import { EnterprisesClient } from '../Services/Utility/WebApiClient';
import { UserManager } from '../Services/Utility/UserManager';

export default function App() {
  const [signedInState, setSignedInState] = useState('');
  const refreshTokenTimer = useRef(null);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies(['site']); //'setCookie' is never used, but needs to be declared to access the removeCookie function

  const availableSites = useSelector((state) => state.siteReducer.availableSites);

  const startRefreshTokenTimer = useCallback(() => {
    if (refreshTokenTimer) {
      clearInterval(refreshTokenTimer);
      refreshTokenTimer.current = null;
    }
    refreshTokenTimer.current = setInterval(() => {
      DataService.checkToken(true)
        .then((result) => {
          if (false === result) {
            // failed to refresh token, expired or server error
            setSignedInState('signedOut');
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }, process.env.REACT_APP_TOKEN_REFRESH_INTERVAL_MINUTES * 60 * 1000);
  }, []);

  const stopRefreshTokenTimer = useCallback(() => {
    if (null !== refreshTokenTimer.current) {
      clearInterval(refreshTokenTimer.current);
      refreshTokenTimer.current = null;
    }
  }, []);

  useEffect(() => {
    // Search for match with cookie
    if (availableSites.some((site) => site.siteid === cookies.site)) {
      dispatch(selectSite(cookies.site));
    } else if (localStorage.getItem('siteid') !== 'null') {
      // Localstorage sets siteid 'null' (string) if the user has no site.
      dispatch(selectSite(localStorage.getItem('siteid')));
    } else {
      // If there are no site-cookies, or siteid in localstorage is 'null', select the first site in availableSites
      if (availableSites.length > 0) {
        dispatch(selectSite(availableSites[0].siteid));
      }
    }
  }, [availableSites, cookies, dispatch]);

  useEffect(() => {
    DataService.checkToken(true)
      .then((result) => {
        setSignedInState(result ? 'signedIn' : 'signedOut');
        if (true === result) {
          startRefreshTokenTimer();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return () => {
      stopRefreshTokenTimer();
    };
  }, [startRefreshTokenTimer, stopRefreshTokenTimer, dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      const ssoToken = UserManager.getIdToken();

      if (signedInState === 'signedIn' && !ssoToken) {
        onSignOut();
      }

      if (signedInState === 'signedOut' && ssoToken) {
        onSuccessfulSignIn({ token: ssoToken });
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    function extractAndOrderSites(enterprises) {
      const sites = [];

      if (enterprises) {
        enterprises.forEach((enterprise) => enterprise.sites.forEach((site) => sites.push(site)));

        // Remove duplicate siteids
        const dataArr = sites.map((site) => {
          return [site.siteid, site];
        }); // creates array of array
        const mapArr = new Map(dataArr); // create key value pair from array of array
        const uniqueSites = [...mapArr.values()]; // converting back to array from mapobject

        // Sort site names if there are more than 1 site available
        if (uniqueSites.length > 1) {
          uniqueSites.sort(function (a, b) {
            const siteA = a.name.toUpperCase();
            const siteB = b.name.toUpperCase();
            if (siteA < siteB) {
              return -1;
            }
            if (siteA > siteB) {
              return 1;
            }
            return 0;
          });
        }

        return uniqueSites;
      }
    }

    async function fetchUserEnterprises() {
      const fetchEnterprises = await EnterprisesClient.GetEnterpriseAndSites();

      if (fetchEnterprises instanceof Error) {
        dispatch(openSnackbar('warning', 'fetchError'));
      } else {
        const sites = extractAndOrderSites(fetchEnterprises);
        dispatch(setAvailableSites(sites));
      }
    }

    if (signedInState === 'signedIn') {
      fetchUserEnterprises();

      if (PermissionCheck.canClassify()) {
        dispatch(farmerOrBacteriologist('bacteriologist'));
      } else {
        dispatch(farmerOrBacteriologist('farmer'));
      }
    }
  }, [signedInState, dispatch]);

  function onSignOut() {
    UserManager.removeUser();
    setSignedInState('signedOut');
    stopRefreshTokenTimer();
    removeCookie('site');
    dispatch(selectSite(null));
  }

  /**
   * @summary A callback to handle when the user successfully login with email and password.
   */
  function onSuccessfulSignIn(user) {
    // Clear local storage of expired token
    UserManager.removeUser();
    UserManager.setIdToken(user);
    DataService.checkToken(true)
      .then((result) => {
        setSignedInState(result ? 'signedIn' : 'signedOut');
        if (true === result) {
          startRefreshTokenTimer();
        } else {
          setSignedInState('forbidden');
        }
      })
      .catch((e) => {
        setSignedInState('forbidden');
        console.log(e);
      });
  }

  return (
    <>
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;

          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return signedInState === '' ? null : (
            <Switch>
              <Route path='/login'>
                <Login
                  successfulSignInCallback={(user) => onSuccessfulSignIn(user)}
                  onPendingSignInCallback={() => setSignedInState('pending')}
                  failedSignInCallback={() => setSignedInState('failed')}
                  forbiddenSignInCallback={() => setSignedInState('forbidden')}
                  signedInState={signedInState}
                />
              </Route>
              <Route
                path='/download'
                component={() => {
                  window.location.href =
                    'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=se.agricam.calfcheck';
                  return null;
                }}
              />

              {/* This route needs to be last since it would match first otherwise */}
              <Route path='/'>
                <Main signedInState={signedInState} onSignOutCallback={() => onSignOut()} />
              </Route>
            </Switch>
          );
        }}
      </CacheBuster>
    </>
  );
}
