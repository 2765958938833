import React, { useEffect, Suspense } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from '../../Services/Routing/privateRoute';
import SettingsView from '../SettingsView';
import AdminView from '../AdminView';
import SnackbarComponent from '../SnackbarComponent';
import Nav from '../Nav';
import TopBar from '../Nav/TopBar';
import SampleContainer from '../SampleContainer';
import { useSelector } from 'react-redux';
import { makeStyles, useMediaQuery, CircularProgress } from '@material-ui/core';
import CommentsView from '../CommentsView';
import PropTypes from 'prop-types';
import PDFView from '../PDFView';
import { parse as parseQuery } from 'query-string';
import WarningBanner from '../WarningBanner';
import PageNotFound from './PageNotFound';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '1px',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: '1',
  },
  contentWrapperMobile: {
    width: '100vw',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingTop: '8rem',
  },
  mobileContent: {
    paddingTop: '3rem',
  },
  spinner: {
    position: 'fixed',
    top: '50%',
  },
});

function transformLocation(location) {
  if ('/samples' === location.pathname) {
    const queryStringParsed = parseQuery(location.search);
    if (undefined !== queryStringParsed.id) {
      return {
        ...location,
        pathname: `/samples/${queryStringParsed.id}`,
      };
    }
  }
  return location;
}

export default function Main(props) {
  const { signedInState, onSignOutCallback } = props;
  const isMobile = useMediaQuery('(max-width:1160px)');
  const classes = useStyles();

  const snackbarOpen = useSelector((state) => state.snackbarReducer.isOpen);
  const PDFopen = useSelector((state) => state.PDFReducer.isOpen);
  const modalOpen = useSelector((state) => state.modalReducer.isOpen);

  useEffect(() => {
    if (modalOpen) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.removeProperty('overflow');
    }
  }, [modalOpen]);

  const pathArray = window.location.pathname.split('/');
  // this check is done to see if we are navigated to '/'
  // we only want to redirect to login from render in that case
  // the other redirects are handled by the PrivateRoute
  if (pathArray.length === 2 && signedInState === 'signedOut') {
    return <Redirect to='/login' />;
  }

  const Spinner = () => (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  );

  return (
    <>
      {!isMobile && <WarningBanner />}
      <div className={isMobile ? classes.mobileWrapper : classes.wrapper}>
        <Nav onSignout={onSignOutCallback} signedInState={signedInState} />
        <div
          className={
            isMobile
              ? `${classes.contentWrapper} ${classes.contentWrapperMobile}`
              : classes.contentWrapper
          }
        >
          {!isMobile && <TopBar onSignout={onSignOutCallback} signedInState={signedInState} />}
          <div
            className={isMobile ? `${classes.content} ${classes.mobileContent}` : classes.content}
          >
            <Suspense fallback={<Spinner />}>
              <Route
                render={(routeProps) => (
                  <Switch location={transformLocation(routeProps.location)}>
                    <PrivateRoute exact path='/admin' signedInState={signedInState}>
                      <AdminView />
                    </PrivateRoute>
                    <PrivateRoute exact path='/samples/:sampleId' signedInState={signedInState}>
                      <SampleContainer />
                    </PrivateRoute>
                    <PrivateRoute exact path='/samples' signedInState={signedInState}>
                      <SampleContainer />
                    </PrivateRoute>
                    <PrivateRoute exact path='/settings' signedInState={signedInState}>
                      <SettingsView />
                    </PrivateRoute>
                    <PrivateRoute exact path='/comments' signedInState={signedInState}>
                      <CommentsView />
                    </PrivateRoute>
                    <PrivateRoute exact path='/' signedInState={signedInState}>
                      <Redirect to='/samples' />
                    </PrivateRoute>
                    <Route component={PageNotFound} signedInState={signedInState} />
                  </Switch>
                )}
              />
            </Suspense>
          </div>
        </div>
        {snackbarOpen && <SnackbarComponent />}
        {PDFopen && <PDFView />}
      </div>
    </>
  );
}

Main.propTypes = {
  signedInState: PropTypes.string.isRequired,
  onSignOutCallback: PropTypes.func.isRequired,
};
