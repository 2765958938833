import { enums } from '../Enumerations';
import {
  getBacteriaAmount,
  getCultureTypeLabel,
  hasFinalBacteriaResult,
  hasFinalInconclusiveResult,
  hasFinalResult,
  hasPreliminaryBacteriaResult,
  hasPreliminaryInconclusiveResult,
  hasPreliminaryResult,
  isResultSetByAI,
  getLocalizedOutcome,
  hasEarlyResult,
  hasEarlyInconclusiveResult,
  hasEarlyBacteriaResult,
} from '../SampleInformation';
import { yyyymmddhhmm } from '../DateUtils';
import i18n from '../../../i18n';

export const otherClassificationOptions = [
  enums.cultureType.negative,
  enums.cultureType.mixedFlora,
];

/**
 * Get information to be displayed in ClassificationCard.
 * Returns both classification text and if the result was set by an AI
 */
export function getClassificationInfo(sample) {
  // Final results
  if (hasFinalResult(sample)) {
    // Final result exists and is either of cultureType 'pure' or 'notSet'.
    if (hasFinalBacteriaResult(sample)) {
      return {
        classificationText: i18n.t('samplesDetails:classificationCard:thisSampleIsClassified', {
          bacteriaName: i18n.t(`bacterias:${sample.finalResult.bacterias[0]?.name}`),
          bacteriaAmount: getBacteriaAmount(sample.finalResult.cultureType.bacteriaAmount),
          date: yyyymmddhhmm(new Date(sample.finalResult.createdAt)),
        }),
        resultIsSetByAi: isResultSetByAI(sample.finalResult),
      };
    } else {
      // Final result exists and is any of the other cultureTypes
      return {
        classificationText: i18n.t('samplesDetails:classificationCard:thisSampleIsClassified', {
          bacteriaName: getCultureTypeLabel(sample.finalResult),
          bacteriaAmount: getBacteriaAmount(sample.finalResult.cultureType.bacteriaAmount),
          date: yyyymmddhhmm(new Date(sample.finalResult.createdAt)),
        }),
        resultIsSetByAi: isResultSetByAI(sample.finalResult),
      };
    }
  } else if (hasFinalInconclusiveResult(sample)) {
    return {
      classificationText: i18n.t('samplesDetails:classificationCard:thisSampleIsInconclusive', {
        outcome: getLocalizedOutcome(sample.finalResult.bacteriaResultOutcome),
        date: yyyymmddhhmm(new Date(sample.finalResult.createdAt)),
      }),
      resultIsSetByAi: isResultSetByAI(sample.finalResult),
    };
  }
  // Preliminary results
  else if (hasPreliminaryResult(sample)) {
    if (hasPreliminaryBacteriaResult(sample)) {
      return {
        classificationText: i18n.t('samplesDetails:classificationCard:thisSampleIsClassified', {
          bacteriaName: i18n.t(`bacterias:${sample.preliminaryResult.bacterias[0]?.name}`),
          bacteriaAmount: getBacteriaAmount(sample.preliminaryResult.cultureType.bacteriaAmount),
          date: yyyymmddhhmm(new Date(sample.preliminaryResult.createdAt)),
        }),
        resultIsSetByAi: isResultSetByAI(sample.preliminaryResult),
      };
    } else {
      return {
        classificationText: i18n.t('samplesDetails:classificationCard:thisSampleIsClassified', {
          bacteriaName: getCultureTypeLabel(sample.preliminaryResult),
          bacteriaAmount: getBacteriaAmount(sample.preliminaryResult.cultureType.bacteriaAmount),
          date: yyyymmddhhmm(new Date(sample.preliminaryResult.createdAt)),
        }),
        resultIsSetByAi: isResultSetByAI(sample.preliminaryResult),
      };
    }
  } else if (hasPreliminaryInconclusiveResult(sample)) {
    return {
      classificationText: i18n.t('samplesDetails:classificationCard:thisSampleIsInconclusive', {
        outcome: getLocalizedOutcome(sample.preliminaryResult.bacteriaResultOutcome),
        date: yyyymmddhhmm(new Date(sample.preliminaryResult.createdAt)),
      }),
      resultIsSetByAi: isResultSetByAI(sample.preliminaryResult),
    };
  } else if (hasEarlyResult(sample)) {
    if (hasEarlyBacteriaResult(sample)) {
      return {
        classificationText: i18n.t('samplesDetails:classificationCard:thisSampleIsClassified', {
          bacteriaName: i18n.t(`bacterias:${sample.earlyResult.bacterias[0]?.name}`),
          bacteriaAmount: getBacteriaAmount(sample.earlyResult.cultureType.bacteriaAmount),
          date: yyyymmddhhmm(new Date(sample.earlyResult.createdAt)),
        }),
        resultIsSetByAi: isResultSetByAI(sample.earlyResult),
      };
    } else {
      return {
        classificationText: i18n.t('samplesDetails:classificationCard:thisSampleIsClassified', {
          bacteriaName: getCultureTypeLabel(sample.earlyResult),
          bacteriaAmount: getBacteriaAmount(sample.earlyResult.cultureType.bacteriaAmount),
          date: yyyymmddhhmm(new Date(sample.earlyResult.createdAt)),
        }),
        resultIsSetByAi: isResultSetByAI(sample.earlyResult),
      };
    }
  } else if (hasEarlyInconclusiveResult(sample)) {
    return {
      classificationText: i18n.t('samplesDetails:classificationCard:thisSampleIsInconclusive', {
        outcome: getLocalizedOutcome(
          sample.earlyResult.bacteriaResultOutcome ===
            enums.bacteriaResultOutcome.humanEvaluationRequired
            ? enums.bacteriaResultOutcome.additionalImagesRequired
            : sample.earlyResult.bacteriaResultOutcome,
        ),
        date: yyyymmddhhmm(new Date(sample.earlyResult.createdAt)),
      }),
      resultIsSetByAi: isResultSetByAI(sample.earlyResult),
    };
  }

  return '';
}

/**
 * Returns the outcomes, ready to be put in a Select-type component
 */
export function getSelectableOutcomes() {
  return Object.keys(enums.bacteriaResultOutcome)
    .filter(
      (name) =>
        ![
          enums.bacteriaResultOutcome.notSet,
          enums.bacteriaResultOutcome.success,
          enums.bacteriaResultOutcome.tooFewBacteriasIgnore,
          enums.bacteriaResultOutcome.missingImages,
          enums.bacteriaResultOutcome.humanEvaluationRequired,
        ].includes(enums.bacteriaResultOutcome[name]),
    )
    .map((name) => {
      return {
        key: enums.bacteriaResultOutcome[name],
        value: enums.bacteriaResultOutcome[name],
        title: i18n.t(`samplesBacteriologist:sampleOutcomes:${name}`),
      };
    });
}

/**
 * Returns the bacteria amounts, ready to be put in a Select-type component
 */
export function getBacteriaAmountList() {
  return Object.keys(enums.bacteriaAmount)
    .filter((name) => ![enums.bacteriaAmount.notSet].includes(enums.bacteriaAmount[name]))
    .map((name) => {
      return {
        key: enums.bacteriaAmount[name],
        value: enums.bacteriaAmount[name],
        title: i18n.t(`samplesBacteriologist:bacteriaAmount:${name}`),
      };
    });
}

/**
 * Check if the sample has a previous result that can be confirmed
 */
export function confirmablePreviousResult(sample) {
  if (
    sample.finalResult &&
    sample.finalResult.bacteriaResultOutcome > 0 &&
    ![
      enums.bacteriaResultOutcome.additionalImagesRequired,
      enums.bacteriaResultOutcome.humanEvaluationRequired,
    ].includes(sample.finalResult.bacteriaResultOutcome)
  ) {
    return sample.finalResult;
  } else if (
    sample.preliminaryResult &&
    sample.preliminaryResult.bacteriaResultOutcome > 0 &&
    ![
      enums.bacteriaResultOutcome.additionalImagesRequired,
      enums.bacteriaResultOutcome.humanEvaluationRequired,
    ].includes(sample.preliminaryResult.bacteriaResultOutcome)
  ) {
    return sample.preliminaryResult;
  } else if (sample.earlyResult) {
    // Early results are not confirmable
    return null;
  }
  return null;
}

/**
 * Check if the classification about to be created already is the most recent classification
 * @param {Object} classificationToBeConfirmed - Classification to be set if confirmation is successful
 * @param {Object} oldClassification - Current classification made on this sample
 * @param {string} typeOfClassification - Type of classification to be set: "cultureType", "bacteria" or "inconclusive"
 * @param {number} sourceKind - The sourceKind of the person who clicks on the "confirm"-button
 */
export function classificationAlreadyExists(
  classificationToBeConfirmed,
  oldClassification,
  typeOfClassification,
  sourceKind,
) {
  if (oldClassification && sourceKind <= oldClassification.sourceKind) {
    if (
      typeOfClassification === 'cultureType' &&
      classificationToBeConfirmed.cultureType.cultureType ===
        oldClassification.cultureType.cultureType &&
      classificationToBeConfirmed.cultureType.bacteriaAmount ===
        oldClassification.cultureType.bacteriaAmount
    ) {
      return true;
    } else if (
      typeOfClassification === 'bacteria' &&
      classificationToBeConfirmed.bacterias[0].bacteriaId ===
        oldClassification.bacterias[0].bacteriaId &&
      classificationToBeConfirmed.cultureType.bacteriaAmount ===
        oldClassification.cultureType.bacteriaAmount
    ) {
      return true;
    } else if (
      typeOfClassification === 'inconclusive' &&
      classificationToBeConfirmed.bacteriaResultOutcome === oldClassification.bacteriaResultOutcome
    ) {
      return true;
    }
  }

  return false;
}

export function getOtherClassificationOptions() {
  const options = [];

  // Culture types
  Object.keys(enums.cultureType)
    .filter(
      (name) =>
        ![
          enums.cultureType.notSet,
          enums.cultureType.mixedInfection,
          enums.cultureType.pure,
        ].includes(enums.cultureType[name]),
    )
    .forEach((name) => {
      options.push({
        key: enums.cultureType[name],
        value: enums.cultureType[name],
        title: i18n.t(`samplesDetails:classificationCard:${name}`),
      });
    });

  // Special cases:
  // * mixedFlora where contagious mastitis bacteria is suspected.
  Object.keys(enums.otherClassificationOptions).forEach((name) => {
    options.push({
      key: enums.otherClassificationOptions[name],
      value: enums.otherClassificationOptions[name],
      title: i18n.t(`samplesDetails:classificationCard:${name}`),
    });
  });

  return options;
}
