import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography, Button } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  card: {
    border: '1px solid #979797',
  },
  contentwrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '0.5rem 0.5rem 0.5rem 1rem',
  },
  buttonwrapper: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'flex-end',
  },
  navigateButton: {
    outline: 'none !important',
  },
  firstButton: {
    marginRight: '0.3rem',
  },
  navigateIcon: {
    color: '#48494B',
  },
  textWrapper: {
    padding: '0.4rem 0.2rem',
  },
});

export default function NextSampleCard(props) {
  const { t } = useTranslation(['samplesDetails']);
  const { currentIndex, onNextOrPrevSample, numberOfSamplesToActOn } = props;
  const classes = useStyles();

  function getNextSampleText() {
    return t('nextSampleCard:nextSample', {
      currentSample: currentIndex,
      totalNumberOfSamples: numberOfSamplesToActOn,
    });
  }

  return (
    <Card className={classes.card}>
      <div className={classes.contentwrapper}>
        <Typography className={classes.textWrapper} variant='subtitle1'>
          {getNextSampleText()}
        </Typography>
        <div className={classes.buttonwrapper}>
          <Button
            className={`${classes.navigateButton} ${classes.firstButton}`}
            variant='contained'
            disabled={currentIndex === 1}
            onClick={() => onNextOrPrevSample(-1)}
            disableElevation
          >
            <NavigateBeforeIcon className={classes.navigateIcon} />
          </Button>
          <Button
            className={classes.navigateButton}
            variant='contained'
            disabled={currentIndex === numberOfSamplesToActOn}
            onClick={() => onNextOrPrevSample(1)}
            disableElevation
          >
            <NavigateNextIcon className={classes.navigateIcon} />
          </Button>
        </div>
      </div>
    </Card>
  );
}

NextSampleCard.propTypes = {
  onNextOrPrevSample: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  numberOfSamplesToActOn: PropTypes.number.isRequired,
};
