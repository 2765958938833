/**
 * Generates a randomized color for a specific category in a piechart
 * @param {string} name Name of the category
 * @param {number} opacity Percentage of opacity. Is null by default.
 * @return {string} Returns a string with a randomized RBG color
 */
export function toRGB(name, opacity = null) {
  let hash = 0;
  if (name.length === 0) return hash;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  const rgb = [0, 0, 0];
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 255;
    rgb[i] = value;
  }
  if (opacity === null) return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
  else return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
}
