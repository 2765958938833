import React from 'react';
import PropTypes from 'prop-types';
import BactSampleTableRow from './BactSampleTableRow';
import { makeStyles, IconButton, Typography, Fade, CircularProgress } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles({
  paginationWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '2%',
  },
  currentPage: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    padding: '0 2rem',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5% 0 5% 0',
  },
});

export default function BactSampleView(props) {
  const {
    samples,
    onPageUpdated,
    loadingAllSamples,
    loadingSamplesToActOn,
    page,
    allSamplesDisplayed,
  } = props;
  const classes = useStyles();

  function getSampleRows() {
    const samplesList = samples.map((sample) => (
      <BactSampleTableRow key={sample.sampleId} sample={sample} />
    ));

    return samplesList;
  }

  function getSpinner() {
    return (
      <div className={classes.spinner}>
        <Fade
          in={loadingAllSamples || loadingSamplesToActOn}
          style={{
            transitionDelay: '300ms',
          }}
          unmountOnExit
        >
          <CircularProgress color='primary' size='5rem' />
        </Fade>
      </div>
    );
  }

  return loadingAllSamples || loadingSamplesToActOn ? (
    getSpinner()
  ) : (
    <>
      {getSampleRows()}
      {allSamplesDisplayed && (
        <div className={classes.paginationWrapper}>
          <IconButton
            disabled={page === 1}
            variant='contained'
            onClick={() => {
              onPageUpdated(page - 1);
            }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Typography className={classes.currentPage}>{page}</Typography>
          <IconButton
            disabled={samples.length < 10}
            variant='contained'
            onClick={() => onPageUpdated(page + 1)}
          >
            <NavigateNextIcon />
          </IconButton>
        </div>
      )}
    </>
  );
}

BactSampleView.propTypes = {
  samples: PropTypes.array.isRequired,
  onPageUpdated: PropTypes.func.isRequired,
  loadingAllSamples: PropTypes.bool.isRequired,
  loadingSamplesToActOn: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  allSamplesDisplayed: PropTypes.bool.isRequired,
};
