import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getAvatar } from '../../../../../Services/Utility/User';
import {
  Card,
  List,
  Typography,
  Divider,
  TextField,
  Button,
  CircularProgress,
  Fade,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import { yyyymmddhhmm } from '../../../../../Services/Utility/DateUtils';
import { openSnackbar } from '../../../../../actions/mainActions';
import { useDispatch, useSelector } from 'react-redux';
import DeleteDialog from './DeleteDialog';
import EditMenu from './EditMenu';
import { AssignmentCommentsClient } from '../../../../../Services/Utility/WebApiClient';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '1rem',
    border: '1px solid #E8E8E8',
    height: '100%',
  },
  farmerMobileCard: {
    padding: '1rem',
    height: '100%',
  },
  adminViewCard: {
    padding: '1rem',
    border: '1px solid #979797',
    minHeight: '19rem',
  },
  commentsWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    top: 0,
    left: 0,
  },
  desktopCommentsWrapper: {
    position: 'absolute',
    padding: '1rem',
  },
  noComments: {
    marginTop: '1rem',
  },
  bacteriaDesc: {
    padding: '0 1rem 1rem 1rem',
  },
  commentsContainer: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  mobileCommentsContainer: {
    maxHeight: theme.spacing(20),
  },
  listWrapper: {
    position: 'relative',
    height: '100%',
    overflowY: 'auto',
    flexGrow: '1',
    marginRight: '-1rem',
  },
  commentList: {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    padding: '0',
    position: 'absolute',
    paddingRight: '1rem',
  },
  mobileCommentList: {
    position: 'relative',
  },
  textFieldWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: '1rem',
  },
  textField: {
    flexGrow: 1,
  },
  sendButton: {
    height: '2.9rem',
    marginLeft: '1rem',
  },
  commentOuterWrapper: {
    padding: '0.6rem 0',
    '&>p': {
      wordBreak: 'break-word',
    },
  },
  commentInnerWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: '1.8rem',
    height: 'auto',
    marginRight: '0.4rem',
    marginBottom: '0.2rem',
  },
  spinner: {
    margin: 'auto',
  },
  moreIcon: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    '&>svg': {
      cursor: 'pointer',
    },
  },
  button: {
    padding: '0.5rem',
    '&>span': {
      lineHeight: '1rem',
    },
  },
  editCommentButtonContainer: {
    paddingTop: '0.6rem',
    '&>button+button': {
      marginLeft: '0.6rem',
    },
  },
  mobileEditCommentButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  editCommentInputField: {
    marginTop: '0.25rem',
    padding: '0.75rem',
  },
  edited: {
    color: '#979899',
    fontSize: '0.8rem',
  },
}));

export default function CommentCard(props) {
  const { t } = useTranslation(['samplesDetails']);
  const { isMobile, adminView, assignmentId, updateSampleOnAddComment, refreshComments } = props;
  const classes = useStyles();
  const [inProgressComment, setInProgressComment] = useState('');
  const [isLoadingSubmitComment, setIsLoadingSubmitComment] = useState(false);
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [clickedComment, setClickedComment] = useState(null);
  const [editComment, setEditComment] = useState(null);
  const [updatedComment, setUpdatedComment] = useState(null);
  const [reloadComments, setReloadComments] = useState(false);
  const [isLoadingUpdateComment, setIsLoadingUpdateComment] = useState(false);
  const dispatch = useDispatch();

  const view = useSelector((state) => state.viewReducer.selectedView);

  useEffect(() => {
    setIsLoading(true);
  }, [assignmentId]);

  useEffect(() => {
    async function fetchComments() {
      const fetchComments = await AssignmentCommentsClient.GetCommentsByAssignmentId({
        assignmentId: assignmentId,
      });
      if (fetchComments instanceof Error) {
        dispatch(openSnackbar('warning', 'fetchCommentsError'));
      } else {
        setComments(fetchComments);
      }
      setIsLoading(false);
      setReloadComments(false);
    }

    if (assignmentId || reloadComments) {
      fetchComments();
    }
  }, [assignmentId, dispatch, reloadComments]);

  async function updateComment(commentId, editedComment) {
    const response = await AssignmentCommentsClient.PutAssignmentComment(commentId, {
      updatedText: editedComment,
    }).then((response) => {
      return response;
    });

    if (response instanceof Error) {
      dispatch(openSnackbar('warning', 'somethingWentWrong'));
      setIsLoadingUpdateComment(false);
    } else {
      setIsLoadingUpdateComment(false);
      setReloadComments(true);
      if (refreshComments) {
        refreshComments();
      }
    }
  }

  async function deleteComment(commentId) {
    const response = await AssignmentCommentsClient.DeleteAssignmentComment(commentId).then(
      (response) => {
        return response;
      },
    );

    if (response instanceof Error) {
      dispatch(openSnackbar('warning', 'somethingWentWrong'));
      setIsLoadingUpdateComment(false);
    } else {
      setIsLoadingUpdateComment(false);
      setReloadComments(true);
      if (refreshComments) {
        refreshComments();
      }
    }
  }

  const closeDeleteDialog = useCallback(() => {
    setShowDeleteDialog(false);
  }, []);

  const openDeleteDialog = useCallback(() => {
    setShowDeleteDialog(true);
  }, []);

  const closeEditMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const selectCommentToEdit = useCallback((comment) => {
    setEditComment(comment);
    setUpdatedComment(comment.commentText);
  }, []);

  const theComments = comments.map((comment) => {
    return (
      <div key={comment.assignmentCommentId} className={classes.commentOuterWrapper}>
        <div className={classes.commentInnerWrapper}>
          <img src={getAvatar(comment.user.roles)} alt='avatar' className={classes.avatar} />
          <div>
            <Typography variant='h6' gutterBottom>
              {comment.user.name}
            </Typography>
            <Typography variant='body2' color='textSecondary' gutterBottom>
              {yyyymmddhhmm(new Date(comment.createdAt))}
            </Typography>
          </div>
          <div className={classes.moreIcon}>
            {comment.user.userid === localStorage.getItem('userid') && (
              <MoreHorizIcon
                data-cy='comment-settings-icon'
                aria-controls='edit-menu'
                aria-haspopup='true'
                disabled={reloadComments || isLoadingUpdateComment}
                onClick={(event) => {
                  setClickedComment(comment);
                  setAnchorEl(event.currentTarget);
                }}
              />
            )}
          </div>
        </div>
        {editComment === comment ? (
          <>
            <TextField
              style={{ width: '100%' }}
              multiline={true}
              variant='filled'
              id='edit-comment'
              value={updatedComment}
              disabled={isLoadingUpdateComment || reloadComments}
              InputProps={{
                disableUnderline: true,
                classes: { multiline: classes.editCommentInputField },
              }}
              onChange={(e) => setUpdatedComment(e.target.value)}
            />
            <div
              className={
                isMobile
                  ? `${classes.editCommentButtonContainer} ${classes.mobileEditCommentButtonContainer}`
                  : classes.editCommentButtonContainer
              }
            >
              <Button
                onClick={() => setEditComment(null)}
                classes={{ root: classes.button }}
                variant='outlined'
                color='primary'
                disableElevation
                disabled={isLoadingUpdateComment || reloadComments}
              >
                {t('commentCard:cancel')}
              </Button>
              <Button
                classes={{ root: classes.button }}
                variant='contained'
                disableElevation
                color='primary'
                disabled={
                  isLoadingUpdateComment ||
                  reloadComments ||
                  comment.commentText === updatedComment ||
                  !updatedComment
                }
                onClick={() => {
                  setIsLoadingUpdateComment(true);
                  updateComment(comment.assignmentCommentId, updatedComment);
                }}
              >
                {t('commentCard:save')}
              </Button>
            </div>
          </>
        ) : (
          <>
            <Typography display='inline'>{comment.commentText}</Typography>
            {comment.modifiedAt && comment.createdAt !== comment.modifiedAt && (
              <Typography display='inline' className={classes.edited}>
                {t('commentCard:edited')}
              </Typography>
            )}
          </>
        )}
      </div>
    );
  });

  for (let index = 0; index < theComments.length; ) {
    const divider = <Divider key={index.toString() + 'gettdse4262gdfs'} component='li' />;
    theComments.splice(index + 1, 0, divider);
    index = index + 2;
  }

  async function onCommentSubmit() {
    const currentValue = inProgressComment;
    if (undefined === currentValue || '' === currentValue || null === currentValue) {
      setIsLoadingSubmitComment(false);
      return;
    }
    const newComment = {
      assignmentId: assignmentId,
      commentText: currentValue,
    };
    const response = await AssignmentCommentsClient.PostAssignmentComment(newComment);

    if (response instanceof Error) {
      dispatch(openSnackbar('warning', 'somethingWentWrong'));
    } else if (response) {
      const responseComment = {
        assignmentCommentId: response.assignmentCommentId,
        createdAt: response.createdAt,
        commentText: response.commentText,
        user: response.user,
      };

      setComments([...comments, responseComment]);
      setInProgressComment('');
      if (updateSampleOnAddComment && view === 'farmer') {
        updateSampleOnAddComment();
      }
    }
    setIsLoadingSubmitComment(false);

    if (refreshComments) {
      refreshComments();
    }
  }

  const spinner = (
    <div className={classes.spinner}>
      <Fade
        in={isLoading}
        style={{
          transitionDelay: isLoading ? '300ms' : '0ms',
        }}
        unmountOnExit
      >
        <CircularProgress color='primary' size='2rem' />
      </Fade>
    </div>
  );

  return (
    <>
      <Card
        className={
          adminView
            ? classes.adminViewCard
            : isMobile && view === 'farmer'
            ? classes.farmerMobileCard
            : classes.card
        }
        elevation={isMobile && view === 'farmer' ? 0 : 1}
      >
        <div
          className={
            isMobile
              ? classes.commentsWrapper
              : `${classes.commentsWrapper} ${classes.desktopCommentsWrapper}`
          }
        >
          <Typography variant='subtitle1' gutterBottom>
            {t('commentCard:comments')}
          </Typography>

          <div
            className={
              isMobile
                ? `${classes.commentsContainer} ${classes.mobileCommentsContainer}`
                : classes.commentsContainer
            }
          >
            {isLoading ? (
              spinner
            ) : comments && comments.length !== 0 ? (
              <div className={classes.listWrapper}>
                <List
                  className={
                    isMobile
                      ? `${classes.commentList} ${classes.mobileCommentList}`
                      : classes.commentList
                  }
                >
                  {theComments}
                </List>
              </div>
            ) : (
              <Typography variant='body2' color='textSecondary' className={classes.noComments}>
                {t('commentCard:noComments')}
              </Typography>
            )}

            <div className={classes.textFieldWrapper}>
              <TextField
                className={classes.textField}
                label={t('commentCard:newCommentPlaceholder')}
                multiline={true}
                variant='filled'
                id='comment-input'
                size='small'
                value={inProgressComment}
                onChange={(e) => setInProgressComment(e.target.value)}
                disabled={isLoadingSubmitComment}
              />
              <Button
                variant='contained'
                color='primary'
                className={classes.sendButton}
                disabled={!inProgressComment || isLoadingSubmitComment}
                onClick={() => {
                  onCommentSubmit();
                  setIsLoadingSubmitComment(true);
                }}
              >
                {t('commentCard:submitButtonLabel')}
              </Button>
            </div>
          </div>
        </div>
      </Card>
      {showDeleteDialog && (
        <DeleteDialog
          close={closeDeleteDialog}
          comment={clickedComment}
          deleteComment={deleteComment}
        />
      )}

      <EditMenu
        anchorEl={anchorEl}
        close={closeEditMenu}
        openDeleteDialog={openDeleteDialog}
        startEditing={selectCommentToEdit}
        comment={clickedComment}
      />
    </>
  );
}

CommentCard.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  adminView: PropTypes.bool,
  updateSampleOnAddComment: PropTypes.func,
  refreshComments: PropTypes.func,
};
