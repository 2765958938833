import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

export const PrivateRoute = ({ children, signedInState, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        switch (signedInState) {
          case 'signedIn':
            return children;
          case 'pending':
            return <></>;
          case 'signedOut':
            return (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            );
          default:
            return <></>;
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.object.isRequired,
  signedInState: PropTypes.string.isRequired,
};
