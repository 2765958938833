import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackbar } from '../../../../../../actions/mainActions';
import { openModal } from '../../../../../../actions/modalActions';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { yyyymmdd } from '../../../../../../Services/Utility/DateUtils';
import { getUdderPart } from '../../../../../../Services/Utility/Udder';
import { getFarmerSampleStatus } from '../../../../../../Services/Utility/SampleInformation';
import { SamplesClient } from '../../../../../../Services/Utility/WebApiClient';

const useStyles = makeStyles({
  panelContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  padding: {
    padding: '1rem',
  },
  historyHeaderText: {
    marginBottom: '0.3rem',
    textAlign: 'left',
  },
  historyListWrapper: {
    maxHeight: '12rem',
    overflowY: 'auto',
    marginRight: '-1rem',
    marginLeft: '-0.5rem',
    paddingRight: '1rem',
  },
  historyLine: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    height: '1.8rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '0.5rem',
    '&:hover': {
      backgroundColor: '#CECECE',
    },
  },
  dateText: {
    maxWidth: '30%',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  udderPartText: {
    maxWidth: '20%',
  },
  bacteriaTextWrapper: {
    width: '50%',
    overflow: 'hidden',
    lineHeight: '0',
  },
  bacteriaText: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis',
  },
  bold: {
    fontWeight: '500',
  },
  link: {
    '&:hover': {
      color: 'black',
    },
  },
});

export default function CowHistory(props) {
  const { subjectId } = props;
  const classes = useStyles();
  const { t } = useTranslation(['samplesDetails']);
  const dispatch = useDispatch();
  const view = useSelector((state) => state.modalReducer.view);

  const [historySamples, setHistorySamples] = useState([]);

  useEffect(() => {
    async function fetchHistorySamples() {
      const fetchSamples = await SamplesClient.GetSamplesBySubjectId(subjectId);
      if (fetchSamples instanceof Error) {
        dispatch(openSnackbar('warning', 'fetchHistoryError'));
      } else {
        setHistorySamples(fetchSamples);
      }
    }

    fetchHistorySamples();
  }, [dispatch, subjectId]);

  const HistoryList = () => {
    return (
      <div className={classes.historyListWrapper}>
        {historySamples.map((historySample) => {
          return (
            <Link
              to={`/samples/${historySample.sampleId}`}
              key={historySample.sampleId}
              className={classes.link}
            >
              <div
                onClick={() => dispatch(openModal(historySample, view, false))}
                className={classes.historyLine}
              >
                <Typography
                  variant='body2'
                  className={`${classes.historyText} ${classes.dateText}`}
                >
                  {yyyymmdd(new Date(historySample.createdAt))}
                </Typography>
                <Typography
                  variant='body1'
                  className={`${classes.historyText} ${classes.udderPartText}`}
                >
                  {getUdderPart(
                    historySample.udderId,
                    'samplesDetails:historyCard:FL',
                    'samplesDetails:historyCard:FR',
                    'samplesDetails:historyCard:BL',
                    'samplesDetails:historyCard:BR',
                    null,
                  )}
                </Typography>
                <span className={classes.bacteriaTextWrapper}>
                  <Typography variant='body1' className={`${classes.bacteriaText} ${classes.bold}`}>
                    {getFarmerSampleStatus(historySample)}
                  </Typography>
                </span>
              </div>
            </Link>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {historySamples ? (
        <div
          className={
            view === 'farmer' ? classes.panelContent : `${classes.panelContent} ${classes.padding}`
          }
        >
          {view === 'bacteriologist' && (
            <Typography>{t('historyCard:cowHistoryHeader')}</Typography>
          )}
          <Typography variant='body2' color='textSecondary' className={classes.historyHeaderText}>
            {t('historyCard:clickOnHistorySample')}
          </Typography>
          {view === 'farmer' && <Divider />}
          <HistoryList />
        </div>
      ) : (
        <Typography variant='body2' color='textSecondary' className={classes.historyHeaderText}>
          {t('historyCard:noHistory')}
        </Typography>
      )}
    </>
  );
}

CowHistory.propTypes = {
  subjectId: PropTypes.string.isRequired,
};
