import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Dialog,
  Typography,
  IconButton,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import svLocale from 'date-fns/locale/sv';
import frLocale from 'date-fns/locale/fr';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { closeExportDialog, openSnackbar } from '../../actions/mainActions';
import { fileDateFormat } from '../../Services/Utility/DateUtils';
import { isSameDay, subMonths } from 'date-fns';
import { ExportClient } from '../../Services/Utility/WebApiClient';

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: theme.spacing(30),
    minHeight: theme.spacing(26),
    padding: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
  },
  dialogTop: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    alignSelf: 'start',
    margin: theme.spacing(-1),
  },
  selectContainer: {
    padding: theme.spacing(1, 0, 0.5),
  },
  formControl: {
    '& label': {
      marginBottom: 0,
    },
  },
  pickDateRangeContainer: {
    '& .MuiFormControl-root:first-of-type': {
      marginRight: theme.spacing(1),
    },
  },
  datePicker: {
    width: theme.spacing(11),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexGrow: 1,
    '& button': {
      width: theme.spacing(5),
    },
    '&>button+button': {
      marginLeft: theme.spacing(0.5),
    },
  },
  downloadStartedDialog: {
    padding: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    minWidth: theme.spacing(18),
    minHeight: theme.spacing(6),
  },
  downloadStartedDialogTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function ExportCowsDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const now = new Date();
  const { t, i18n } = useTranslation(['samplesFarmer']);
  const [fileFormat, setFileFormat] = useState('xlsx');
  const [startDate, setStartDate] = useState(subMonths(now, 3));
  const [endDate, setEndDate] = useState(now);
  const [startDateErrorMessage, setStartDateErrorMessage] = useState('');
  const [endDateErrorMessage, setEndDateErrorMessage] = useState('');
  const [downloadStarted, setDownloadStarted] = useState(false);

  const selectedSiteId = useSelector((state) => state.siteReducer.selectedSiteId);

  function handleChangeFileFormat(e) {
    setFileFormat(e.target.value);
  }

  const handleChangeStartDate = (date) => {
    if (date > now) {
      setStartDateErrorMessage('futureDate');
    } else if (date > endDate) {
      setEndDateErrorMessage('endDateSmallerThanStartDate');
      if (date <= now) {
        setStartDateErrorMessage('');
      }
    } else {
      if (endDateErrorMessage === 'endDateSmallerThanStartDate' && date <= endDate) {
        setEndDateErrorMessage('');
      }
      setStartDateErrorMessage('');
    }
    setStartDate(date);
  };

  const handleChangeEndDate = (date) => {
    if (date > now) {
      setEndDateErrorMessage('futureDate');
    } else if (!isSameDay(date, startDate) && date < startDate) {
      setEndDateErrorMessage('endDateSmallerThanStartDate');
    } else {
      setEndDateErrorMessage('');
    }
    setEndDate(date);
  };

  function getFileName(responseHeader, fileFormat) {
    let fileName = '';

    // Check if a filename already exists for this file
    if (responseHeader?.['content-disposition']) {
      const headerval = responseHeader['content-disposition'];
      const headerFileName = headerval
        .split(';')[1]
        .split('=')[1]
        .replace('"', '')
        .replace('"', '');
      fileName = headerFileName;
    } else {
      fileName = `${`Bacticam_${fileDateFormat(now)}`}.${fileFormat}`;
    }

    return fileName;
  }

  async function exportSampleData(fileFormat, startDate, endDate) {
    const response = await ExportClient.Download({
      siteId: selectedSiteId,
      from: startDate,
      to: endDate,
      fileType: fileFormat,
    });
    if (response instanceof Error) {
      dispatch(openSnackbar('warning', 'fetchError'));
    } else {
      setDownloadStarted(true);

      let blob = new Blob([response.data]);
      if (fileFormat === 'csv') {
        blob = new Blob(['\ufeff', response.data]);
      }

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', getFileName(response.header, fileFormat));
      document.body.appendChild(link);
      link.click();
    }
  }

  function onClickExport(fileFormat, startDate, endDate) {
    const startDateISO = startDate.toISOString();
    const endDateISO = endDate.toISOString();

    exportSampleData(fileFormat, startDateISO, endDateISO);
  }

  const SelectFileFormat = () => {
    return (
      <div className={classes.selectContainer}>
        <Typography variant='h5'>{t('export:fileFormat')}</Typography>
        <FormControl component='fieldset' classes={{ root: classes.formControl }}>
          <RadioGroup
            aria-label='fileFormat'
            name='fileFormat'
            value={fileFormat}
            onChange={handleChangeFileFormat}
          >
            <FormControlLabel
              value='xlsx'
              control={<Radio color='primary' />}
              label='Excel (.xlsx)'
            />
            <FormControlLabel value='csv' control={<Radio color='primary' />} label='CSV (.csv)' />
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  const SelectDateRange = () => {
    return (
      <div className={classes.pickDateRangeContainer}>
        <Typography variant='h5'>{t('export:dateRange')}</Typography>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={i18n.language === 'sv' ? svLocale : i18n.language === 'fr' ? frLocale : null}
        >
          <KeyboardDatePicker
            classes={{ root: classes.datePicker }}
            disableToolbar
            variant='inline'
            format='dd/MM/yyyy'
            margin='normal'
            id='date-picker-inline'
            label={t('export:startDate')}
            value={startDate}
            onChange={handleChangeStartDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            error={Boolean(startDateErrorMessage)}
            helperText={startDateErrorMessage ? t(`export:${startDateErrorMessage}`) : ''}
          />
          <KeyboardDatePicker
            classes={{ root: classes.datePicker }}
            disableToolbar
            variant='inline'
            format='dd/MM/yyyy'
            margin='normal'
            id='date-picker-inline'
            label={t('export:endDate')}
            value={endDate}
            onChange={handleChangeEndDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            error={Boolean(endDateErrorMessage)}
            helperText={endDateErrorMessage ? t(`export:${endDateErrorMessage}`) : ''}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  };

  const DownloadStartedView = () => {
    return (
      <div className={classes.downloadStartedDialog}>
        <div className={classes.downloadStartedDialogTop}>
          <Typography variant='h3' gutterBottom>
            {t('export:exportList')}
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              dispatch(closeExportDialog());
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Typography variant='body2'>{t('export:downloadStarted')}</Typography>
      </div>
    );
  };

  return (
    <Dialog open={true} onClose={() => dispatch(closeExportDialog())}>
      {downloadStarted ? (
        <DownloadStartedView />
      ) : (
        <div className={classes.dialog}>
          <div className={classes.dialogTop}>
            <div>
              <Typography variant='h3' gutterBottom>
                {t('export:exportList')}
              </Typography>
              <Typography variant='body2'>
                {t('export:selectFileFormat')} <br /> {t('export:warning')}
              </Typography>
            </div>
            <IconButton
              className={classes.closeButton}
              onClick={() => dispatch(closeExportDialog())}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <SelectFileFormat />
          <SelectDateRange />
          <div className={classes.buttonContainer}>
            <Button
              variant='outlined'
              color='primary'
              disableElevation
              size='small'
              onClick={() => dispatch(closeExportDialog())}
            >
              {t('export:cancel')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              size='small'
              disabled={Boolean(startDateErrorMessage) || Boolean(endDateErrorMessage)}
              onClick={() => onClickExport(fileFormat, startDate, endDate)}
            >
              {t('export:export')}
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
}
