import React, { useState, useCallback } from 'react';
import SnackbarComponent from '../SnackbarComponent';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import logo from './agricamlogga.png';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { ReactComponent as MilkSampleIcon } from '../../icons/milksampleicon.svg';

import {
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  Select,
  Input,
  MenuItem,
  FormControl,
  FormHelperText,
  NativeSelect,
  Grid,
  Switch,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PieChartIcon from '@material-ui/icons/PieChart';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import HelpIcon from '@material-ui/icons/Help';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import InputIcon from '@material-ui/icons/Input';
import MessageIcon from '@material-ui/icons/Message';
import PermissionCheck from '../../Services/PermissionCheck';

import { useSelector, useDispatch } from 'react-redux';
import { openFeedbackDialog, closeFeedbackDialog, openSnackbar } from '../../actions/mainActions';
import { selectSite } from '../../actions/siteActions';
import { farmerOrBacteriologist } from '../../actions/sampleActions';
import { closeModal } from '../../actions/modalActions';
import WarningBanner from '../WarningBanner';
import MobileTopBar from './MobileTopBar';
import { FeedbackClient } from '../../Services/Utility/WebApiClient';
import ExternalLink from './ExternalLink';
import AgricamSVG from '../../icons/agricam_icon.svg';
import StatisticsDialog from './StatisticsDialog';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  navBar: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  greenBar: {
    zIndex: '1250',
    background: theme.palette.primary.main,
    position: 'fixed',
    width: drawerWidth,
    padding: '1.5rem 0 1.5rem 0',
    display: 'flex',
    justifyContent: 'center',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    width: drawerWidth,
    flexShrink: 0,
    zIndex: '1240',
  },
  drawerPaper: {
    width: drawerWidth,
    border: 'none',
    top: '50px',
  },
  drawerPaperDesktopPosition: {
    top: '7rem',
    height: 'calc(100% - 7rem)',
  },
  selectedItem: {
    backgroundColor: 'lightgray',
  },
  supportText: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  versionText: {
    textAlign: 'center',
  },
  listInformation: {
    diplay: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  logoStyle: {
    height: '45px',
    width: '180px',
  },
  dividerStyle: {
    margin: '8px 0',
  },
  feedbackWrapper: {
    cursor: 'pointer',
  },
  greenCircleIcon: {
    background: theme.palette.primary.main,
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    textAlign: 'center',
  },
  feedbackIcon: {
    color: 'white',
    fontSize: '1.5rem',
    paddingTop: '0.3rem',
  },
  leaveFeedback: {
    color: theme.palette.primary.main,
  },
  linkStyleGreen: {
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  milkSampleIcon: {
    width: '1em',
    height: '1em',
    fontSize: '1.5rem',
    '&>g>g>.cls-2': {
      fill: '#707070',
    },
  },

  adminSwitch: {
    alignSelf: 'center',
  },
  switchStyle: {
    color: theme.palette.lightGrays.gray20,
  },
  switchTrackStyle: {
    backgroundColor: `${theme.palette.darkGrays.gray70} !important`,
  },
  list: {
    '&>a:not(:nth-last-of-type(-n+2)):hover': {
      color: 'black',
    },
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  empty: {
    display: 'flex',
    flex: 1,
  },
  roundedImage: {
    backgroundColor: theme.palette.complementColors.blueGrey,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    padding: '5px',
    marginRight: '24px',
    position: 'relative',

    '&>svg': {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  },
  pieChartIcon: {
    marginRight: '30px',
    color: '#757575',
  },
  helpIcon: {
    marginRight: '30px',
    color: '#757575',
  },
  dialogLink: {
    textDecoration: 'none',
    color: 'inherit',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: '8px',
    height: '12px',
    width: '12px',
  },
}));

export default function Nav(props) {
  const { signedInState } = props;
  const isMobile = useMediaQuery('(max-width:1160px)');
  const classes = useStyles();
  const { t, i18n } = useTranslation(['main', 'data']);
  const history = useHistory();
  const feedbackOpen = useSelector((state) => state.feedbackReducer.isOpen);
  const snackbarOpen = useSelector((state) => state.snackbarReducer.isOpen);
  const modalOpen = useSelector((state) => state.modalReducer.isOpen);
  const selectedSiteId = useSelector((state) => state.siteReducer.selectedSiteId);
  const availableSites = useSelector((state) => state.siteReducer.availableSites);
  const view = useSelector((state) => state.viewReducer.selectedView);
  const dispatch = useDispatch();
  const isAdmin = PermissionCheck.isAdmin();
  const canClassify = PermissionCheck.canClassify();
  const [feedbackEmpty, setFeedbackEmpty] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileStatisticsDialogOpen, setMobileStatisticsDialogOpen] = useState(false);
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['site']); //'cookies' is never used, but needs to be declared to access the setCookie function
  const language = i18n.language ? i18n.language.slice(0, 2) : 'en';

  const handleSnackbarOpen = useCallback(
    (severity, text) => {
      dispatch(openSnackbar(severity, text));
    },
    [dispatch],
  );

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  function handleFeedbackOpen() {
    dispatch(openFeedbackDialog());
    setFeedbackEmpty(false);
  }

  function handleSend() {
    if (
      document.getElementById('feedback-input').value === undefined ||
      document.getElementById('feedback-input').value === ''
    ) {
      setFeedbackEmpty(true);
      return;
    }

    (async () => {
      try {
        const feedback = {
          text: document.getElementById('feedback-input').value,
        };

        const feedbackResponse = await FeedbackClient.PostFeedback(feedback).then((response) => {
          if (response === undefined) {
            // did not get a response, server probably down
            return null;
          }
          return response;
        });

        if (feedbackResponse !== null && feedbackResponse !== undefined) {
          switch (feedbackResponse.status) {
            case 500:
              handleSnackbarOpen('success', 'main:feedback:feedbackFailed');
              break;
            default:
              dispatch(closeFeedbackDialog());
              handleSnackbarOpen('success', 'main:feedback:feedbackSent');
              break;
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }

  const handleChangeSite = (event) => {
    setCookie('site', event.target.value);
    dispatch(selectSite(event.target.value));
    handleDrawerToggle();
    history.push('/samples');
  };

  function getSiteInfo() {
    if (availableSites.length > 0 && selectedSiteId) {
      if (availableSites.length > 1) {
        return (
          <ListItem>
            <FormControl fullWidth>
              <NativeSelect
                value={selectedSiteId}
                onChange={handleChangeSite}
                input={<Input disableUnderline={true} name='name' />}
              >
                {availableSites.map((site) => {
                  return (
                    <option key={site.siteid} value={site.siteid}>
                      {site.name}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          </ListItem>
        );
      } else {
        return (
          <ListItem>
            <ListItemText>{availableSites[0].name}</ListItemText>
          </ListItem>
        );
      }
    }
  }

  // Button to switch between views, only for admins.
  const switchViewButton = (
    <Typography variant='body2' component='div' className={classes.adminSwitch}>
      <Grid component='label' container alignItems='center' spacing={0}>
        <Grid item>{t('navigation:bacteriologist')}</Grid>
        <Grid item>
          <Switch
            classes={{ thumb: classes.switchStyle, track: classes.switchTrackStyle }}
            checked={view === 'farmer'}
            onChange={() => {
              if (modalOpen) {
                dispatch(closeModal());
              }
              dispatch(farmerOrBacteriologist(view === 'farmer' ? 'bacteriologist' : 'farmer'));
            }}
          />
        </Grid>
        <Grid item>{t('navigation:farmer')}</Grid>
      </Grid>
    </Typography>
  );

  return (
    <div className={classes.navBar}>
      <CssBaseline />
      {isMobile ? (
        <>
          <WarningBanner />
          <MobileTopBar
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            signedInState={signedInState}
          />
        </>
      ) : (
        <div className={classes.greenBar}>
          <Toolbar>
            <NavLink to={'/samples'}>
              <img src={logo} alt={'Agricam'} className={classes.logoStyle} hidden={!!isMobile} />
            </NavLink>
          </Toolbar>
        </div>
      )}

      <Drawer
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        className={isMobile ? `${classes.drawer} ${classes.drawerMobile}` : classes.drawer}
        variant={isMobile ? 'temporary' : 'permanent'}
        classes={{
          paper: isMobile
            ? classes.drawerPaper
            : `${classes.drawerPaper} ${classes.drawerPaperDesktopPosition}`,
        }}
      >
        <List disablePadding={true} className={classes.list}>
          {isMobile && (
            <>
              <ListItem>
                <ListItemText>{localStorage.getItem('name')}</ListItemText>
              </ListItem>
              {view === 'farmer' && getSiteInfo()}
            </>
          )}
          <ListItemLink
            to='/samples'
            primary={t('navigation:milkSamples')}
            icon={<MilkSampleIcon className={classes.milkSampleIcon} />}
            setMobileOpen={setMobileOpen}
          />
          {canClassify && (
            <ListItemLink
              to='/comments'
              primary={t('navigation:Comments')}
              icon={<MessageIcon />}
              setMobileOpen={setMobileOpen}
            />
          )}
          {isAdmin && (
            <ListItemLink
              to='/admin'
              primary={t('navigation:Admin')}
              icon={<SupervisorAccountIcon />}
              setMobileOpen={setMobileOpen}
            />
          )}
          {!isMobile && <div className={classes.empty}></div>}
          {!isMobile && isAdmin && switchViewButton}
          {isMobile && (
            <>
              <ListItemLink
                to='/settings'
                primary={t('navigation:Settings')}
                icon={<SettingsIcon />}
                setMobileOpen={setMobileOpen}
              />
              <div className={classes.empty}></div>
              {isAdmin && switchViewButton}
              <Divider />
              <NavLink to={'/login'}>
                <ListItem
                  button
                  key={'logout'}
                  onClick={() => {
                    props.onSignout();
                    if (modalOpen) {
                      dispatch(closeModal());
                    }
                  }}
                >
                  <ListItemIcon>
                    <InputIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navigation:Logout')} />
                </ListItem>
              </NavLink>
              <ListItem>
                <FormControl fullWidth>
                  <FormHelperText>{t('navigation:Change Language')}</FormHelperText>
                  <Select
                    value={language}
                    onChange={(e) => i18n.changeLanguage(e.target.value)}
                    name='name'
                    input={<Input name='name' />}
                    IconComponent={KeyboardArrowUpIcon}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      },
                      transformOrigin: {
                        vertical: 'center',
                        horizontal: 'right',
                      },
                    }}
                  >
                    <MenuItem value='sv'>
                      <Typography variant='caption' align='center'>
                        Svenska
                      </Typography>
                    </MenuItem>
                    <MenuItem value='en'>
                      <Typography variant='caption' align='center'>
                        English
                      </Typography>
                    </MenuItem>
                    <MenuItem value='fr'>
                      <Typography variant='caption' align='center'>
                        Français
                      </Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </ListItem>
            </>
          )}
          <Divider className={classes.dividerStyle} />
          <ExternalLink
            url={process.env.REACT_APP_PORTAL_URL}
            text={t('navigation:Portal')}
            icon={<img src={AgricamSVG} className={classes.roundedImage} alt='Portalen icon' />}
          />
          {isMobile && (
            <div className={classes.dialogLink} onClick={() => setMobileStatisticsDialogOpen(true)}>
              {<PieChartIcon alt='Statistics icon' className={classes.pieChartIcon} />}
              <Typography>{t('navigation:Statistics')}</Typography>
              <InfoOutlinedIcon className={classes.icon} />
            </div>
          )}
          {isMobile && mobileStatisticsDialogOpen && (
            <StatisticsDialog closeDialog={() => setMobileStatisticsDialogOpen(false)} />
          )}
          {!isMobile && (
            <ExternalLink
              url={process.env.REACT_APP_PORTAL_URL + '/statistics/bacticam/bacteria-distribution'}
              text={t('navigation:Statistics')}
              icon={<PieChartIcon alt='Statistics icon' className={classes.pieChartIcon} />}
            />
          )}
          <Divider className={classes.dividerStyle} />
          <ListItem onClick={handleFeedbackOpen} className={classes.feedbackWrapper}>
            <ListItemIcon>
              <div className={classes.greenCircleIcon}>
                <RecordVoiceOverIcon className={classes.feedbackIcon} />
              </div>
            </ListItemIcon>
            <ListItemText>
              <Typography variant='h5' className={classes.leaveFeedback}>
                {t('navigation:leaveFeedback')}
              </Typography>
            </ListItemText>
          </ListItem>
          <ExternalLink
            url={'https://knowledge.agricam.se/knowledge/bacticam'}
            text={t('navigation:Faq')}
            icon={<HelpIcon className={classes.helpIcon} alt='FAQ icon' />}
          />
          <ListItem
            component='a'
            key='Email'
            href='https://knowledge.agricam.se/knowledge/kb-tickets/new'
            classes={{ root: classes.linkStyleGreen }}
            target='_blank'
            rel='noreferrer'
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText>{t('main:contactSupport')}</ListItemText>
          </ListItem>
          <ListItem
            component='a'
            key='Phone'
            href='tel:+46768619908'
            classes={{ root: classes.linkStyleGreen }}
          >
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText>+46768619908</ListItemText>
          </ListItem>
        </List>
      </Drawer>

      {snackbarOpen && <SnackbarComponent />}

      {feedbackOpen && (
        <Dialog
          open={feedbackOpen}
          keepMounted
          onClose={() => dispatch(closeFeedbackDialog())}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='form-dialog-title'>
            <Typography variant='h4'>{t('feedback:leaveFeedbackTitle')}</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{t('feedback:leaveFeedback')}</DialogContentText>
            <TextField
              error={feedbackEmpty}
              id='feedback-input'
              label={feedbackEmpty ? t('feedback:feedbackEmpty') : t('feedback:leaveFeedbackHere')}
              multiline
              rows='5'
              variant='outlined'
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => dispatch(closeFeedbackDialog())} color='primary'>
              {t('feedback:cancel')}
            </Button>
            <Button onClick={handleSend} color='primary'>
              {t('feedback:send')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export function ListItemLink(props) {
  const { icon, primary, to, setMobileOpen, children } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const modalOpen = useSelector((state) => state.modalReducer.isOpen);

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <NavLink to={to} innerRef={ref} {...itemProps} />),
    [to],
  );

  return (
    <ListItem
      button
      component={renderLink}
      activeClassName={classes.selectedItem}
      onClick={() => {
        if (modalOpen) {
          dispatch(closeModal());
        }
        setMobileOpen(false);
      }}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primary} />
      {children}
    </ListItem>
  );
}

Nav.propTypes = {
  onSignout: PropTypes.func.isRequired,
  signedInState: PropTypes.string.isRequired,
};

ListItemLink.propTypes = {
  icon: PropTypes.object.isRequired,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  setMobileOpen: PropTypes.func.isRequired,
  children: PropTypes.node,
};
