import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import AddBacteria from './AdminCards/AddBacteria';
import PermissionCheck from '../../Services/PermissionCheck';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AddBacteriaGroup from './AdminCards/AddBacteriaGroup';

export default function AdminView() {
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { t } = useTranslation(['main']);

  useEffect(() => {
    document.title = `${t('main:navigation:Admin')} | Bacticam`;
  }, [t]);

  if (!PermissionCheck.isAdmin()) {
    return <Redirect to={'/samples'} />;
  }

  return (
    <>
      <div style={{ width: isMobile ? '100%' : '80%', maxWidth: '1200px', padding: '5px' }}>
        <AddBacteriaGroup />
        <AddBacteria />
      </div>
    </>
  );
}
