import React, { useState, useEffect } from 'react';
import { makeStyles, useMediaQuery, Dialog, Typography, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { openSnackbar } from '../../../../actions/mainActions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { BacticamSiteSettingsClient } from '../../../../Services/Utility/WebApiClient';

const useStyles = makeStyles({
  dialogContent: {
    padding: '2.5rem',
    position: 'relative',
  },
  mobileDialogContent: {
    padding: '1.75rem 1.5rem',
    position: 'relative',
  },
  updateSettingsButton: {
    marginTop: '1.5rem',
    textAlign: 'center',
    '&>span': {
      color: 'white',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: '1rem',
    cursor: 'pointer',
  },
  mobileCloseIcon: {
    margin: '0.5rem',
  },
});

export default function FarmerInfoDialog() {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const dispatch = useDispatch();
  const { t } = useTranslation(['samplesFarmer']);
  const [showDialog, setShowDialog] = useState(false);
  const [siteSettings, setSiteSettings] = useState(null);
  const siteId = useSelector((state) => state.siteReducer.selectedSiteId);

  useEffect(() => {
    async function fetchSiteSettings() {
      await BacticamSiteSettingsClient.GetSiteSettings({ siteId: siteId }).then((response) => {
        if (response instanceof Error) {
          dispatch(openSnackbar('warning', 'somethingWentWrong'));
        } else {
          setSiteSettings(response);
        }
      });
    }

    if (siteId) {
      fetchSiteSettings(siteId);
    }
  }, [dispatch, siteId]);

  useEffect(() => {
    if (
      siteSettings &&
      !siteSettings.registrationNumber &&
      window.sessionStorage.getItem('hideFarmerInfoDialog') !== 'true' &&
      i18next.language !== 'fr'
    ) {
      setShowDialog(true);
    }
  }, [siteSettings]);

  function handleClose() {
    window.sessionStorage.setItem('hideFarmerInfoDialog', true);
    setShowDialog(false);
  }

  return (
    <Dialog open={showDialog}>
      <div className={isMobile ? classes.mobileDialogContent : classes.dialogContent}>
        <CloseIcon
          className={
            isMobile ? `${classes.closeIcon} ${classes.mobileCloseIcon}` : classes.closeIcon
          }
          onClick={() => handleClose()}
        />
        <Typography gutterBottom variant={isMobile ? 'h4' : 'h3'}>
          {t('farmerInfoDialog:header')}
        </Typography>
        <Typography>{t('farmerInfoDialog:textBody')}</Typography>
        <Button
          disableElevation
          size='large'
          variant='contained'
          color='primary'
          className={classes.updateSettingsButton}
          component={Link}
          to='/settings'
        >
          {t('farmerInfoDialog:button')}
        </Button>
      </div>
    </Dialog>
  );
}
