import useSWR, { useSWRInfinite } from 'swr';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../../actions/mainActions';
import { AssignmentCommentsClient, SamplesClient } from '../../WebApiClient';

/**
 * Global hook for fetching and caching remote data using SWR library. If the fetching of data fails, a warning snackbar is displayed.
 * @param {string} key - Use to make the SWR-key unique.
 * @param {string} queryParams - The query params for clientFn.
 * @param {Object} clientFn - The web api client.
 */
const useFetch = (key, queryParams, clientFn) => {
  const dispatch = useDispatch();

  const fetcher = async (_queryParams) => {
    const response = await clientFn(_queryParams);

    if (response instanceof Error) {
      dispatch(openSnackbar('warning', 'somethingWentWrong'));
      return [];
    }

    return response;
  };

  const { data: fetchedData, mutate } = useSWR([key, ...Object.values(queryParams)], () =>
    fetcher(queryParams),
  );

  const data = fetchedData || [];
  const isLoading = !fetchedData;

  return { data, isLoading, mutate };
};

/**
 * Custom hook for fetching and caching farmer sample data.
 * Returns the data used in the farmer 'all samples' view and a loading bool.
 * @param {Object} queryParams - Parameters to pass with the endpoint
 */
export const useFarmerSamples = (queryParams) => {
  const { data: samples, isLoading: isLoadingSamples } = useFetch(
    'useFarmerSamples',
    queryParams,
    SamplesClient.GetSiteSamples,
  );

  return { samples, isLoadingSamples };
};

/**
 * Custom hook for fetching, caching and refreshing bacteriologist sample data.
 * Returns the data used in the bacteriologist 'all samples' view, loading bool and a refresh function.
 * @param {Object} queryParams - Parameters to pass with the endpoint
 */
export const useBactSamples = (queryParams) => {
  const { data: samples, isLoading: isLoadingSamples, mutate } = useFetch(
    'useBactSamples',
    queryParams,
    SamplesClient.GetSamples,
  );

  // mutate will refresh the data in the background
  const refreshSamples = () => {
    mutate();
  };

  return { samples, isLoadingSamples, refreshSamples };
};

/**
 * Custom hook for fetching and caching comments data used in /comments.
 * This hook uses useSWRInfinite instead of useSWR since /comments has infinite scroll functionality.
 * Gives access to values size and setSize
 */
export const useComments = () => {
  const dispatch = useDispatch();

  const fetcher = async (pageIndex) => {
    const data = await AssignmentCommentsClient.GetCommentsForUser({ page: pageIndex });

    if (data instanceof Error) {
      dispatch(openSnackbar('warning', 'somethingWentWrong'));
      return [];
    }

    return data;
  };

  const { data: pagesOfComments, mutate, size, setSize } = useSWRInfinite(
    (pageIndex, previousPageData) => {
      if (previousPageData && !previousPageData.length) return null;
      return pageIndex + 1;
    },
    (pageIndex) => fetcher(pageIndex),
    { revalidateAll: true },
  );

  return { pagesOfComments, mutate, size, setSize };
};
