import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { openExportDialog } from '../../actions/mainActions';
import { useDispatch } from 'react-redux';

export default function ExportCowsButton() {
  const { t } = useTranslation(['samplesFarmer']);
  const dispatch = useDispatch();

  return (
    <Button
      variant='contained'
      color='primary'
      size='large'
      disableElevation
      startIcon={<GetAppIcon />}
      onClick={() => dispatch(openExportDialog())}
    >
      {t('export:exportButton')}
    </Button>
  );
}
