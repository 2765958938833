import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import OpenInNew from '@material-ui/icons/OpenInNew';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  styledLink: {
    textDecoration: 'none',
    color: 'inherit',
    padding: '8px 16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&:hover > p, &:hover > svg': {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    marginLeft: '8px',
    height: '12px',
    width: '12px',
  },
}));

export default function ExternalLink(props) {
  const { url, icon, text } = props;
  const classes = useStyles();

  return (
    <a href={url} target={'_blank'} className={classes.styledLink} rel='noreferrer'>
      {icon && icon}
      <Typography>{text}</Typography>
      <OpenInNew className={classes.icon} />
    </a>
  );
}

ExternalLink.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};
