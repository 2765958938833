import { SELECT_SITE, SET_AVAILABLE_SITES } from '../actions/types';

/**
 * Sets the selected siteid. Used to display site specific data.
 * @param {string} selectedSiteId
 */
export function selectSite(selectedSiteId) {
  return {
    type: SELECT_SITE,
    selectedSiteId,
  };
}

/**
 * Function that takes an array of sites that the user has access to. Used to display sites to select from if the user can access more than one site.
 * @param {Array} availableSites
 */
export function setAvailableSites(availableSites) {
  return {
    type: SET_AVAILABLE_SITES,
    availableSites,
  };
}
