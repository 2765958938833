import { combineReducers } from 'redux';
import feedbackReducer from './feedbackReducer';
import snackbarReducer from './snackbarReducer';
import modalReducer from './modalReducer';
import siteReducer from './siteReducer';
import viewReducer from './viewReducer';
import PDFReducer from './PDFreducer';
import bacteriologistReducer from './bacteriologistReducer';
import exportCowsReducer from './exportCowsReducer';

const allReducers = combineReducers({
  feedbackReducer,
  snackbarReducer,
  modalReducer,
  siteReducer,
  viewReducer,
  PDFReducer,
  bacteriologistReducer,
  exportCowsReducer,
});

export default allReducers;
