import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  green: {
    color: theme.palette.primary.main,
  },
  yellow: {
    color: '#FFC672',
  },
  grey: {
    color: '#8D8D8D',
  },
  statusIconStyle: {
    width: '1rem',
    height: '1rem',
    '&:hover': { opacity: '.8' },
  },
}));

export default function StatusIcon(props) {
  const { iconType } = props;
  const classes = useStyles();
  let icon = <></>;

  switch (iconType) {
    case 'resultsAvailable':
      icon = <CheckIcon className={`${classes.green} ${classes.statusIconStyle}`} />;
      break;
    case 'preliminaryResults':
      icon = (
        <RadioButtonUncheckedIcon className={`${classes.yellow} ${classes.statusIconStyle}`} />
      );
      break;
    case 'error':
      icon = <ClearIcon className={`${classes.grey} ${classes.statusIconStyle}`} />;
      break;
    default:
      break;
  }
  return <>{iconType && <>{icon}</>}</>;
}

StatusIcon.propTypes = {
  iconType: PropTypes.string,
};
