import React from 'react';
import {
  makeStyles,
  Card,
  Divider,
  Typography,
  Button,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { useTranslation } from 'react-i18next';
import { PhotoStatus } from '../StatusProps/PhotoStatus';
import { getSmallGrayUdderIcon, getUdderPart } from '../../../../../Services/Utility/Udder';
import {
  getSampleLastActivityDate,
  getSampleInformation,
  getStatusLabel,
} from '../../../../../Services/Utility/SampleInformation';
import PropTypes from 'prop-types';
import { openModal } from '../../../../../actions/modalActions';
import { useDispatch } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import { Link } from 'react-router-dom';

const useStyle = makeStyles({
  cardStyle: {
    width: '100%',
    marginBottom: '1rem',
  },
  headerPanel: {
    padding: '1.25rem 1.5rem',
    display: 'flex',
    alignItems: 'center',
  },
  mobileHeaderPanel: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  tagContainer: {
    display: 'flex',
    '&> div + div': {
      marginLeft: '1rem',
    },
  },
  sampleInfo: {
    padding: '1.5rem 1.5rem',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1.5fr 2fr 3fr 5fr 1fr 2fr',
    '&>div>p+p': {
      color: '#8D8D8D',
    },
  },
  mobileSampleInfo: {
    display: 'grid',
    gridTemplateColumns: '2fr 3fr 1fr',
    columnGap: '0.5rem',
    rowGap: '0.8rem',
    padding: '0.5rem 0',
    '&>div:nth-of-type(3)': {
      justifySelf: 'flex-end',
      alignSelf: 'center',
    },
    '&>div>p+p': {
      color: '#8D8D8D',
    },
    '&>div:last-of-type': {
      gridColumn: 'span 2',
    },
  },
  cardContent: {
    '&>div': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '&>div:last-child': {
      borderBottom: '0px',
    },
  },
  mobileCardContent: {
    '&>div': {
      padding: '1rem',
    },
  },
  udderInfo: {
    display: 'flex',
    alignItems: 'center',
    '&>img': {
      width: '1.8rem',
      height: '1.8rem',
      marginRight: '1rem',
    },
  },
  mobileUdderInfo: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    '&>img': {
      width: '1.5rem',
      height: '1.5rem',
      marginRight: '0.5rem',
    },
  },
  udderIcon: {
    width: '1.5rem',
    height: '1.5rem',
  },
  chatIconwrapper: {
    display: 'flex',
    alignItems: 'center',
    color: '#828282',
  },
  chatIconHover: {
    '&:hover': {
      opacity: '0.4',
    },
  },
  chatIcon: {
    color: '#757575',
    width: '1.4rem',
    height: '1.4rem',
    marginLeft: '0.5rem',
  },
  showMoreButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonLabel: {
    whiteSpace: 'nowrap',
  },
  mobileShowSampleButton: {
    margin: '0 1rem 1rem 1rem',
    height: '2rem',
  },
  tooltipLabel: {
    fontSize: '0.8rem',
  },
  truncateText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  sampleStatus: {
    flex: '1 1 100%',
    minWidth: 0,
    maxWidth: '90%',
  },
  somethingWentWrong: {
    display: 'flex',
  },
  mobileSomethingWentWrong: {
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    color: '#D10505',
    marginRight: '0.5rem',
  },
});

export default function CowCard(props) {
  const { cow, activeSamples } = props;
  const classes = useStyle();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { t } = useTranslation(['samplesFarmer']);
  const dispatch = useDispatch();

  function getCommentInfo(sample) {
    if (sample.commentCount > 0) {
      return (
        <>
          <Typography>{sample.commentCount}</Typography>
          <ChatBubbleOutlineIcon className={classes.chatIcon} />
        </>
      );
    }
  }

  // Check if the sample should have extra information displayed on the CowCard.
  function checkForSampleInformation(sample) {
    const info = getSampleInformation(sample);

    if (info === 'somethingWentWrong') {
      return (
        <div className={isMobile ? classes.mobileSomethingWentWrong : classes.somethingWentWrong}>
          <WarningIcon className={classes.warningIcon} />
          <Typography className={classes.truncateText}>
            {t('cowCard:somethingWentWrong')}
          </Typography>
        </div>
      );
    } else {
      return <Typography className={classes.truncateText}>{info}</Typography>;
    }
  }

  if (isMobile)
    return (
      <Card className={classes.cardStyle} elevation={0}>
        <div className={classes.mobileHeaderPanel}>
          <Typography variant='h5'>{t('cowCard:cow', { cowNumber: cow })}</Typography>
        </div>
        <Divider />
        <div className={classes.mobileCardContent}>
          {activeSamples.map((sample) => {
            return (
              <React.Fragment key={sample.sampleId}>
                <div className={classes.mobileSampleInfo}>
                  <div className={classes.mobileUdderInfo} style={{ maxWidth: '4rem' }}>
                    <img src={getSmallGrayUdderIcon(sample.udderId)} alt='udderIcon' />
                    <Typography variant='h5'>
                      {getUdderPart(
                        sample.udderId,
                        'samplesFarmer:cowCard:FL',
                        'samplesFarmer:cowCard:FR',
                        'samplesFarmer:cowCard:BL',
                        'samplesFarmer:cowCard:BR',
                        'samplesFarmer:cowCard:udderPartUnknown',
                      )}
                    </Typography>
                  </div>
                  <div>
                    <Typography>{getSampleLastActivityDate(sample)}</Typography>
                    <Typography variant='body2'>{t('cowCard:lastUpdated')}</Typography>
                  </div>
                  <div>
                    <div className={classes.chatIconwrapper}>{getCommentInfo(sample)}</div>
                  </div>
                  <div style={{ maxWidth: '4rem' }}>
                    <PhotoStatus sample={sample} />
                  </div>
                  <div>
                    {checkForSampleInformation(sample)}
                    <Typography variant='body2'>{getStatusLabel(sample)}</Typography>
                  </div>
                </div>
                <Link to={`/samples/${sample.sampleId}`}>
                  <Button
                    id='show-sample-info'
                    data-cy='show-sample-info'
                    classes={{ label: classes.buttonLabel, root: classes.mobileShowSampleButton }}
                    variant='outlined'
                    color='primary'
                    onClick={() => {
                      dispatch(openModal(sample, 'farmer'));
                    }}
                  >
                    {t('cowCard:sampleInfo')}
                  </Button>
                </Link>
                <Divider />
              </React.Fragment>
            );
          })}
        </div>
      </Card>
    );
  else
    return (
      <Card className={classes.cardStyle} elevation={0}>
        <div className={classes.headerPanel}>
          <Typography variant='h5'>{t('cowCard:cow', { cowNumber: cow })}</Typography>
        </div>
        <Divider />

        <div className={classes.cardContent}>
          {activeSamples.map((sample) => {
            return (
              <div key={sample.sampleId} className={classes.sampleInfo}>
                <div className={classes.udderInfo}>
                  <img src={getSmallGrayUdderIcon(sample.udderId)} alt='udderIcon' />
                  <Typography variant='h5'>
                    {getUdderPart(
                      sample.udderId,
                      'samplesFarmer:cowCard:FL',
                      'samplesFarmer:cowCard:FR',
                      'samplesFarmer:cowCard:BL',
                      'samplesFarmer:cowCard:BR',
                      'samplesFarmer:cowCard:udderPartUnknown',
                    )}
                  </Typography>
                </div>
                <PhotoStatus sample={sample} />
                <div>
                  <Typography>{getSampleLastActivityDate(sample)}</Typography>
                  <Typography variant='body2'>{t('cowCard:lastUpdated')}</Typography>
                </div>
                <div className={classes.sampleStatus}>
                  {checkForSampleInformation(sample)}
                  <Typography variant='body2'>{getStatusLabel(sample)}</Typography>
                </div>
                <Tooltip
                  title={t('cowCard:commentsTooltip')}
                  classes={{ tooltip: classes.tooltipLabel }}
                >
                  <div className={`${classes.chatIconwrapper} ${classes.chatIconHover}`}>
                    {getCommentInfo(sample)}
                  </div>
                </Tooltip>
                <div className={classes.showMoreButtonWrapper}>
                  <Link to={`/samples/${sample.sampleId}`}>
                    <Button
                      id='show-sample-info'
                      data-cy='show-sample-info'
                      classes={{ label: classes.buttonLabel }}
                      variant='outlined'
                      color='primary'
                      size='small'
                      onClick={() => {
                        dispatch(openModal(sample, 'farmer'));
                      }}
                    >
                      {t('cowCard:sampleInfo')}
                    </Button>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </Card>
    );
}

CowCard.propTypes = {
  cow: PropTypes.number.isRequired,
  activeSamples: PropTypes.array.isRequired,
};
