import {
  OPEN_FEEDBACK_DIALOG,
  CLOSE_FEEDBACK_DIALOG,
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  OPEN_PDF_DIALOG,
  CLOSE_PDF_DIALOG,
  OPEN_EXPORT_DIALOG,
  CLOSE_EXPORT_DIALOG,
} from '../actions/types';

/**
 * Opens the feedback dialog when "Leave feedback"-button is clicked.
 */
export function openFeedbackDialog() {
  return {
    type: OPEN_FEEDBACK_DIALOG,
  };
}

export function closeFeedbackDialog() {
  return {
    type: CLOSE_FEEDBACK_DIALOG,
  };
}

/**
 * Opens the snackbar, providing information to the user
 * @param {string} severity The severity of the snackbar (error, info, success, warning)
 * @param {string} text The key of the localized translation to use
 * @param {object} translationParams Optional object describing the arguments to use as translation parameters
 */
export function openSnackbar(severity, text, translationParams = null) {
  return {
    type: OPEN_SNACKBAR,
    severity,
    text,
    translationParams,
  };
}

export function closeSnackbar() {
  return {
    type: CLOSE_SNACKBAR,
  };
}

/**
 * Opens the PDF dialog
 */
export function openPDFDialog(sample) {
  return {
    type: OPEN_PDF_DIALOG,
    sample,
  };
}

/**
 * Closes the PDF dialog
 */
export function closePDFDialog() {
  return {
    type: CLOSE_PDF_DIALOG,
  };
}

/**
 * Opens the export cows dialog
 */
export function openExportDialog() {
  return {
    type: OPEN_EXPORT_DIALOG,
  };
}

/**
 * Closes the export cows dialog
 */
export function closeExportDialog() {
  return {
    type: CLOSE_EXPORT_DIALOG,
  };
}
