import { Cookies } from 'react-cookie';

export class UserManager {
  /**
   * Use to access token stored in local storage.
   * @remark This token only allowed access to a specific system and should be used as the de-factor token in API requests.
   * @type string
   */
  static TOKEN = 'token';

  /**
   * Use to access token stored in cookies (is accessible across different subdomains). This token acts as a flag that the user has been authenticated.
   * @remark API requests should only use this token to get hold of a system-specific access token, as it does not guarantee access to multiple systems.
   * @type string
   */
  static ID_TOKEN = process.env.REACT_APP_COOKIE_ID_TOKEN_KEY;
  static COOKIE_OPTIONS = {
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    secure: process.env.REACT_APP_COOKIE_SECURE,
    httpOnly: false,
    sameSite: 'lax',
  };

  static setIdToken(user) {
    this.COOKIE_OPTIONS.expires = new Date(user.validTo);
    new Cookies().set(this.ID_TOKEN, user.token, this.COOKIE_OPTIONS);
  }

  static getIdToken() {
    return new Cookies().get(this.ID_TOKEN, this.COOKIE_OPTIONS);
  }

  static setUser(user) {
    localStorage.setItem('userid', user.userid);
    localStorage.setItem('siteid', user.siteid);
    localStorage.setItem('enterpriseid', user.enterpriseid);
    localStorage.setItem('name', user.name);
    localStorage.setItem('usergroups', JSON.stringify(user.usergroups));
    localStorage.setItem(this.TOKEN, user.token);

    this.COOKIE_OPTIONS.expires = new Date(user.validTo);
    new Cookies().set(this.ID_TOKEN, user.token, this.COOKIE_OPTIONS);
  }

  static removeUser() {
    localStorage.removeItem('userid');
    localStorage.removeItem('siteid');
    localStorage.removeItem('enterpriseid');
    localStorage.removeItem('name');
    localStorage.removeItem('usergroups');
    localStorage.removeItem(this.TOKEN);

    new Cookies().remove(this.ID_TOKEN, this.COOKIE_OPTIONS);
  }
}
