import React, { useState, useEffect } from 'react';
import { makeStyles, useMediaQuery, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  banner: {
    background: 'gold',
    width: '100%',
    position: 'fixed',
    zIndex: 10000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '1rem',
    '&>p': {
      fontSize: '0.9rem',
    },
  },
  bannerMobile: {
    height: '0.8rem',
    '&>p': {
      fontSize: '0.7rem',
    },
  },
});

export default function WarningBanner() {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' }).then((response) =>
      response.json().then((meta) => {
        const environment = meta.env;

        if (environment && environment !== 'prod') {
          setShowWarning(true);
        }
      }),
    );
  }, []);

  return (
    showWarning && (
      <div className={isMobile ? `${classes.banner} ${classes.bannerMobile}` : classes.banner}>
        <Typography>TESTBACTICAM</Typography>
        <Typography>FÅR EJ DEMAS</Typography>
      </div>
    )
  );
}
