import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

export default function SelectViewBar(props) {
  const { t } = useTranslation(['samplesDetails']);
  const { displayAdmin, selectAdmin } = props;
  const [showButtons, setShowButtons] = useState(false);
  const useStyles = makeStyles({
    wrapper: {
      position: 'relative',
      display: 'flex',
      backgroundColor: '#CECECE',
    },
    buttonWrapper: {
      marginLeft: '2rem',
      '&> button:first-of-type': {
        margin: '0.5rem',
      },
    },
    buttonContainer: {
      margin: '0.3rem',
      backgroundColor: '#ffffff',
      border: '3px solid #CECECE',
      borderRadius: '7px',
    },
    button: {
      outline: 'none !important',
    },
    buttonNotSelected: {
      backgroundColor: 'inherit',
      color: '#bdbdbd',
    },
    icon: {
      margin: '0.5rem',
      position: 'absolute',
      top: '0',
      zIndex: '80000',
      color: '#828282',
    },
  });

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {showButtons ? (
        <MenuOpenIcon className={classes.icon} onClick={() => setShowButtons(false)} />
      ) : (
        <MenuIcon className={classes.icon} onClick={() => setShowButtons(true)} />
      )}
      {showButtons && (
        <div className={classes.buttonWrapper}>
          <div className={classes.buttonContainer}>
            <Button
              variant='contained'
              size='small'
              disableElevation={true}
              style={{ zIndex: displayAdmin ? '2' : '1', outline: 'none' }}
              className={displayAdmin ? classes.button : classes.buttonNotSelected}
              onClick={() => selectAdmin(true)}
            >
              {t('selectViewBar:bacteriologist')}
            </Button>
            <Button
              variant='contained'
              size='small'
              disableElevation={true}
              style={{ zIndex: !displayAdmin ? '2' : '1', outline: 'none' }}
              className={!displayAdmin ? classes.button : classes.buttonNotSelected}
              onClick={() => selectAdmin(false)}
            >
              {t('selectViewBar:farmer')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

SelectViewBar.propTypes = {
  selectAdmin: PropTypes.func.isRequired,
  displayAdmin: PropTypes.bool.isRequired,
};
