import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import CommentCard from '../DetailCards/CommentCard';
import InformationCard from '../DetailCards/InformationCard';
import TimelineCard from '../DetailCards/TimelineCard';
import ImageCard from '../DetailCards/ImageCard';
import ResultCard from '../DetailCards/ResultCard';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Tab, makeStyles, Typography, Dialog } from '@material-ui/core';
import { getUdderPart } from '../../../../Services/Utility/Udder';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { openSnackbar } from '../../../../actions/mainActions';
import { openModal } from '../../../../actions/modalActions';
import FarmerVerificationCard from '../DetailCards/FarmerVerificationCard';
import {
  hasFinalBacteriaResult,
  hasPreliminaryBacteriaResult,
} from '../../../../Services/Utility/SampleInformation';
import BacteriaCard from '../DetailCards/BacteriaCard';
import { enums } from '../../../../Services/Utility/Enumerations';
import { SamplesClient } from '../../../../Services/Utility/WebApiClient';

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    maxHeight: 'calc(100% - 4.5rem)',
    paddingTop: '4.5rem',
  },
  header: {
    paddingLeft: '1rem',
  },
  tabContainer: { paddingLeft: '1rem' },
  tab: {
    padding: 0,
    textTransform: 'none',
    minWidth: 'auto',
    outline: 'none',
    marginRight: '1rem',
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    fontWeight: theme.typography.body1.fontWeight,
    letterSpacing: theme.typography.body1.letterSpacing,
  },
  tabIndicator: {
    bottom: '10px',
  },
  tabView: {
    padding: '0 1rem',
    minHeight: '77vh',
    '&>div + div': {
      marginTop: '0.3rem',
    },
    '&>div:last-of-type': {
      marginBottom: '11rem',
    },
  },
  closeDialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: '2.3rem',
  },
  imageDialog: {
    borderRadius: 0,
    width: '100%',
  },
}));

export default function MobileFarmerDetails(props) {
  const { updateFarmerSample, isUpdatingFarmerVerificationCard } = props;
  const { t } = useTranslation(['samplesDetails']);
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();

  const [tab, setTab] = useState(0);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [image, setImage] = useState('');

  const sample = useSelector((state) => state.modalReducer.sample);

  useEffect(() => {
    async function fetchSampleData(id) {
      const fetchSample = await SamplesClient.GetSample(id);
      if (fetchSample instanceof Error) {
        history.push('/samples');
        dispatch(openSnackbar('warning', 'fetchError'));
      } else if (fetchSample) {
        dispatch(openModal(fetchSample, 'farmer'));
      }
    }

    // If the opened sample and sampleID in the URL don't match, fetch and open the sample from the URL.
    if (match.params.sampleId !== undefined && sample.sampleId !== match.params?.sampleId) {
      fetchSampleData(match.params.sampleId);
    }
  });

  async function updateSampleOnAddComment() {
    await updateFarmerSample('commentCard', sample.sampleId);
  }

  async function updateSampleOnMarkAsManaged() {
    await updateFarmerSample('farmerVerificationCard', sample.sampleId);
  }

  const overViewView = (
    <div className={classes.tabView}>
      {[enums.labFlag.hasBeenSent, enums.labFlag.sendLocked].includes(sample.labFlag) && (
        <FarmerVerificationCard
          updateSampleOnMarkAsManaged={updateSampleOnMarkAsManaged}
          isUpdating={isUpdatingFarmerVerificationCard}
        />
      )}
      <ResultCard sample={sample} />
      <CommentCard
        assignmentId={sample.assignmentId}
        isMobile={true}
        updateSampleOnAddComment={updateSampleOnAddComment}
      />
      {(hasFinalBacteriaResult(sample) && (
        <BacteriaCard bacteria={sample.finalResult.bacterias[0]} />
      )) ||
        (hasPreliminaryBacteriaResult(sample) && (
          <BacteriaCard bacteria={sample.preliminaryResult.bacterias[0]} />
        ))}
    </div>
  );

  const sampleInfoView = (
    <div className={classes.tabView}>
      <InformationCard sample={sample} />
      <TimelineCard sample={sample} />
      <ImageCard
        imagePairs={sample.sampleImagePairs}
        sample={sample}
        handleOpenImageDialog={handleOpenImageDialog}
      />
      <Dialog
        classes={{ paper: classes.imageDialog }}
        open={imageDialogOpen}
        onClick={() => setImageDialogOpen(false)}
      >
        <img src={image} alt='icon' width='100%' height='auto' />
        <div className={classes.closeDialog}>
          <Typography>{t('farmerDetails:close')}</Typography>
        </div>
      </Dialog>
    </div>
  );

  function handleOpenImageDialog(image) {
    setImageDialogOpen(true);
    setImage(image);
  }

  return (
    <div className={classes.detailsContainer}>
      <Typography variant='h4' className={classes.header}>
        {sample.subject.number} -{' '}
        {getUdderPart(
          sample.udderId,
          'samplesDetails:farmerDetails:udderPartFrontLeft',
          'samplesDetails:farmerDetails:udderPartFrontRight',
          'samplesDetails:farmerDetails:udderPartBackLeft',
          'samplesDetails:farmerDetails:udderPartBackRight',
          'samplesDetails:farmerDetails:udderPartUnknown',
        )}
      </Typography>
      <Tabs
        value={tab}
        onChange={(event, value) => {
          setTab(value);
        }}
        classes={{ root: classes.tabContainer, indicator: classes.tabIndicator }}
        indicatorColor='primary'
      >
        <Tab
          label={t('farmerDetails:overview')}
          classes={{ root: classes.tab }}
          style={{ outline: 'none', indicator: classes.tabIndicator }}
          disableRipple
        ></Tab>
        <Tab
          label={t('farmerDetails:sampleInfo')}
          classes={{ root: classes.tab }}
          style={{ outline: 'none', indicator: classes.tabIndicator }}
          disableRipple
        ></Tab>
      </Tabs>
      <SwipeableViews
        containerStyle={{ height: 'calc(100vh - (50px + 5.8rem))' }}
        index={tab}
        onChangeIndex={(index) => {
          setTab(index);
        }}
      >
        {overViewView}
        {sampleInfoView}
      </SwipeableViews>
    </div>
  );
}

MobileFarmerDetails.propTypes = {
  updateFarmerSample: PropTypes.func.isRequired,
  isUpdatingFarmerVerificationCard: PropTypes.bool,
};
