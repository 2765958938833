const initialState = {
  isOpen: false,
  sample: null,
  view: null,
  startButtonIsClicked: false,
  slideShowSamples: null,
};

function modalReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        isOpen: true,
        sample: action.sample,
        view: action.view,
        startButtonIsClicked: action.startButtonIsClicked,
        slideShowSamples: action.slideShowSamples,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        isOpen: false,
        sample: '',
        view: null,
        startButtonIsClicked: false,
        slideShowSamples: null,
      };
    default:
      return state;
  }
}
export default modalReducer;
