import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../actions/modalActions';
import { useHistory, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    zIndex: '1300',
    left: '0',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    top: '0',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.47)',
  },
  contentWrapper: {
    width: '75%',
    maxWidth: '118rem',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  contentWrapperFarmerView: {
    width: '85%',
  },
  contentWrapperMobile: {
    width: '100%',
    overflowY: 'auto',
  },
  content: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 5rem)',
    backgroundColor: '#ffffff',
  },
  bactScrollbar: {
    overflowY: 'scroll',
  },
  contentMobile: {
    backgroundColor: '#f4f4f4',
  },
  close: {
    opacity: '1 !important',
    backgroundColor: '#f4f4f4',
    borderTop: '1px solid #979797',
    zIndex: '6000',
    float: 'none !important',
    textAlign: 'center',
    cursor: 'pointer',
  },
  mobileClose: {
    position: 'fixed',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: '0.6rem 30%',
    bottom: '1.4rem;',
    zIndex: '3',
    pointerEvents: 'none',
    '&> button': {
      pointerEvents: 'auto',
    },
  },
}));

export default function Modal(props) {
  const { children } = props;
  const { t } = useTranslation(['main']);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();

  const view = useSelector((state) => state.viewReducer.selectedView);

  return (
    <div
      className={classes.modalWrapper}
      onClick={(e) => {
        dispatch(closeModal());
        e.stopPropagation();

        if (match.path !== '/comments') {
          history.push('/samples');
        }
      }}
    >
      <div
        className={
          isMobile
            ? classes.contentWrapperMobile
            : view === 'farmer'
            ? `${classes.contentWrapper} ${classes.contentWrapperFarmerView}`
            : classes.contentWrapper
        }
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={
            isMobile
              ? classes.contentMobile
              : view === 'bacteriologist' && !isMobile
              ? `${classes.content} ${classes.bactScrollbar}`
              : classes.content
          }
        >
          {children}
        </div>
        {!isMobile && (
          <div
            className={classes.close}
            onClick={(e) => {
              dispatch(closeModal());
              e.stopPropagation();
              if (match.path !== '/comments') {
                history.push('/samples');
              }
            }}
          >
            <Button color='primary'>{t('modal:close')}</Button>
          </div>
        )}

        {isMobile && (
          <div className={classes.mobileClose}>
            <Button
              onClick={(e) => {
                dispatch(closeModal());
                e.stopPropagation();
                if (match.path !== '/comments') {
                  history.push('/samples');
                }
              }}
              color='primary'
              size='large'
              variant='contained'
              fullWidth={true}
            >
              {t('modal:close')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};
