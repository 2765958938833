const initialState = {
  isOpen: false,
};

function bacteriologistReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_LABFORM_DIALOG':
      return { ...state, isOpen: true };
    case 'CLOSE_LABFORM_DIALOG':
      return { ...state, isOpen: false };
    default:
      return state;
  }
}
export default bacteriologistReducer;
