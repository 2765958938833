import React, { useState, useEffect, useCallback } from 'react';
import {
  makeStyles,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { yyyymmdd, hhmm } from '../../Services/Utility/DateUtils';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../actions/modalActions';
import { openSnackbar } from '../../actions/mainActions';
import Modal from '../SampleContainer/Modal';
import BactDetails from '../SampleContainer/Details/BactDetails';
import { Redirect } from 'react-router-dom';
import PermissionCheck from '../../Services/PermissionCheck';
import { useComments } from '../../Services/Utility/CustomHooks/useFetch';
import LabFormDialog from '../SampleContainer/BactSampleContainer/LabFormDialog';
import { SamplesClient } from '../../Services/Utility/WebApiClient/index.js';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '70vw',
    height: '100vh',
  },
  wrapperMobile: {
    width: '90vw',
    height: '100vh',
    paddingTop: '1rem',
  },
  root: {
    width: '100%',
    padding: 0,
  },
  site: {
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  summaryContent: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  userAndDate: {
    padding: '0 0 0.5rem 0',
  },
  commentText: {
    wordBreak: 'break-word',
    color: theme.palette.darkGrays.gray70,
  },
  dateLabel: {
    margin: '0.5rem 0 0.5rem 1rem',
    animation: '$fadein 1s',
  },
  commentsContainer: {
    marginBottom: '4.5rem',
  },
  commentsContainerMobile: {
    marginBottom: '3rem',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
  commentCard: {
    marginBottom: '1rem',
    animation: '$fadein 1s',
  },
  '@keyframes fadein': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  editedComment: {
    color: '#AEAFB0',
  },
}));

export function DateLabel(props) {
  const { date } = props;
  const classes = useStyles();
  const { t } = useTranslation(['comments', 'samplesBacteriologist', 'samplesFarmer']);
  let dateLabel = '';

  if (date === 'today') {
    dateLabel = t(date);
  } else {
    dateLabel = yyyymmdd(date);
  }

  return (
    <div className={classes.dateLabel}>
      <Typography variant={'subtitle1'} className={classes.upperCase}>
        {dateLabel}
      </Typography>
    </div>
  );
}

export default function CommentsView() {
  const classes = useStyles();
  const { t } = useTranslation(['comments']);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:1160px)');

  const [isBottom, setIsBottom] = useState(false);
  const [sampleId, setSampleId] = useState(null);
  const [sample, setSample] = useState(null);
  const [refetchSample, setRefetchSample] = useState(false);
  const [isLoadingSample, setIsLoadingSample] = useState(false);
  const [isLoadingUpdateSample, setIsLoadingUpdateSample] = useState(false);
  const [
    isLoadingUpdateAfterSendInForVerification,
    setIsLoadingUpdateAfterSendInForVerification,
  ] = useState(false);
  const [
    isLoadingUpdateAfterCheckSendToBacteriologist,
    setIsLoadingUpdateAfterCheckSendToBacteriologist,
  ] = useState(false);
  const [isLoadingUpdateAfterSendToFinal, setIsLoadingUpdateAfterSendToFinal] = useState(false);
  const [isLoadingClassifyAsLaboratory, setIsLoadingClassifyAsLaboratory] = useState(false);
  const modalOpen = useSelector((state) => state.modalReducer.isOpen);
  const { pagesOfComments, mutate, size, setSize } = useComments();

  const loadNextPage = useCallback(() => {
    if (pagesOfComments && pagesOfComments[pagesOfComments.length - 1].length !== 0) {
      setSize(size + 1);
      setIsBottom(false);
    }
  }, [pagesOfComments, setSize, size]);

  const refreshComments = useCallback(() => {
    mutate();
  }, [mutate]);

  useEffect(() => {
    document.title = `${t('main:navigation:Comments')} | Bacticam`;
  }, [t]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (isBottom) {
      loadNextPage();
    }
  }, [isBottom, loadNextPage]);

  useEffect(() => {
    async function fetchSampleData(id) {
      const fetchSample = await SamplesClient.GetSample(id);
      if (fetchSample instanceof Error) {
        dispatch(openSnackbar('warning', 'fetchError'));
      } else if (fetchSample) {
        dispatch(openModal(fetchSample, 'bacteriologist'));
        setSample(fetchSample);
      }
      setIsLoadingSample(false);
      setRefetchSample(false);
    }
    if (sampleId || refetchSample) {
      setIsLoadingSample(true);
      fetchSampleData(sampleId);
    }
  }, [sampleId, dispatch, refetchSample]);

  async function updateSample(component, sampleId) {
    if (component === 'classificationCard') {
      setIsLoadingUpdateSample(true);
    } else if (component === 'sendToLab') {
      setIsLoadingUpdateAfterSendInForVerification(true);
    } else if (component === 'sendToBacteriologist') {
      setIsLoadingUpdateAfterCheckSendToBacteriologist(true);
    } else if (component === 'labformDialog') {
      setIsLoadingClassifyAsLaboratory(true);
    } else if (component === 'sendToFinal') {
      setIsLoadingUpdateAfterSendToFinal(true);
    }

    const fetchSample = await SamplesClient.GetSample(sampleId);

    if (fetchSample instanceof Error) {
      dispatch(openSnackbar('warning', 'somethingWentWrong'));
    } else if (fetchSample) {
      if (fetchSample.sampleId === sampleId) {
        setRefetchSample(true);
      } else {
        setSampleId(fetchSample.sampleId);
      }
    }
    setIsLoadingUpdateSample(false);
    setIsLoadingUpdateAfterSendInForVerification(false);
    setIsLoadingUpdateAfterCheckSendToBacteriologist(false);
    setIsLoadingClassifyAsLaboratory(false);
    setIsLoadingUpdateAfterSendToFinal(false);
  }

  function handleScroll() {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
      setIsBottom(true);
    }
  }

  function openSample(sampleId) {
    setSampleId(sampleId);
  }

  function getUserLocation(comment) {
    return comment.user.site?.name || comment.user.enterprise?.name || '';
  }

  const spinner = (
    <div className={classes.spinner}>
      <CircularProgress color='primary' size='3rem' />
    </div>
  );

  if (!PermissionCheck.canClassify()) {
    return <Redirect to={'/samples'} />;
  }

  return (
    <>
      <div className={isMobile ? classes.wrapperMobile : classes.wrapper}>
        <List className={classes.root}>
          {pagesOfComments &&
            pagesOfComments.map((page) => {
              return page.map((dateObj, index) => {
                return (
                  <div
                    className={
                      isMobile ? classes.commentsContainerMobile : classes.commentsContainer
                    }
                    key={index}
                  >
                    <DateLabel date={new Date(dateObj.date)} />
                    {dateObj.comments.map((comment) => {
                      return (
                        <Paper
                          key={comment.assignmentCommentId}
                          elevation={0}
                          className={classes.commentCard}
                        >
                          <ListItem className={classes.summaryContent}>
                            <ListItemText
                              secondary={
                                <Typography className={classes.commentText}>
                                  {comment.commentText}
                                </Typography>
                              }
                            >
                              <Typography className={classes.site} variant='h6'>
                                {getUserLocation(comment)}
                              </Typography>
                              <Typography color='textPrimary' className={classes.userAndDate}>
                                {`${comment.user.name}, ${hhmm(new Date(comment.createdAt))}`}{' '}
                              </Typography>
                              {comment.modifiedAt && comment.modifiedAt !== comment.createdAt && (
                                <Typography variant='body2' className={classes.editedComment}>
                                  {`(${t('editedComment')})`}
                                </Typography>
                              )}
                            </ListItemText>
                            <Button
                              disabled={isLoadingSample}
                              classes={{ label: classes.upperCase }}
                              style={{ marginLeft: '-0.5rem' }}
                              onClick={() => {
                                if (comment.sampleId === sampleId) {
                                  dispatch(openModal(sample, 'bacteriologist'));
                                } else {
                                  openSample(comment.sampleId);
                                }
                              }}
                              color='primary'
                            >
                              {t('showSample')}
                            </Button>{' '}
                          </ListItem>
                        </Paper>
                      );
                    })}
                  </div>
                );
              });
            })}
        </List>
        {!pagesOfComments ||
        (pagesOfComments && pagesOfComments[0].length === 0) ||
        (size > 0 && pagesOfComments && typeof pagesOfComments[size - 1] === 'undefined') ? (
          spinner
        ) : (
          <></>
        )}
      </div>

      {modalOpen && (
        <Modal>
          <BactDetails
            updateSample={updateSample}
            isLoadingUpdateSample={isLoadingUpdateSample}
            isLoadingUpdateAfterSendInForVerification={isLoadingUpdateAfterSendInForVerification}
            isLoadingUpdateAfterCheckSendToBacteriologist={
              isLoadingUpdateAfterCheckSendToBacteriologist
            }
            isLoadingUpdateAfterSendToFinal={isLoadingUpdateAfterSendToFinal}
            refreshComments={refreshComments}
          />
          <LabFormDialog
            updateSample={updateSample}
            isUpdatingSample={isLoadingClassifyAsLaboratory}
          />
        </Modal>
      )}
    </>
  );
}

DateLabel.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};
