import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, useMediaQuery, InputBase, Paper, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(() => ({
  searchIcon: {
    height: '3rem',
    width: '3rem',
  },
  mobileSearchIcon: {
    height: '2rem',
    width: '2rem',
  },
  inputField: {
    display: 'flex',
    width: '23rem',
    height: '3rem',
    backgroundColor: '#fff',
  },
  mobileInputField: {
    display: 'flex',
    backgroundColor: '#fff',
    width: '45%',
    height: '2rem',

    '& input': {
      fontSize: '0.9rem',
    },
  },
}));

export default function SearchSamples(props) {
  const { onSearchTextUpdated, loadingSamples } = props;
  const classes = useStyles();
  const { t } = useTranslation(['samplesFarmer']);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const [searchText, setSearchText] = useState('');
  const [query, setQuery] = useState('');

  useEffect(() => {
    onSearchTextUpdated(searchText);
    setQuery('');
  }, [searchText, onSearchTextUpdated]);

  function handleOnSubmit(e) {
    e.preventDefault();
    if (query === '') {
      setSearchText('');
    } else {
      setSearchText(query);
    }
  }

  return (
    <Paper
      elevation={0}
      className={isMobile ? classes.mobileInputField : classes.inputField}
      component='form'
      onSubmit={handleOnSubmit}
    >
      <IconButton
        type='submit'
        className={isMobile ? classes.mobileSearchIcon : classes.searchIcon}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        placeholder={t('search:searchCow')}
        onChange={(e) => (e.target.value === '' ? setSearchText('') : setQuery(e.target.value))}
        fullWidth
        disabled={loadingSamples}
      />
    </Paper>
  );
}

SearchSamples.propTypes = {
  onSearchTextUpdated: PropTypes.func.isRequired,
  loadingSamples: PropTypes.bool.isRequired,
};
