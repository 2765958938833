import React, { useEffect, useState, useCallback } from 'react';
import {
  makeStyles,
  Dialog,
  Button,
  Typography,
  useMediaQuery,
  Fade,
  CircularProgress,
} from '@material-ui/core';
import pdf from '../../docs/remiss_editable.pdf';
import { useSelector, useDispatch } from 'react-redux';
import { closePDFDialog, openSnackbar } from '../../actions/mainActions';
import { yyyymmdd } from '../../Services/Utility/DateUtils';
import { enums } from '../../Services/Utility/Enumerations';
import { PDFDocument } from 'pdf-lib';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { BacticamSiteSettingsClient } from '../../Services/Utility/WebApiClient';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    backgroundColor: theme.palette.lightGrays.gray10,
  },
  pdfFrame: {
    width: '100%',
    position: 'relative',
  },
  sideBox: {
    width: '65vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textBox: {
    padding: '8% 5%',
    maxHeight: '85vh',
    minHeight: '70vh',
    overflow: 'auto',
  },
  large: {
    fontSize: '1.25rem',
    lineHeight: '2rem',
  },
  h2: {
    fontSize: '2.5rem',
    lineHeight: '3rem',
    fontWeight: 700,
    marginBottom: '0.2rem',
  },
  h5: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.25rem',
  },
  body: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
  },
  italic: {
    fontStyle: 'italic',
  },
  small: {
    fontSize: '0.875rem',
  },
  bold: {
    fontWeight: 700,
  },
  alternativeList: {
    background: 'white',
    padding: '1rem',
    marginBottom: '1rem',
  },
  alternative: {
    // square bullet point
    '&>p:first-of-type': {
      marginRight: '0.5rem',
      fontSize: '0.875rem',
    },
    '&>p:nth-of-type(2)': {
      textDecoration: 'underline',
      fontSize: '0.875rem',
    },
    '&>div': {
      '&>p': {
        fontSize: '0.875rem',
      },
    },
  },
  closeButton: {
    minHeight: '2rem',
    border: '1px solid black',
    borderRadius: '0',
    background: 'white',
    '&>span>p': {
      fontWeight: 700,
    },
  },
  spinner: {
    position: 'absolute',
    flexDirection: 'column',
    height: '100%',
    width: '55%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>p': {
      marginTop: '1rem',
    },
  },
  important: {
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    color: theme.palette.secondary.main,
    marginRight: '0.3rem',
  },
  underline: {
    textDecoration: 'underline',
    textDecorationThickness: '2px',
    textDecorationColor: theme.palette.secondary.main,
    '-webkit-text-decoration-color': theme.palette.secondary.main,
  },
}));

function mapEmailsToTextfield(mailArray) {
  let fieldValue = '';

  mailArray.forEach((mail) => {
    fieldValue += `${fieldValue.length ? '\n' : ''}${mail}`;
  });

  return fieldValue;
}

export default function PDFView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [populatedPDF, setPopulatedPDF] = useState(null);
  const [isLoadingPDF, setIsLoadingPDF] = useState(true);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { t } = useTranslation(['samplesFarmer']);

  const PDFopen = useSelector((state) => state.PDFReducer.isOpen);
  const sample = useSelector((state) => state.PDFReducer.sample);
  const siteId = useSelector((state) => state.siteReducer.selectedSiteId);

  useEffect(() => {
    async function fetchSiteSettings(siteId) {
      const siteSettings = await BacticamSiteSettingsClient.GetSiteSettings({ siteId: siteId });

      if (siteSettings instanceof Error) {
        dispatch(openSnackbar('warning', 'fetchSiteSettingsError'));
      } else {
        setData({
          site: {
            addressRow1: siteSettings.addressLine1 || '',
            addressRow2: siteSettings.addressLine2 || '',
            PPN: siteSettings.registrationNumber || '',
            city: siteSettings.city || '',
            zipCode: siteSettings.zipCode || '',
            siteMail: siteSettings.mail || '',
            veterinarianMail: siteSettings.veterinarianEmail || '',
          },
        });
      }
    }

    if (siteId) {
      fetchSiteSettings(siteId);
    }
  }, [dispatch, siteId]);

  const populateFields = useCallback(
    async (data) => {
      setIsLoadingPDF(true);
      const url = pdf;
      const urlBytes = await fetch(url).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(urlBytes);
      const form = pdfDoc.getForm();

      // user/site/sample info fields
      const addressField = form.getTextField('Djurägare (om annan än insändare)');
      const emailField = form.getTextField('Epost djurägare 3');
      const vetEmailField = form.getTextField('Extra svarsmottagare epost 4');
      const ppnField = form.getTextField('PPN');
      const sampleDateField = form.getTextField('Provtagningsdatum 5');

      // subject info fields
      const subjectField = form.getTextField(
        'Djuridentitet Ange löpnummer bruksnummer 4 siffror EJ kontrollsiffraRow1',
      );
      const checkBoxFR = form.getCheckBox('hf row_1');
      const checkBoxFL = form.getCheckBox('vf row_1');
      const checkBoxBR = form.getCheckBox('hb row_1');
      const checkBoxBL = form.getCheckBox('vb row_1');
      const CMTFR = form.getTextField('CMThf');
      const CMTBR = form.getTextField('CMThb');
      const CMTBL = form.getTextField('CMTvb');
      const CMTFL = form.getTextField('CMTvf');
      const checkBoxSub = form.getCheckBox('sub row_1');
      const checkBoxClin = form.getCheckBox('clin row_1');

      // Populate fields
      ppnField.setText(data.site.PPN.toString());
      sampleDateField.setText(yyyymmdd(new Date(sample.createdAt)));
      subjectField.setText(sample.subject.number.toString());

      // Email fields (can contain multiple mails)
      const farmerEmails = data.site.siteMail.split(';') || [];
      const vetEmails = data.site.veterinarianMail.split(';') || [];

      emailField.setText(mapEmailsToTextfield(farmerEmails));
      vetEmailField.setText(mapEmailsToTextfield(vetEmails));

      // Address field
      if (data.site.addressRow2) {
        addressField.setText(
          `${data.site.addressRow1}\n${data.site.addressRow2}\n${
            data.site.zipCode ? data.site.zipCode + ' ' : ''
          }${data.site.city}`,
        );
      } else {
        addressField.setText(
          `${data.site.addressRow1}\n${data.site.zipCode ? data.site.zipCode + ' ' : ''}${
            data.site.city
          }`,
        );
      }

      switch (sample.udderId) {
        case enums.udderPart.frontLeft:
          checkBoxFL.check();
          break;
        case enums.udderPart.frontRight:
          checkBoxFR.check();
          break;
        case enums.udderPart.backLeft:
          checkBoxBL.check();
          break;
        case enums.udderPart.backRight:
          checkBoxBR.check();
          break;
      }

      // If sample reason is subclinical, also display CMT values, if they exist.
      if (sample.sampleReason === enums.reason.subClinical) {
        checkBoxSub.check();
        if (sample.cmtFrontRight < 6) {
          CMTFR.setText(sample.cmtFrontRight.toString());
        }
        if (sample.cmtBackRight < 6) {
          CMTBR.setText(sample.cmtBackRight.toString());
        }
        if (sample.cmtBackLeft < 6) {
          CMTBL.setText(sample.cmtBackLeft.toString());
        }
        if (sample.cmtFrontLeft < 6) {
          CMTFL.setText(sample.cmtFrontLeft.toString());
        }
      } else {
        checkBoxClin.check();
      }

      setPopulatedPDF(await pdfDoc.saveAsBase64({ dataUri: true }));
      const buffer = await pdfDoc.save();
      const blob = new Blob([buffer], { type: 'application/pdf' });

      if (isMobile) {
        saveAs(blob, `remiss_SVA_${yyyymmdd(new Date())}.pdf`);
        dispatch(closePDFDialog());
      }
      setIsLoadingPDF(false);
    },
    [dispatch, isMobile, sample],
  );

  useEffect(() => {
    if (PDFopen && data) {
      populateFields(data);
    } else {
      setIsLoadingPDF(false);
    }
  }, [PDFopen, data, populateFields]);

  useEffect(() => {
    if (populatedPDF && !isMobile) {
      document.getElementById('pdf-render').src = populatedPDF;
    }
  });

  const Spinner = () => {
    return (
      <div className={classes.spinner}>
        <Fade
          in={isLoadingPDF}
          style={{
            transitionDelay: '300ms',
          }}
          unmountOnExit
        >
          <CircularProgress color='primary' size='5rem' />
        </Fade>
        <Typography>Laddar pdf...</Typography>
      </div>
    );
  };

  const handleClose = () => {
    dispatch(closePDFDialog());
  };

  return (
    <Dialog maxWidth='xl' open={!isMobile && PDFopen} onClose={handleClose}>
      <div className={classes.dialogContent}>
        <iframe id='pdf-render' className={classes.pdfFrame} title='SVA Remiss'></iframe>
        {isLoadingPDF && <Spinner />}
        <div className={classes.sideBox}>
          <div className={classes.textBox}>
            <Typography className={classes.h2}>Verifiering krävs</Typography>
            <Typography className={classes.large} style={{ marginBottom: '1rem' }}>
              Skicka din odling till SVA
            </Typography>
            <Typography className={classes.h5} style={{ marginBottom: '1rem' }}>
              A. Editera förifyllda fält, om det behövs
            </Typography>
            <Typography className={classes.h5}>B. Skriv information om din odling:</Typography>
            <Typography
              className={`${classes.body} ${classes.italic}`}
              style={{ marginBottom: '1rem' }}
            >
              (digitalt före utskrift eller skriv ut och komplettera för hand){' '}
            </Typography>
            <Typography className={classes.body} style={{ marginBottom: '1rem' }}>
              1. Provtagare – den som tog mjölkprovet eller den som skickar in platta/rör{' '}
            </Typography>
            <Typography className={classes.body} style={{ marginBottom: '1rem' }}>
              2. På remissen, markera/kryssa i önskad provtagning. Valet beror på vad du skickar:
            </Typography>

            <div className={classes.alternativeList}>
              <div className={classes.alternative}>
                <Typography display='inline'>❏</Typography>
                <Typography display='inline'>
                  Odlingsplatta (från kyl, ej äldre än en vecka)
                </Typography>
                <div>
                  <Typography display='inline' className={classes.bold}>
                    ”Bakteriologisk typning
                  </Typography>
                  <Typography display='inline'> av isolat från mjölk (stam på platta)”</Typography>
                </div>
              </div>
              <div className={classes.alternative}>
                <Typography display='inline'>❏</Typography>
                <Typography display='inline'>
                  Om plattan är förstörd (äldre än en vecka/fryst) och du skickar det frysta
                  originalprovröret
                </Typography>
                <div>
                  <Typography className={classes.bold} display='inline'>
                    ”Odling
                  </Typography>
                  <Typography display='inline'>
                    – Mastitbakteriologisk undersökning (mjölk)”
                  </Typography>
                </div>
              </div>
              <Typography style={{ marginTop: '1rem' }} className={classes.small}>
                Om Klebsiella misstänks, där lagen kräver resistensundersökning för behandling,
                vilket förklaras i provsvaret på bacticam.agricam.se
              </Typography>
              <div className={classes.alternative}>
                <Typography display='inline'>❏</Typography>
                <Typography display='inline'>
                  Odlingsplatta (från kyl, ej äldre än en vecka)
                </Typography>
                <div>
                  <Typography className={classes.bold} display='inline'>
                    ”Bakteriologisk typning
                  </Typography>
                  <Typography display='inline'> från isolat + </Typography>
                  <Typography className={classes.bold} display='inline'>
                    resistens av Klebsiella”
                  </Typography>
                </div>
              </div>
              <div className={classes.alternative}>
                <Typography display='inline'>❏</Typography>
                <Typography display='inline'>
                  Om plattan är förstörd (äldre än en vecka/fryst) och du skickar det frysta
                  originalprovröret
                </Typography>
                <div>
                  <Typography className={classes.bold} display='inline'>
                    ”Odling + resistens
                  </Typography>
                  <Typography display='inline'> vid växt av Klebsiella (mjölk)”</Typography>
                </div>
              </div>
            </div>
            <Typography display='inline' className={classes.body}>
              3.{' '}
            </Typography>
            <Typography display='inline' className={`${classes.body} ${classes.underline}`}>
              Kom ihåg att skriva under
            </Typography>
            <Typography className={classes.h5} style={{ marginBottom: '1rem', marginTop: '1rem' }}>
              C. Skicka remiss och platta/rör i förtryckt SVA-kuvert{' '}
            </Typography>

            <div className={classes.important}>
              <ErrorOutlineIcon className={classes.warningIcon} />
              <Typography className={classes.body} display='inline'>
                Viktigt! Kryssa på kuvertets framsida ”Mastitodling”
              </Typography>
            </div>
          </div>
          <Button fullWidth className={classes.closeButton} onClick={handleClose}>
            <Typography>{t('pdfDialog:close')}</Typography>
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
