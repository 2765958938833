const defaultSeverity = 'success';

const initialState = {
  isOpen: false,
  text: '',
  severity: defaultSeverity,
  translationParams: null,
};

function snackbarReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_SNACKBAR':
      return {
        ...state,
        isOpen: true,
        severity: action.severity ? action.severity : defaultSeverity,
        text: action.text,
        translationParams: action.translationParams,
      };

    case 'CLOSE_SNACKBAR':
      return {
        ...state,
        isOpen: false,
        text: '',
        severity: defaultSeverity,
        translationParams: null,
      };

    default:
      return state;
  }
}

export default snackbarReducer;
