import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { closeSnackbar } from '../../actions/mainActions';

export default function SnackbarComponent() {
  const { t } = useTranslation(['snackbar']);
  const dispatch = useDispatch();

  const textTranslationKey = useSelector((state) => state.snackbarReducer.text);
  const severity = useSelector((state) => state.snackbarReducer.severity);
  const snackbarTranslationParams = useSelector((state) => state.snackbarReducer.translationParams);

  let translatedText;

  if (snackbarTranslationParams) {
    translatedText = t(textTranslationKey, snackbarTranslationParams);
  } else {
    translatedText = t(textTranslationKey);
  }

  return (
    <Snackbar
      style={{ zIndex: '7000' }}
      open={true}
      autoHideDuration={severity === 'warning' ? 6000 : severity === 'success' ? 3000 : null}
      onClose={() => dispatch(closeSnackbar())}
    >
      <MuiAlert elevation={6} variant='filled' severity={severity} onClose={() => {}}>
        {translatedText}
      </MuiAlert>
    </Snackbar>
  );
}
