import React from 'react';
import { makeStyles } from '@material-ui/core';
import { getEmptyImage, getCmt } from '../../../../../Services/Utility/Udder';
import { enums } from '../../../../../Services/Utility/Enumerations';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  udderIconWrapper: {
    position: 'relative',
  },
  defaultSize: {
    minWidth: '80px',
    minHeight: '80px',
    maxWidth: '80px',
    maxHeight: '80px',
  },
  large: {
    minWidth: '96px',
    minHeight: '96px',
    maxWidth: '96px',
    maxHeight: '96px',
  },
  small: {
    minWidth: '64px',
    minHeight: '64px',
    maxWidth: '64px',
    maxHeight: '64px',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  valueContainer: {
    height: '50%',
    position: 'relative',

    '&>span': {
      color: 'white',
    },
  },
  smallText: {
    fontSize: '0.8rem',
  },
  text: {
    fontSize: '1rem',
  },
  largeText: {
    fontSize: '1.2rem',
  },
});

export default function UdderIcon(props) {
  const { sample, size } = props;
  const classes = useStyles();
  const { t } = useTranslation(['samplesDetails']);

  function udderPart(vertical, horizontal, udderPart, label) {
    return (
      <div className={classes.valueContainer}>
        <span
          style={{ position: 'absolute', [vertical]: '10%', [horizontal]: '10%' }}
          className={
            size === 'small' && sample.sampleReason !== enums.reason.subClinical
              ? classes.smallText
              : size === 'large'
              ? classes.largeText
              : classes.text
          }
        >
          {sample.sampleReason === enums.reason.subClinical ? getCmt(udderPart) : t(label)}
        </span>
      </div>
    );
  }

  return (
    <div
      className={`${classes.udderIconWrapper} ${
        size === 'large' ? classes.large : size === 'small' ? classes.small : classes.defaultSize
      }`}
    >
      <img src={getEmptyImage(sample.udderId)} alt='udderIcon' width='100%' />
      <div className={classes.wrapper}>
        <div className={classes.column}>
          {udderPart('bottom', 'right', sample.cmtFrontLeft, 'informationCard:FL')}
          {udderPart('top', 'right', sample.cmtBackLeft, 'informationCard:RL')}
        </div>
        <div className={classes.column}>
          {udderPart('bottom', 'left', sample.cmtFrontRight, 'informationCard:FR')}
          {udderPart('top', 'left', sample.cmtBackRight, 'informationCard:RR')}
        </div>
      </div>
    </div>
  );
}

UdderIcon.propTypes = {
  sample: PropTypes.object.isRequired,
  size: PropTypes.string,
};
