import React, { useEffect } from 'react';
import FarmerSampleContainer from './FarmerSampleContainer';
import BactSampleContainer from './BactSampleContainer';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, closeModal } from '../../actions/modalActions';
import { openSnackbar } from '../../actions/mainActions';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { getURLTitle } from '../../Services/Utility/SampleInformation';
import { useTranslation } from 'react-i18next';
import { SamplesClient } from '../../Services/Utility/WebApiClient';

export default function SampleContainer() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['bacterias', 'samplesDetails']);
  const view = useSelector((state) => state.viewReducer.selectedView);
  const modalOpen = useSelector((state) => state.modalReducer.isOpen);
  const sample = useSelector((state) => state.modalReducer.sample);
  const selectedSiteId = useSelector((state) => state.siteReducer.selectedSiteId);
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (sample) {
      document.title = `${getURLTitle(sample)} | Bacticam`;
    } else {
      document.title = `${t('main:navigation:milkSamples')} | Bacticam`;
    }
  }, [sample, t]);

  useEffect(() => {
    // Make sure the modal is closed if the path is any other than /samples/:sampleId
    if (match.path !== '/samples/:sampleId' && modalOpen) {
      dispatch(closeModal());
    }
  });

  useEffect(() => {
    async function fetchSampleData(id) {
      const fetchSample = await SamplesClient.GetSample(id);
      if (fetchSample instanceof Error) {
        history.push('/samples');
        dispatch(openSnackbar('warning', 'fetchError'));
      } else if (fetchSample) {
        dispatch(openModal(fetchSample, view));
      }
    }
    // If the path is /samples/:sampleId and the modal is closed, the user is probably trying to open a sample with a direct link
    // or navigating to a historic sample link in the browser
    if (
      match.path === '/samples/:sampleId' &&
      match.params.sampleId !== undefined &&
      view &&
      !sample &&
      !modalOpen
    ) {
      fetchSampleData(match.params.sampleId);
    }
  });

  return (
    <>
      {selectedSiteId && view === 'farmer' ? (
        <FarmerSampleContainer selectedSiteId={selectedSiteId} />
      ) : view === 'bacteriologist' ? (
        <BactSampleContainer />
      ) : (
        <></>
      )}
    </>
  );
}
