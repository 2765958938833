import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { openSnackbar } from '../../actions/mainActions';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
  Paper,
  Typography,
  TextField,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputAdornment,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import i18next from 'i18next';
import { AccountClient } from '../../Services/Utility/WebApiClient';
import SiteSettings from './SiteSettings';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '50%',
    minWidth: '600px',
  },
  wrapperMobile: {
    width: '100%',
    padding: '1rem 0.3rem',
  },
  titlePaper: {
    textAlign: 'center',
    marginBottom: '0.6rem',
  },
  title: {
    padding: '1rem 0',
  },
  contentWrapper: {
    paddingTop: '1.5rem !important',
    backgroundColor: '#e9e9e9',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  passwordRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    marginTop: theme.spacing(-0.5),
    '&>div': {
      minWidth: theme.spacing(15),
    },
  },
  passwordInput: {
    marginTop: theme.spacing(0.5),
  },
  changePasswordColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  expansionPanel: {
    margin: '0 !important',
    '&::before': {
      opacity: '1 !important',
    },
  },
  summaryContent: {
    margin: 'unset !important',
  },
  summaryExpanded: {
    minHeight: 'unset !important',
    opacity: '1 !important',
    '&>iconButton': {
      marginRight: '-0.8rem',
    },
  },
  errorRow: {
    display: 'flex',
    marginTop: '1rem',
  },
  errorIcon: {
    marginRight: '0.3rem',
  },
  changePasswordButton: {
    marginTop: '1rem',
  },
  settingsPaper: {
    marginBottom: '2rem',
  },
  subtitle: {
    marginBottom: theme.spacing(0.75),
  },
}));

export default function SettingsView(props) {
  const { t } = useTranslation(['settings']);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const classes = useStyles();
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [changeFail, setChangeFail] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [errorText, setErrorText] = useState('');
  const view = useSelector((state) => state.viewReducer.selectedView);

  useEffect(() => {
    document.title = `${t('main:navigation:Settings')} | Bacticam`;
  }, [t]);

  function scorePassword(password) {
    let score = 0;
    if (!password || password.length < 8) return score;

    const letters = {};
    for (let i = 0; i < password.length; i++) {
      letters[password[i]] = (letters[password[i]] || 0) + 1;
      score += 5.0 / letters[password[i]];
    }

    const variations = {
      digits: /\d/.test(password),
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password),
    };

    let variationCount = 0;
    for (const check in variations) {
      variationCount += variations[check] === true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return parseInt(score);
  }

  function checkNewPassword() {
    const score = scorePassword(newPassword);

    if (score >= 60) {
      changePassword();
    } else {
      setChangeFail(true);
      setErrorText('notSecure');
    }
  }

  function changePassword() {
    (async () => {
      try {
        const changePasswordObject = {
          oldPassword: oldPassword,
          newPassword: newPassword,
        };

        const userResponse = await AccountClient.ChangePassword(changePasswordObject).then(
          (response) => {
            if (response === undefined) {
              // did not get a response, server probably down
              return null;
            }
            return response;
          },
        );

        if (userResponse instanceof Error) {
          switch (userResponse.response.status) {
            case 400:
              setErrorText(userResponse.response.data);
              setChangeFail(true);
              setChangeSuccess(false);
              break;
            default:
              dispatch(openSnackbar('warning', 'somethingWentWrong'));
              break;
          }
        } else {
          setErrorText('');
          setChangeFail(false);
          setChangeSuccess(true);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }

  return (
    <>
      <div className={isMobile ? classes.wrapperMobile : classes.wrapper}>
        <Paper className={classes.titlePaper} elevation={2}>
          <Typography variant='h4' className={classes.title}>
            {t('settings')}
          </Typography>
        </Paper>
        <Paper className={classes.settingsPaper} elevation={2}>
          <Accordion classes={{ root: classes.expansionPanel }}>
            <AccordionSummary
              classes={{
                content: classes.summaryContent,
                expanded: classes.summaryExpanded,
              }}
              expandIcon={<ExpandMoreIcon classes={{ root: classes.iconButton }} />}
            >
              <Typography className='heading'>{t('changePassword')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.contentWrapper}>
              <div className={classes.changePasswordColumn}>
                <Typography className={classes.subtitle}>{t('changePasswordSubTitle')}</Typography>
                <div className={classes.passwordRow}>
                  <TextField
                    id='old-password'
                    className={classes.passwordInput}
                    type={'password'}
                    label={t('oldPassword')}
                    onChange={(event) => setOldPassword(event.target.value)}
                  />
                  <TextField
                    id='new-password'
                    className={classes.passwordInput}
                    type={showNewPassword ? 'text' : 'password'}
                    label={t('newPassword')}
                    onChange={(event) => setNewPassword(event.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            edge='end'
                          >
                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    className={classes.changePasswordButton}
                    variant='contained'
                    component='span'
                    color='primary'
                    size='large'
                    disabled={!oldPassword || !newPassword}
                    onClick={checkNewPassword}
                  >
                    {t('changePassword')}
                  </Button>
                </div>

                {changeFail && (
                  <div className={classes.errorRow}>
                    <ErrorIcon color={'error'} className={classes.errorIcon} />
                    <Typography color={'error'}>{t(errorText)}</Typography>
                  </div>
                )}
                {changeSuccess && (
                  <div className={classes.errorRow}>
                    <ErrorIcon color={'primary'} className={classes.errorIcon} />
                    <Typography color={'primary'}>{t('changeSuccess')}</Typography>
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </Paper>
        {view === 'farmer' && i18next.language !== 'fr' && <SiteSettings />}
      </div>
    </>
  );
}
