import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { openSnackbar } from '../../../../../actions/mainActions';
import { openLabFormDialog } from '../../../../../actions/bacteriologistActions';
import { useDispatch } from 'react-redux';
import {
  Card,
  Typography,
  Button,
  CircularProgress,
  Fade,
  TextField,
  Tooltip,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  confirmablePreviousResult,
  classificationAlreadyExists,
  getOtherClassificationOptions,
  otherClassificationOptions,
  getClassificationInfo,
  getSelectableOutcomes,
  getBacteriaAmountList,
} from '../../../../../Services/Utility/Classification';
import robotIcon from '../../../../../icons/robot.png';
import { enums } from '../../../../../Services/Utility/Enumerations';
import PermissionCheck from '../../../../../Services/PermissionCheck';
import { BacteriasClient, SamplesClient } from '../../../../../Services/Utility/WebApiClient';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: '1px solid #979797',
    borderRadius: '5px',
  },
  card: {
    height: '100%',
    position: 'relative',
  },
  coverShadow: {
    background: '#bbbbbb',
    opacity: '0.7',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    zIndex: '2',
    marginLeft: '-1rem',
  },
  buttonWrapper: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    '&> button': {
      width: '6rem',
    },
    '&> button+button': {
      marginTop: '0.3rem',
    },
    marginLeft: '0.3rem',
  },
  singleButtonWrapper: {
    justifyContent: 'flex-end',
  },
  mobileButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: '1rem',
    '&> button': {
      width: '6rem',
    },
    '&> button + button': {
      marginLeft: '0.5rem',
    },
  },
  halfWrapper: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    padding: '1rem',
  },
  halfWrapperClassified: {
    height: '50%',
  },
  mobileHalfWrapper: {
    position: 'relative',
    padding: '1rem',
  },
  topHalfLeftColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomHalfLeftColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  changeViewButton: {
    outline: 'none !important',
    minWidth: 'fit-content',
    marginTop: '0.3rem',
  },
  changeViewButtonWrapperMobile: {
    marginTop: '1rem',
  },
  selectionViewWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  },
  autoComplete: {
    minWidth: '225px',
    marginRight: '1rem',
  },
  spinnerContainer: {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#bbbbbb',
    opacity: '0.7',
    zIndex: '3',
  },
  spinner: {
    opacity: '1',
  },
  robotIcon: {
    height: '21px',
    width: '24px',
    marginLeft: '0.5rem',
    marginBottom: '0.4rem',
  },
  selectBacteriaView: {
    marginTop: '1rem',
  },
  selectBacteriaAndAmount: {
    display: 'flex',
    marginTop: '-1rem',
    flexWrap: 'wrap',
    alignItems: 'end',
  },
  selectAmountView: {
    marginTop: '1rem',
  },
  selectStatusView: {
    paddingTop: '1rem',
    display: 'flex',
    marginBottom: '1rem',
    flexWrap: 'wrap',
  },
  tooltipLabel: {
    fontSize: '0.8rem',
  },
  labResultButton: {
    backgroundColor: theme.palette.complementColors.yellow,
    '&:hover': {
      backgroundColor: '#d7a709',
    },
  },
}));

export default function ClassificationCard(props) {
  const { sample, onFinished, isLoadingUpdateSample } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const isSmallMobile = useMediaQuery('(max-width:400px)');
  const { t } = useTranslation(['samplesDetails', 'bacterias']);
  const dispatch = useDispatch();
  const canClassifyAsLaboratory = PermissionCheck.canClassifyAsLaboratory();
  const sourceKind = PermissionCheck.canClassifyAsSeniorBacteriologist()
    ? enums.sourceKind.seniorBacteriologist
    : enums.sourceKind.juniorBacteriologist;

  const [isConfirmable, setIsConfirmable] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [classificationText, setClassificationText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingClassification, setIsLoadingClassification] = useState(false);
  const [bacterias, setBacterias] = useState([]);
  const [failedOutcomes, setFailedOutcomes] = useState([]);
  const [bacteriaAmountList, setBacteriaAmountList] = useState([]);
  const [selectedOutcome, setSelectedOutcome] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [selectedBacteria, setSelectedBacteria] = useState(null);
  const [pickBacteriaOrStatusViewOpen, setPickBacteriaOrStatusViewOpen] = useState(false);
  const [selectBacteriaViewOpen, setSelectBacteriaViewOpen] = useState(true);
  const [resultIsSetByAi, setResultIsSetByAi] = useState(false);
  const [previousResult, setPreviousResult] = useState(null);
  const [showBactTooltip, setShowBactTooltip] = useState(false);
  const [showAmountTooltip, setShowAmountTooltip] = useState(false);
  const [typeOfResultToBeSet, setTypeOfResultToBeSet] = useState(null);

  // Find out if the result to be set will be a final, preliminary or early result.
  useEffect(() => {
    if (sample.finalImagesCreatedAt) {
      setTypeOfResultToBeSet(enums.resultType.final);
    } else if (sample.preliminaryImagesCreatedAt) {
      setTypeOfResultToBeSet(enums.resultType.preliminary);
    } else if (sample.earlyImagesCreatedAt) {
      setTypeOfResultToBeSet(enums.resultType.early);
    }
  }, [sample]);

  // Find out if the sample has a previous classification.
  // If the previous classification was 'humanEvaluationRequired' or 'additionalImagesRequired',
  // the result will be ignored.
  useEffect(() => {
    const confirmableResult = confirmablePreviousResult(sample);
    const classificationInfo = getClassificationInfo(sample);
    const classifyText = classificationInfo.classificationText;

    if (classifyText) {
      setShowConfirmDialog(true);
    } else {
      setShowConfirmDialog(false);
    }

    setClassificationText(classifyText);
    setResultIsSetByAi(classificationInfo.resultIsSetByAi);

    if (confirmableResult) {
      setPreviousResult(confirmableResult);
      setIsConfirmable(true);
      setPickBacteriaOrStatusViewOpen(false);
    } else {
      setIsConfirmable(false);
      setPickBacteriaOrStatusViewOpen(true);
    }
  }, [sample]);

  useEffect(() => {
    const fetchBacterias = async () => {
      const bacterias = await BacteriasClient.GetBacteriasSettableBy({
        sourceKind: sourceKind,
        orderBy: 'asc',
      });
      if (bacterias instanceof Error) {
        dispatch(openSnackbar('warning', 'fetchBacteriasError'));
      } else {
        const bacteriaList = bacterias.map((bacteria) => {
          return {
            key: bacteria.bacteriaid,
            value: bacteria.bacteriaid,
            title: t(`bacterias:${bacteria.name}`),
          };
        });

        // Add other classification options that are not a specific bacteria to the list
        bacteriaList.unshift(...getOtherClassificationOptions());

        setBacterias(bacteriaList);
      }
      setIsLoading(false);
    };

    fetchBacterias();
  }, [dispatch, sourceKind, t]);

  useEffect(() => {
    let outcomeList = getSelectableOutcomes();

    if (sample.finalImagesCreatedAt) {
      outcomeList = outcomeList.filter(
        (outcome) => outcome.value !== enums.bacteriaResultOutcome.additionalImagesRequired,
      ); // remove 'additionalImagesRequired' as option if final images exist.
    }
    setFailedOutcomes(outcomeList);
  }, [sample]);

  useEffect(() => {
    setBacteriaAmountList(getBacteriaAmountList());
  }, [sample]);

  useEffect(() => {
    setSelectedBacteria(null);
    setSelectedOutcome(null);
    setSelectedAmount(null);
  }, [sample]);

  /**
   * When saving or confirming a bacteria/cultureType classification, a classification object is sent to the server.
   * @param {string|number} classification - Can be either a bacteriaId or cultureType
   * @param {number} bacteriaAmount - The amount of bacterias (is "notSet" if cultureType = "negative")
   */
  const onClassifySubmit = async (classification, bacteriaAmount = enums.bacteriaAmount.notSet) => {
    setIsLoadingClassification(true);

    let classificationObject = {};

    if (classification === enums.cultureType.negative) {
      // Negative growth - not possible to set bacteriaAmount
      classificationObject = {
        resultType: typeOfResultToBeSet,
        sourceKind: sourceKind,
        outcome: enums.bacteriaResultOutcome.success,
        cultureType: classification,
      };
    } else if (classification === enums.cultureType.mixedFlora) {
      // Mixed flora - bacteriaAmount is obligatory
      classificationObject = {
        resultType: typeOfResultToBeSet,
        sourceKind: sourceKind,
        outcome: enums.bacteriaResultOutcome.success,
        cultureType: classification,
        bacteriaAmount: bacteriaAmount,
      };
    } else if (
      classification === enums.otherClassificationOptions.mixedFloraContagiousBacteriaSuspected
    ) {
      // Mixed flora - contagious mastitis bacteria is suspected
      classificationObject = {
        resultType: typeOfResultToBeSet,
        sourceKind: sourceKind,
        outcome: enums.bacteriaResultOutcome.success,
        cultureType: enums.cultureType.mixedFlora,
        bacteriaAmount: bacteriaAmount,
        contagiousBacteriaSuspected: true,
      };
    } else {
      // Pure - bacteriaAmount is obligatory
      classificationObject = {
        resultType: typeOfResultToBeSet,
        sourceKind: sourceKind,
        bacteriaAmount: bacteriaAmount,
        outcome: enums.bacteriaResultOutcome.success,
        cultureType: enums.cultureType.pure,
        bacterias: [
          {
            bacteriaId: classification,
            resultGroup: 1,
          },
        ],
      };
    }

    const classifyResponse = await SamplesClient.ClassifySample(
      sample.sampleId,
      sample.lastActivity,
      classificationObject,
    ).then((response) => {
      if (response === undefined) {
        return null;
      }
      return response;
    });

    if (classifyResponse instanceof Error) {
      if (classifyResponse.response.status === 403) {
        onFinished('forbidden');
      } else if (classifyResponse.response.status === 428) {
        onFinished('newerBacteriaResultExists');
      } else {
        onFinished('classifyFailed');
      }
      setIsLoadingClassification(false);
    } else {
      onFinished('classify');
      setIsLoadingClassification(false);
    }
  };

  const onInconclusiveSubmit = async (outcome) => {
    setIsLoadingClassification(true);

    const statusObject = {
      resultType: typeOfResultToBeSet,
      outcome: outcome,
      sourceKind: sourceKind,
    };

    const setOutcomeResponse = await SamplesClient.ClassifySample(
      sample.sampleId,
      sample.lastActivity,
      statusObject,
    ).then((response) => {
      if (response === undefined) {
        return null;
      }
      return response;
    });

    if (setOutcomeResponse instanceof Error) {
      if (setOutcomeResponse.response.status === 403) {
        onFinished('forbidden');
      } else if (setOutcomeResponse.response.status === 428) {
        onFinished('newerBacteriaResultExists');
      } else {
        onFinished('inconclusiveFailed');
      }
      setIsLoadingClassification(false);
    } else {
      onFinished('inconclusive');
      setIsLoadingClassification(false);
    }
  };

  function resetSelectStates() {
    setSelectedBacteria(null);
    setSelectedOutcome(null);
    setSelectedAmount(null);
  }

  // Confirm previous result
  function ConfirmButton() {
    // Find out if the previous result to be confirmed is a bacteria, a status or culture type.
    let previousBacteriaResult = null;
    let previousCultureTypeResult = null;

    if (previousResult) {
      previousBacteriaResult = previousResult.bacterias[0]?.bacteriaId;
      previousCultureTypeResult = previousResult.cultureType.cultureType;
    }

    const isBacteria = bacterias.some((bacteria) => bacteria.value === previousBacteriaResult);
    const isCultureType = otherClassificationOptions.includes(previousCultureTypeResult);

    return (
      <Button
        disableElevation
        onClick={() => {
          if (isCultureType) {
            // Display helper snackbar if user tries to confirm a mixedFlora-result where bacteria amount is missing.
            if (
              previousCultureTypeResult === enums.cultureType.mixedFlora &&
              previousResult.cultureType.bacteriaAmount === enums.bacteriaAmount.notSet
            ) {
              dispatch(openSnackbar('info', 'snackBarBacteriaAmountMissing'));
              setPickBacteriaOrStatusViewOpen(true);
              if (previousResult.contagiousBacteriaSuspected) {
                setSelectedBacteria(
                  bacterias.find(
                    (bacteria) =>
                      bacteria.value ===
                      enums.otherClassificationOptions.mixedFloraContagiousBacteriaSuspected,
                  ),
                );
              } else {
                setSelectedBacteria(
                  bacterias.find((bacteria) => bacteria.value === enums.cultureType.mixedFlora),
                );
              }
            } else if (
              classificationAlreadyExists(
                previousResult,
                typeOfResultToBeSet === enums.resultType.final
                  ? sample.finalResult
                  : sample.preliminaryResult,
                'cultureType',
                sourceKind,
              )
            ) {
              dispatch(openSnackbar('info', 'snackBarClassificationAlreadyExists'));
            } else {
              if (previousCultureTypeResult === enums.cultureType.negative) {
                onClassifySubmit(previousCultureTypeResult);
              } else {
                onClassifySubmit(
                  previousResult.cultureType.cultureType === enums.cultureType.mixedFlora &&
                    previousResult.contagiousBacteriaSuspected
                    ? enums.otherClassificationOptions.mixedFloraContagiousBacteriaSuspected
                    : previousCultureTypeResult,
                  previousResult.cultureType.bacteriaAmount,
                );
              }
            }
          } else if (isBacteria) {
            // If the previous result is a bacteria without amount set (happens when AI sets the result), it can't be confirmed
            if (previousResult.cultureType.bacteriaAmount === enums.bacteriaAmount.notSet) {
              dispatch(openSnackbar('info', 'snackBarBacteriaAmountMissing'));
              setPickBacteriaOrStatusViewOpen(true);
              setSelectedBacteria(
                bacterias.find((bacteria) => bacteria.value === previousBacteriaResult),
              );
            } else if (
              classificationAlreadyExists(
                previousResult,
                typeOfResultToBeSet === enums.resultType.final
                  ? sample.finalResult
                  : sample.preliminaryResult,
                'bacteria',
                sourceKind,
              )
            ) {
              dispatch(openSnackbar('info', 'snackBarClassificationAlreadyExists'));
            } else {
              onClassifySubmit(
                previousResult.bacterias[0].bacteriaId,
                previousResult.cultureType.bacteriaAmount,
              );
            }
          } else {
            if (
              classificationAlreadyExists(
                previousResult,
                typeOfResultToBeSet === enums.resultType.final
                  ? sample.finalResult
                  : sample.preliminaryResult,
                'inconclusive',
                sourceKind,
              )
            ) {
              dispatch(openSnackbar('info', 'snackBarClassificationAlreadyExists'));
            } else {
              onInconclusiveSubmit(previousResult.bacteriaResultOutcome);
            }
          }
        }}
        variant='contained'
        size={isMobile ? 'small' : 'medium'}
        disabled={pickBacteriaOrStatusViewOpen}
        color='primary'
        style={{ maxHeight: '2rem' }}
      >
        {t('classificationCard:confirm')}
      </Button>
    );
  }

  // Save new classification
  function SaveButton() {
    return (
      <Button
        disableElevation
        onClick={() => {
          if (selectBacteriaViewOpen) {
            onClassifySubmit(selectedBacteria?.value, selectedAmount?.value);
          } else {
            onInconclusiveSubmit(selectedOutcome?.value);
          }
          setShowAmountTooltip(false);
          setShowBactTooltip(false);
          resetSelectStates();
        }}
        variant='contained'
        disabled={
          (selectBacteriaViewOpen &&
            (selectedBacteria === null ||
              (selectedAmount === null &&
                selectedBacteria &&
                selectedBacteria?.value !== enums.cultureType.negative))) ||
          (!selectBacteriaViewOpen && selectedOutcome === null)
        }
        color='primary'
        size={isMobile ? 'small' : 'medium'}
        style={{ maxHeight: '2rem' }}
      >
        {t('classificationCard:save')}
      </Button>
    );
  }

  const goBackToBacteria = (
    <Button
      className={classes.changeViewButton}
      size='small'
      color='primary'
      onClick={() => setSelectBacteriaViewOpen(!selectBacteriaViewOpen)}
    >
      {t('classificationCard:selectBacteria')}
    </Button>
  );

  const chooseInconclusive = (
    <Button
      className={classes.changeViewButton}
      size='small'
      color='primary'
      onClick={() => setSelectBacteriaViewOpen(!selectBacteriaViewOpen)}
    >
      {t('classificationCard:canNotDetermine')}
    </Button>
  );

  const selectBacteriaView = (
    <div className={classes.selectBacteriaView}>
      <Tooltip
        title={t('classificationCard:selectBacteriaFirst')}
        open={showBactTooltip}
        arrow
        placement={'top-end'}
        classes={{ tooltip: classes.tooltipLabel }}
      >
        <Autocomplete
          size='small'
          value={selectedBacteria}
          getOptionSelected={(option, value) => option.value === value.value}
          className={classes.autoComplete}
          options={bacterias}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('classificationCard:pleaseSelectBacteria')}
              variant='outlined'
              label={t('classificationCard:bacteria')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          onChange={(event, value) => {
            setSelectedBacteria(value);
            setShowBactTooltip(false);
          }}
          disableClearable={true}
          noOptionsText={t('classificationCard:noOptions')}
        />
      </Tooltip>
    </div>
  );

  const selectStatusView = (
    <div>
      <div className={classes.selectStatusView}>
        <Autocomplete
          size='small'
          value={selectedOutcome}
          getOptionSelected={(option, value) => option.value === value.value}
          className={classes.autoComplete}
          options={failedOutcomes}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('classificationCard:howIsItInconclusive')}
              variant='outlined'
              label={t('classificationCard:canNotDetermine')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          onChange={(event, value) => setSelectedOutcome(value)}
          disableClearable={true}
          noOptionsText={t('classificationCard:noOptions')}
        />
        {goBackToBacteria}
      </div>
    </div>
  );

  const selectBacteriaAmount = (
    <div
      className={classes.selectAmountView}
      onClick={() => {
        if (!selectedBacteria) {
          setShowBactTooltip(true);
        } else if (selectedBacteria?.value === enums.cultureType.negative) {
          setShowAmountTooltip(true);
        }
      }}
    >
      <Tooltip
        title={t('classificationCard:cantSetBacteriaAmount')}
        open={showAmountTooltip && selectedBacteria?.value === enums.cultureType.negative}
        arrow
        placement={'top-end'}
        classes={{ tooltip: classes.tooltipLabel }}
      >
        <Autocomplete
          size='small'
          value={
            selectedBacteria && selectedBacteria?.value === enums.cultureType.negative
              ? null
              : selectedAmount
          }
          getOptionSelected={(option, value) => option.value === value.value}
          className={classes.autoComplete}
          options={bacteriaAmountList}
          getOptionLabel={(option) => option.title}
          onChange={(event, value) => setSelectedAmount(value)}
          disabled={
            !selectedBacteria ||
            (selectedBacteria && selectedBacteria?.value === enums.cultureType.negative)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('classificationCard:pleaseSelectAmount')}
              variant='outlined'
              label={t('classificationCard:bacteriaAmount')}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          )}
          noOptionsText={t('classificationCard:noOptions')}
        ></Autocomplete>
      </Tooltip>
    </div>
  );

  const spinner = (
    <div className={classes.spinner}>
      <Fade
        in={true}
        style={{
          transitionDelay: '300ms',
        }}
        unmountOnExit
      >
        <CircularProgress color='primary' size='2rem' />
      </Fade>
    </div>
  );

  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>
        {(isLoading || isLoadingClassification || isLoadingUpdateSample) && (
          <div className={classes.spinnerContainer}>{spinner}</div>
        )}
        {showConfirmDialog && (
          <div
            className={
              isSmallMobile
                ? classes.mobileHalfWrapper
                : `${classes.halfWrapper} ${classes.halfWrapperClassified}`
            }
          >
            {(pickBacteriaOrStatusViewOpen || isLoading) && (
              <div className={classes.coverShadow}></div>
            )}
            <div className={classes.topHalfLeftColumn}>
              <Typography variant='subtitle1' gutterBottom>
                {t('classificationCard:classification')}
                {resultIsSetByAi && (
                  <img className={classes.robotIcon} alt='AI robot' src={robotIcon} />
                )}
              </Typography>
              <div>
                <Typography>{classificationText}</Typography>
              </div>
            </div>
            <div className={isSmallMobile ? classes.mobileButtonWrapper : classes.buttonWrapper}>
              <ConfirmButton />
              <Button
                disableElevation
                disabled={pickBacteriaOrStatusViewOpen}
                onClick={() => {
                  setPickBacteriaOrStatusViewOpen(true);
                }}
                variant='contained'
                size={isMobile ? 'small' : 'medium'}
              >
                {t('classificationCard:edit')}
              </Button>
            </div>
          </div>
        )}

        <div
          className={
            isSmallMobile
              ? classes.mobileHalfWrapper
              : isConfirmable || showConfirmDialog
              ? `${classes.halfWrapper} ${classes.halfWrapperClassified}`
              : classes.halfWrapper
          }
        >
          {(isConfirmable || showConfirmDialog) && !pickBacteriaOrStatusViewOpen && (
            <div className={classes.coverShadow}></div>
          )}
          <div className={classes.bottomHalfLeftColumn}>
            {!(isConfirmable || showConfirmDialog) && (
              <Typography variant='subtitle1' gutterBottom>
                {t('classificationCard:classification')}
              </Typography>
            )}

            {selectBacteriaViewOpen ? (
              <div style={{ paddingTop: '0.5rem' }}>
                <div className={classes.selectBacteriaAndAmount}>
                  {selectBacteriaView}
                  {selectBacteriaAmount}
                  {chooseInconclusive}
                </div>
              </div>
            ) : (
              selectStatusView
            )}
          </div>
          <div
            className={
              isSmallMobile
                ? classes.mobileButtonWrapper
                : !(isConfirmable || showConfirmDialog)
                ? `${classes.buttonWrapper} ${classes.singleButtonWrapper}`
                : classes.buttonWrapper
            }
          >
            <SaveButton />
            {canClassifyAsLaboratory && (
              <Button
                size={isMobile ? 'small' : 'medium'}
                disableElevation
                variant='contained'
                className={classes.labResultButton}
                disabled={!pickBacteriaOrStatusViewOpen}
                onClick={() => dispatch(openLabFormDialog())}
              >
                {t('classificationCard:labResult')}
              </Button>
            )}
            <Button
              disableElevation
              variant='contained'
              disabled={!pickBacteriaOrStatusViewOpen || !confirmablePreviousResult(sample)}
              size={isMobile ? 'small' : 'medium'}
              hidden={!(isConfirmable || showConfirmDialog)}
              onClick={() => {
                setPickBacteriaOrStatusViewOpen(false);
                setShowBactTooltip(false);
                setShowAmountTooltip(false);
                resetSelectStates();
              }}
            >
              {t('classificationCard:cancel')}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}

ClassificationCard.propTypes = {
  sample: PropTypes.object.isRequired,
  onFinished: PropTypes.func.isRequired,
  isLoadingUpdateSample: PropTypes.bool.isRequired,
};
