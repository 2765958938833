import React, { useState } from 'react';
import {
  makeStyles,
  useMediaQuery,
  Typography,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import subclinicalInstructions from '../../../icons/subclinical-instructions.png';
import clinicalInstructions from '../../../icons/clinical-instructions.png';

const useStyles = makeStyles((theme) => ({
  infoBar: {
    background: '#FFC672',
    color: 'black',
    width: '100%',
    marginTop: 'calc(-8rem + var(--topbar-height-desktop))',
    marginBottom: '3rem',
    minHeight: 'initial !important',
  },
  infoBarContent: {
    padding: '0.75rem 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>p': {
      fontWeight: 600,
      '&>span': {
        textDecoration: 'underline',
        marginLeft: '0.3rem',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  },
  infoBarMobile: {
    margin: '0',
  },
  icon: {
    margin: '0 0.5rem',
    alignSelf: 'baseline',
  },
  dialogPaper: {
    margin: '7rem 0 3rem',
    maxHeight: 'calc(100% - 7rem)',
  },
  dialogTitle: {
    padding: '2rem 2rem 0.5rem 2rem',
  },
  dialogContent: {
    '@media (max-width: 1160px)': {
      padding: '0 1.5rem',
    },
    padding: '1rem 2rem 2rem',

    '& #clinical': {
      color: '#00846F',
    },

    '& #subclinical': {
      color: '#E38801',
    },

    ' & img': {
      margin: '1rem 0',
    },
  },
  mobileDialogPaper: {
    paddingTop: '3rem',
  },
}));

export default function InfoBar() {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { t } = useTranslation(['main']);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Collapse
        in={true}
        classes={{
          root: isMobile ? `${classes.infoBar} ${classes.infoBarMobile}` : classes.infoBar,
          wrapperInner: classes.infoBarContent,
        }}
      >
        <InfoOutlined className={classes.icon} />
        <Typography variant={isMobile ? 'body2' : 'body1'}>
          Bacticam är ViLA-godkänd! Nu ska bara prov från SUBKLINISKA mastiter skickas till SVA så
          snart du strukit provet.
          <span onClick={() => setIsDialogOpen(true)}>Läs mer</span>
        </Typography>
      </Collapse>
      <Dialog
        open={isDialogOpen}
        maxWidth='sm'
        fullScreen={isMobile}
        classes={{ paperScrollPaper: isMobile ? classes.mobileDialogPaper : classes.dialogPaper }}
        onClose={() => setIsDialogOpen(false)}
      >
        <DialogTitle disableTypography classes={{ root: isMobile ? '' : classes.dialogTitle }}>
          <Typography variant='h3'>
            Nu är Bacticamsystemet godkänt för användning inom ViLA!
          </Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Typography>
            <strong id='clinical'>Kliniska mastiter</strong>
          </Typography>
          <Typography>
            Du behöver inte längre skicka in prov från klinisk mastit direkt efter strykning utan
            bara i de fall då det anges och remiss finns att skriva ut. Det kan till exempel handla
            om odlingar där det inte går att fastställa vad som växer. I dessa fall är det i första
            hand
            <strong> den färdigodlade plattan </strong>
            som skickas till SVA.
          </Typography>
          <img src={clinicalInstructions} width={'100%'} />
          <Typography>
            <strong id='subclinical'>Subkliniska prov</strong> ska även i fortsättningen
            <strong> skickas till SVA direkt efter strykning </strong>
            tillsammans med en ny, anpassad följesedel.
          </Typography>
          <img src={subclinicalInstructions} width={'100%'} />
          <Typography>
            <strong>Stort tack på förhand för att du hjälper oss att utveckla Bacticam!</strong>
          </Typography>
        </DialogContent>
        <Button onClick={() => setIsDialogOpen(false)} color={'primary'}>
          {t('infoBar:close')}
        </Button>
      </Dialog>
    </>
  );
}
