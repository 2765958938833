import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  filterButton: {
    borderRadius: '2rem',
    padding: '0.2rem 0.7rem',
    maxHeight: '1.6rem',
    background: 'white',
  },
  clicked: {
    background: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: '#295035',
    },
  },
  unClicked: {
    border: '1px solid black',
  },
  bold: {
    fontWeight: 600,
  },
}));

export default function FilterButtons(props) {
  const { statuses, onSelectedStatusesUpdated } = props;
  const classes = useStyles();
  const { t } = useTranslation(['samplesFarmer']);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  useEffect(() => {
    onSelectedStatusesUpdated(selectedStatuses);
  }, [selectedStatuses, onSelectedStatusesUpdated]);

  return (
    <>
      {statuses.map((status) => {
        return (
          <Button
            key={status}
            onClick={() =>
              setSelectedStatuses((prevStatus) => {
                if (prevStatus.includes(status)) {
                  return selectedStatuses.filter((currentStatus) => currentStatus !== status);
                } else {
                  return [...prevStatus, status];
                }
              })
            }
            className={
              selectedStatuses.includes(status)
                ? `${classes.clicked} ${classes.filterButton}`
                : classes.filterButton
            }
            variant={selectedStatuses.includes(status) ? null : 'outlined'}
            style={{ outline: 'none' }}
          >
            <Typography variant='body2' className={classes.bold}>
              {t(`filter:${status}`)}
            </Typography>
          </Button>
        );
      })}
    </>
  );
}

FilterButtons.propTypes = {
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectedStatusesUpdated: PropTypes.func.isRequired,
};
