import React, { useState } from 'react';
import {
  List,
  ListItem,
  Button,
  Divider,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  useMediaQuery,
  makeStyles,
  Link,
} from '@material-ui/core';
import logo from './agricamlogga.svg';
import ErrorIcon from '@material-ui/icons/Error';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import CopyrightIcon from '@material-ui/icons/Copyright';
import HelpIcon from '@material-ui/icons/Help';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../actions/mainActions';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { AccountClient } from '../../Services/Utility/WebApiClient';
import { UserManager } from '../../Services/Utility/UserManager';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    maxWidth: '420px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignSelf: 'center',
    borderRadius: '4px',
    textAlign: 'center',
    boxShadow: '0 0 1.25rem rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    padding: '0 32px 16px',
  },
  loginContainerMobile: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignSelf: 'center',
    backgroundColor: '#fff',
    padding: '0 32px 16px',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '420px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  loginLogo: {
    height: '100px',
  },
  loginForm: {
    background: '#fff',
    paddingBottom: '24px',
  },
  group: {
    position: 'relative',
    marginBottom: '16px',
  },
  loginFailedWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '2%',
  },
  loginNotFailed: {
    display: 'none',
  },
  loadingWrapper: {
    marginTop: '2%',
  },
  supportContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '420px',
  },
  supportList: {
    width: '100%',
  },
  supportListItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  forgotPasswordWrapper: {
    cursor: 'pointer',
  },
  supportListItemText: {
    marginLeft: '2%',
  },
  iconWrapper: {
    marginRight: '0.25rem',
  },
  dialogTextField: {
    marginTop: '0.3rem',
  },
  divider: {
    margin: '0 -32px',
  },
  thirdPartySoftware: {
    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
}));

export default function LoginBox(props) {
  const {
    successfulSignInCallback,
    onPendingSignInCallback,
    failedSignInCallback,
    forbiddenSignInCallback,
    signedInState,
    previousPage,
  } = props;
  const isSmallMobile = useMediaQuery('(max-width:500px)');
  const classes = useStyles();
  const now = new Date();
  const dispatch = useDispatch();
  const { t } = useTranslation(['main']);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);

  let loginFailed = false;
  let errorText = '';
  // Credentials check

  if (signedInState === 'signedIn') {
    if (previousPage) {
      return <Redirect to={previousPage} />;
    } else {
      return <Redirect to='/samples' />;
    }
  } else if (signedInState === 'failed') {
    loginFailed = true;
    errorText = t('login:loginFailed');
  } else if (signedInState === 'forbidden') {
    loginFailed = true;
    errorText = t('login:loginForbidden');
  }

  async function login(event) {
    onPendingSignInCallback();
    event.preventDefault();
    (async () => {
      try {
        const loginObject = {
          mail: email,
          password: password,
        };
        UserManager.removeUser();
        const userResponse = await AccountClient.Login(loginObject).then((response) => {
          if (response === undefined) {
            // did not get a response, server probably down
            return null;
          }
          return response;
        });
        if (userResponse instanceof Error) {
          switch (userResponse.response.status) {
            case 401:
              failedSignInCallback();
              break;
            case 403:
              // user response returned 'forbidden' code
              forbiddenSignInCallback();
              break;
            default:
              failedSignInCallback();
              dispatch(openSnackbar('warning', 'somethingWentWrong'));
              break;
          }
        } else if (userResponse) {
          successfulSignInCallback(userResponse);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }

  function handleResetPassword() {
    if (
      document.getElementById('password-input').value === undefined ||
      document.getElementById('password-input').value === ''
    ) {
      return;
    }

    (async () => {
      try {
        const mail = {
          mail: document.getElementById('password-input').value,
        };

        const passwordResponse = await AccountClient.ResetPassword(mail).then((response) => {
          if (response === undefined) {
            // did not get a response, server probably down
            return null;
          }
          return response;
        });

        if (passwordResponse instanceof Error) {
          switch (passwordResponse.response.status) {
            case 500:
            case 429:
              dispatch(openSnackbar('warning', 'resetFailed'));
              break;
            case 400:
              dispatch(openSnackbar('warning', 'wrongFormat'));
              break;
            default:
              dispatch(openSnackbar('warning', 'somethingWentWrong'));
              break;
          }
        } else {
          setResetPasswordOpen(false);
          dispatch(openSnackbar('success', 'passwordReset'));
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }

  return (
    <div className={isSmallMobile ? classes.loginContainerMobile : classes.loginContainer}>
      <div className={classes.logoContainer}>
        <img className={classes.loginLogo} src={logo} alt={'Agricam'} />
      </div>
      <Divider className={classes.divider} />
      <form className={classes.loginForm} onSubmit={login}>
        <div>
          <div className={classes.group}>
            <TextField
              id='standard-email-input'
              label={t('login:Email')}
              type='email'
              onChange={(event) => setEmail(event.target.value)}
              fullWidth
              margin='normal'
            />
          </div>
          <div className={classes.group}>
            <TextField
              id='standard-password-input'
              label={t('login:Password')}
              type='password'
              autoComplete='current-password'
              margin='normal'
              fullWidth
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <div
            className={
              loginFailed
                ? classes.loginFailedWrapper
                : `${classes.loginFailedWrapper} ${classes.loginNotFailed}`
            }
          >
            <ErrorIcon color={'error'} className={classes.iconWrapper} />
            <Typography color={'error'}>{errorText}</Typography>
          </div>
        </div>
        {signedInState === 'pending' ? (
          <CircularProgress className={classes.loadingWrapper} />
        ) : (
          <Button
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            size='small'
            data-cy='login-button'
          >
            {t('login:Login')}
          </Button>
        )}
      </form>
      <Divider className={classes.divider} />
      <div className={classes.supportContainer}>
        <List className={classes.supportList}>
          <ListItem disableGutters>
            <Typography color='primary' variant='subtitle1'>
              Support
            </Typography>
          </ListItem>
          <ListItem disableGutters onClick={() => setResetPasswordOpen(true)}>
            <div className={`${classes.supportListItemWrapper} ${classes.forgotPasswordWrapper}`}>
              <HelpIcon color='primary' />
              <Typography color='primary' className={classes.supportListItemText}>
                {t('login:forgotPassword')}
              </Typography>
            </div>
          </ListItem>
          <ListItem
            disableGutters
            component='a'
            key='Email'
            href='https://knowledge.agricam.se/knowledge/kb-tickets/new'
            target='_blank'
            rel='noreferrer'
          >
            <div className={classes.supportListItemWrapper}>
              <MailIcon color='primary' />
              <Typography color='primary' className={classes.supportListItemText}>
                {t('contactSupport')}
              </Typography>
            </div>
          </ListItem>
          <ListItem disableGutters component='a' key='Phone' href='tel:+46768619908'>
            <div className={classes.supportListItemWrapper}>
              <PhoneIcon color='primary' />
              <Typography color='primary' className={classes.supportListItemText}>
                +46768619908
              </Typography>
            </div>
          </ListItem>
        </List>
      </div>
      <Typography variant='caption' color='primary'>
        Version: {process.env.REACT_APP_VERSION}
      </Typography>
      <Link
        href='/licenses.json'
        variant='caption'
        color='primary'
        className={classes.thirdPartySoftware}
      >
        {t('navigation:thirdPartySoftwareSettingTitle')}
      </Link>
      <div>
        <CopyrightIcon color='primary' fontSize='inherit' className={classes.iconWrapper} />
        <Typography variant='caption' color='primary'>
          {`${now.getFullYear()} Agricam AB`}
        </Typography>
      </div>

      {resetPasswordOpen && (
        <Dialog
          open={resetPasswordOpen}
          keepMounted
          onClose={() => setResetPasswordOpen(false)}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='form-dialog-title'>{t('login:resetPassword')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('login:resetPasswordSubtitle')}</DialogContentText>
            <TextField
              id='password-input'
              label={t('login:Email')}
              variant='outlined'
              fullWidth
              className={classes.dialogTextField}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setResetPasswordOpen(false)} color='primary'>
              {t('login:cancel')}
            </Button>
            <Button onClick={handleResetPassword} color='primary'>
              {t('login:send')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

LoginBox.propTypes = {
  onPendingSignInCallback: PropTypes.func,
  failedSignInCallback: PropTypes.func.isRequired,
  successfulSignInCallback: PropTypes.func.isRequired,
  forbiddenSignInCallback: PropTypes.func.isRequired,
  signedInState: PropTypes.string.isRequired,
  previousPage: PropTypes.string,
};
