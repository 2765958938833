import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import allReducers from '../reducers/allReducers';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const middleware = [thunk];

const store = createStore(allReducers, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
