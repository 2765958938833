import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useMediaQuery, Dialog, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  mobileRoot: {
    zIndex: '1400 !important', // make backdrop cover mobile toolbar
  },
  deleteDialog: {
    padding: '1.75rem 1.5rem',
    width: '25rem',
  },
  mobileDeleteDialog: {
    padding: '1.75rem 1.25rem 1rem',
  },
  deleteDialogHeader: {
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    marginBottom: '1rem',
  },
  deleteDialogBody: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  deleteDialogButtonContainer: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '&>button+button': {
      marginLeft: '0.75rem',
    },
  },
  mobileDeleteDialogButtonContainer: {
    justifyContent: 'space-between',
    marginTop: '2rem',
  },
  button: {
    padding: '0.5rem',
    '&>span': {
      lineHeight: '1rem',
    },
  },
  mobileButton: {
    padding: '0.6rem 3rem',
  },
  deleteButton: {
    backgroundColor: '#D10505',
    color: 'white',
    '&:hover': {
      backgroundColor: '#b50303',
    },
  },
});

export default function DeleteDialog(props) {
  const { close, comment, deleteComment } = props;
  const classes = useStyles();
  const { t } = useTranslation(['samplesDetails']);
  const isMobile = useMediaQuery('(max-width:1160px)');

  return (
    <Dialog
      open={true}
      classes={{
        paper: isMobile
          ? `${classes.deleteDialog} ${classes.mobileDeleteDialog}`
          : classes.deleteDialog,
        root: isMobile ? classes.mobileRoot : '',
      }}
      PaperProps={{ elevation: 0 }}
      onClose={close}
    >
      <Typography className={classes.deleteDialogHeader}>
        {t('commentCard:deleteDialogHeader')}
      </Typography>
      <Typography className={classes.deleteDialogBody}>
        {t('commentCard:deleteDialogBody')}
      </Typography>
      <div
        className={
          isMobile
            ? `${classes.deleteDialogButtonContainer} ${classes.mobileDeleteDialogButtonContainer}`
            : classes.deleteDialogButtonContainer
        }
      >
        <Button
          onClick={close}
          classes={{
            root: isMobile ? `${classes.button} ${classes.mobileButton}` : classes.button,
          }}
          variant='outlined'
          color='primary'
          disableElevation
          data-cy='cancel-button'
        >
          {t('commentCard:cancel')}
        </Button>
        <Button
          classes={{
            root: isMobile
              ? `${classes.button} ${classes.mobileButton} ${classes.deleteButton}`
              : `${classes.button} ${classes.deleteButton}`,
          }}
          variant='contained'
          disableElevation
          data-cy='delete-button'
          onClick={() => {
            deleteComment(comment.assignmentCommentId);
            close();
          }}
        >
          {t('commentCard:delete')}
        </Button>
      </div>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  close: PropTypes.func.isRequired,
  comment: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired,
};
