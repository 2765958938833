import DataService from '../../DataService';

export class FeedbackClient {
  static PostFeedback(feedbackModel) {
    return DataService.postObject('Feedback/PostFeedback', feedbackModel);
  }
}

export class AccountClient {
  static TokenLogin(requestModel = { systemId: null }) {
    return DataService.postObject('Account/TokenLogin', null, requestModel);
  }

  /**
   * @remark Returns a LoginResponseModel object that sets the usergroups property to null.
   * @example
   * // To fecth a LoginResponseModel object with the usergroups property.
   * let user = AccountClient.Login(loginRequestModel);
   * UserManager.removeUser(user);
   * UserManager.setIdToken(user);
   * user = AccountClient.TokenLogin(systemId);
   */
  static Login(loginRequestModel) {
    return DataService.postObject('Account/Login', loginRequestModel);
  }

  static ResetPassword(resetPasswordModel) {
    return DataService.postObject('Account/ResetPassword', resetPasswordModel);
  }

  static ChangePassword(changeUserPasswordModel) {
    return DataService.putObject('Account/ChangePassword', changeUserPasswordModel, '');
  }
}

export class SubjectsClient {
  static PutCowImportance(subjectId, updateCowImportanceModel) {
    return DataService.putObject('Subjects/PutCowImportance', updateCowImportanceModel, subjectId);
  }

  static PutSymptom(subjectId, updateSymptomModel) {
    return DataService.putObject('Subjects/PutSymptom', updateSymptomModel, subjectId);
  }
}

export class SampleLabFlagClient {
  static PutSampleLabFlag(sampleId, labFlagTrigger) {
    return DataService.putObject('SampleLabFlag/PutSampleLabFlag', {}, sampleId, {
      labFlagTrigger: labFlagTrigger,
    });
  }
}

export class ExportClient {
  static Download(requestModel = { from: null, to: null, fileType: null }) {
    return DataService.fetchFileById('Export/Download', '', requestModel);
  }
}

export class AssignmentCommentsClient {
  static PostAssignmentComment(assignmentCommentModel) {
    return DataService.postObject(
      'AssignmentComments/PostAssignmentComment',
      assignmentCommentModel,
    );
  }

  static PutAssignmentComment(assignmentCommentId, assignmentCommentModel) {
    return DataService.putObject(
      'AssignmentComments/PutAssignmentComment',
      assignmentCommentModel,
      assignmentCommentId,
    );
  }

  static DeleteAssignmentComment(assignmentCommentId) {
    return DataService.axiosDelete(
      'AssignmentComments/DeleteAssignmentComment',
      assignmentCommentId,
    );
  }

  static GetCommentsForUser(requestModel = { startDate: null, endDate: null, page: null }) {
    return DataService.fetchData('AssignmentComments/GetCommentsForUser', requestModel);
  }

  static GetCommentsByAssignmentId(requestModel = { assignmentId: null, newerThan: null }) {
    return DataService.fetchData('AssignmentComments/GetCommentsByAssignmentId', requestModel);
  }
}

export class BacticamSiteSettingsClient {
  static PutSiteSettings(siteSettingsModel) {
    return DataService.putObject('BacticamSiteSettings/PutSiteSettings', siteSettingsModel, '');
  }

  static GetSiteSettings(requestModel = { siteId: null }) {
    return DataService.fetchData('BacticamSiteSettings/GetSiteSettings', requestModel);
  }
}

export class SampleStatusesClient {
  static GetStatuses() {
    return DataService.fetchData('SampleStatuses/GetStatuses');
  }
}

export class BacteriaDataClient {
  static GetBacteriaData(requestModel = { startDate: null, endDate: null, siteId: null }) {
    return DataService.fetchData('BacteriaData/GetBacteriaData', requestModel);
  }
}

export class SamplesClient {
  static FireTrigger(sampleId, trigger) {
    return DataService.putObject('Samples/FireTrigger', {}, sampleId, {
      trigger: trigger,
    });
  }

  static ClassifySample(sampleId, lastActivity, sampleModel) {
    return DataService.putObject('Samples/ClassifySample', sampleModel, sampleId, {
      lastActivity: lastActivity,
    });
  }

  static GetActiveSamplesForSite(requestModel = { siteId: null, statusIds: null }) {
    return DataService.fetchData('Samples/GetActiveSamplesForSite', requestModel);
  }

  static GetSamplesBySubjectId(subjectId) {
    return DataService.fetchDataById('Samples/GetSamplesBySubjectId', subjectId);
  }

  static GetSiteSamples(
    requestModel = { siteId: null, page: null, cowNumber: null, orderBy: null, order: null },
  ) {
    return DataService.fetchData('Samples/GetSiteSamples', requestModel);
  }

  static GetSamples(
    requestModel = { page: null, cowNumber: null, statusIds: null, orderBy: null, order: null },
  ) {
    return DataService.fetchData('Samples/GetSamples', requestModel);
  }

  static GetSample(sampleId) {
    return DataService.fetchDataById('Samples/GetSample', sampleId);
  }
}

export class BacteriaActionsClient {
  static PostBacteriaAction(bacteriaActionModel) {
    return DataService.postObject('BacteriaActions/PostBacteriaAction', bacteriaActionModel);
  }

  static DeleteBacteriaAction(bacteriaActionId) {
    return DataService.axiosDelete('BacteriaActions/DeleteBacteriaAction', bacteriaActionId);
  }

  static GetBacteriaAction(bacteriaId, actionId) {
    return DataService.fetchData('BacteriaActions/GetBacteriaAction', {
      bacteriaId: bacteriaId,
      actionId: actionId,
    });
  }
}

export class BacteriaGroupsClient {
  static GetBacteriaGroups(requestModel = { orderBy: null }) {
    return DataService.fetchData('BacteriaGroups/GetBacteriaGroups', requestModel);
  }

  static Post(bacteriaGroupModel) {
    return DataService.postObject('BacteriaGroups/Post', bacteriaGroupModel);
  }

  static Put(id, bacteriaGroupModel) {
    return DataService.putObject('BacteriaGroups/Put', bacteriaGroupModel, id);
  }

  static Delete(id) {
    return DataService.axiosDelete('BacteriaGroups/Delete', id);
  }
}

export class BacteriasClient {
  static PostBacteria(bacteriaModel) {
    return DataService.postObject('Bacterias/PostBacteria', bacteriaModel);
  }

  static PutBacteria(bacteriaId, bacteriaModel) {
    return DataService.putObject('Bacterias/PutBacteria', bacteriaModel, bacteriaId);
  }

  static GetBacteriasSettableBy(requestModel = { sourceKind: null, orderBy: null }) {
    return DataService.fetchData('Bacterias/GetBacteriasSettableBy', requestModel);
  }

  static GetBacterias(requestModel = { orderBy: null }) {
    return DataService.fetchData('Bacterias/GetBacterias', requestModel);
  }
}

export class ActionsClient {
  static PostAction(actionModel) {
    return DataService.postObject('Actions/PostAction', actionModel);
  }

  static InactiveAction(actionId, actionModel) {
    actionModel.active = false;
    return this.PutAction(actionId, actionModel);
  }

  static PutAction(actionId, actionModel) {
    return DataService.putObject('Actions/PutAction', actionModel, actionId);
  }

  static GetActionsByBacteriaId(bacteriaId) {
    return DataService.fetchData('Actions/GetActionsByBacteriaId', {
      bacteriaId: bacteriaId,
    });
  }

  static GetActions() {
    return DataService.fetchData('Actions/GetActions');
  }
}

export class EnterprisesClient {
  static GetEnterpriseAndSites() {
    return DataService.fetchData('Enterprises/GetEnterpriseAndSites');
  }
}
