import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  makeStyles,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { closeLabFormDialog } from '../../../../actions/bacteriologistActions';
import { openSnackbar } from '../../../../actions/mainActions';
import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import { enums } from '../../../../Services/Utility/Enumerations';
import {
  getConfidenceLevelOptions,
  getCultureTypeOptions,
} from '../../../../Services/Utility/SampleInformation';
import { getBacteriaAmountList } from '../../../../Services/Utility/Classification';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { BacteriasClient, SamplesClient } from '../../../../Services/Utility/WebApiClient';

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    paddingTop: '50px',
  },
  desktopDialog: {
    maxHeight: '80vh',
  },
  labFormDialog: {
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    paddingTop: '0.5rem',
  },
  pre: {
    border: '1px solid #ccc',
    background: 'rgba(0, 0, 0, 0.1)',
    boxShadow: 'inset 1px 1px 3px rgba(0, 0, 0, 0.2)',
  },
  form: {
    margin: '10px auto',
    borderRadius: '3px',
    width: '100%',
    height: '100%',
    minHeight: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    '&>div': {
      display: 'flex',
      lineHeight: '2em',
      '&>label': {
        width: '10rem',
        margin: 0,
      },
      '&>select': {
        flex: 1,
        marginLeft: '1rem',
        borderRadius: '3px',
        border: '1px solid #ccc',
        padding: '10px 0 10px 5px',
      },
      '&>div': {
        '&>label': {
          width: '4rem',
          margin: 0,
        },
        '&>select': {
          flex: 1,
          marginLeft: '1rem',
          borderRadius: '3px',
          border: '1px solid #ccc',
          width: '100%',
        },
      },
    },
  },
  bacteriaContainer: {
    margin: '1.5rem 0 1rem',
    flexDirection: 'column',
    border: '1px solid rgb(0 0 0 / 10%)',
    borderRadius: '5px',
    padding: '1.5rem',
    position: 'relative',
  },
  bacteriaHeader: {
    padding: '0 0.5rem',
    background: 'white',
    top: '-0.6rem',
    left: '40%',
    position: 'absolute',
  },
  bactSelectContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  searchSelect: {
    '&>div': {
      flex: 1,
      minWidth: 'calc(100% - 4rem)',
    },
  },
  mobileBactSelect: {
    maxWidth: '10rem',
  },
  selectContainer: {
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  remove: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginBottom: '-1.5rem',
    marginRight: '-1.5rem',
  },
  buttonContainer: {
    justifyContent: 'space-between',
    marginTop: '4rem',
    flexGrow: 1,
  },
  button: {
    alignSelf: 'flex-end',
  },
  cursor: {
    cursor: 'pointer',
  },
  info: {
    margin: '1rem 0 1.5rem',
  },
  addBacteriaContainer: {
    justifyContent: 'flex-end',
  },
  mobileLabel: {
    width: '6rem !important',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    padding: '2rem 2rem 0.5rem',
  },
  close: {
    position: 'absolute',
    top: 0,
    right: '0.5rem',
    color: 'grey',
    cursor: 'pointer',
  },
  spinner: {
    color: '#FFFFFF',
    marginLeft: '0.75rem',
  },
}));

export default function LabFormDialog(props) {
  const { updateSample, isUpdatingSample } = props;
  const { t } = useTranslation(['bacterias']);
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const [bacterias, setBacterias] = useState([]);

  const sample = useSelector((state) => state.modalReducer.sample);
  const labFormDialogOpen = useSelector((state) => state.bacteriologistReducer.isOpen);
  const cultureTypes = getCultureTypeOptions();
  const condfidenceLevels = getConfidenceLevelOptions();
  const amount = getBacteriaAmountList();

  const initialValues = {
    resultType: enums.resultType.final,
    cultureType: enums.cultureType.negative,
    sourceKind: enums.sourceKind.laboratory,
    outcome: enums.bacteriaResultOutcome.success,
  };

  useEffect(() => {
    const fetchBacterias = async () => {
      const bacterias = await BacteriasClient.GetBacteriasSettableBy({
        sourceKind: enums.sourceKind.laboratory,
        orderBy: 'asc',
      });
      if (bacterias instanceof Error) {
        dispatch(openSnackbar('warning', 'fetchBacteriasError'));
      } else {
        const bacteriaList = bacterias.map((bacteria) => {
          return {
            value: bacteria.bacteriaid,
            label: t(`bacterias:${bacteria.name}`),
          };
        });

        setBacterias(bacteriaList);
      }
    };

    fetchBacterias();
  }, [dispatch, t]);

  const handleClose = () => {
    dispatch(closeLabFormDialog());
  };

  async function onSubmit(values) {
    if (values.bacterias?.[0]?.bacteriaId) {
      values.bacterias[0].resultGroup = 1;
    }

    const classifyResponse = await SamplesClient.ClassifySample(
      sample.sampleId,
      sample.lastActivity,
      values,
    ).then((response) => {
      if (response === undefined) {
        return null;
      }
      return response;
    });

    if (classifyResponse instanceof Error) {
      if (classifyResponse.response.status === 403) {
        dispatch(openSnackbar('warning', 'snackBarForbiddenAction'));
      } else {
        dispatch(openSnackbar('warning', 'somethingWentWrong'));
      }
    } else {
      dispatch(openSnackbar('success', 'snackBarClassified'));
      updateSample('labformDialog', sample.sampleId);
    }
  }

  function BacteriaForm() {
    return (
      <FieldArray name='bacterias'>
        {({ fields }) =>
          fields.map((name, index) => (
            <React.Fragment key={name}>
              <div className={classes.bacteriaContainer}>
                <h6 className={classes.bacteriaHeader}>
                  {index > 0 ? `Bakterie #${index + 1}` : 'Bakterie'}
                </h6>
                <div className={`${classes.bactSelectContainer} ${classes.searchSelect}`}>
                  <label>Namn:</label>
                  <Field
                    name={`${name}.bacteriaId`}
                    component={SearchSelect}
                    options={bacterias}
                    parse={(val) => val && val.value}
                    format={(val) => find(bacterias, (b) => b.value === val)}
                    autoFocus={!isMobile}
                    className={isMobile ? classes.mobileBactSelect : ''}
                  ></Field>
                </div>
                <div className={classes.bactSelectContainer} hidden>
                  <label>Säkerhet:</label>
                  <Field
                    name={`${name}.confidenceLevel`}
                    component='select'
                    className={isMobile ? classes.mobileBactSelect : ''}
                  >
                    <option />
                    {condfidenceLevels.map((c) => {
                      return (
                        <option key={c.key} value={c.value}>
                          {c.title}
                        </option>
                      );
                    })}
                  </Field>
                </div>
                <div className={classes.bactSelectContainer} hidden>
                  <label>Penicillinresistens:</label>
                  <Field
                    name={`${name}.penicillinResistance`}
                    component='select'
                    parse={(value) => (isNaN(parseFloat(value)) ? undefined : parseFloat(value))}
                  >
                    <option />
                    <option value={1}>{'+'}</option>
                    <option value={2}>{'-'}</option>
                  </Field>
                </div>
                <div className={classes.bactSelectContainer} hidden>
                  <label>Atypiskhet:</label>
                  <Field name={`${name}.appearance`} component='select'>
                    <option />
                    <option value={1}>{'Typisk'}</option>
                    <option value={2}>{'Atypisk'}</option>
                  </Field>
                </div>
                {/* <Condition
                  when='cultureType'
                  is={[enums.cultureType.mixedFlora, enums.cultureType.mixedInfection]}
                >
                  <div className={classes.bactSelectContainer}>
                    <label>Mängd:</label>
                    <Field name={`${name}.amount`} component='select'>
                      <option />
                      {amount.map((a) => {
                        return (
                          <option key={a.key} value={a.value}>
                            {a.title}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                </Condition> */}
                <div className={classes.remove}>
                  <span onClick={() => fields.remove(index)} className={classes.cursor}>
                    Ta bort 🗑️
                  </span>
                </div>
              </div>
            </React.Fragment>
          ))
        }
      </FieldArray>
    );
  }

  return (
    <Dialog
      open={labFormDialogOpen}
      onClose={handleClose}
      fullScreen={isMobile}
      classes={{
        root: classes.dialogWrapper,
        paperScrollPaper: isMobile ? '' : classes.desktopDialog,
      }}
    >
      <div className={classes.labFormDialog}>
        <DialogContent className={classes.contentWrapper}>
          <CloseIcon className={classes.close} onClick={handleClose} />
          <Typography variant='h3'>Provsvar från lab</Typography>
          <Typography className={classes.info}>
            {
              'Mata in resultatet från rapporten utfärdad av ett ackrediterat laboratorium. När du klickar på "Klassificera" kommer prosvsvaret uppdateras med de nya uppgifterna.'
            }
          </Typography>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            mutators={{
              ...arrayMutators,
            }}
            render={({
              form: {
                mutators: { push, pop },
              },
              handleSubmit,
              submitting,
              values,
              form,
            }) => (
              <form className={classes.form} onSubmit={handleSubmit} noValidate>
                <div className={classes.selectContainer}>
                  <label className={isMobile ? classes.mobileLabel : ''}>Bakteriekultur:</label>
                  <Field
                    disabled={values.bacterias?.length > 0}
                    name='cultureType'
                    component='select'
                    parse={(value) => (isNaN(parseFloat(value)) ? undefined : parseFloat(value))}
                  >
                    {/* <option /> */}
                    {cultureTypes.map((c) => {
                      return (
                        <option
                          disabled={c.value === enums.cultureType.negative && values.bacteriaAmount}
                          key={c.key}
                          value={c.value}
                        >
                          {c.value === enums.cultureType.pure ? `${c.title}/Annat` : c.title}
                        </option>
                      );
                    })}
                  </Field>
                </div>
                <Condition
                  when='cultureType'
                  is={[enums.cultureType.mixedFlora, enums.cultureType.pure]}
                >
                  <div className={classes.selectContainer}>
                    <label className={isMobile ? classes.mobileLabel : ''}>Växtmängd:</label>
                    <Field
                      name='bacteriaAmount'
                      component='select'
                      parse={(value) => (isNaN(parseFloat(value)) ? undefined : parseFloat(value))}
                    >
                      <option />
                      {amount.map((c) => {
                        return (
                          <option key={c.key} value={c.value}>
                            {c.title}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                </Condition>
                <Condition
                  when='cultureType'
                  is={[
                    enums.cultureType.mixedFlora,
                    enums.cultureType.mixedInfection,
                    enums.cultureType.pure,
                  ]}
                >
                  <BacteriaForm />
                  <div className={classes.addBacteriaContainer}>
                    <Button
                      disableElevation
                      className={classes.button}
                      type='button'
                      startIcon={<AddIcon />}
                      onClick={() => push('bacterias')}
                      hidden={
                        values.bacterias?.length === 1 &&
                        values.cultureType !== enums.cultureType.mixedInfection
                      }
                    >
                      Lägg till bakterie
                    </Button>
                  </div>
                </Condition>
                <div className={classes.buttonContainer}>
                  <Button
                    disableElevation
                    variant='contained'
                    size='large'
                    className={classes.button}
                    onClick={form.reset}
                  >
                    Rensa fält
                  </Button>
                  <Button
                    disableElevation
                    variant='contained'
                    color='primary'
                    size='large'
                    className={classes.button}
                    type='submit'
                    disabled={
                      submitting ||
                      values.cultureType === undefined ||
                      (values.cultureType === enums.cultureType.pure &&
                        (!values.bacterias || !values.bacterias[0]?.bacteriaId))
                    }
                  >
                    Klassificera
                    {(isUpdatingSample || submitting) && (
                      <CircularProgress className={classes.spinner} size='1.25rem' />
                    )}
                  </Button>
                </div>
                {/* <pre className={classes.pre}>{JSON.stringify(values, 0, 2)}</pre> */}
              </form>
            )}
          ></Form>
        </DialogContent>
      </div>
    </Dialog>
  );
}

export function Condition({ when, is = [], children }) {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (is.includes(value) ? children : null)}
    </Field>
  );
}

export function SearchSelect({ input, ...rest }) {
  const styles = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? '1px solid black' : '1px solid #ccc',
      boxShadow: state.isFocused ? '0px 0px 0px 1px black inset' : 'none',
      ':hover': {
        border: state.isFocused ? '1px solid black' : '1px solid #ccc',
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      border: 'none',
      backgroundColor: 'transparent',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      '&>div': {
        padding: 0,
        marginRight: '2px',
        width: '17px',
        height: '17px',
      },
    }),
    option: (base, state) => ({
      ...base,
      padding: '5px',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <Select
      {...input}
      {...rest}
      searchable
      menuPortalTarget={document.body}
      placeholder='Sök eller välj en bakterie'
      noOptionsMessage={() => 'Inga resultat'}
      styles={styles}
    />
  );
}

LabFormDialog.propTypes = {
  updateSample: PropTypes.func.isRequired,
  isUpdatingSample: PropTypes.bool.isRequired,
};

Condition.propTypes = {
  when: PropTypes.string.isRequired,
  is: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
};

SearchSelect.propTypes = {
  input: PropTypes.object.isRequired,
};
