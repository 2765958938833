import { yyyymmddhhmm, yyyymmdd } from '../DateUtils';
import { enums } from '../Enumerations';
import i18n from '../../../i18n';
import { differenceInHours } from 'date-fns';
import { getUdderPart } from '../Udder';

export const allSamplesFilter = 'all';

const farmerStatusFilterFinalResultAvailable = 'finalResultAvailable';
const farmerStatusFilterPreliminaryResultAvailable = 'preliminaryResultAvailable';
const farmerStatusFilterEarlyResultAvailable = 'earlyResultAvailable';
const farmerStatusFilterSupplementNeeded = 'supplementNeeded';
const farmerStatusFilterPhotoshootExpected = 'photoshootExpected';

const outcomesSetByHumanNotPossibleToActOn = [
  enums.bacteriaResultOutcome.incorrectSampleApplication,
  enums.bacteriaResultOutcome.tooLongCultivationTime,
  enums.bacteriaResultOutcome.badCulturePlate,
  enums.bacteriaResultOutcome.otherProblemFailed,
  enums.bacteriaResultOutcome.additionalImagesRequired,
  enums.bacteriaResultOutcome.undersideMarkingOnCulturePlate,
];

const outcomesSetByAIPossibleToActOn = [
  enums.bacteriaResultOutcome.poorImages,
  enums.bacteriaResultOutcome.missingImages,
];

const finalOutcomesSetByHumanPossibleToActOn = [
  enums.bacteriaResultOutcome.poorImages,
  enums.bacteriaResultOutcome.missingImages,
  enums.bacteriaResultOutcome.unknownBacteria,
  enums.bacteriaResultOutcome.tooFewBacteriasAdditionalEvaluationRequired,
  enums.bacteriaResultOutcome.tooShortCultivationTime,
  enums.bacteriaResultOutcome.otherProblemInconclusive,
  enums.bacteriaResultOutcome.undersideMarkingOnCulturePlate,
];

const preliminaryOutcomesSetByHumanPossibleToActOn = [
  enums.bacteriaResultOutcome.poorImages,
  enums.bacteriaResultOutcome.missingImages,
  enums.bacteriaResultOutcome.tooShortCultivationTime,
  enums.bacteriaResultOutcome.otherProblemInconclusive,
];

const now = new Date();

export function getSampleLocation(sample) {
  if (null !== sample) {
    return sample.site.name;
  }
  return '';
}

/**
 * Gets date string on form yyyy-mm-dd - hh:mm
 * @param {object} sample Sample to get date string for
 */
export function getSampleDate(sample) {
  return sample !== null ? yyyymmddhhmm(new Date(sample.createdAt)) : '';
}

/**
 * Gets date string on form yyyy-mm-dd
 * @param {object} sample Sample to get short date string for
 */
export function getShortSampleDate(sample) {
  return sample !== null ? yyyymmdd(new Date(sample.createdAt)) : '';
}

/**
 * Gets date string on form yyyy-mm-dd - hh:mm representing the latest activity on the sample
 * @param {object} sample Sample to get last activity date string for
 */
export function getSampleLastActivityDate(sample) {
  const lastActivityDate = sample
    ? sample.lastActivity
      ? new Date(sample.lastActivity)
      : new Date(sample.createdAt)
    : null;
  return lastActivityDate ? yyyymmddhhmm(new Date(lastActivityDate)) : '';
}

/**
 * Gets localized sample reason string
 * @param {number} reason The reason to get localized string for
 */
export function getSampleReason(reason) {
  switch (reason) {
    case enums.reason.clinical:
      return i18n.t('samplesFarmer:sampleReason:mastitisLabelClinical');
    case enums.reason.subClinical:
      return i18n.t('samplesFarmer:sampleReason:mastitisLabelSubClinical');
    default:
      return i18n.t('samplesFarmer:sampleReason:unknownReason');
  }
}

/**
 * Gets informative text regarding the result of a sample for a farmer
 * @param {object} result The result to get information text for
 */
export function getFarmerSampleOutcomeInfo(result) {
  const outcomeName = Object.keys(enums.bacteriaResultOutcome).find(
    (name) => enums.bacteriaResultOutcome[name] === result.bacteriaResultOutcome,
  );

  // outcome 'humanEvaluationRequired' displays slightly different information if its a preliminary or final result
  if (result.bacteriaResultOutcome === enums.bacteriaResultOutcome.humanEvaluationRequired) {
    if (result.resultType === enums.resultType.early) {
      return i18n.t('samplesFarmer:farmerSampleOutcomes:humanEvaluationRequiredEarly');
    } else if (result.resultType === enums.resultType.preliminary) {
      return i18n.t('samplesFarmer:farmerSampleOutcomes:humanEvaluationRequiredPreliminary');
    } else {
      return i18n.t('samplesFarmer:farmerSampleOutcomes:humanEvaluationRequired');
    }
  } else {
    return i18n.t(`samplesFarmer:farmerSampleOutcomes:${outcomeName}`);
  }
}

export function isSampleFailedOnEarlyByAI(sample) {
  if (
    sample.earlyResult &&
    outcomesSetByAIPossibleToActOn.includes(sample.earlyResult.bacteriaResultOutcome)
  ) {
    return true;
  }

  return false;
}

/**
 * Decide if the sample is failed on a preliminary level
 */
export function isSampleFailedOnPreliminary(sample) {
  if (
    sample.preliminaryResult &&
    outcomesSetByHumanNotPossibleToActOn.includes(sample.preliminaryResult.bacteriaResultOutcome)
  ) {
    return true;
  }

  return false;
}

/**
 * Decide if the sample is failed on a final level
 */
export function isSampleFailedOnFinal(sample) {
  if (
    sample.finalResult &&
    outcomesSetByHumanNotPossibleToActOn.includes(sample.finalResult.bacteriaResultOutcome)
  ) {
    return true;
  }

  return false;
}

/**
 * Decide if there is an inconclusive preliminary result which the user can act on
 */
export function isSampleInconclusivePreliminary(sample) {
  if (
    sample.preliminaryResult &&
    preliminaryOutcomesSetByHumanPossibleToActOn.includes(
      sample.preliminaryResult.bacteriaResultOutcome,
    )
  ) {
    return true;
  }

  return false;
}

/**
 * Decide if there is an inconclusive preliminary result that the user should ignore/do nothing about
 */
export function isSampleInconclusivePreliminaryDoNothing(sample) {
  if (
    sample.preliminaryResult &&
    [enums.bacteriaResultOutcome.additionalImagesRequired].includes(
      sample.preliminaryResult.bacteriaResultOutcome,
    )
  ) {
    return true;
  }
  return false;
}

/**
 * Decide if there is an inconclusive final result which the user can act on
 */
export function isSampleInconclusiveFinal(sample) {
  if (
    sample.finalResult &&
    finalOutcomesSetByHumanPossibleToActOn.includes(sample.finalResult.bacteriaResultOutcome)
  ) {
    return true;
  }

  return false;
}

/**
 * Check if the sample has a early bacteria result (outcome = success)
 */
export function hasEarlyResult(sample) {
  if (
    sample.earlyResult &&
    sample.earlyResult.bacteriaResultOutcome === enums.bacteriaResultOutcome.success
  ) {
    return true;
  }

  return false;
}

/**
 * Check if the sample has a preliminary bacteria result (outcome = success)
 */
export function hasPreliminaryResult(sample) {
  if (
    sample.preliminaryResult &&
    sample.preliminaryResult.bacteriaResultOutcome === enums.bacteriaResultOutcome.success
  ) {
    return true;
  }

  return false;
}

/**
 * Check if the sample has a final bacteria result (outcome = success)
 */
export function hasFinalResult(sample) {
  if (
    sample.finalResult &&
    sample.finalResult.bacteriaResultOutcome === enums.bacteriaResultOutcome.success
  ) {
    return true;
  }

  return false;
}

/**
 * Check if the sample has a early result where no bacteria could be set.
 */
export function hasEarlyInconclusiveResult(sample) {
  if (sample.earlyResult && sample.earlyResult.bacteriaResultOutcome > 1) {
    return true;
  }

  return false;
}

/**
 * Check if the sample has a preliminary result where no bacteria could be set.
 */
export function hasPreliminaryInconclusiveResult(sample) {
  if (sample.preliminaryResult && sample.preliminaryResult.bacteriaResultOutcome > 1) {
    return true;
  }

  return false;
}

/**
 * Check if the sample has a final result where no bacteria could be set.
 */
export function hasFinalInconclusiveResult(sample) {
  if (sample.finalResult && sample.finalResult.bacteriaResultOutcome > 1) {
    return true;
  }

  return false;
}

/**
 * Check if the successful final classification is expected to have a bacteria set (cultureType = 0 or 4)
 */
export function hasFinalBacteriaResult(sample) {
  if (
    hasFinalResult(sample) &&
    [enums.cultureType.pure, enums.cultureType.notSet].includes(
      sample.finalResult.cultureType.cultureType,
    )
  ) {
    return true;
  }

  return false;
}

/**
 * Check if the successful preliminary classification is expected to have a bacteria set (cultureType = 0 or 4)
 */
export function hasPreliminaryBacteriaResult(sample) {
  if (
    hasPreliminaryResult(sample) &&
    [enums.cultureType.pure, enums.cultureType.notSet].includes(
      sample.preliminaryResult.cultureType.cultureType,
    )
  ) {
    return true;
  }

  return false;
}

/**
 * Check if the successful early classification is expected to have a bacteria set (cultureType = 0 or 4)
 */
export function hasEarlyBacteriaResult(sample) {
  if (
    hasEarlyResult(sample) &&
    [enums.cultureType.pure, enums.cultureType.notSet].includes(
      sample.earlyResult.cultureType.cultureType,
    )
  ) {
    return true;
  }

  return false;
}

/**
 * Expects a result to be a cultureType and returns a string describing the result.
 * @param {Object} result - A preliminary or final result
 * @returns
 */
export function getCultureTypeLabel(result) {
  if (result.contagiousBacteriaSuspected) {
    return i18n.t('samplesDetails:classificationCard:mixedFloraContagiousBacteriaSuspected');
  } else {
    const cultureTypeName = Object.keys(enums.cultureType).find(
      (name) => enums.cultureType[name] === result.cultureType.cultureType,
    );
    return i18n.t(`samplesFarmer:cultureType:${cultureTypeName}`);
  }
}

/**
 * Decide what should be written as the 'sample information' on the CowCard (active sample),
 * and in the MilkSamplePanel (all samples), following a prioritization list
 */
export function getSampleInformation(sample) {
  if (hasFinalBacteriaResult(sample)) {
    return `${i18n.t(`bacterias:${sample.finalResult.bacterias[0]?.name}`)}${getBacteriaAmount(
      sample.finalResult.cultureType.bacteriaAmount,
    )}`;
  } else if (hasFinalResult(sample)) {
    return `${getCultureTypeLabel(sample.finalResult)}${getBacteriaAmount(
      sample.finalResult.cultureType.bacteriaAmount,
    )}`;
  }

  if (isSampleFailedOnFinal(sample) || isSampleInconclusiveFinal(sample)) {
    return 'somethingWentWrong';
  }

  if (hasPreliminaryBacteriaResult(sample)) {
    return `${i18n.t(
      `bacterias:${sample.preliminaryResult.bacterias[0]?.name}`,
    )}${getBacteriaAmount(sample.preliminaryResult.cultureType.bacteriaAmount)}`;
  } else if (hasPreliminaryResult(sample)) {
    return ` ${getCultureTypeLabel(sample.preliminaryResult)}${getBacteriaAmount(
      sample.preliminaryResult.cultureType.bacteriaAmount,
    )}`;
  }

  if (isSampleInconclusivePreliminaryDoNothing(sample)) {
    return '';
  }

  if (isSampleFailedOnPreliminary(sample) || isSampleInconclusivePreliminary(sample)) {
    return 'somethingWentWrong';
  }

  if (hasEarlyResult(sample)) {
    if (hasEarlyBacteriaResult(sample)) {
      return `${i18n.t(`bacterias:${sample.earlyResult.bacterias[0]?.name}`)}${getBacteriaAmount(
        sample.earlyResult.cultureType.bacteriaAmount,
      )}`;
    } else {
      return `${getCultureTypeLabel(sample.earlyResult)}${getBacteriaAmount(
        sample.earlyResult.cultureType.bacteriaAmount,
      )}`;
    }
  }

  if (isSampleFailedOnEarlyByAI(sample)) {
    return 'somethingWentWrong';
  }

  return '';
}

/**
 * Decide what should be written as the 'status' on the CowCard (active sample),
 * displayed below the 'sample information',
 */
export function getStatusLabel(sample) {
  if (hasFinalResult(sample)) {
    return '';
  }

  // In case we have a preliminary bacteria result, but also a failed or inconclusive final result,
  // the failed/inconclusive final result trumps the preliminary bacteria result
  if (isSampleFailedOnFinal(sample) || isSampleInconclusiveFinal(sample)) {
    return '';
  }

  if (hasPreliminaryResult(sample)) {
    return i18n.t('samplesFarmer:cowCard:preliminaryResultAvailable');
  }

  if (hasEarlyResult(sample)) {
    return i18n.t('samplesFarmer:cowCard:earlyResultAvailable');
  }

  return '';
}

/**
 * Gets farmer sample status text.
 * @param {object} sample The sample to get status text for
 */
export function getFarmerSampleStatus(sample) {
  switch (sample.status.statusId) {
    case enums.status.registered:
      return i18n.t('samplesFarmer:statuses:registered');

    case enums.status.finalResultAvailable:
      return i18n.t('samplesFarmer:statuses:finalResultAvailable');

    case enums.status.awaitingBac:
      return i18n.t('samplesFarmer:statuses:awaitingBac');

    case enums.status.awaitingSeniorBac:
      return i18n.t('samplesFarmer:statuses:awaitingSeniorBac');

    default:
      return i18n.t('samplesFarmer:statuses:waitingForResult');
  }
}

/**
 * The human-readable statuses to be able to filter on as farmer
 */
export const farmerStatusFilters = [
  farmerStatusFilterFinalResultAvailable,
  farmerStatusFilterPreliminaryResultAvailable,
  farmerStatusFilterEarlyResultAvailable,
  farmerStatusFilterSupplementNeeded,
  farmerStatusFilterPhotoshootExpected,
];

/**
 * Gets the localized text describing the given bacteria result outcome enum
 * @param {number} outcome The outcome to get localized text for
 */
export function getLocalizedOutcome(outcome) {
  const outcomeName = Object.keys(enums.bacteriaResultOutcome).find(
    (name) => enums.bacteriaResultOutcome[name] === outcome,
  );
  return i18n.t(`samplesBacteriologist:sampleOutcomes:${outcomeName}`);
}

/**
 * Bacteriologist filter list in "All samples"
 */
export function getSampleStatuses() {
  const statuses = [];

  // This option is used to show all samples
  statuses.push({
    key: '0',
    value: allSamplesFilter,
    title: i18n.t('samplesBacteriologist:tableHeader:filterStatusAllLabel'),
  });

  Object.keys(enums.status).forEach((name) => {
    statuses.push({
      key: enums.status[name],
      value: enums.status[name],
      title: i18n.t(`samplesFarmer:statuses:${name}`),
    });
  });

  return statuses;
}

/**
 * Checks if a sample matches a given bacteriologist view status filter
 * @param {object} sample The sample to check
 * @param {string} filter The filter to check the sample against
 */
export function hasBactStatus(sample, filter) {
  switch (filter) {
    case allSamplesFilter:
      return true;
    default:
      return sample.status.statusId === filter;
  }
}

/**
 * Gets localized sample status to display for bacteriologist in expanded expansionpanel
 * @param {object} sample The sample to get localized status for
 */
export function getBactSampleStatus(sample) {
  const statusKey = Object.keys(enums.status).find(
    (name) => enums.status[name] === sample.status.statusId,
  );

  return i18n.t(`samplesFarmer:statuses:${statusKey}`);
}

/**
 * Gets sample information to display for bacteriologist
 * @param {object} sample The sample to get info for
 */
export function getBactSampleInfo(sample) {
  if (hasFinalResult(sample)) {
    if (hasFinalBacteriaResult(sample)) {
      return `${i18n.t(`bacterias:${sample.finalResult.bacterias[0]?.name}`)}${getBacteriaAmount(
        sample.finalResult.cultureType.bacteriaAmount,
      )}`;
    } else {
      return `${getCultureTypeLabel(sample.finalResult)}${getBacteriaAmount(
        sample.finalResult.cultureType.bacteriaAmount,
      )}`;
    }
  } else if (hasPreliminaryResult(sample)) {
    if (hasPreliminaryBacteriaResult(sample)) {
      return `${i18n.t(
        `bacterias:${sample.preliminaryResult.bacterias[0]?.name}`,
      )}${getBacteriaAmount(sample.preliminaryResult.cultureType.bacteriaAmount)}`;
    } else {
      return `${getCultureTypeLabel(sample.preliminaryResult)}${getBacteriaAmount(
        sample.preliminaryResult.cultureType.bacteriaAmount,
      )}`;
    }
  } else if (hasEarlyResult(sample)) {
    if (hasEarlyBacteriaResult(sample)) {
      return `${i18n.t(`bacterias:${sample.earlyResult.bacterias[0]?.name}`)}${getBacteriaAmount(
        sample.earlyResult.cultureType.bacteriaAmount,
      )}`;
    } else {
      return `${getCultureTypeLabel(sample.earlyResult)}${getBacteriaAmount(
        sample.earlyResult.cultureType.bacteriaAmount,
      )}`;
    }
  } else if (enums.status.awaitingBac === sample.status) {
    return i18n.t('samplesBacteriologist:sampleOutcomes:humanEvaluationRequired');
  } else if (sample.finalResult) {
    return getLocalizedOutcome(sample.finalResult.bacteriaResultOutcome);
  } else if (sample.preliminaryResult) {
    return getLocalizedOutcome(sample.preliminaryResult.bacteriaResultOutcome);
  } else if (sample.earlyResult) {
    return getLocalizedOutcome(
      sample.earlyResult.bacteriaResultOutcome ===
        enums.bacteriaResultOutcome.humanEvaluationRequired
        ? enums.bacteriaResultOutcome.additionalImagesRequired
        : sample.earlyResult.bacteriaResultOutcome,
    );
  } else {
    return getFarmerSampleStatus(sample);
  }
}

/**
 * If there are preliminary photos, find out if they are correct or if the user needs to take new ones.
 */
export function arePreliminaryImagesCorrect(sample) {
  if (
    sample.preliminaryResult &&
    sample.preliminaryResult.bacteriaResultOutcome === enums.bacteriaResultOutcome.poorImages
  ) {
    return false;
  }

  return true;
}

/**
 * If there are final photos, find out if they are correct or if the user needs to take new ones.
 */
export function areFinalImagesCorrect(sample) {
  if (
    sample.finalResult &&
    sample.finalResult.bacteriaResultOutcome === enums.bacteriaResultOutcome.poorImages
  ) {
    return false;
  }

  return true;
}

/**
 * Filters active samples on 'statuses':
 * - Final (bacterial) result available
 * - Preliminary (bacterial) result available
 * - Supplement needed
 * - Photoshoot is expected
 * @param {array} cows - List of cows with active samples to be filtered
 * @param {array} filterOptions - Statuses the samples should be filtered on
 */
export function filterActiveSamples(cows, filterOptions) {
  if (filterOptions.length === 0) {
    return cows;
  }

  const temp = [];

  cows.forEach((cow) => {
    const filteredSamples = cow.samples.filter((sample) => {
      let includeSample = false;
      if (filterOptions.includes(farmerStatusFilterFinalResultAvailable)) {
        includeSample = includeSample || hasFinalResult(sample);
      }
      if (includeSample) {
        return true;
      }

      if (filterOptions.includes(farmerStatusFilterPreliminaryResultAvailable)) {
        includeSample = includeSample || hasPreliminaryResult(sample);
      }
      if (includeSample) {
        return true;
      }

      if (filterOptions.includes(farmerStatusFilterSupplementNeeded)) {
        includeSample = includeSample || sampleHasSupplementNeededFilter(sample);
      }
      if (includeSample) {
        return true;
      }

      if (filterOptions.includes(farmerStatusFilterPhotoshootExpected)) {
        includeSample = includeSample || sampleHasPhotoshootExpectedFilter(sample);
      }
      if (includeSample) {
        return true;
      }

      if (filterOptions.includes(farmerStatusFilterEarlyResultAvailable)) {
        includeSample = includeSample || hasEarlyResult(sample);
      }
      if (includeSample) {
        return true;
      }

      return false;
    });
    if (filteredSamples.length > 0) {
      temp.push({ ...cow, samples: filteredSamples });
    }
  });

  return temp;
}

/**
 * Returns true if the user is expected to act on the sample
 */
function sampleHasSupplementNeededFilter(sample) {
  // incorrect preliminary images
  if (sample.preliminaryImagesCreatedAt && !arePreliminaryImagesCorrect(sample)) {
    return true;
  }

  // incorrect final images
  if (sample.finalImagesCreatedAt && !areFinalImagesCorrect(sample)) {
    return true;
  }

  if (
    isSampleInconclusivePreliminary(sample) ||
    isSampleFailedOnPreliminary(sample) ||
    isSampleFailedOnFinal(sample)
  ) {
    return true;
  }

  return false;
}

/**
 * Returns true if the user is expected to take photos
 */
function sampleHasPhotoshootExpectedFilter(sample) {
  const sampleCreated = new Date(sample.createdAt);
  const cultivationHours = differenceInHours(now, sampleCreated);
  const dayOnePhotoReady = cultivationHours >= 18 && cultivationHours < 36;
  const dayTwoPhotoReady = cultivationHours >= 36 && cultivationHours < 72;

  // check if first photoshoot is expected
  if (!sample.preliminaryImagesCreatedAt && dayOnePhotoReady) {
    return true;
  }

  if (!sample.finalImagesCreatedAt && dayTwoPhotoReady) {
    return true;
  }

  return false;
}

/**
 * Determine if the result was set by the AI.
 * @param {object} result - Result to be checked (sample.preliminaryResult or sample.finalResult)
 */
export function isResultSetByAI(result) {
  if ([enums.sourceKind.ai, enums.sourceKind.bdh].includes(result.sourceKind)) {
    return true;
  }

  return false;
}

/**
 * Determine if the result was set by a bacteriologist.
 * @param {object} result - Result to be checked (sample.preliminaryResult or sample.finalResult)
 */
export function isResultSetByBacteriologist(result) {
  if ([enums.sourceKind.seniorBacteriologist].includes(result.sourceKind)) {
    return true;
  }

  return false;
}

/**
 * Determine if the result was set by a veterinarian.
 * @param {object} result - Result to be checked (sample.preliminaryResult or sample.finalResult)
 */
export function isResultSetByVeterinarian(result) {
  if ([enums.sourceKind.juniorBacteriologist].includes(result.sourceKind)) {
    return true;
  }

  return false;
}

/**
 * Determine if the result was determined by a laboratory.
 * @param {object} result - Result to be checked (sample.preliminaryResult or sample.finalResult)
 */
export function isResultSetByLab(result) {
  if ([enums.sourceKind.laboratory].includes(result.sourceKind)) {
    return true;
  }

  return false;
}

/**
 * Returns a string describing the role of the person/AI who set this result
 * @param {object} result - Result to be checked (sample.preliminaryResult or sample.finalResult)
 */
export function whoSetThisResultName(result) {
  if (result === 'preliminaryResultMissing' || isResultSetByAI(result)) {
    return i18n.t('samplesDetails:informationCard:automatic');
  } else if (isResultSetByVeterinarian(result)) {
    return i18n.t('samplesDetails:informationCard:veterinarian');
  } else if (isResultSetByLab(result)) {
    return i18n.t('samplesDetails:informationCard:lab');
  } else if (isResultSetByBacteriologist(result)) {
    return i18n.t('samplesDetails:informationCard:bacteriologist');
  }
}

/**
 * Returns translated outcome the sample was given if it was inconclusive
 */
export function getInconclusiveOutcome(sample) {
  if (hasFinalInconclusiveResult(sample)) {
    return getLocalizedOutcome(sample.finalResult.bacteriaResultOutcome);
  } else if (hasPreliminaryInconclusiveResult(sample)) {
    return getLocalizedOutcome(sample.preliminaryResult.bacteriaResultOutcome);
  } else {
    return '';
  }
}

/**
 * Check if the preliminary result should be ignored/rejected.
 * If the preliminary result is outcome 'humanEvaluationRequired',
 * and there exist a final result, the preliminary result should be ignored.
 */
export function shouldPreliminaryResultBeRejected(sample) {
  if (
    sample.finalResult !== null &&
    sample.finalResult.bacteriaResultOutcome !== enums.bacteriaResultOutcome.notSet &&
    sample.preliminaryResult !== null &&
    sample.preliminaryResult.bacteriaResultOutcome ===
      enums.bacteriaResultOutcome.humanEvaluationRequired
  ) {
    return true;
  }

  return false;
}

/**
 * Decide if the preliminary result of the sample should be displayed in the timeline.
 */
export function displayPreliminaryResultInTimeline(sample) {
  if (
    shouldPreliminaryResultBeRejected(sample) ||
    (hasPreliminaryInconclusiveResult(sample) &&
      [
        enums.bacteriaResultOutcome.additionalImagesRequired,
        enums.bacteriaResultOutcome.humanEvaluationRequired,
      ].includes(sample.preliminaryResult.bacteriaResultOutcome))
  ) {
    return false;
  }

  return true;
}

/**
 * Decide if the final result of the sample should be displayed in the timeline.
 */
export function displayFinalResultInTimeline(sample) {
  if (
    hasFinalInconclusiveResult(sample) &&
    sample.finalResult.bacteriaResultOutcome === enums.bacteriaResultOutcome.humanEvaluationRequired
  ) {
    return false;
  }

  return true;
}

/**
 *  Returns a string with information about the sample in the format 'Cow X, Site (UdderPart)'
 */
export function getURLTitle(sample) {
  const cow = sample.subject.number;
  const udderPart = getUdderPart(
    sample.udderId,
    'samplesDetails:farmerDetails:udderPartFrontLeft',
    'samplesDetails:farmerDetails:udderPartFrontRight',
    'samplesDetails:farmerDetails:udderPartBackLeft',
    'samplesDetails:farmerDetails:udderPartBackRight',
    'samplesDetails:farmerDetails:udderPartUnknown',
  );
  const site = sample.site.name;

  return i18n.t('samplesDetails:sampleURL', {
    cow: cow,
    siteName: site,
    udderPart: udderPart,
  });
}

/**
 * Returns a string in the format ' (bacteriaAmount)' if the amount to be translated is any other than 'notSet'.
 */
export function getBacteriaAmount(amount) {
  const amountName = Object.keys(enums.bacteriaAmount)
    .filter((name) => ![enums.bacteriaAmount.notSet].includes(enums.bacteriaAmount[name]))
    .find((name) => enums.bacteriaAmount[name] === amount);

  if (amountName) {
    return ` (${i18n.t(`samplesDetails:bacteriaAmount:${amountName}`)})`;
  }

  return '';
}

export function sampleIsMarkedAsSendInForVerification(sample) {
  if (
    [
      enums.labFlag.sendLocked,
      enums.labFlag.hasBeenSent,
      enums.labFlag.requestedResultAvailable,
      enums.labFlag.send,
      enums.labFlag.notificationScheduled,
    ].includes(sample.labFlag)
  ) {
    return true;
  }
  return false;
}

/**
 * Returns a list of samples that should be verified
 */
export function getSamplesToVerify(cows) {
  const samplesToVerify = [];

  if (cows.length > 0) {
    cows.forEach((cow) =>
      cow.samples.forEach((sample) => {
        if (sample.labFlag === enums.labFlag.sendLocked) {
          samplesToVerify.push(sample);
        }
      }),
    );
  }

  return samplesToVerify;
}

/**
 * Returns a list of sorted events for a sample
 */
export function getSampleHistory(sample) {
  // Sample registration
  const events = [
    {
      eventType: 'registration',
      eventDate: sample.createdAt,
    },
  ];

  // Early classifications
  if (sample.earlyBacteriaResults) {
    sample.earlyBacteriaResults.forEach((result) => {
      if (result.bacteriaResultOutcome !== 0) {
        events.push({
          eventType: 'classification',
          eventDate: result.createdAt,
          classification: result,
        });
      }
    });
  }

  // Preliminary classifications
  if (sample.preliminaryBacteriaResults) {
    sample.preliminaryBacteriaResults.forEach((result) => {
      if (result.bacteriaResultOutcome !== 0) {
        events.push({
          eventType: 'classification',
          eventDate: result.createdAt,
          classification: result,
        });
      }
    });
  }
  // Final classifications
  if (sample.finalBacteriaResults) {
    sample.finalBacteriaResults.forEach((result) => {
      if (result.bacteriaResultOutcome !== 0) {
        events.push({
          eventType: 'classification',
          eventDate: result.createdAt,
          classification: result,
        });
      }
    });
  }
  // Received images
  if (sample.sampleImagePairs) {
    sample.sampleImagePairs.forEach((imagePair) => {
      if (imagePair.receivedAt) {
        events.push({
          eventType: 'imagesReceived',
          eventDate: imagePair.receivedAt,
        });
      }
    });
  }
  // Sort events on date
  events.sort(function (a, b) {
    const keyA = a.eventDate;
    const keyB = b.eventDate;
    // Compare the 2 dates
    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
  });

  return events;
}

/**
 * Takes a result object and returns a string. Used in the sample history timeline
 */
export function getClassificationText(result) {
  if (result.bacteriaResultOutcome === enums.bacteriaResultOutcome.success) {
    if (
      [enums.cultureType.notSet, enums.cultureType.pure].includes(result.cultureType.cultureType)
    ) {
      return `${i18n.t(`bacterias:${result.bacterias[0]?.name}`)}${getBacteriaAmount(
        result.cultureType.bacteriaAmount,
      )}`;
    } else {
      return `${getCultureTypeLabel(result)}${getBacteriaAmount(
        result.cultureType.bacteriaAmount,
      )}`;
    }
  } else {
    // On early images, if AI can't say if it is E coli or Klebsiella, outcome "humanEvaluationRequired" will be set.
    // However, a human is not expected to classify the sample based on the early images.
    // Instead we expect the next photo shoot.
    if (
      result.bacteriaResultOutcome === enums.bacteriaResultOutcome.humanEvaluationRequired &&
      result.resultType === enums.resultType.early
    ) {
      return getLocalizedOutcome(enums.bacteriaResultOutcome.additionalImagesRequired);
    }
    return getLocalizedOutcome(result.bacteriaResultOutcome);
  }
}

/**
 * Returns a string if the sourceKind to be translated is any other than 'notSet'.
 */
export function getSourceLabel(sourceKind) {
  const sourceName = Object.keys(enums.sourceKind)
    .filter((source) => ![enums.sourceKind.notSet].includes(enums.sourceKind[source]))
    .find((source) => enums.sourceKind[source] === sourceKind);

  if (sourceName) {
    return `${i18n.t(`samplesDetails:sourceKind:${sourceName}`)}`;
  }

  return '';
}

export function getCultureTypeOptions() {
  return Object.keys(enums.cultureType)
    .filter(
      (name) =>
        ![enums.cultureType.notSet, enums.cultureType.mixedInfection].includes(
          enums.cultureType[name],
        ),
    )
    .map((name) => {
      return {
        key: enums.cultureType[name],
        value: enums.cultureType[name],
        title: i18n.t(`samplesDetails:classificationCard:${name}`),
      };
    });
}

export function getConfidenceLevelOptions() {
  return Object.keys(enums.confidenceLevel)
    .filter((name) => ![enums.confidenceLevel.notSet].includes(enums.confidenceLevel[name]))
    .map((name) => {
      return {
        key: enums.confidenceLevel[name],
        value: enums.confidenceLevel[name],
        title: i18n.t(`samplesDetails:classificationCard:${name}`),
      };
    });
}
