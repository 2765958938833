import React, { useState, useEffect } from 'react';
import { enums } from '../../../../../Services/Utility/Enumerations';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { isBefore, subDays } from 'date-fns';
import UdderIcon from '../../../FarmerSampleContainer/FarmerSampleView/UdderIcon';
import ResultIcon from '../ResultIcon';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Card,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  Tooltip,
  CircularProgress,
  Fade,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import SaveIcon from '@material-ui/icons/Save';
import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';
import {
  getSampleLocation,
  getSampleDate,
  getSampleReason,
  getFarmerSampleOutcomeInfo,
  whoSetThisResultName,
  shouldPreliminaryResultBeRejected,
  getBacteriaAmount,
  getCultureTypeLabel,
} from '../../../../../Services/Utility/SampleInformation';
import { openSnackbar } from '../../../../../actions/mainActions';
import { useDispatch } from 'react-redux';
import { SubjectsClient } from '../../../../../Services/Utility/WebApiClient';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  card: {
    padding: '1rem',
    position: 'relative',
    border: '1px solid #E8E8E8',
  },
  mobileCard: {
    padding: '1rem',
    position: 'relative',
  },
  shortListItem: {
    width: 'calc(100% - 7.2rem)',
  },
  shortListItemMobile: {
    width: '100%',
  },
  helpIcon: {
    opacity: '0.54',
    margin: '0 0 0.15rem 0.3rem',
    cursor: 'pointer',
  },
  udderIconWrapper: {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    width: '6.3rem',
  },
  udderIconMobileWrapper: {
    width: '4.1rem',
    marginTop: '1.4rem',
  },
  lineWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  infoDialogSubtitle: {
    marginTop: '1rem',
  },
  buttonWrapper: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'flex-end',
  },
  spinnerWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    opacity: '0.6',
    zIndex: '2',
  },
  editValueColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonWithRightMargin: {
    margin: '0.5rem',
  },
  symptomSelectorWrapper: {
    margin: '0 1rem 1rem 0',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  removePadding: {
    paddingBottom: 0,
  },
  resultTextBox: {
    paddingBottom: '0.5rem',
    display: 'flex',
    '&>p>a': {
      textDecoration: 'underline',
      marginLeft: '0.3rem',
    },
  },
}));

export default function InformationCard(props) {
  const { t } = useTranslation(['samplesDetails', 'samplesFarmer', 'bacterias']);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { sample } = props;
  const [oldSample, setOldSample] = useState(false);
  const [editCowImportance, setEditCowImportance] = useState(false);
  const [cowImportanceIsUpdating, setCowImportanceIsUpdating] = useState(false);
  const [cowImportance, setCowImportance] = useState(sample.cowImportance);
  const [cowImportanceInfo, setCowImportanceInfo] = useState(false);
  const [editSymptom, setEditSymptom] = useState(false);
  const [symptomIsUpdating, setSymptomIsUpdating] = useState(false);
  const [symptom, setSymptom] = useState(sample.mastitisSymptom);
  const [symptomInfo, setSymptomInfo] = useState(false);
  const isLoading = cowImportanceIsUpdating || symptomIsUpdating;
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    function checkOldSample() {
      const now = new Date();
      const thresholdDate = subDays(now, 7);
      const sampleDate = new Date(sample.createdAt);

      setOldSample(isBefore(sampleDate, thresholdDate));
    }

    setCowImportance(sample.cowImportance);
    setSymptom(sample.mastitisSymptom);
    setEditCowImportance(false);
    setEditSymptom(false);
    setCowImportanceIsUpdating(false);
    setSymptomIsUpdating(false);
    checkOldSample();
  }, [sample]);

  function getPenicillinTreatment(treated) {
    switch (treated) {
      case enums.penicillin.notTreated:
        return t('informationCard:noPenicillin');
      case enums.penicillin.treated:
        return t('informationCard:penicillin');
      default:
        return t('informationCard:unknownPenicillin');
    }
  }

  function getCowImportance(treated) {
    switch (treated) {
      case enums.cowImportance.low:
        return t('informationCard:low');
      case enums.cowImportance.medium:
        return t('informationCard:medium');
      case enums.cowImportance.high:
        return t('informationCard:high');
      default:
        return t('informationCard:unknownImportance');
    }
  }

  function getMastisisSymptom(treated) {
    switch (treated) {
      case enums.symptom.cellCountChanges:
        return t('informationCard:cellCountChanges');
      case enums.symptom.milkChanges:
        return t('informationCard:milkChanges');
      case enums.symptom.swollenUdder:
        return t('informationCard:swollenUdder');
      case enums.symptom.affectedGeneralCondition:
        return t('informationCard:affectedGeneralCondition');
      case enums.symptom.stronglyAffectedGeneralCondition:
        return t('informationCard:stronglyAffectedGeneralCondition');
      default:
        return t('informationCard:unknownSymptom');
    }
  }

  function doCloseInfo() {
    setSymptomInfo(false);
    setCowImportanceInfo(false);
  }

  function handleImportanceChange(event) {
    setCowImportance(parseInt(event.target.value));
  }

  function handleImportanceSave() {
    setCowImportanceIsUpdating(true);
    (async () => {
      try {
        const updateImportanceObject = {
          ReferencePoint: sample.createdAt,
          CowImportance: cowImportance,
        };
        const importanceResponse = await SubjectsClient.PutCowImportance(
          sample.subject.subjectId,
          updateImportanceObject,
        );

        if (importanceResponse instanceof Error) {
          setCowImportance(sample.cowImportance);
          dispatch(openSnackbar('warning', 'editFailed'));
        } else if (importanceResponse && importanceResponse.updatedAssignments) {
          dispatch(
            openSnackbar('success', 'editSuccessful', {
              editedSamples: importanceResponse.updatedAssignments,
            }),
          );
        }
        setCowImportanceIsUpdating(false);
      } catch (e) {
        console.log(e);
      }
    })();

    setEditCowImportance(false);
  }

  function handleImportanceClose() {
    setEditCowImportance(false);
    setCowImportance(sample.cowImportance);
  }

  function handleSymptomChange(event) {
    setSymptom(parseInt(event.target.value));
  }

  function handleSymptomSave() {
    setSymptomIsUpdating(true);
    (async () => {
      try {
        const updateSymptomObject = {
          SampleCreatedAt: sample.createdAt,
          MastitisSymptom: symptom,
        };
        const symptomResponse = await SubjectsClient.PutSymptom(
          sample.subject.subjectId,
          updateSymptomObject,
        );
        if (symptomResponse instanceof Error) {
          setSymptom(sample.mastitisSymptom);
          dispatch(openSnackbar('warning', 'editFailed'));
        } else if (symptomResponse && symptomResponse.updatedAssignments) {
          dispatch(
            openSnackbar('success', 'editSuccessful', {
              editedSamples: symptomResponse.updatedAssignments,
            }),
          );
        }
        setSymptomIsUpdating(false);
      } catch (e) {
        console.log(e);
      }
    })();

    setEditSymptom(false);
  }

  function handleSymptomClose() {
    setEditSymptom(false);
    setSymptom(sample.mastitisSymptom);
  }

  function getSampleResult(result, resultType) {
    let text = null;

    if (result && result !== 'preliminaryResultMissing') {
      if (result.bacteriaResultOutcome === enums.bacteriaResultOutcome.success) {
        if (
          [enums.cultureType.pure, enums.cultureType.notSet].includes(
            result.cultureType.cultureType,
          )
        ) {
          text = `${t(`bacterias:${result.bacterias[0]?.name}`)} ${getBacteriaAmount(
            result.cultureType.bacteriaAmount,
          )}`;
        } else {
          text = `${getCultureTypeLabel(result)}${getBacteriaAmount(
            result.cultureType.bacteriaAmount,
          )}`;
        }
      } else {
        text = getFarmerSampleOutcomeInfo(result);
      }
    } else if (result === 'preliminaryResultMissing') {
      text = t('samplesFarmer:farmerSampleOutcomes:preliminaryResultMissing');
    }

    return text ? (
      <>
        <Divider component='li' />
        <ListItem disableGutters={true} classes={{ root: classes.removePadding }}>
          <ListItemText
            disableTypography={true}
            primary={
              <Typography variant='h6' gutterBottom>
                {t(`informationCard:${resultType}`)}
              </Typography>
            }
            secondary={
              <div className={classes.resultTextBox}>
                <ResultIcon result={result} />
                <div>
                  <Typography variant='h6' gutterBottom>
                    {text}
                  </Typography>
                  <Typography variant='body2' color='textSecondary'>
                    {t('informationCard:createdBy', { name: whoSetThisResultName(result) })}
                  </Typography>
                </div>
              </div>
            }
          />
        </ListItem>
      </>
    ) : (
      <></>
    );
  }

  const symptomSelector = (
    <div className={classes.symptomSelectorWrapper}>
      <Select value={symptom} onChange={handleSymptomChange}>
        <MenuItem value={enums.symptom.milkChanges}>{t('informationCard:milkChanges')}</MenuItem>
        <MenuItem value={enums.symptom.swollenUdder}>{t('informationCard:swollenUdder')}</MenuItem>
        <MenuItem value={enums.symptom.affectedGeneralCondition}>
          {t('informationCard:affectedGeneralCondition')}
        </MenuItem>
        <MenuItem value={enums.symptom.stronglyAffectedGeneralCondition}>
          {t('informationCard:stronglyAffectedGeneralCondition')}
        </MenuItem>
      </Select>
    </div>
  );

  const spinner = (
    <div className={classes.spinnerWrapper}>
      <Fade
        in={isLoading}
        style={{
          transitionDelay: isLoading ? '300ms' : '0ms',
        }}
        unmountOnExit
      >
        <CircularProgress color='primary' size='2rem' />
      </Fade>
    </div>
  );

  return (
    <Card className={isMobile ? classes.mobileCard : classes.card} elevation={isMobile ? 0 : 1}>
      <Typography variant='subtitle1' gutterBottom>
        {t('informationCard:information')}
      </Typography>
      <div
        className={
          isMobile
            ? `${classes.udderIconMobileWrapper} ${classes.udderIconWrapper}`
            : classes.udderIconWrapper
        }
      >
        <UdderIcon sample={sample} size={isMobile ? 'small' : 'large'} />
      </div>
      <List component='div' disablePadding={true}>
        <ListItem
          disableGutters={true}
          className={isMobile ? classes.shortListItemMobile : classes.shortListItem}
        >
          <ListItemText
            primary={
              <Typography variant='h6' gutterBottom>
                {t('informationCard:locationLabel')}
              </Typography>
            }
            secondary={
              <Typography variant='body2'>
                {getSampleDate(sample)} {!isMobile && getSampleLocation(sample)}
              </Typography>
            }
          />
        </ListItem>
        <Divider
          component='li'
          className={isMobile ? classes.shortListItemMobile : classes.shortListItem}
        />
        <ListItem disableGutters={true}>
          <ListItemText
            disableTypography
            primary={
              <Typography variant='h6' gutterBottom>
                {getSampleReason(sample.sampleReason)}
              </Typography>
            }
            secondary={
              <Grid container direction='row' alignItems='center'>
                <Typography variant='body2'>
                  {getPenicillinTreatment(sample.penicillinTreatment)}
                </Typography>
              </Grid>
            }
          />
        </ListItem>
        <Divider component='li' />
        <ListItem disableGutters={true}>
          {editCowImportance ? (
            <div className={classes.fullWidth}>
              <div className={classes.editValueColumn}>
                <ListItemText
                  disableTypography={true}
                  primary={
                    <Typography variant='h6' gutterBottom>
                      {t('informationCard:cowImportanceLabel')}
                      <HelpIcon
                        fontSize='small'
                        className={classes.helpIcon}
                        onClick={() => setCowImportanceInfo(true)}
                      />
                    </Typography>
                  }
                  secondary={
                    <div>
                      <Typography variant='body2'>
                        {t('informationCard:editCowImportanceSubtitle')}
                      </Typography>
                    </div>
                  }
                />
              </div>
              <div className={classes.lineWrapper}>
                <RadioGroup row value={cowImportance} onChange={handleImportanceChange}>
                  <FormControlLabel
                    value={enums.cowImportance.low}
                    control={<Radio color='default' size='small' />}
                    label={<Typography variant='body2'>{t('informationCard:low')}</Typography>}
                  />
                  <FormControlLabel
                    value={enums.cowImportance.medium}
                    control={<Radio color='default' size='small' />}
                    label={<Typography variant='body2'>{t('informationCard:medium')}</Typography>}
                  />
                  <FormControlLabel
                    value={enums.cowImportance.high}
                    control={<Radio color='default' size='small' />}
                    label={<Typography variant='body2'>{t('informationCard:high')}</Typography>}
                  />
                </RadioGroup>

                <div className={classes.buttonWrapper}>
                  <Button
                    variant='outlined'
                    size='small'
                    color='default'
                    onClick={handleImportanceClose}
                    startIcon={<CloseIcon />}
                    className={classes.buttonWithRightMargin}
                  >
                    {t('informationCard:cancel')}
                  </Button>
                  <Button
                    variant='outlined'
                    size='small'
                    color='primary'
                    onClick={handleImportanceSave}
                    startIcon={<SaveIcon />}
                    className={classes.buttonWithRightMargin}
                  >
                    {t('informationCard:save')}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.lineWrapper}>
              <div className={classes.editValueColumn}>
                <ListItemText
                  disableTypography={true}
                  primary={
                    <Typography variant='h6' gutterBottom>
                      {t('informationCard:cowImportanceLabel')}
                    </Typography>
                  }
                  secondary={
                    <div>
                      <Typography variant='body2'>{getCowImportance(cowImportance)}</Typography>
                    </div>
                  }
                />
              </div>
              {cowImportanceIsUpdating && spinner}
              <Tooltip arrow={true} title={oldSample ? t('informationCard:tooOldToEdit') : ''}>
                <span>
                  {cowImportance && cowImportance !== 0 ? (
                    <Button
                      variant='outlined'
                      color='default'
                      size='small'
                      disabled={oldSample}
                      className={classes.button}
                      startIcon={<ImportExportIcon />}
                      onClick={() => setEditCowImportance(true)}
                    >
                      {t('informationCard:edit')}
                    </Button>
                  ) : (
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      disabled={oldSample}
                      className={classes.button}
                      startIcon={<PriorityHighIcon />}
                      onClick={() => setEditCowImportance(true)}
                    >
                      {t('informationCard:specify')}
                    </Button>
                  )}
                </span>
              </Tooltip>
            </div>
          )}
        </ListItem>
        {sample.sampleReason === enums.reason.clinical && (
          <div>
            <Divider component='li' />
            <ListItem disableGutters={true}>
              {editSymptom ? (
                <div className={classes.fullWidth}>
                  <div className={classes.editValueColumn}>
                    <ListItemText
                      disableTypography={true}
                      primary={
                        <Typography variant='h6' gutterBottom>
                          {t('informationCard:symptom')}
                          <HelpIcon
                            fontSize='small'
                            className={classes.helpIcon}
                            onClick={() => setSymptomInfo(true)}
                          />
                        </Typography>
                      }
                      secondary={
                        <div>
                          <Typography variant='body2'>
                            {t('informationCard:editSymptomSubtitle')}
                          </Typography>
                        </div>
                      }
                    />
                  </div>
                  <div className={classes.lineWrapper}>
                    {symptomSelector}
                    <div className={classes.buttonWrapper}>
                      <Button
                        variant='outlined'
                        size='small'
                        color='default'
                        onClick={handleSymptomClose}
                        startIcon={<CloseIcon />}
                        className={classes.buttonWithRightMargin}
                      >
                        {t('informationCard:cancel')}
                      </Button>
                      <Button
                        variant='outlined'
                        size='small'
                        color='primary'
                        onClick={handleSymptomSave}
                        startIcon={<SaveIcon />}
                        className={classes.buttonWithRightMargin}
                      >
                        {t('informationCard:save')}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.lineWrapper}>
                  <div className={classes.editValueColumn}>
                    <ListItemText
                      disableTypography={true}
                      primary={
                        <Typography variant='h6' gutterBottom>
                          {t('informationCard:symptom')}
                        </Typography>
                      }
                      secondary={
                        <div>
                          <Typography variant='body2'>{getMastisisSymptom(symptom)}</Typography>
                        </div>
                      }
                    />
                  </div>
                  {symptomIsUpdating && spinner}
                  <Tooltip arrow={true} title={oldSample ? t('informationCard:tooOldToEdit') : ''}>
                    <span>
                      <Button
                        variant='outlined'
                        color='default'
                        size='small'
                        disabled={oldSample}
                        className={classes.button}
                        startIcon={<EditIcon />}
                        onClick={() => setEditSymptom(true)}
                      >
                        {t('informationCard:edit')}
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              )}
            </ListItem>
          </div>
        )}

        {!isMobile && getSampleResult(sample.earlyResult, 'earlyResult')}
        {!isMobile && shouldPreliminaryResultBeRejected(sample)
          ? getSampleResult('preliminaryResultMissing', 'testResultDay1')
          : getSampleResult(sample.preliminaryResult, 'testResultDay1')}

        {!isMobile && getSampleResult(sample.finalResult, 'testResult')}
      </List>

      <Dialog open={symptomInfo} onClose={doCloseInfo} maxWidth='sm'>
        <DialogContent>
          <div className={classes.fullWidth}>
            <Typography variant='h4' className={classes.marginBottom}>
              {t('informationCard:symptom')}
            </Typography>
            <Typography variant='body2' className={classes.infoDialogSubtitle}>
              {t('informationCard:milkChanges')}
            </Typography>
            <Typography variant='body2'>{t('informationCard:milkChangesDesc')}</Typography>
            <Typography variant='body2' className={classes.infoDialogSubtitle}>
              {t('informationCard:swollenUdder')}
            </Typography>
            <Typography variant='body2'>{t('informationCard:swollenUdderDesc')}</Typography>
            <Typography variant='body2' className={classes.infoDialogSubtitle}>
              {t('informationCard:affectedGeneralCondition')}
            </Typography>
            <Typography variant='body2'>{t('informationCard:affectedDesc')}</Typography>
            <Typography variant='body2' className={classes.infoDialogSubtitle}>
              {t('informationCard:stronglyAffectedGeneralCondition')}
            </Typography>
            <Typography variant='body2' className={classes.marginBottom}>
              {t('informationCard:stronglyAffectedDesc')}
            </Typography>
          </div>
        </DialogContent>
        <Button color='primary' onClick={doCloseInfo}>
          {t('informationCard:close')}
        </Button>
      </Dialog>

      <Dialog open={cowImportanceInfo} onClose={doCloseInfo} maxWidth='sm'>
        <DialogContent>
          <div className={classes.fullWidth}>
            <Typography variant='h4' className={classes.marginBottom}>
              {t('informationCard:cowImportanceLabel')}
            </Typography>
            <Typography variant='body2' className={classes.infoDialogSubtitle}>
              {t('informationCard:low')}
            </Typography>
            <Typography variant='body2'>{t('informationCard:lowDesc')}</Typography>
            <Typography variant='body2' className={classes.infoDialogSubtitle}>
              {t('informationCard:medium')}
            </Typography>
            <Typography variant='body2'>{t('informationCard:mediumDesc')}</Typography>
            <Typography variant='body2' className={classes.infoDialogSubtitle}>
              {t('informationCard:high')}
            </Typography>
            <Typography variant='body2' className={classes.marginBottom}>
              {t('informationCard:highDesc')}
            </Typography>
          </div>
        </DialogContent>
        <Button color='primary' onClick={doCloseInfo}>
          {t('informationCard:close')}
        </Button>
      </Dialog>
    </Card>
  );
}

InformationCard.propTypes = {
  sample: PropTypes.object.isRequired,
};
