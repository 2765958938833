const initialState = {
  availableSites: [],
  selectedSiteId: '',
};

function siteReducer(state = initialState, action) {
  switch (action.type) {
    case 'SELECT_SITE':
      return {
        ...state,
        selectedSiteId: action.selectedSiteId,
      };
    case 'SET_AVAILABLE_SITES':
      return {
        ...state,
        availableSites: action.availableSites,
      };
    default:
      return state;
  }
}

export default siteReducer;
