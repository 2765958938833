const initialState = {
  selectedView: null,
};

function viewReducer(state = initialState, action) {
  switch (action.type) {
    case 'SELECT_VIEW':
      return {
        ...state,
        selectedView: action.selectedView,
      };
    default:
      return state;
  }
}

export default viewReducer;
