import axios from 'axios';
import i18next from 'i18next';
import { enums } from '../Utility/Enumerations/index.js';
import { UserManager } from '../Utility/UserManager/index.js';

function encodeQueryParameters(queryParams) {
  return Object.keys(queryParams)
    .map((key) => {
      if (Array.isArray(queryParams[key])) {
        return queryParams[key].map((value) => `${key}=${encodeURIComponent(value)}`).join('&');
      }

      return `${key}=${encodeURIComponent(queryParams[key])}`;
    })
    .join('&');
}

// Fetches data from core api Database

class DataService {
  // Api url for the database
  static apiUrl = process.env.REACT_APP_API_URL;

  static fetchDataById(endpoint, id = '', queryParams = null) {
    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        authorization: 'Bearer ' + localStorage.getItem('token'),
        'Accept-Language': i18next.language,
        SiteId: localStorage.getItem('siteid'),
      },
    };

    const queryParamString = queryParams !== null ? encodeQueryParameters(queryParams) : null;

    return axios
      .get(
        `${this.apiUrl}/${endpoint}/${id}${queryParams !== null ? '?' + queryParamString : ''}`,
        config,
      )
      .then((response) => response.data)
      .catch((e) => {
        return e;
      });
  }

  static fetchData(endpoint, queryParams = null) {
    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        authorization: 'Bearer ' + localStorage.getItem('token'),
        'Accept-Language': i18next.language,
      },
    };

    const queryParamString = queryParams !== null ? encodeQueryParameters(queryParams) : null;

    return axios
      .get(
        `${this.apiUrl}/${endpoint}${queryParams !== null ? '?' + queryParamString : ''}`,
        config,
      )
      .then((response) => response.data)
      .catch((e) => {
        return e;
      });
  }

  static fetchFileById(endpoint, id = '', queryParams = null) {
    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        authorization: 'Bearer ' + localStorage.getItem('token'),
        'Accept-Language': i18next.language,
        SiteId: localStorage.getItem('siteid'),
      },
      responseType: 'blob',
    };

    const queryParamString = queryParams !== null ? encodeQueryParameters(queryParams) : null;

    return axios
      .get(
        `${this.apiUrl}/${endpoint}/${id}${queryParams !== null ? '?' + queryParamString : ''}`,
        config,
      )
      .then((response) => {
        return response;
      })
      .catch((e) => {
        return e;
      });
  }

  // Make deletes in the database
  static axiosDelete(endpoint, id = '', queryParams = null) {
    const config = {
      headers: {
        'content-type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
        'Accept-Language': i18next.language,
        SiteId: localStorage.getItem('siteid'),
      },
      data: queryParams,
    };

    const queryParamString = queryParams !== null ? encodeQueryParameters(queryParams) : null;
    const URL = `${this.apiUrl}/${endpoint}/${id}${
      queryParams !== null ? '?' + queryParamString : ''
    }`;

    return axios
      .delete(URL, config)
      .then((response) => response.data)
      .catch((e) => {
        return e;
      });
  }

  // Save object to database
  static postObject(endpoint, object) {
    const config = {
      headers: {
        'content-type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
        'Accept-Language': i18next.language,
        SiteId: localStorage.getItem('siteid'),
      },
    };

    object.createdby = localStorage.getItem('userid');
    object.createdat = new Date();

    return axios
      .post(`${this.apiUrl}/${endpoint}/`, JSON.stringify(object), config)
      .then((response) => response.data)
      .catch((e) => {
        return e;
      });
  }

  // Make changes to item in database
  static putObject(endpoint, object, id, queryParams = '') {
    const config = {
      headers: {
        'content-type': 'application/json',
        authorization: 'Bearer ' + localStorage.getItem('token'),
        'Accept-Language': i18next.language,
        SiteId: localStorage.getItem('siteid'),
      },
    };

    object.modifiedat = new Date();
    object.modifiedby = localStorage.getItem('userid');

    const jsonObj = JSON.stringify(object);

    const queryParamString = queryParams !== null ? encodeQueryParameters(queryParams) : null;

    return axios
      .put(
        `${this.apiUrl}/${endpoint}/${id}${queryParams !== null ? '?' + queryParamString : ''}`,
        jsonObj,
        config,
      )
      .then((response) => response.data)
      .catch((e) => {
        return e;
      });
  }

  /**
   * Checks if token is valid, returns true if it is, false otherwise.
   * @param {boolean} refresh Whether to also refresh the token if it is still valid (default false)
   */
  static async checkToken(refresh = false) {
    const config = {
      headers: {
        'content-type': 'application/json',
        authorization: 'Bearer ' + UserManager.getIdToken(),
        'Accept-Language': i18next.language,
        SiteId: localStorage.getItem('siteid'),
      },
    };

    try {
      return await axios
        .post(`${this.apiUrl}/Account/TokenLogin?systemId=${enums.System.Bacticam}`, null, config)
        .then((response) => {
          if (refresh) {
            UserManager.setUser(response.data);
          }
          return true;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}

export default DataService;
