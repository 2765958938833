import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { yyyymmdd } from '../../../../../Services/Utility/DateUtils';
import { getUdderPart, getSmallUdderIcon } from '../../../../../Services/Utility/Udder';
import { getSampleInformation } from '../../../../../Services/Utility/SampleInformation';
import ActiveTag from '../StatusProps/ActiveTag';
import { openModal } from '../../../../../actions/modalActions';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  expanPanelStyle: {
    width: '100%',
  },
  firstPanelStyle: {
    '&.Mui-expanded': {
      borderLeft: '0.313rem solid #40B581',
    },
  },
  mobileFirstPanelStyle: {
    padding: '0 0.5rem 0 0.5rem',
  },
  secondPanelStyle: {
    backgroundColor: '#EEEEEE',
    borderLeft: '0.313rem solid #B1DAC7',
  },
  mobileSecondPanelStyle: {
    padding: '0 0.5rem 0 0.5rem',
  },
  thirdPanelDetailStyle: {
    backgroundColor: '#F4F4F4',
    borderLeft: '0.313rem solid #E2F1EA',
    padding: 0,
  },
  firstPanelGrid: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 1fr 1fr',
    '&>div': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '&>div:first-child': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  mobileFirstPanelGrid: {
    '&>div': {
      display: 'flex',
      justifyContent: 'center',
    },
    '&>div:last-child': {
      justifyContent: 'space-evenly',
    },
  },
  secondPanelGrid: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'center',
    '&>div': {
      display: 'flex',
    },
    '&>div:last-child': {
      justifyContent: 'flex-end',
      minWidth: '7rem',
    },
  },
  mobileSecondPanelGrid: {
    '&>div:last-child': {
      textAlign: 'end',
    },
    '&>div:nth-child(2n)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  thirdPanelGrid: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    '&>div': {
      backgroundColor: '#F9F9F9',
      borderTop: '1px solid #E5E5E5',
      borderBottom: '1px solid #E5E5E5',
      borderRight: '1px solid #E5E5E5',
      margin: '-1px 0 0 0',
    },
    '&>div:nth-child(2n)': {
      borderRight: 'none',
    },
  },
  mobileThirdPanelGrid: {
    gridTemplateColumns: '1fr',
  },
  detailSampleCardStyle: {
    padding: '1.25rem 1.5rem 1.25rem 1.118rem',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    '&>div:last-child': {
      display: 'flex',
      justifyContent: 'flex-end',
      minWidth: 'max-content',
    },
    '&>div:first-child': {
      display: 'flex',
      alignItems: 'center',
    },
    alignItems: 'center',
  },
  mobileDetailSampleCardStyle: {
    padding: '1.25rem 0.5rem 1.25rem 0.5rem',
    gridTemplateColumns: '1fr 1fr 1fr',
    '&>div:first-child': {
      display: 'flex',
      alignItems: 'center',
    },
    '&>div:nth-child(2n)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  noExpansionGap: {
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  greenArrow: {
    color: theme.palette.primary.main,
  },
  showMoreArrow: {
    fontSize: '1rem',
    paddingLeft: '0.3rem',
  },
  seeNoOfSamples: {
    color: theme.palette.primary.main,

    '@media (max-width: 1160px)': {
      fontWeight: 700,
    },
  },
  udderInfoText: {
    margin: '0 1rem',
    minWidth: 'fit-content',
  },
  udderIcon: {
    width: '1.6rem',
    height: '1.6rem',
  },
  udderInfoWrapper: {
    alignItems: 'center',
    minWidth: 'fit-content',
  },
  udderInfo: {
    display: 'flex',
    minWidth: '9.5rem',
  },
  mobileUdderInfo: {
    display: 'flex',
  },
  greenCircle: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '2rem',
    minWidth: '0.8rem',
    minHeight: '0.8rem',
    marginLeft: '0.5rem',
  },
  greenCircleLeft: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '2rem',
    width: '0.8rem',
    height: '0.8rem',
    marginRight: '0.5rem',
  },
  truncateText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  thirdPanelSampleInfo: {
    flex: '1 1 100%',
    minWidth: 0,
  },
  thirdPanelDate: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
  },
}));

// First/top level panel
export function FirstPanel(props) {
  const { cow, samples, latestCreatedAt, numberOfSamples } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const [hasActiveSamples, setHasActiveSamples] = useState(false);
  const [secondPanels, setSecondPanels] = useState([]);

  const handleChildHasActiveSample = useCallback(() => {
    setHasActiveSamples(true);
  }, []);

  /**
   * Group samples by udderid and render one SecondPanel for each unique udder for this cow
   */
  useEffect(() => {
    const newPanels = [];
    if (samples) {
      samples.forEach((obj) => {
        newPanels.push(
          <SecondPanel
            key={obj.udderId}
            udder={obj.udderId}
            samples={obj.samples}
            latestCreatedAt={obj.latestCreatedAt}
            onHasActiveSample={handleChildHasActiveSample}
          />,
        );
      });
    }
    setSecondPanels(newPanels);
  }, [samples, handleChildHasActiveSample]);

  return (
    <Accordion
      className={classes.expanPanelStyle}
      classes={{ root: classes.noExpansionGap }}
      elevation={0}
    >
      <AccordionSummary
        classes={{
          root: isMobile
            ? `${classes.firstPanelStyle} ${classes.mobileFirstPanelStyle}`
            : classes.firstPanelStyle,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <div
          className={
            isMobile
              ? `${classes.firstPanelGrid} ${classes.mobileFirstPanelGrid}`
              : classes.firstPanelGrid
          }
        >
          <div>
            {isMobile && hasActiveSamples ? <div className={classes.greenCircleLeft} /> : <></>}
            <Typography variant='h5'>{cow.number}</Typography>
          </div>
          <div>
            <Typography>{yyyymmdd(new Date(latestCreatedAt))}</Typography>
          </div>
          <div>
            <Typography>{numberOfSamples}</Typography>
            {!isMobile && hasActiveSamples ? <ActiveTag /> : <></>}
          </div>
        </div>
      </AccordionSummary>
      {secondPanels.length > 0 ? secondPanels : <></>}
    </Accordion>
  );
}

FirstPanel.propTypes = {
  cow: PropTypes.object.isRequired,
  samples: PropTypes.array.isRequired,
  latestCreatedAt: PropTypes.string.isRequired,
  numberOfSamples: PropTypes.number.isRequired,
};

// Second level panel (inside FirstPanel)
export function SecondPanel(props) {
  const { udder, samples, latestCreatedAt, onHasActiveSample } = props;
  const classes = useStyles();
  const { t } = useTranslation(['samplesFarmer']);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const [hasActiveSample, setHasActiveSample] = useState(false);

  const handleChildHasActiveSample = useCallback(() => {
    setHasActiveSample(true);
    onHasActiveSample();
  }, [onHasActiveSample]);

  function getNumberOfSamplesLabel(noOfSamples) {
    if (noOfSamples > 1) {
      return t('tableRow:seeNoOfSamples', { totalNoOfSamples: noOfSamples });
    } else if (noOfSamples === 1) {
      return t('tableRow:seeOneSample');
    }
  }

  return (
    <Accordion
      className={classes.expanPanelStyle}
      classes={{ root: classes.noExpansionGap }}
      elevation={0}
    >
      <AccordionSummary
        classes={{
          root: isMobile
            ? `${classes.secondPanelStyle} ${classes.mobileSecondPanelStyle}`
            : classes.secondPanelStyle,
        }}
        expandIcon={<ExpandMoreIcon className={classes.greenArrow} />}
      >
        <div
          className={
            isMobile
              ? `${classes.secondPanelGrid} ${classes.mobileSecondPanelGrid}`
              : classes.secondPanelGrid
          }
        >
          <div className={classes.udderInfoWrapper}>
            <div className={isMobile ? classes.mobileUdderInfo : classes.udderInfo}>
              <img src={getSmallUdderIcon(udder)} alt='udderIcon' className={classes.udderIcon} />
              {isMobile ? (
                <></>
              ) : (
                <Typography variant='h5' className={classes.udderInfoText}>
                  {getUdderPart(
                    parseInt(udder),
                    'samplesFarmer:tableRow:udderPartFrontLeft',
                    'samplesFarmer:tableRow:udderPartFrontRight',
                    'samplesFarmer:tableRow:udderPartBackLeft',
                    'samplesFarmer:tableRow:udderPartBackRight',
                    'samplesFarmer:tableRow:udderPartUnknown',
                  )}
                </Typography>
              )}
            </div>
            {!hasActiveSample ? (
              <></>
            ) : isMobile ? (
              <div className={classes.greenCircle} />
            ) : (
              <ActiveTag />
            )}
          </div>
          <div>
            <Typography>{yyyymmdd(new Date(latestCreatedAt))}</Typography>
          </div>
          <div>
            <Typography variant={isMobile ? 'body2' : 'h5'} className={classes.seeNoOfSamples}>
              {getNumberOfSamplesLabel(samples.length)}
            </Typography>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.thirdPanelDetailStyle }}>
        <ThirdPanel samples={samples} onHasActiveSample={handleChildHasActiveSample} />
      </AccordionDetails>
    </Accordion>
  );
}

SecondPanel.propTypes = {
  udder: PropTypes.number.isRequired,
  samples: PropTypes.array.isRequired,
  latestCreatedAt: PropTypes.string.isRequired,
  onHasActiveSample: PropTypes.func.isRequired,
};

// Third level panel, inside SecondPanel
export function ThirdPanel(props) {
  const { samples, onHasActiveSample } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { t } = useTranslation(['samplesFarmer']);
  const dispatch = useDispatch();

  /**
   * Checks if a sample is active. If it is, calls parent informing of this fact
   * @param {object} sample The sample to check if it is active
   */
  function isActive(sample) {
    const sampleIsActive = sample.activeSample;

    if (sampleIsActive) {
      onHasActiveSample();
    }

    return sampleIsActive;
  }

  function displaySampleInformation(sample) {
    const sampleInfo = getSampleInformation(sample);

    if (sampleInfo === 'somethingWentWrong') {
      return '';
    }

    return sampleInfo;
  }

  return (
    <div
      className={
        isMobile
          ? `${classes.thirdPanelGrid} ${classes.mobileThirdPanelGrid}`
          : classes.thirdPanelGrid
      }
    >
      {samples &&
        samples.map((sample) => {
          return (
            <div
              key={sample.sampleId}
              className={
                isMobile
                  ? `${classes.detailSampleCardStyle} ${classes.mobileDetailSampleCardStyle}`
                  : classes.detailSampleCardStyle
              }
            >
              <div className={classes.thirdPanelDate}>
                <Typography>{yyyymmdd(new Date(sample.createdAt))}</Typography>
                {isActive(sample) ? <div className={classes.greenCircle} /> : <></>}
              </div>
              <div className={classes.thirdPanelSampleInfo}>
                <Typography className={classes.truncateText}>
                  {displaySampleInformation(sample)}
                </Typography>
              </div>
              <div>
                <Link to={`/samples/${sample.sampleId}`}>
                  <Button
                    classes={{ text: classes.showMoreButton, outlined: classes.showMoreButton }}
                    variant='outlined'
                    size='small'
                    color='primary'
                    onClick={() => {
                      dispatch(openModal(sample, 'farmer'));
                    }}
                    id='show-sample-info'
                  >
                    {t('tableRow:sampleInfo')}
                    <ArrowForwardIosIcon classes={{ root: classes.showMoreArrow }} />
                  </Button>
                </Link>
              </div>
            </div>
          );
        })}
    </div>
  );
}

ThirdPanel.propTypes = {
  samples: PropTypes.array.isRequired,
  onHasActiveSample: PropTypes.func.isRequired,
};
