import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  Typography,
  Button,
  Paper,
  Fade,
  CircularProgress,
  useMediaQuery,
  IconButton,
  Card,
  Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { FirstPanel } from './MilkSampleTable';
import { ReactComponent as MilkSampleIcon } from '../../../../icons/milksampleicon.svg';
import FilterButtons from './FilterButtons';
import SearchSamples from './SearchSamples';
import CowCard from './CowCard';
import { farmerStatusFilters } from '../../../../Services/Utility/SampleInformation';
import { useSelector } from 'react-redux';
import FilterListIcon from '@material-ui/icons/FilterList';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import VerifySampleCard from './VerifySampleCard';
import NoActiveSamples from './NoActiveSamples';
import ExportCowsButton from '../../../ExportCows/ExportCowsButton';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '70vw',
    height: '60vh',
  },
  mobileWrapper: {
    width: '95vw',
    height: '60vh',
    marginTop: '1rem',
  },
  headerPanel: {
    width: '100%',
    padding: '1.25rem 3.8rem 1.25rem 1.5rem',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  mobileHeaderPanel: {
    padding: '1.25rem 0',
    justifyItems: 'center',
  },
  paginationWrapper: {
    margin: '1rem 0',
    display: 'flex',
    width: '15rem',
    justifyContent: 'space-around',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  sampleIconStyle: {
    height: theme.spacing(2),
    fontSize: '2.1875rem',
    marginRight: theme.spacing(0.75),
  },
  mobileSampleIconStyle: {
    height: theme.spacing(1.5),
    marginRight: '0.5rem',
  },
  actionPanel: {
    minHeight: '5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mobileActionPanel: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  switcherStyle: {
    minWidth: '14rem',
  },
  displaySamples: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&>div:last-child': {
      marginBottom: '4rem',
    },
  },
  buttonStyle: {
    padding: theme.spacing(0.5, 2),
    borderRadius: 0,
    width: '7rem',
    color: 'white',
  },
  mobileButtonStyle: {
    padding: '0.3rem 2rem',
    borderRadius: 0,
    width: '5rem',
    height: '2rem',
    color: 'white',
  },
  leftButtonStyle: {
    borderTopLeftRadius: '1rem',
    borderBottomLeftRadius: '1rem',
  },
  rightButtonStyle: {
    borderTopRightRadius: '1rem',
    borderBottomRightRadius: '1rem',
  },
  sortIcon: {
    transform: 'rotate(0)',
  },
  sortIconAsc: {
    transform: 'rotate(-180deg)',
  },
  paginationItem: {
    outline: 'none !important',
  },
  headerText: {
    cursor: 'pointer',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
  },
  currentPage: {
    display: 'flex',
    alignItems: 'center',
  },
  noSamplesOnThisPage: {
    padding: '1rem',
  },
  filterHeader: {
    marginRight: '0.8rem',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '&>button+button': {
      marginLeft: '0.5rem',
    },
  },
  mobileButtonGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&>button+button': {
      marginTop: '0.5rem',
    },
  },
  filterButtonMobile: {
    backgroundColor: '#E8E8E8',
    padding: '0.2rem 0.8rem',
  },
  filterCard: {
    width: '100%',
    margin: '1rem 0',
    padding: '1rem',
    '&>p': {
      marginBottom: '0.7rem',
    },
  },
  verifyHeader: {
    display: 'flex',
    color: theme.palette.secondary.main,
    alignItems: 'center',
    '&>svg': {
      marginRight: '0.5rem',
    },
  },
  verifyWrapper: {
    marginBottom: '2.5rem',
    '&>div+div': {
      marginTop: theme.spacing(1.75),
    },
  },
  verifyCard: {
    border: `2px solid ${theme.palette.secondary.main}`,
    '&>div:first-child': {
      padding: '1.25rem 1.5rem',
    },
  },
  mobileVerifyCard: {
    flexWrap: 'wrap',
    border: `1px solid ${theme.palette.secondary.main}`,
    '&>div:first-child': {
      padding: '1rem',
    },
  },
  green: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  gray: {
    background: theme.palette.lightGrays.gray40,
    '&:hover': {
      background: theme.palette.lightGrays.gray50,
    },
  },
  headerSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function FarmerSampleView(props) {
  const {
    activeSamples,
    loadingActiveSamples,
    samplesToVerify,
    samples,
    loadingSamples,
    onStatusFilterUpdated,
    onOrderOptionUpdated,
    onSearchTextUpdated,
    onPageUpdated,
    cowNumber,
    noActiveSamples,
  } = props;
  const { t } = useTranslation(['samplesFarmer']);
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const isSmallMobile = useMediaQuery('(max-width:360px)');
  const [displayActiveSamples, setDisplayActiveSamples] = useState(true);
  const [displayedPanels, setDisplayedPanels] = useState([]);
  const [sortIdentifierAsc, setSortIdentifierAsc] = useState(false);
  const [sortDateAsc, setSortDateAsc] = useState(false);
  const [sortCountAsc, setSortCountAsc] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [isFilterListOpen, setIsFilterListOpen] = useState(false);
  const selectedSiteId = useSelector((state) => state.siteReducer.selectedSiteId);

  const onFilterButtonClicked = useCallback((isOpen) => {
    setIsFilterListOpen(isOpen);
  }, []);

  /**
   * Generate panels for 'All samples'
   */
  useEffect(() => {
    const panels = samples.map((obj) => {
      return (
        <FirstPanel
          key={obj.subject.subjectId}
          cow={obj.subject}
          samples={obj.samplesPerUdderPart}
          latestCreatedAt={obj.latestCreatedAt}
          numberOfSamples={obj.numberOfSamples}
        />
      );
    });
    setDisplayedPanels(panels);
  }, [samples]);

  useEffect(() => {
    onPageUpdated(activePage);
  }, [activePage, onPageUpdated]);

  /**
   * Always start on page 1 when switching between All/Active samples, changing site,
   * search filter is updated or when selecting a sorting option.
   */
  useEffect(() => {
    setActivePage(1);
  }, [
    displayActiveSamples,
    selectedSiteId,
    cowNumber,
    sortDateAsc,
    sortCountAsc,
    sortIdentifierAsc,
  ]);

  function getCowCards() {
    const cards = activeSamples.map((obj) => {
      return (
        <CowCard key={obj.subject.subjectId} cow={obj.subject.number} activeSamples={obj.samples} />
      );
    });

    return cards;
  }

  function VerificationSection() {
    if (samplesToVerify.length > 0) {
      return (
        <div className={classes.verifyWrapper}>
          <div className={classes.verifyHeader}>
            <ErrorOutlineIcon fontSize={isMobile ? 'default' : 'large'} />
            <Typography variant={isMobile ? 'h4' : 'h2'}>{t('verifySampleCard:header')}</Typography>
          </div>
          <Paper className={isMobile ? classes.mobileVerifyCard : classes.verifyCard} elevation={0}>
            <div>
              <Typography variant='h5'>{t('verifySampleCard:sendToLab')}</Typography>
            </div>
            <Divider />
            {samplesToVerify.map((sample) => (
              <React.Fragment key={sample.sampleId}>
                <VerifySampleCard sample={sample} />
                <Divider />
              </React.Fragment>
            ))}
          </Paper>
        </div>
      );
    } else {
      return <></>;
    }
  }

  const viewSwitcher = (
    <div className={isMobile ? classes.mobileSwitcherStyle : classes.switcherStyle}>
      <Button
        classes={{
          root: isMobile
            ? `${classes.mobileButtonStyle} ${classes.leftButtonStyle} ${
                displayActiveSamples ? classes.green : classes.gray
              }`
            : `${classes.buttonStyle} ${classes.leftButtonStyle} ${
                displayActiveSamples ? classes.green : classes.gray
              }`,
        }}
        variant='contained'
        disableElevation={true}
        onClick={() => setDisplayActiveSamples(true)}
        style={{ outline: 'none' }}
      >
        {t('mainButton:activeSamples')}
      </Button>
      <Button
        classes={{
          root: isMobile
            ? `${classes.mobileButtonStyle} ${classes.rightButtonStyle} ${
                !displayActiveSamples ? classes.green : classes.gray
              }`
            : `${classes.buttonStyle} ${classes.rightButtonStyle} ${
                !displayActiveSamples ? classes.green : classes.gray
              }`,
          containedSecondary: classes.containedButtonStyle,
        }}
        variant='contained'
        style={{ outline: 'none' }}
        disableElevation={true}
        onClick={() => setDisplayActiveSamples(false)}
      >
        {t('mainButton:allSamples')}
      </Button>
    </div>
  );

  function getSpinner() {
    return (
      <div>
        <Fade
          in={loadingActiveSamples || loadingSamples}
          style={{
            transitionDelay: loadingActiveSamples || loadingSamples ? '300ms' : '0ms',
          }}
          unmountOnExit
        >
          <CircularProgress color='primary' size='5rem' />
        </Fade>
      </div>
    );
  }

  if (isMobile || isSmallMobile)
    return (
      <div className={classes.mobileWrapper}>
        <VerificationSection />
        <Typography variant='h4'>
          <MilkSampleIcon className={classes.mobileSampleIconStyle} />
          {t('mainHeader:milkSample')}
        </Typography>
        <div className={classes.mobileActionPanel}>
          {displayActiveSamples ? (
            <Button
              variant='outlined'
              classes={{ outlined: classes.filterButtonMobile }}
              startIcon={<FilterListIcon />}
              onClick={() => onFilterButtonClicked(!isFilterListOpen)}
            >
              {t('filter:filter')}
            </Button>
          ) : (
            <SearchSamples
              onSearchTextUpdated={onSearchTextUpdated}
              loadingSamples={loadingSamples}
            />
          )}
          {viewSwitcher}
        </div>

        <Card
          elevation={0}
          className={classes.filterCard}
          hidden={!isFilterListOpen || !displayActiveSamples}
        >
          <Typography variant='h5' gutterBottom>
            {t('filter:listIsFilteredOn')}
          </Typography>
          <div className={classes.mobileButtonGroup}>
            <FilterButtons
              statuses={farmerStatusFilters}
              onSelectedStatusesUpdated={onStatusFilterUpdated}
            />
          </div>
        </Card>

        {displayActiveSamples && (
          <div className={classes.displaySamples}>
            {loadingActiveSamples ? (
              getSpinner()
            ) : activeSamples.length <= 0 ? (
              <NoActiveSamples listIsFiltered={!noActiveSamples} />
            ) : (
              getCowCards()
            )}
          </div>
        )}

        {!displayActiveSamples && (
          <div className={classes.displaySamples}>
            {loadingSamples ? (
              getSpinner()
            ) : (
              <>
                <Paper
                  className={`${classes.headerPanel} ${classes.mobileHeaderPanel}`}
                  elevation={0}
                >
                  <Typography
                    className={classes.headerText}
                    variant='h6'
                    onClick={() => {
                      setSortIdentifierAsc((prev) => !prev);
                      onOrderOptionUpdated('cowNumber', sortIdentifierAsc);
                    }}
                  >
                    {t('tableHeader:cowNumber')}
                    <ExpandMoreIcon
                      className={sortIdentifierAsc ? classes.sortIconAsc : classes.sortIcon}
                    />
                  </Typography>
                  <Typography
                    className={classes.headerText}
                    variant='h6'
                    onClick={() => {
                      setSortDateAsc((prev) => !prev);
                      onOrderOptionUpdated('latestSample', sortDateAsc);
                    }}
                  >
                    {t('tableHeader:lastSample')}
                    <ExpandMoreIcon
                      className={sortDateAsc ? classes.sortIconAsc : classes.sortIcon}
                    />
                  </Typography>
                  <Typography
                    className={classes.headerText}
                    variant='h6'
                    onClick={() => {
                      setSortCountAsc((prev) => !prev);
                      onOrderOptionUpdated('numberOfSamples', sortCountAsc);
                    }}
                  >
                    {t('tableHeader:noOfSamples')}
                    <ExpandMoreIcon
                      className={sortCountAsc ? classes.sortIconAsc : classes.sortIcon}
                    />
                  </Typography>
                </Paper>
                {displayedPanels.length > 0 ? (
                  displayedPanels
                ) : (
                  <div className={classes.noSamplesOnThisPage}>
                    <Typography>{t('error:noSamplesOnThisPage')}</Typography>
                  </div>
                )}
              </>
            )}
            <div className={classes.paginationWrapper}>
              <IconButton
                disabled={activePage === 1}
                variant='contained'
                onClick={() => {
                  setActivePage((prevPage) => (prevPage === 1 ? 1 : prevPage - 1));
                }}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant='h5' className={classes.currentPage}>
                {activePage}
              </Typography>
              <IconButton
                disabled={samples.length < 10}
                variant='contained'
                onClick={() => setActivePage(activePage + 1)}
              >
                <NavigateNextIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    );
  else
    return (
      <div className={classes.wrapper}>
        <VerificationSection />
        <div className={classes.headerSection}>
          <Typography variant='h2' className={classes.header}>
            <MilkSampleIcon className={classes.sampleIconStyle} />
            {t('mainHeader:milkSample')}
          </Typography>
          <ExportCowsButton />
        </div>
        <div className={classes.actionPanel}>
          {displayActiveSamples && (
            <div className={classes.buttonGroup}>
              <Typography variant='h5' className={classes.filterHeader}>
                {t('filter:header')}
              </Typography>
              <FilterButtons
                statuses={farmerStatusFilters}
                onSelectedStatusesUpdated={onStatusFilterUpdated}
              />
            </div>
          )}
          {!displayActiveSamples && (
            <SearchSamples
              onSearchTextUpdated={onSearchTextUpdated}
              loadingSamples={loadingSamples}
            />
          )}
          {viewSwitcher}
        </div>

        {displayActiveSamples && (
          <div className={classes.displaySamples}>
            {loadingActiveSamples ? (
              getSpinner()
            ) : activeSamples.length <= 0 ? (
              <NoActiveSamples listIsFiltered={!noActiveSamples} />
            ) : (
              getCowCards()
            )}
          </div>
        )}

        {!displayActiveSamples && (
          <div className={classes.displaySamples}>
            {loadingSamples ? (
              getSpinner()
            ) : (
              <>
                <Paper className={classes.headerPanel} elevation={0}>
                  <Typography
                    className={classes.headerText}
                    variant='h5'
                    onClick={() => {
                      setSortIdentifierAsc((prev) => !prev);
                      onOrderOptionUpdated('cowNumber', sortIdentifierAsc);
                    }}
                  >
                    {t('tableHeader:cowNumber')}
                    <ExpandMoreIcon
                      className={sortIdentifierAsc ? classes.sortIconAsc : classes.sortIcon}
                    />
                  </Typography>
                  <Typography
                    className={classes.headerText}
                    variant='h5'
                    onClick={() => {
                      setSortDateAsc((prev) => !prev);
                      onOrderOptionUpdated('latestSample', sortDateAsc);
                    }}
                  >
                    {t('tableHeader:lastSample')}
                    <ExpandMoreIcon
                      className={sortDateAsc ? classes.sortIconAsc : classes.sortIcon}
                    />
                  </Typography>
                  <Typography
                    className={classes.headerText}
                    variant='h5'
                    onClick={() => {
                      setSortCountAsc((prev) => !prev);
                      onOrderOptionUpdated('numberOfSamples', sortCountAsc);
                    }}
                  >
                    {t('tableHeader:noOfSamples')}
                    <ExpandMoreIcon
                      className={sortCountAsc ? classes.sortIconAsc : classes.sortIcon}
                    />
                  </Typography>
                </Paper>
                {displayedPanels.length > 0 ? (
                  displayedPanels
                ) : (
                  <div className={classes.noSamplesOnThisPage}>
                    <Typography>{t('error:noSamplesOnThisPage')}</Typography>
                  </div>
                )}
              </>
            )}
            <div className={classes.paginationWrapper}>
              <IconButton
                disabled={activePage === 1}
                variant='contained'
                onClick={() => {
                  setActivePage((prevPage) => (prevPage === 1 ? 1 : prevPage - 1));
                }}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography variant='h5' className={classes.currentPage}>
                {activePage}
              </Typography>
              <IconButton
                disabled={samples.length < 10}
                variant='contained'
                onClick={() => setActivePage(activePage + 1)}
              >
                <NavigateNextIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    );
}

FarmerSampleView.propTypes = {
  activeSamples: PropTypes.array.isRequired,
  loadingActiveSamples: PropTypes.bool.isRequired,
  samples: PropTypes.array.isRequired,
  loadingSamples: PropTypes.bool.isRequired,
  onStatusFilterUpdated: PropTypes.func.isRequired,
  onOrderOptionUpdated: PropTypes.func.isRequired,
  onSearchTextUpdated: PropTypes.func.isRequired,
  onPageUpdated: PropTypes.func.isRequired,
  cowNumber: PropTypes.string.isRequired,
  samplesToVerify: PropTypes.array.isRequired,
  noActiveSamples: PropTypes.bool.isRequired,
};
