const initialState = {
  isOpen: false,
};

function exportCowsReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_EXPORT_DIALOG':
      return { ...state, isOpen: true };
    case 'CLOSE_EXPORT_DIALOG':
      return { ...state, isOpen: false };
    default:
      return state;
  }
}
export default exportCowsReducer;
