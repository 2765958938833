import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import { ReactComponent as MilkSampleIcon } from '../../../../../icons/milksampleicon.svg';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  noResultContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& p': {
      textAlign: 'center',
    },
  },
  grayCircle: {
    background: '#C6C6C6',
    width: '10rem',
    height: '10rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1.3rem',
  },
  milkSampleIcon: {
    height: '50%',
  },
  searchIcon: {
    fontSize: '6rem',
    height: '100%',
  },
  infoText: {
    color: theme.palette.darkGrays.gray80,
  },
  noResult: {
    fontWeight: 700,
    color: '#525355',
  },
}));

export default function NoActiveSamples(props) {
  const { listIsFiltered } = props;
  const classes = useStyles();
  const { t } = useTranslation(['samplesFarmer']);

  return (
    <div className={classes.noResultContainer}>
      <div className={classes.grayCircle}>
        {listIsFiltered ? (
          <SearchIcon classes={{ root: classes.searchIcon }} />
        ) : (
          <MilkSampleIcon className={classes.milkSampleIcon} data-cy='no-active-samples-icon' />
        )}
      </div>
      {listIsFiltered && (
        <Typography className={classes.noResult} gutterBottom>
          {t('noActiveSamples:noResults')}
        </Typography>
      )}
      <Typography>
        {listIsFiltered ? t('noActiveSamples:adjustFilter') : t('noActiveSamples:fillThisList')}
      </Typography>
    </div>
  );
}

NoActiveSamples.propTypes = {
  listIsFiltered: PropTypes.bool.isRequired,
};
