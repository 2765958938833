import React from 'react';
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  Tooltip,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  getSampleLocation,
  getSampleDate,
  getSampleReason,
} from '../../../../../Services/Utility/SampleInformation';
import UdderIcon from '../../../FarmerSampleContainer/FarmerSampleView/UdderIcon';
import LinkIcon from '@material-ui/icons/Link';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  sampleInfoWrapper: {
    padding: theme.spacing(1),
    border: '1px solid #979797',
    position: 'relative',
  },
  sampleInfoWrapperMobile: {
    marginTop: theme.spacing(1.3),
  },
  udderIconWrapper: {
    position: 'absolute',
    right: theme.spacing(1),
    width: theme.spacing(6.3),
  },
  udderIconWrapperWidthMobile: {
    width: theme.spacing(5.6),
  },
  udderIconDesktop: {
    margin: theme.spacing(0.5, 0, 0, 0.5),
  },
  leftContentMobileWidth: {
    width: 'calc(100% - 5.6rem)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tooltipLabel: {
    fontSize: theme.spacing(0.8),
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    '&>li': {
      padding: 0,
    },
  },
  banner: {
    backgroundColor: theme.palette.complementColors.brightBlue,
    color: '#FFFFFF',
    borderRadius: '4px',
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    '&>svg': {
      marginRight: theme.spacing(0.5),
      alignSelf: 'end',
    },
  },
  mobileBanner: {
    marginTop: theme.spacing(1.5),
  },
}));

export default function BactInformationCard(props) {
  const { t } = useTranslation(['samplesDetails']);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { sample, copySampleURL } = props;
  const classes = useStyles();
  const match = useRouteMatch();

  const ParticipantInValidationStudyBanner = () => {
    return (
      <div className={isMobile ? `${classes.banner} ${classes.mobileBanner}` : classes.banner}>
        <CheckCircleIcon />
        <Typography variant='body2'>{t('bactInformationCard:participantInStudy')}</Typography>
      </div>
    );
  };

  return (
    <>
      {sample.site.validationParticipant && <ParticipantInValidationStudyBanner />}
      <Card
        className={
          isMobile && !sample.site.validationParticipant
            ? `${classes.sampleInfoWrapper} ${classes.sampleInfoWrapperMobile}`
            : classes.sampleInfoWrapper
        }
      >
        {isMobile ? (
          <div className={classes.header}>
            <Typography variant='subtitle1'>{t('bactInformationCard:sampleInfo')}</Typography>
            {match.path === '/comments' && (
              <div style={{ marginTop: '-0.4rem' }}>
                <Tooltip title={t('copySampleLink:copyDirectLink')}>
                  <Button startIcon={<LinkIcon />} onClick={() => copySampleURL()}>
                    {t('copySampleLink:copy')}
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        <div
          className={
            isMobile
              ? `${classes.udderIconWrapperWidthMobile} ${classes.udderIconWrapper}`
              : classes.udderIconWrapper
          }
        >
          {isMobile && <UdderIcon sample={sample} />}
        </div>
        <List component='div' disablePadding={true} className={classes.list}>
          <ListItem
            disableGutters={true}
            className={isMobile ? classes.leftContentMobileWidth : ''}
          >
            <ListItemText
              primaryTypographyProps={{ variant: 'body1' }}
              secondaryTypographyProps={{ component: 'span', variant: 'body1' }}
              primary={`${t('bactInformationCard:cow', {
                cowNumber: sample.subject.number,
              })}, ${getSampleLocation(sample)}`}
              secondary={
                <div>
                  <div>
                    <Typography>{getSampleDate(sample)}</Typography>
                  </div>
                </div>
              }
            />
          </ListItem>
          <Divider
            className={isMobile ? classes.leftContentMobileWidth : classes.leftContentScreenWidth}
            component='li'
          />
          <ListItem
            className={isMobile ? classes.leftContentMobileWidth : classes.leftContentScreenWidth}
            disableGutters={true}
          >
            <ListItemText
              primaryTypographyProps={{ variant: 'body1' }}
              primary={getSampleReason(sample.sampleReason)}
            />
            {!isMobile && (
              <div className={classes.udderIconDesktop}>
                <UdderIcon sample={sample} size='small' />
              </div>
            )}
          </ListItem>
        </List>
      </Card>
    </>
  );
}

BactInformationCard.propTypes = {
  sample: PropTypes.object.isRequired,
  copySampleURL: PropTypes.func,
};
