import { OPEN_MODAL, CLOSE_MODAL } from '../actions/types';

/**
 * Redux action where Modal is opened, displaying the clicked sample in a detailed view.
 * @param {object} sample - Sample to be displayed in a detailed view
 * @param {string} view - To display Bacteriologist Modal or Farmer Modal
 * @param {Boolean} startButtonIsClicked - Param should be sent in as true if Bacteriologist Start-button is clicked
 * @param {Array} slideShowSamples - Array of samples to browse if Bacteriologist Start-button is clicked and there are samples to classify.
 */
export function openModal(sample, view, startButtonIsClicked, slideShowSamples) {
  return {
    type: OPEN_MODAL,
    sample,
    view,
    startButtonIsClicked,
    slideShowSamples,
  };
}

export function closeModal(sample, view, startButtonIsClicked, slideShowSamples) {
  return {
    type: CLOSE_MODAL,
    sample,
    view,
    startButtonIsClicked,
    slideShowSamples,
  };
}
