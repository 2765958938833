/**
 * Static class to check permissions for a signed in user by accessing the storage
 * where the user information is stored.
 */
export class PermissionCheck {
  static enums = {
    admin: '5c955008-9803-4fb9-a9ba-77dc2d830143',
    user: '25f95c51-833c-45cf-9def-9ef57c2bb745',
    canChat: '5762f6a3-5e57-4734-a872-6809f8061d63',
    canClassifyAsSeniorBacteriologist: '8684863c-3b82-491b-be8a-59a1a3104a29',
    canClassifyAsJuniorBacteriologist: 'e2dcf939-099e-4bb6-9da1-54aa5e3fd7d7',
    CanAssignActions: 'e718b17b-f393-499a-bc6e-6612b9b0f457',
    CanViewAllSites: '126cdca4-a9de-41bd-bb7f-6c745ae1887f',
    canViewEconomics: '0726ed72-9af1-4146-8ceb-933617e26a89',
    canFlagLab: 'ae5f818c-70f1-43d1-935e-6820b85e829a',
    canClassifyAsLaboratory: '4b2de362-d618-4148-ad22-c5d8404a7777',
  };

  /**
   * Gets list of usergroup objects from localstorage.
   * Returns false if no such entry, otherwise returns the entries as an array.
   */
  static getUserGroups() {
    const usergroups = localStorage.getItem('usergroups');
    if (null === usergroups || undefined === usergroups) {
      return false;
    }

    return JSON.parse(usergroups);
  }

  /**
   * Checks if ALL of the given permissions exists for the signed in user.
   * @returns {boolean} True if all of permissions exists, false otherwise.
   * @param {String} permissions The permissions to check for.
   */
  static hasAllOfPermissions(...permissions) {
    const usergroups = this.getUserGroups();
    if (!usergroups) {
      return false;
    }

    return usergroups.some((usergroup) => {
      return usergroup.usergrouproles.some((usergrouprole) => {
        return usergrouprole.role.rolepermissions.every((rolepermission) => {
          return permissions.includes(rolepermission.permission.permissionid);
        });
      });
    });
  }

  /**
   * Checks if ANY of the given permissions exists for the signed in user.
   * @returns {boolean} True if any of permissions exists, false otherwise.
   * @param {String} permissions The permissions to check for.
   */
  static hasAnyOfPermissions(...permissions) {
    const usergroups = this.getUserGroups();
    if (!usergroups) {
      return false;
    }

    return usergroups.some((usergroup) => {
      return usergroup.usergrouproles.some((usergrouprole) => {
        return usergrouprole.role.rolepermissions.some((rolepermission) => {
          return permissions.includes(rolepermission.permission.permissionid);
        });
      });
    });
  }

  /**
   * Checks if the permission Bacticam.Admin exists for any of the user groups
   * belonging to the signed in user.
   * Grants all other permissions.
   */
  static isAdmin() {
    return this.hasAnyOfPermissions(this.enums.admin);
  }

  /**
   * Checks if the permission Bacticam.User exists for any of the user groups
   * belonging to the signed in user.
   * Grants access to the system.
   */
  static isUser() {
    return this.hasAnyOfPermissions(this.enums.admin, this.enums.user);
  }

  /**
   * Checks if the permission Bacticam.CanChat exists for any of the user groups
   * belonging to the signed in user.
   * Grants access to sample chat.
   */
  static canChat() {
    return this.hasAnyOfPermissions(this.enums.admin, this.enums.canChat);
  }

  /**
   * Checks if the permission Bacticam.canClassifyAsSeniorBacteriologist or Bacticam.canClassifyAsJuniorBacteriologist
   * exists for any of the user groups belonging to the signed in user.
   * Grants access to classify view and classify dialog.
   */
  static canClassify() {
    return this.hasAnyOfPermissions(
      this.enums.admin,
      this.enums.canClassifyAsSeniorBacteriologist,
      this.enums.canClassifyAsJuniorBacteriologist,
    );
  }

  /**
   * Checks if the signed in user has the permission to classify as senior bacteriologist.
   */
  static canClassifyAsSeniorBacteriologist() {
    return this.hasAnyOfPermissions(this.enums.admin, this.enums.canClassifyAsSeniorBacteriologist);
  }

  /**
   * Checks if the signed in user has the permission to classify as junior bacteriologist.
   */
  static canClassifyAsJuniorBacteriologist() {
    return this.hasAnyOfPermissions(this.enums.canClassifyAsJuniorBacteriologist);
  }

  /**
   * Checks if the permission Bacticam.CanAssignActions exists for any of the user groups
   * belonging to the signed in user.
   * Grants access to classify view and assign action dialog.
   */
  static canAssignActions() {
    return this.hasAnyOfPermissions(this.enums.admin, this.enums.canAssignActions);
  }

  /**
   * Checks if the permission Bacticam.CanViewAllSites exists for any of the user groups
   * belonging to the signed in user.
   * Grants access to view data for all sites in the system.
   */
  static canViewAllSites() {
    return this.hasAnyOfPermissions(this.enums.admin, this.enums.canViewAllSites);
  }

  /**
   * Checks if the permission Bacticam.Economics exists for any of the user groups
   * belonging to the signed in user.
   * Grants access to economy view.
   */
  static canViewEconomics() {
    return this.hasAnyOfPermissions(this.enums.admin, this.enums.canViewEconomics);
  }

  /**
   * Checks if the permission Bacticam.canFlagLab exists for any of the user groups
   * belonging to the signed in user.
   * Allows the user to see and use the 'Send to lab for verification' functionality
   * in Bacteriologist detail view.
   */
  static canFlagLab() {
    return this.hasAnyOfPermissions(this.enums.admin, this.enums.canFlagLab);
  }

  /**
   * Checks if the permission Bacticam.canClassifyAsLaboratory exists for any of the user groups
   * belonging to the signed in user.
   * Allows the user to add classifications from laboratories using a specific dialog in the
   * Bacteriologist detail view.
   */
  static canClassifyAsLaboratory() {
    return this.hasAnyOfPermissions(this.enums.admin, this.enums.canClassifyAsLaboratory);
  }
}

export default PermissionCheck;
