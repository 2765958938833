/**
 * Function for changing the format on a Date
 * @return {string} The returning value is a string with the format YYYY-MM-DD
 */
export function yyyymmdd(date) {
  if (date) {
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    const yyyy = date.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return yyyy + '-' + mm + '-' + dd;
  } else return null;
}

/**
 * Function for changing the format on a Date
 * @return {string} The returning value is a string with the format YYYY-MM-DD - HH:MM
 */
export function yyyymmddhhmm(date) {
  let mm = date.getMonth() + 1;
  let dd = date.getDate();
  const yyyy = date.getFullYear();
  let hh = date.getHours();
  let min = date.getMinutes();

  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (min < 10) {
    min = '0' + min;
  }
  if (hh < 10) {
    hh = '0' + hh;
  }
  return yyyy + '-' + mm + '-' + dd + ', ' + hh + ':' + min;
}

/**
 * Function for changing the format on a Date
 * @return {string} The returning value is a string with the format YYYY-MM-DD_HH-MM-SS
 */
export function fileDateFormat(date) {
  let mm = date.getMonth() + 1;
  let dd = date.getDate();
  const yyyy = date.getFullYear();
  let hh = date.getHours();
  let min = date.getMinutes();
  let sec = date.getSeconds();

  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (hh < 10) {
    hh = '0' + hh;
  }
  if (min < 10) {
    min = '0' + min;
  }
  if (sec < 10) {
    sec = '0' + sec;
  }

  return `${yyyy}-${mm}-${dd}_${hh}-${min}-${sec}`;
}

/**
 * Function for changing the format on a Date
 * @return {string} The returning value is a string with the format HH:MM
 */
export function hhmm(date) {
  let hh = date.getHours();
  let min = date.getMinutes();

  if (min < 10) {
    min = '0' + min;
  }
  if (hh < 10) {
    hh = '0' + hh;
  }

  return hh + ':' + min;
}

/**
 * Returns the most recent (createdat) from an array of samples
 * @return {date} The returned value is a Date that can be formatted with any of the date format functions.
 */
export function getMostRecentDate(samples) {
  let sampleLastDate = null;

  if (samples) {
    sampleLastDate = new Date(
      Math.max.apply(
        null,
        samples.map(function (e) {
          return new Date(e.createdAt);
        }),
      ),
    );
  }
  return sampleLastDate;
}
