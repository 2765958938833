import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, Button, Typography, makeStyles } from '@material-ui/core';
import InformationCard from '../DetailCards/InformationCard';
import BacteriaCard from '../DetailCards/BacteriaCard';
import CommentCard from '../DetailCards/CommentCard';
import TimelineCard from '../DetailCards/TimelineCard';
import HistoryCard from '../DetailCards/HistoryCard';
import ImageCard from '../DetailCards/ImageCard';
import FarmerVerificationCard from '../DetailCards/FarmerVerificationCard';
import { getUdderPart } from '../../../../Services/Utility/Udder';
import { useSelector, useDispatch } from 'react-redux';
import cowPattern from '../../../../icons/cow-print-gray.png';
import PropTypes from 'prop-types';
import {
  hasFinalBacteriaResult,
  hasPreliminaryBacteriaResult,
} from '../../../../Services/Utility/SampleInformation';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { openSnackbar } from '../../../../actions/mainActions';
import { openModal } from '../../../../actions/modalActions';
import { enums } from '../../../../Services/Utility/Enumerations';
import { SamplesClient } from '../../../../Services/Utility/WebApiClient';

const useStyles = makeStyles({
  outerWrapper: {
    height: '100%',
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '[col-start] auto [col-start] 29%',
    justifyItems: 'stretch',
    height: '100%',
  },
  leftWrapper: {
    backgroundImage: `url(${cowPattern})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    gridColumnStart: 'col-start 1',
    boxShadow: '7px 4px 4px rgba(0, 0, 0, 0.25)',
    zIndex: 9000,
    padding: '1.9rem',
  },
  innerColumnContainer: {
    display: 'flex',
  },
  innerColumn: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    '&> div': {
      margin: '0.6rem',
    },
  },
  right: {
    width: '45%',
    float: 'right',
    '&> div:nth-of-type(1)': {
      position: 'relative',
      flexGrow: 1,
    },
  },
  left: {
    width: '55%',
    float: 'left',
  },
  rightWrapper: {
    gridColumnStart: 'col-start 2',
    backgroundColor: '#cecece',
    padding: '1rem',
    '&> div': {
      marginBottom: '1rem',
    },
  },
  center: {
    textAlign: 'center',
  },
  dialogContentWrapper: {
    width: '80vh',
    maxWidth: '850px',
    position: 'relative',
  },
  dialogDateWrapper: {
    position: 'absolute',
    top: 0,
    height: '2rem',
    width: 'auto',
    backgroundColor: '#ffffff',
    opacity: '0.6',
    padding: '0 1rem',
    borderRadius: '4px 0',
  },
  dialogDate: {
    opacity: 1,
    lineHeight: '2rem !important',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '48%',
  },
  imageDialogContainer: {
    marginTop: '3.5rem',
  },
  imageDialogContent: {
    padding: '0 !important',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default function FarmerDetails(props) {
  const { updateFarmerSample, isUpdatingFarmerVerificationCard } = props;
  const { t } = useTranslation(['samplesDetails']);
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();

  const [imageDialog, setImageDialog] = useState(false);
  const [openImage, setOpenImage] = useState('');
  const [openImageDate, setOpenImageDate] = useState('');

  const sample = useSelector((state) => state.modalReducer.sample);

  useEffect(() => {
    async function fetchSampleData(id) {
      const fetchSample = await SamplesClient.GetSample(id);
      if (fetchSample instanceof Error) {
        history.push('/samples');
        dispatch(openSnackbar('warning', 'fetchError'));
      } else if (fetchSample) {
        dispatch(openModal(fetchSample, 'farmer'));
      }
    }

    // If the opened sample and sampleID in the URL don't match, fetch and open the sample from the URL.
    if (match.params.sampleId !== undefined && sample.sampleId !== match.params?.sampleId) {
      fetchSampleData(match.params.sampleId);
    }
  });

  function doOpenImage(image, imageDate) {
    setImageDialog(true);
    setOpenImage(image);
    setOpenImageDate(imageDate);
  }

  function doCloseImage() {
    setImageDialog(false);
    setOpenImage('');
    setOpenImageDate('');
  }

  async function updateSampleOnAddComment() {
    await updateFarmerSample('commentCard', sample.sampleId);
  }

  async function updateSampleOnMarkAsManaged() {
    await updateFarmerSample('farmerVerificationCard', sample.sampleId);
  }

  return (
    <div className={classes.outerWrapper}>
      {sample && (
        <div className={classes.wrapper}>
          <div className={classes.leftWrapper}>
            <div className={classes.header}>
              <Typography variant='h3' gutterBottom>
                {sample.subject.number} -{' '}
                {getUdderPart(
                  sample.udderId,
                  'samplesDetails:farmerDetails:udderPartFrontLeft',
                  'samplesDetails:farmerDetails:udderPartFrontRight',
                  'samplesDetails:farmerDetails:udderPartBackLeft',
                  'samplesDetails:farmerDetails:udderPartBackRight',
                  'samplesDetails:farmerDetails:udderPartUnknown',
                )}
              </Typography>
            </div>
            <div className={classes.innerColumnContainer}>
              <div className={`${classes.innerColumn} ${classes.left}`}>
                {[enums.labFlag.hasBeenSent, enums.labFlag.sendLocked].includes(sample.labFlag) && (
                  <FarmerVerificationCard
                    updateSampleOnMarkAsManaged={updateSampleOnMarkAsManaged}
                    isUpdating={isUpdatingFarmerVerificationCard}
                  />
                )}
                <InformationCard sample={sample} />
                {(hasFinalBacteriaResult(sample) && (
                  <BacteriaCard bacteria={sample.finalResult.bacterias[0]} />
                )) ||
                  (hasPreliminaryBacteriaResult(sample) && (
                    <BacteriaCard bacteria={sample.preliminaryResult.bacterias[0]} />
                  ))}
              </div>
              <div className={`${classes.innerColumn} ${classes.right}`}>
                {/*  <NextStepCard /> 
                                When implementing this component, change back .right > div:nth-of-type(1) in details.css to .right > div:nth-of-type(2) to make commentCard grow
                           */}

                <CommentCard
                  assignmentId={sample.assignmentId}
                  updateSampleOnAddComment={updateSampleOnAddComment}
                />
              </div>
            </div>
          </div>
          <div className={classes.rightWrapper}>
            <TimelineCard sample={sample} />
            <HistoryCard subjectId={sample.subject.subjectId} />
            <ImageCard
              imagePairs={sample.sampleImagePairs}
              doOpenImage={doOpenImage}
              sample={sample}
            />
          </div>

          <Dialog
            open={imageDialog}
            onClose={doCloseImage}
            maxWidth='md'
            className={classes.imageDialogContainer}
          >
            <DialogContent className={classes.imageDialogContent}>
              <div className={classes.dialogContentWrapper}>
                <img src={openImage} alt='icon' width='100%' height='auto' />
                <div className={classes.dialogDateWrapper}>
                  <Typography variant='h4' className={classes.dialogDate}>
                    {openImageDate}
                  </Typography>
                </div>
              </div>
            </DialogContent>
            <Button color='primary' onClick={doCloseImage} size='large'>
              {t('farmerDetails:close')}
            </Button>
          </Dialog>
        </div>
      )}
    </div>
  );
}

FarmerDetails.propTypes = {
  updateFarmerSample: PropTypes.func,
  isUpdatingFarmerVerificationCard: PropTypes.bool,
};
