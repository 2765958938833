import { OPEN_LABFORM_DIALOG, CLOSE_LABFORM_DIALOG } from '../actions/types';

export function openLabFormDialog() {
  return {
    type: OPEN_LABFORM_DIALOG,
  };
}

export function closeLabFormDialog() {
  return {
    type: CLOSE_LABFORM_DIALOG,
  };
}
