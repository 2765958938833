import React, { useState, useEffect } from 'react';
import {
  Paper,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  DialogContent,
  DialogContentText,
  makeStyles,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import MaterialTable from '@material-table/core';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { openSnackbar } from '../../../../actions/mainActions';
import { useDispatch } from 'react-redux';
import {
  ActionsClient,
  BacteriaActionsClient,
  BacteriasClient,
  BacteriaGroupsClient,
} from '../../../../Services/Utility/WebApiClient';
import DataService from '../../../../Services/DataService';
import ReactSelect from 'react-select';

const useStyles = makeStyles({
  wrapper: {
    marginTop: '1rem',
  },
  contentWrapper: {
    overflowX: 'auto',
    position: 'relative',
  },
  formWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '1rem',
  },
  iconWrapper: {
    paddingBottom: 0,
    fontSize: 'large',
  },
  descriptionFontStyle: {
    fontSize: '13px',
  },
  descriptionWidthStyle: {
    width: '15rem',
  },
  loadingOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: 'rgb(255, 255, 255, 0.7)',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default function AddBacteria() {
  const { t } = useTranslation(['admin']);
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');

  const [bacteria, setBacteria] = useState(null); // list of bacterias
  const [bacteriaGroups, setBacteriaGroups] = useState([]); // list of bacteriaGroups
  const [bacteriaActions, setBacteriaActions] = useState(null); // actions applied to a specific bacteria
  const [actions, setActions] = useState(null); // list of actions
  const [theAction, setTheAction] = useState(null); // selected action from list in add-action-dialog
  const [theBacteria, setTheBacteria] = useState(null); // selected bacteria from when opening add-action-dialog
  const [open, setOpen] = useState(false); // dialog open/close state
  const [isLoadingAddNewBacteria, setIsLoadingAddNewBacteria] = useState(false);
  const [isLoadingUpdateBacteria, setIsLoadingUpdateBacteria] = useState(false);
  const [isUpdatingTable, setIsUpdatingTable] = useState(false);
  const idRegex = new RegExp(/\w*id\b/);
  const dispatch = useDispatch();

  useEffect(() => {
    if (bacteria === null) {
      (async () => {
        try {
          Promise.all([
            ActionsClient.GetActions().then((response) =>
              response !== undefined ? response : null,
            ),
            BacteriasClient.GetBacterias().then((response) =>
              response !== undefined ? response : null,
            ),
            BacteriaGroupsClient.GetBacteriaGroups({ orderBy: 'asc' }),
          ])
            .then(([actions, bacteria, bacteriaGroups]) => {
              if (bacteria instanceof Error || actions instanceof Error) {
                dispatch(openSnackbar('warning', 'somethingWentWrong'));
              } else {
                setActions(actions);
                setBacteria(bacteria);
                setBacteriaGroups([
                  { bacteriagroupid: null, name: '-', label: '-', value: null }, // option for setting to no group
                  ...bacteriaGroups.map((group) => ({
                    ...group,
                    label: group.name, // for react-select-component
                    value: group.name, // for react-select-component
                  })),
                ]);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [bacteria, dispatch]);

  function updateTable() {
    setIsUpdatingTable(true);
    BacteriasClient.GetBacterias().then((response) => {
      if (response instanceof Error) {
        dispatch(openSnackbar('warning', 'somethingWentWrong'));
      } else if (response !== undefined) {
        setBacteria(response);
      }
      setIsUpdatingTable(false);
    });
  }

  function shouldBeShown(theThing) {
    const idRegex = new RegExp(/\w*id\b/);

    if (
      !idRegex.test(theThing.toLowerCase()) &&
      theThing !== 'maincontact' &&
      theThing !== 'createdby' &&
      theThing !== 'createdat' &&
      theThing !== 'modifiedby' &&
      theThing !== 'modifiedat' &&
      theThing !== 'active' &&
      theThing !== 'sites' &&
      theThing !== 'password' &&
      theThing !== 'locationimage'
    ) {
      return true;
    }

    return false;
  }

  function generateColumns() {
    const columns = [];

    const name = { title: t('name'), field: 'name' };
    const description = { title: t('description'), field: 'description' };
    const group = { title: t('bacteriaGroup'), field: 'bacteriaGroupName' };

    columns.push(name, description, group);

    return columns;
  }

  // Expects param data to be an object representing a bacteria
  // uses the object properties to generate the titles
  function displayTitlesObj(data) {
    if (data === undefined || data === null) {
      return [];
    }
    const newData = Object.keys(data).reduce((result, currentKey) => {
      if (
        currentKey === 'bacteriagroupid' ||
        ((typeof data[currentKey] === 'string' ||
          data[currentKey] instanceof String ||
          typeof data[currentKey] === 'number' ||
          currentKey.includes('date')) &&
          typeof data[currentKey] &&
          !Array.isArray(data[currentKey]) &&
          shouldBeShown(currentKey))
      ) {
        if (currentKey === 'name') {
          const elementToPush = { title: t(currentKey), field: currentKey };
          result.push(elementToPush);
        } else if (currentKey === 'bacteriagroupid') {
          const elementToPush = {
            title: t('bacteriaGroup'),
            field: 'bacteriaGroupName',
            editComponent: ({ value, rowData, onRowDataChange }) => {
              return (
                <ReactSelect
                  options={bacteriaGroups}
                  name='bacteriaGroupSelect'
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(selectedOption) => {
                    const index = bacteriaGroups.findIndex(
                      (group) => group.name === selectedOption.value,
                    );
                    const id = bacteriaGroups[index]?.bacteriagroupid || null;

                    const data = { ...rowData };
                    data.bacteriaGroupName = selectedOption.value;
                    data.bacteriaGroupId = id;
                    onRowDataChange(data);
                  }}
                  value={value}
                  placeholder={value && value !== '-' ? value : '-'}
                />
              );
            },
          };
          result.push(elementToPush);
        } else {
          const elementToPush = {
            title: t(currentKey),
            field: currentKey,
            editComponent: (tableData) => (
              <div className='MuiFormControl-root MuiTextField-root'>
                <div
                  className={`MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl ${classes.descriptionFontStyle}`}
                >
                  <TextareaAutosize
                    className={
                      isMobile
                        ? 'MuiInputBase-input MuiInput-input'
                        : `MuiInputBase-input MuiInput-input ${classes.descriptionWidthStyle}`
                    }
                    value={tableData.value}
                    onChange={(e) => tableData.onChange(e.target.value)}
                  />
                </div>
              </div>
            ),
          };
          result.push(elementToPush);
        }
      }
      return result;
    }, []);
    return newData;
  }

  function displayRowsObj(data) {
    if (data === undefined || data === null) {
      return [];
    }
    const result = {};
    Object.keys(data).forEach((currentKey) => {
      if (
        currentKey === 'bacteriagroupid' ||
        ((typeof data[currentKey] === 'string' ||
          data[currentKey] instanceof String ||
          typeof data[currentKey] === 'number' ||
          currentKey.includes('date')) &&
          !Array.isArray(data[currentKey]) &&
          !idRegex.test(currentKey.toLowerCase()) &&
          shouldBeShown(currentKey))
      ) {
        if (currentKey === 'bacteriagroupid') {
          const bacteriaGroupId = data[currentKey];
          let bacteriaGroupName = '-';

          if (bacteriaGroupId) {
            const groupIndex = bacteriaGroups.findIndex(
              (group) => group.bacteriagroupid === bacteriaGroupId,
            );
            bacteriaGroupName = bacteriaGroups[groupIndex]?.name;
          }
          result.bacteriaGroupId = bacteriaGroupId || null;
          result.bacteriaGroupName = bacteriaGroupName || '-';
        } else {
          const text =
            data[currentKey] === null || data[currentKey] === '' ? '-' : String(data[currentKey]);
          result[currentKey] = text;
        }
      }
    });
    return result;
  }

  function updateBacteriaAction(id) {
    (async () => {
      try {
        Promise.all([
          ActionsClient.GetActions().then((response) => (response !== undefined ? response : null)),
          ActionsClient.GetActionsByBacteriaId(id).then((response) =>
            response !== undefined ? response : null,
          ),
        ])
          .then(([actions, bacteriaActions]) => {
            if (actions instanceof Error || bacteriaActions instanceof Error) {
              dispatch(openSnackbar('warning', 'somethingWentWrong'));
              setOpen(false);
            } else {
              setActions(actions);
              setBacteriaActions(bacteriaActions);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    })();
  }

  function handleDeleteAction(obj) {
    (async () => {
      try {
        await BacteriaActionsClient.GetBacteriaAction(theBacteria, obj.actionid).then(
          (response) => {
            if (response instanceof Error) {
              dispatch(openSnackbar('warning', 'somethingWentWrong'));
            } else {
              (async () => {
                try {
                  await BacteriaActionsClient.DeleteBacteriaAction(
                    response.bacteriaactionid,
                    response,
                  ).then(() => {
                    updateBacteriaAction(theBacteria);
                  });
                } catch (e) {
                  console.log(e);
                }
              })();
            }
          },
        );
      } catch (e) {
        console.log(e);
      }
    })();
  }

  function handleAddingAction() {
    for (let index = 0; index < bacteriaActions.length; index++) {
      if (bacteriaActions[index].actionid === theAction) {
        dispatch(openSnackbar('warning', 'BacteriaActionAlreadyAssignedAlert'));
        return;
      }
    }
    const object2 = {
      bacteriaid: theBacteria,
      actionid: theAction,
    };
    (async () => {
      try {
        await BacteriaActionsClient.PostBacteriaAction(object2).then((response) => {
          if (response instanceof Error) {
            dispatch(openSnackbar('warning', 'somethingWentWrong'));
          } else {
            updateBacteriaAction(theBacteria);
            setTheAction(null);
          }
          return response;
        });
      } catch (e) {
        console.log(e);
      }
    })();
  }

  function handleClose() {
    setOpen(!open);
    setTheAction(null);
    setBacteriaActions(null);
  }

  let myColumns = [];
  let myData = [];
  if (bacteria !== null) {
    myColumns = bacteria.length === 0 ? generateColumns() : displayTitlesObj(bacteria[0]);

    myData = Array.from(bacteria).map((object) => {
      return displayRowsObj(object);
    });
  }

  let actionList;
  if (actions !== undefined && actions !== null) {
    actionList = actions.map((a) => {
      return (
        <MenuItem key={a.actionid} value={a.actionid}>
          {a.name}
        </MenuItem>
      );
    });
  }

  return (
    <div className={classes.wrapper}>
      {bacteria !== null && (
        <Paper className={classes.contentWrapper}>
          <MaterialTable
            title={t('Bacterias')}
            columns={myColumns}
            data={myData}
            options={{
              addRowPosition: 'first',
            }}
            localization={{
              header: {
                actions: t('tableHeaderActions'),
              },
              body: {
                addTooltip: t('addTooltip'),
                editTooltip: t('editTooltip'),
                editRow: {
                  deleteText: t('deleteText'),
                  cancelTooltip: t('cancelTooltip'),
                  saveTooltip: t('saveTooltip'),
                },
                emptyDataSourceMessage: t('BacteriasEmptyDataSourceMessage'),
              },
              toolbar: {
                searchTooltip: t('searchTooltip'),
                searchPlaceholder: t('searchPlaceholder'),
              },
              pagination: {
                labelDisplayedRows: t('labelDisplayedRows'),
                labelRowsSelect: t('labelRowsSelect'),
                firstTooltip: t('firstTooltip'),
                previousTooltip: t('previousTooltip'),
                nextTooltip: t('nextTooltip'),
                lastTooltip: t('lastTooltip'),
              },
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (newData.name === undefined || newData.description === undefined) {
                      reject();
                    } else {
                      setIsLoadingAddNewBacteria(true);
                      myData.push(newData);
                      (async () => {
                        try {
                          await BacteriasClient.PostBacteria(newData).then((response) => {
                            updateTable();
                            if (response instanceof Error) {
                              dispatch(openSnackbar('warning', 'somethingWentWrong'));
                              setIsLoadingAddNewBacteria(false);
                            } else {
                              setIsLoadingAddNewBacteria(false);
                              return response;
                            }
                          });
                        } catch (e) {
                          console.log(e);
                        }
                      })();
                      resolve();
                    }
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    if (newData.name === undefined || newData.description === undefined) {
                      reject();
                    } else {
                      if (
                        newData.name === oldData.name &&
                        newData.description === oldData.description &&
                        newData.active === oldData.active &&
                        newData.bacteriaGroupId === oldData.bacteriaGroupId
                      ) {
                        reject();
                      } else {
                        const completeData = bacteria[oldData.tableData.index];
                        (async () => {
                          setIsLoadingUpdateBacteria(true);
                          try {
                            await DataService.putObject(
                              'Bacterias/PutBacteria',
                              { ...completeData, ...newData },
                              bacteria[oldData.tableData.index].bacteriaid,
                            ).then((response) => {
                              if (response instanceof Error) {
                                dispatch(openSnackbar('warning', 'somethingWentWrong'));
                              }
                              updateTable();
                              setIsLoadingUpdateBacteria(false);
                              return response;
                            });
                          } catch (e) {
                            console.log(e);
                          }
                        })();
                        resolve();
                      }
                    }
                  }, 600);
                }),
            }}
            actions={[
              {
                icon: 'loupe',
                tooltip: t('assignAction'),
                onClick: (_, rowData) => {
                  updateBacteriaAction(bacteria[rowData.tableData.index].bacteriaid);
                  setOpen(true);
                  setTheBacteria(bacteria[rowData.tableData.index].bacteriaid);
                },
              },
            ]}
          />
          {(isLoadingAddNewBacteria || isLoadingUpdateBacteria || isUpdatingTable) && (
            <div className={classes.loadingOverlay}>
              <CircularProgress color='primary' size='3rem' />
            </div>
          )}
        </Paper>
      )}

      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby='simple-dialog-title'
        open={open}
      >
        <DialogTitle id='simple-dialog-title'>{t('assignActionsDialogHeader')}</DialogTitle>
        {bacteriaActions !== null && (
          <DialogContent>
            <DialogContentText>{t('assignActionsDialogContentText')}</DialogContentText>
            <List>
              {bacteriaActions.map((obj) => (
                <ListItem key={obj.actionid}>
                  <ListItemText>{obj.name}</ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => handleDeleteAction(obj)}
                      edge='end'
                      aria-label='delete'
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </DialogContent>
        )}
        <form className={classes.formWrapper} autoComplete='off'>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor='action'>{t('Action')}</InputLabel>
            <Select
              value={theAction === null ? '' : theAction}
              onChange={(e) => {
                setTheAction(e.target.value);
              }}
              inputProps={{
                name: 'action',
                id: 'action',
              }}
            >
              {actionList}
            </Select>
          </FormControl>
          <IconButton
            color='primary'
            className={classes.iconWrapper}
            onClick={handleAddingAction}
            edge='end'
            aria-label='add'
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </form>
      </Dialog>
    </div>
  );
}
