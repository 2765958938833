import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import icon from '../../../../../icons/missing-image.png';
import { Card, Typography, useMediaQuery, makeStyles, Divider } from '@material-ui/core';
import { enums } from '../../../../../Services/Utility/Enumerations';
import { yyyymmddhhmm } from '../../../../../Services/Utility/DateUtils';
import { differenceInHours } from 'date-fns';

const useStyles = makeStyles({
  card: {
    padding: '1rem',
    border: '1px solid #979797',
  },
  mobileCard: {
    padding: '1rem',
  },
  allImagesWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  imageWrapper: {
    flexGrow: '1',
    minWidth: '9.4rem',
    maxWidth: '12.5rem',
    position: 'relative',
    margin: '0.3rem',
    cursor: 'pointer',
    width: '45%',
  },
  mobileImageWrapper: {
    position: 'relative',
    cursor: 'pointer',
    width: '50%',
  },
  imagePair: {
    display: 'flex',
  },
  imageContainer: {
    position: 'relative',
    width: '50%',
  },
  emptyImage: {
    padding: '0.3rem',
    cursor: 'not-allowed',
  },
  emptyImageTextContainer: {
    position: 'absolute',
    top: '0',
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  largeImageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  largeImage: {
    maxWidth: '25rem',
    margin: '0.5rem auto 0 auto',
  },
  imageLabel: {
    width: '100%',
    backgroundColor: '#ffffff',
    opacity: '0.8',
    position: 'absolute',
    bottom: '0',
    textAlign: 'center',
    border: '1px solid black',
  },
  noImages: {
    textAlign: 'left',
    marginBottom: '0.3rem',
  },
  imageLabelText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

export default function ImageCard(props) {
  const { t } = useTranslation(['samplesDetails']);
  const { sample, imagePairs, doOpenImage, handleOpenImageDialog } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const sampleCreated = new Date(sample.createdAt);

  function getDate(event) {
    return yyyymmddhhmm(new Date(event));
  }

  function enlargeImage(image) {
    isMobile
      ? handleOpenImageDialog(image.medium)
      : doOpenImage(image.medium, getDate(image.createdAt));
  }

  function singleImage(image) {
    return (
      <div className={classes.imageContainer}>
        <img
          src={image.thumbnail ? image.thumbnail : icon}
          alt='sampleImage'
          width='100%'
          height='auto'
          className={image === icon ? classes.emptyImage : ''}
          onClick={image === icon ? null : () => enlargeImage(image)}
        ></img>
        {image === icon && (
          <div className={classes.emptyImageTextContainer}>
            <Typography variant='body2' color='textSecondary'>
              {t('imageCard:missingImage')}
            </Typography>
          </div>
        )}
      </div>
    );
  }

  function imageSamplePair(imagePair, label) {
    const firstPicture = imagePair.images.find((pic) => pic.lightMode === enums.lightMode.under);
    const secondPicture = imagePair.images.find((pic) => pic.lightMode === enums.lightMode.over);

    return (
      <div className={classes.imagePair}>
        {singleImage(firstPicture || icon)}
        {singleImage(secondPicture || icon)}
        <div className={classes.imageLabel}>
          <Typography variant='body2' className={classes.imageLabelText}>
            {label}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <Card className={isMobile ? classes.mobileCard : classes.card} elevation={isMobile ? 0 : 1}>
      <Typography variant='subtitle1' gutterBottom>
        {t('imageCard:images')}
      </Typography>
      {imagePairs && imagePairs.length !== 0 ? (
        <div>
          <Typography variant='body2' color='textSecondary' gutterBottom>
            {t('imageCard:clickOnImage')}
          </Typography>
          {isMobile && <Divider style={{ margin: '1rem 0' }} />}
          <div className={classes.allImagesWrapper}>
            {imagePairs.map((imagePair) => {
              const photoShootTime = new Date(imagePair.createdAt);
              const label = t('imageCard:afterHours', {
                hours: differenceInHours(photoShootTime, sampleCreated),
              });

              return (
                <div
                  className={isMobile ? classes.mobileImageWrapper : classes.imageWrapper}
                  key={imagePair.createdAt}
                >
                  {imageSamplePair(imagePair, label)}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <Typography variant='body2' color='textSecondary' className={classes.noImages}>
          {t('imageCard:noImages')}
        </Typography>
      )}
    </Card>
  );
}

ImageCard.propTypes = {
  doOpenImage: PropTypes.func,
  imagePairs: PropTypes.array.isRequired,
  sample: PropTypes.object.isRequired,
  handleOpenImageDialog: PropTypes.func,
};
