import { SELECT_VIEW } from './types';

/**
 * Function to set the selectedView ('farmer' or 'bacteriologist') to easier distinguish between user specific functionalities.
 * @param {string} selectedView
 */
export function farmerOrBacteriologist(selectedView) {
  return {
    type: SELECT_VIEW,
    selectedView,
  };
}
