import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SnackbarComponent from '../SnackbarComponent';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import TopBar from '../Nav/TopBar';
import MobileTopBar from '../Nav/MobileTopBar';
import LoginBox from './LoginBox';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    boxSizing: 'border-box',
    paddingTop: 'var(--topbar-height-desktop)',

    '@media (max-width: 1160px)': {
      paddingTop: 'var(--topbar-height-mobile)',
    },
  },
  wrapperMobile: {
    backgroundColor: '#ffff',
  },
});

export default function Login(props) {
  const { signedInState } = props;
  const isSmallMobile = useMediaQuery('(max-width:500px)');
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { t } = useTranslation(['main']);
  const classes = useStyles();
  const history = useHistory();
  const [previousPage, setPreviousPage] = useState(null);

  const snackbarOpen = useSelector((state) => state.snackbarReducer.isOpen);

  useEffect(() => {
    document.title = `${t('main:navigation:Login')} | Bacticam`;
  }, [t]);

  useEffect(() => {
    if (history.location.state?.from) {
      setPreviousPage(history.location.state?.from.pathname);
    }
  }, [history.location.state?.from]);

  return (
    <>
      {isMobile ? (
        <MobileTopBar signedInState={signedInState} />
      ) : (
        <TopBar signedInState={signedInState} />
      )}
      <div
        className={isSmallMobile ? `${classes.wrapper} ${classes.wrapperMobile}` : classes.wrapper}
      >
        <LoginBox {...props} previousPage={previousPage} />
        {snackbarOpen && <SnackbarComponent />}
      </div>
    </>
  );
}

Login.propTypes = {
  onPendingSignInCallback: PropTypes.func,
  failedSignInCallback: PropTypes.func.isRequired,
  successfulSignInCallback: PropTypes.func.isRequired,
  forbiddenSignInCallback: PropTypes.func.isRequired,
  signedInState: PropTypes.string.isRequired,
};
