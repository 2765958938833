import React, { useEffect, useState } from 'react';
import { useMediaQuery, makeStyles, Tooltip, Button } from '@material-ui/core';
import BactImageCard from '../DetailCards/BactImageCard';
import VerificationCard from '../DetailCards/VerificationCard';
import BactInformationCard from '../DetailCards/BactInformationCard';
import ClassificationCard from '../DetailCards/ClassificationCard';
import SiteBacteriasCard from '../DetailCards/SiteBacteriasCard';
import HistoryCard from '../DetailCards/HistoryCard';
import CommentCard from '../DetailCards/CommentCard';
import NextSampleCard from '../DetailCards/NextSampleCard';
import { openSnackbar } from '../../../../actions/mainActions';
import { openModal } from '../../../../actions/modalActions';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PermissionCheck from '../../../../Services/PermissionCheck';
import LinkIcon from '@material-ui/icons/Link';
import { useTranslation } from 'react-i18next';
import { SamplesClient } from '../../../../Services/Utility/WebApiClient';

const useStyles = makeStyles({
  columnWrapper: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',
    backgroundColor: '#cecece',
  },
  topBar: {
    paddingTop: '1.7rem',
  },
  column: {
    width: '75%',
    '&> div': {
      margin: '0.6rem',
    },
  },
  leftColumn: {
    width: '67%',
    '&> div': {
      marginRight: 0,
    },
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '33%',
    '&> div': {
      marginBottom: 0,
    },
    '&> div:last-of-type': {
      position: 'relative',
      flexGrow: 1,
      marginBottom: '0.6rem',
    },
  },
  bottomRow: {
    display: 'flex',
    flexDirection: 'row',
    '&>div:last-of-type': {
      flexGrow: 1,
      marginLeft: '0.6rem',
    },
  },
  miniLeftColumn: {
    width: '20%',
    minWidth: '14rem',
    '&> div:nth-of-type(2)': {
      marginTop: '0.6rem',
    },
  },
  mobileColumn: {
    padding: '0.6rem',
    paddingTop: '2.4rem',
    '&> div': {
      marginBottom: '0.6rem',
      border: '1px solid #e8e8e8 !important',
    },
    '&>div:last-of-type': {
      marginBottom: '10rem',
    },
  },
  imagePairs: {
    margin: '0 !important',
    position: 'relative',
  },
  copyLink: {
    position: 'absolute',
    zIndex: 500,
    right: 0,
    bottom: 0,
    marginRight: '0.4rem',
  },
  getLinkButtonStyle: {
    color: 'white',
  },
  getLinkButtonStyleNoImages: {
    color: 'black',
  },
  tooltipLabel: {
    fontSize: '0.8rem',
  },
});

export default function BactDetails(props) {
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { t } = useTranslation(['samplesDetails']);
  const {
    updateSample,
    isLoadingUpdateSample,
    isLoadingUpdateAfterSendInForVerification,
    isLoadingUpdateAfterCheckSendToBacteriologist,
    isLoadingUpdateAfterSendToFinal,
    refreshComments,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  let currentIndex;
  const history = useHistory();
  const match = useRouteMatch();
  const canFlagLab = PermissionCheck.canFlagLab();
  const isJuniorBacteriologist = PermissionCheck.canClassifyAsJuniorBacteriologist();
  const [sampleURL, setSampleURL] = useState('');

  const slideShowSamples = useSelector((state) => state.modalReducer.slideShowSamples);
  const startButtonIsClicked = useSelector((state) => state.modalReducer.startButtonIsClicked);
  const sample = useSelector((state) => state.modalReducer.sample);

  if (startButtonIsClicked) {
    currentIndex = slideShowSamples.findIndex((x) => x.sampleId === sample.sampleId);
  }

  useEffect(() => {
    if (match.path === '/comments') {
      const url = `${document.URL}/${sample.sampleId}`;
      setSampleURL(url.replace('comments', 'samples'));
    }
  }, [match, sample]);

  useEffect(() => {
    async function fetchSampleData(id) {
      const fetchSample = await SamplesClient.GetSample(id);
      if (fetchSample instanceof Error) {
        history.push('/samples');
        dispatch(openSnackbar('warning', 'fetchError'));
      } else if (fetchSample) {
        dispatch(openModal(fetchSample, 'bacteriologist', startButtonIsClicked, slideShowSamples));
      }
    }

    // If the opened sample and sampleID in the URL don't match, fetch and open the sample from the URL instead.
    if (match.params.sampleId !== undefined && sample.sampleId !== match.params?.sampleId) {
      fetchSampleData(match.params.sampleId);
    }
  });

  function onNextOrPrevSample(change) {
    const newSample = slideShowSamples[currentIndex + change];
    dispatch(openModal(newSample, 'bacteriologist', true, slideShowSamples));
    history.push(`/samples/${newSample.sampleId}`);
  }

  async function onCheckSendInForVerification(type) {
    await updateSample('sendToLab', sample.sampleId);

    switch (type) {
      case 'unauthorized':
        dispatch(openSnackbar('warning', 'snackBarForbiddenAction'));
        break;
      case 'error':
        dispatch(openSnackbar('warning', 'somethingWentWrong'));
        break;
      default:
    }
  }

  async function onCheckSendToBacteriologist(type) {
    await updateSample('sendToBacteriologist', sample.sampleId);

    switch (type) {
      case 'unauthorized':
        dispatch(openSnackbar('warning', 'snackBarForbiddenAction'));
        break;
      case 'error':
        dispatch(openSnackbar('warning', 'somethingWentWrong'));
        break;
      default:
    }
  }

  async function onCheckSendToFinal(type) {
    await updateSample('sendToFinal', sample.sampleId);

    switch (type) {
      case 'error':
        dispatch(openSnackbar('warning', 'somethingWentWrong'));
        break;
      default:
    }
  }

  async function onClassifyDialogFinished(type) {
    await updateSample('classificationCard', sample.sampleId);

    switch (type) {
      case 'classify':
        dispatch(openSnackbar('success', 'snackBarClassified'));
        break;
      case 'inconclusive':
        dispatch(openSnackbar('success', 'snackBarInconclusive'));
        break;
      case 'classifyFailed':
        dispatch(openSnackbar('warning', 'snackBarClassificationFailed'));
        break;
      case 'inconclusiveFailed':
        dispatch(openSnackbar('warning', 'snackBarInconclusiveFailed'));
        break;
      case 'forbidden':
        dispatch(openSnackbar('warning', 'snackBarForbiddenAction'));
        break;
      case 'newerBacteriaResultExists':
        dispatch(openSnackbar('info', 'snackBarNewerResultExists'));
        break;
      default:
    }
  }

  async function copySampleURLToClipboard() {
    await navigator.clipboard.writeText(sampleURL);
  }

  function getDirectLink() {
    if (match.path === '/comments') {
      return (
        <div>
          <Tooltip
            title={t('copySampleLink:copyDirectLink')}
            classes={{ tooltip: classes.tooltipLabel }}
            placement='top'
            arrow
          >
            <Button
              className={
                sample.sampleImagePairs.length === 0
                  ? classes.getLinkButtonStyleNoImages
                  : classes.getLinkButtonStyle
              }
              startIcon={<LinkIcon />}
              onClick={() => copySampleURLToClipboard()}
            >
              {t('copySampleLink:copy')}
            </Button>
          </Tooltip>
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <>
      {!isMobile && sample && (
        <div
          className={
            sample.sampleImagePairs.length === 0
              ? `${classes.columnWrapper} ${classes.topBar}`
              : classes.columnWrapper
          }
        >
          <div className={`${classes.column} ${classes.leftColumn}`}>
            {sample.sampleImagePairs.length > 0 && (
              <div className={classes.imagePairs}>
                <div className={classes.copyLink}>{getDirectLink()}</div>
                <BactImageCard
                  sampleCreatedAt={sample.createdAt}
                  imagePairs={sample.sampleImagePairs}
                />
              </div>
            )}
            <div className={classes.bottomRow}>
              <div className={classes.miniLeftColumn}>
                <BactInformationCard sample={sample} copySampleURL={copySampleURLToClipboard} />
              </div>
              <ClassificationCard
                sample={sample}
                onFinished={onClassifyDialogFinished}
                isLoadingUpdateSample={isLoadingUpdateSample}
              />
            </div>
            {sample.sampleImagePairs.length === 0 && getDirectLink()}
          </div>
          <div className={`${classes.column} ${classes.rightColumn}`}>
            {startButtonIsClicked && (
              <NextSampleCard
                onNextOrPrevSample={onNextOrPrevSample}
                currentIndex={currentIndex + 1}
                numberOfSamplesToActOn={slideShowSamples.length}
              />
            )}
            <HistoryCard subjectId={sample.subject.subjectId} expansionPanel />
            <SiteBacteriasCard siteId={sample.site.siteId} />
            {(canFlagLab || isJuniorBacteriologist) && (
              <VerificationCard
                onCheckSendInForVerification={onCheckSendInForVerification}
                onCheckSendToBacteriologist={onCheckSendToBacteriologist}
                onCheckSendToFinal={onCheckSendToFinal}
                isLoadingUpdateAfterSendInForVerification={
                  isLoadingUpdateAfterSendInForVerification
                }
                isLoadingUpdateAfterCheckSendToBacteriologist={
                  isLoadingUpdateAfterCheckSendToBacteriologist
                }
                isLoadingUpdateAfterSendToFinal={isLoadingUpdateAfterSendToFinal}
              />
            )}
            <CommentCard
              assignmentId={sample.assignmentId}
              adminView={true}
              refreshComments={refreshComments}
            />
          </div>
        </div>
      )}

      {isMobile && sample && (
        <div className={classes.mobileColumn}>
          <BactInformationCard sample={sample} copySampleURL={copySampleURLToClipboard} />
          {startButtonIsClicked && (
            <NextSampleCard
              onNextOrPrevSample={onNextOrPrevSample}
              currentIndex={currentIndex + 1}
              numberOfSamplesToActOn={slideShowSamples.length}
            />
          )}
          {sample.sampleImagePairs.length > 0 && (
            <BactImageCard
              sampleCreatedAt={sample.createdAt}
              imagePairs={sample.sampleImagePairs}
            />
          )}
          <ClassificationCard
            sample={sample}
            onFinished={onClassifyDialogFinished}
            isLoadingUpdateSample={isLoadingUpdateSample}
          />
          {(canFlagLab || isJuniorBacteriologist) && (
            <VerificationCard
              onCheckSendInForVerification={onCheckSendInForVerification}
              onCheckSendToBacteriologist={onCheckSendToBacteriologist}
              onCheckSendToFinal={onCheckSendToFinal}
              isLoadingUpdateAfterSendInForVerification={isLoadingUpdateAfterSendInForVerification}
              isLoadingUpdateAfterCheckSendToBacteriologist={
                isLoadingUpdateAfterCheckSendToBacteriologist
              }
              isLoadingUpdateAfterSendToFinal={isLoadingUpdateAfterSendToFinal}
            />
          )}
          <HistoryCard subjectId={sample.subject.subjectId} expansionPanel />
          <SiteBacteriasCard siteId={sample.site.siteId} />
          <CommentCard
            isMobile={true}
            assignmentId={sample.assignmentId}
            refreshComments={refreshComments}
          />
        </div>
      )}
    </>
  );
}

BactDetails.propTypes = {
  updateSample: PropTypes.func.isRequired,
  isLoadingUpdateSample: PropTypes.bool.isRequired,
  isLoadingUpdateAfterSendInForVerification: PropTypes.bool.isRequired,
  isLoadingUpdateAfterCheckSendToBacteriologist: PropTypes.bool.isRequired,
  isLoadingUpdateAfterSendToFinal: PropTypes.bool.isRequired,
  refreshComments: PropTypes.func,
};
