import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  tooltipContent: {
    paddingLeft: '1rem',
    position: 'relative',
  },
  verticalLineOne: {
    backgroundColor: '#dee2e6',
    height: '100%',
    width: '1px',
    position: 'absolute',
    left: '1.7rem',
  },
  verticalLineTwo: {
    backgroundColor: '#dee2e6',
    height: '100%',
    width: '1px',
    position: 'absolute',
    left: '3.8rem',
  },
  firstPhotoStatus: {
    display: 'flex',
    alignItems: 'center',
    width: '18.5rem',
    paddingTop: '1rem',
  },
  firstPhotoInfo: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '1.2rem',
    width: '18.5rem',
    paddingLeft: '0.25rem',
  },
  secondPhotoStatus: {
    display: 'flex',
    alignItems: 'center',
    width: '18.5rem',
    paddingLeft: '2.1rem',
    marginTop: '1.2rem',
  },
  secondPhotoInfo: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '1.2rem',
    paddingLeft: '2.35rem',
    width: '18.5rem',
    paddingBottom: '1rem',
  },
  icon: {
    zIndex: 1,
    backgroundColor: '#F4F4F4',
  },
  textOnePosition: {
    marginLeft: '3.1rem',
  },
  textTwoPosition: {
    marginLeft: '3.3rem',
  },
  textThreePosition: {
    marginLeft: '1rem',
  },
  textFourPosition: {
    marginLeft: '1.2rem',
  },
});

export default function GroupedTooltip(props) {
  const {
    firstPhotoShootStatus,
    secondPhotoShootStatus,
    firstPhotoShootInfo,
    secondPhotoShootInfo,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.tooltipContent}>
      <div className={classes.verticalLineOne}></div>

      <div className={classes.verticalLineTwo}></div>

      <div className={classes.firstPhotoStatus}>
        <div className={classes.icon}>{firstPhotoShootStatus.camera} </div>
        <Typography className={classes.textOnePosition} variant='body2'>
          {firstPhotoShootStatus.text}
        </Typography>
      </div>

      <div className={classes.firstPhotoInfo}>
        <div className={classes.icon}>{firstPhotoShootInfo.icon}</div>
        <Typography className={classes.textTwoPosition} variant='body2'>
          {firstPhotoShootInfo.text}
        </Typography>
      </div>

      <div className={classes.secondPhotoStatus}>
        <div className={classes.icon}>{secondPhotoShootStatus.camera}</div>
        <Typography className={classes.textThreePosition} variant='body2'>
          {secondPhotoShootStatus.text}
        </Typography>
      </div>

      <div className={classes.secondPhotoInfo}>
        <div className={classes.icon}>{secondPhotoShootInfo.icon} </div>
        <Typography className={classes.textFourPosition} variant='body2'>
          {secondPhotoShootInfo.text}
        </Typography>
      </div>
    </div>
  );
}

GroupedTooltip.propTypes = {
  firstPhotoShootStatus: PropTypes.object.isRequired,
  secondPhotoShootStatus: PropTypes.object.isRequired,
  firstPhotoShootInfo: PropTypes.object.isRequired,
  secondPhotoShootInfo: PropTypes.object.isRequired,
};
