import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useMediaQuery, Typography } from '@material-ui/core';
import confusedCow from '../../../icons/ko_404_error.png';
import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import { openFeedbackDialog } from '../../../actions/mainActions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  pageNotFoundContainer: {
    display: 'flex',
    maxWidth: '80rem',
    paddingTop: '6%',
    width: '70%',
    justifyContent: 'space-evenly',
  },
  body: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  links: {
    padding: '1.75rem 0',
    display: 'flex',
    flexWrap: 'wrap',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.75rem 2.5rem 0.75rem 0',
    '&>a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
    '&>p': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  greenCircle: {
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    marginRight: '1rem',
    alignItems: 'center',
    '&>svg': {
      color: 'white',
    },
  },
  mobileContainer: {
    flexWrap: 'wrap',
    width: '100%',
    paddingLeft: '1.5rem',
    paddingBottom: '1rem',
  },
  mobileImage: {
    height: '200px',
  },
}));

export default function PageNotFound() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { t } = useTranslation(['main']);

  useEffect(() => {
    document.title = `${t('navigation:pageNotFound')} | Bacticam`;
  }, [t]);

  return (
    <>
      <div
        className={
          isMobile
            ? `${classes.pageNotFoundContainer} ${classes.mobileContainer}`
            : classes.pageNotFoundContainer
        }
      >
        <div>
          <Typography variant='h1' gutterBottom>
            {t('pageNotFound:header')}
          </Typography>
          <div className={classes.body}>
            <Typography variant='subtitle1'>{t('pageNotFound:body1')}</Typography>
            {'\u00A0'}
            <Typography variant='subtitle1'>{t('pageNotFound:body2')}</Typography>
          </div>
          <div className={classes.links}>
            <div className={classes.link}>
              <div className={classes.greenCircle}>
                <HomeOutlinedIcon />
              </div>
              <Link to={'/samples'}>
                <Typography>{t('pageNotFound:goToHomePage')}</Typography>
              </Link>
            </div>
            <div className={classes.link}>
              <div className={classes.greenCircle}>
                <RecordVoiceOverOutlinedIcon fontSize='small' />
              </div>
              <Typography onClick={() => dispatch(openFeedbackDialog())}>
                {t('pageNotFound:leaveFeedback')}
              </Typography>
            </div>
            <div className={classes.link}>
              <div className={classes.greenCircle}>
                <MailOutlinedIcon />
              </div>
              <Typography
                component='a'
                key='Email'
                href='https://knowledge.agricam.se/knowledge/kb-tickets/new'
                target='_blank'
                rel='noreferrer'
              >
                {t('pageNotFound:mailSupport')}
              </Typography>
            </div>
          </div>
        </div>
        <img src={confusedCow} className={isMobile ? classes.mobileImage : ''} />
      </div>
    </>
  );
}
