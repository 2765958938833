import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Card, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Remarkable } from 'remarkable';

const useStyles = makeStyles({
  card: {
    border: '1px solid #E8E8E8',
  },
  mobileCard: {
    boxShadow: 'none',
    border: 'none',
  },
  bacteria: {
    maxHeight: '20rem',
    overflowY: 'auto',
    padding: '0 1rem 1rem 1rem',
    marginRight: '-1rem',
    paddingRight: '1rem'
  }
});

export default function BacteriaCard(props) {
  const classes = useStyles();
  const { t } = useTranslation(['bacterias']);
  const { bacteria } = props;
  const isMobile = useMediaQuery('(max-width:1160px)');
  const md = new Remarkable({
    breaks: true,
  });

  return (
    <Card className={isMobile ? `${classes.card} ${classes.mobileCard}` : classes.card}>
          <div className={classes.bacteria}>
          <Typography variant='subtitle1'>{t(`bacterias:${bacteria.name}`)}</Typography>
      <Typography
        dangerouslySetInnerHTML={{ __html: md.render(t(`bacterias:${bacteria.description}`)) }}
      />
      </div>
    </Card>
  );
}

BacteriaCard.propTypes = {
  bacteria: PropTypes.object.isRequired,
};
