import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import BactDetails from '../Details/BactDetails';
import FarmerDetails from '../Details/FarmerDetails';
import BactSampleView from './BactSampleView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import SelectViewBar from '../SelectViewBar';
import LabFormDialog from './LabFormDialog';
import { enums } from '../../../Services/Utility/Enumerations';
import {
  makeStyles,
  Button,
  Typography,
  Paper,
  FormControl,
  Select,
  InputBase,
  Icon,
  IconButton,
  useMediaQuery,
} from '@material-ui/core/';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../actions/modalActions';
import { openSnackbar } from '../../../actions/mainActions';
import { getSampleStatuses } from '../../../Services/Utility/SampleInformation';
import { useBactSamples } from '../../../Services/Utility/CustomHooks/useFetch';
import { Link } from 'react-router-dom';
import PermissionCheck from '../../../Services/PermissionCheck';
import { SamplesClient } from '../../../Services/Utility/WebApiClient';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  wrapperStyle: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '0.3rem',
  },
  formDiv: {
    display: 'flex',
    padding: '5px',
    width: '100%',
    minWidth: 64,
    maxWidth: 200,
    height: '48px',
  },
  sortingIcon: {
    lineHeight: 0,
  },
  divStyle: {
    display: 'flex',
    padding: 10,
    width: '100%',
    minWidth: 64,
    cursor: 'pointer',
    maxWidth: 200,
  },
  mobileDivStyle: {
    maxWidth: 100,
  },
  pointer: {
    cursor: 'pointer',
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'row',
  },
  searchWrapper: {
    marginLeft: 10,
    maxWidth: '20rem',
  },
  searchBar: {
    display: 'flex',
  },
  sortDown: {
    transform: 'rotate(0)',
  },
  sortUp: {
    transform: 'rotate(-180deg)',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
  buttonWrapperMobile: {
    justifyContent: 'center',
  },
  switchButtonContainer: {
    margin: '0.6rem',
    backgroundColor: '#ffffff',
    border: '3px solid #009D59',
    borderRadius: '7px',
    display: 'inline-table',
  },
  switchButtonOff: {
    zIndex: '1',
    outline: 'none !important',
    width: '7rem',
    color: '#bdbdbd',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  switchButtonOn: {
    zIndex: '2',
    outline: 'none !important',
    width: '7rem',
  },
  switchButtonMobile: {
    width: '6rem',
  },
  topWrapperStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topWrapperStyleMobile: {
    display: 'initial',
    flexWrap: 'nowrap',
  },
  startButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  startButtonWrapperMobile: {
    justifyContent: 'space-between',
  },
  startButtonStyle: {
    marginLeft: '1rem',
  },
  bactSampleView: {
    width: '80%',
    maxWidth: '1200px',
    padding: '5px',
    height: 'auto',
  },
  bactSampleViewMobile: {
    width: '100%',
    paddingTop: '2rem',
  },
  sampleCount: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2%',
  },
});

export default function BactSampleContainer() {
  const classes = useStyles();
  const { t } = useTranslation(['samplesBacteriologist', 'samplesDetails', 'samplesFarmer']);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const dispatch = useDispatch();

  const [samplesToActOn, setSamplesToActOn] = useState([]);
  const [loadingSamplesToActOn, setLoadingSamplesToActOn] = useState(false);
  const [slideShowSamples, setSlideShowSamples] = useState([]);
  const [sortIdentifierDesc, setSortIdentifierDesc] = useState(false);
  const [sortDateDesc, setSortDateDesc] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [allSamplesDisplayed, setAllSamplesDisplayed] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [query, setQuery] = useState('');
  const [displayAdmin, setDisplayAdmin] = useState(true);
  const [isLoadingUpdateSample, setIsLoadingUpdateSample] = useState(false);
  const [
    isLoadingUpdateAfterSendInForVerification,
    setIsLoadingUpdateAfterSendInForVerification,
  ] = useState(false);
  const [
    isLoadingUpdateAfterCheckSendToBacteriologist,
    setIsLoadingUpdateAfterCheckSendToBacteriologist,
  ] = useState(false);
  const [isLoadingClassifyAsLaboratory, setIsLoadingClassifyAsLaboratory] = useState(false);
  const [isLoadingUpdateAfterSendToFinal, setIsLoadingUpdateAfterSendToFinal] = useState(false);

  const [page, setPage] = useState(1);
  const [cowNumber, setCowNumber] = useState('');
  const [statusIds, setStatusIds] = useState([]);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState('descending');
  const [queryParams, setQueryParams] = useState({
    page: page,
    cowNumber: cowNumber,
    statusIds: statusIds,
    orderBy: orderBy,
    order: order,
  });

  const modalOpen = useSelector((state) => state.modalReducer.isOpen);
  const startButtonIsClicked = useSelector((state) => state.modalReducer.startButtonIsClicked);
  const view = useSelector((state) => state.modalReducer.view);

  const { samples, isLoadingSamples, refreshSamples } = useBactSamples(queryParams);

  /**
   * When the user browse 'All samples' using the forward and backward button in
   * BactSampleView, the page state is updated.
   */
  const onPageUpdated = useCallback((pageNumber) => {
    setPage(pageNumber);
  }, []);

  useEffect(() => {
    const samplesToActOnFilter = [];

    if (PermissionCheck.canClassifyAsSeniorBacteriologist()) {
      samplesToActOnFilter.push(enums.status.awaitingSeniorBac, enums.status.awaitingBac);
    } else {
      samplesToActOnFilter.push(enums.status.awaitingBac);
    }

    async function fetchSamplesToActOn() {
      const fetchedSamples = await SamplesClient.GetSamples({
        page: 0,
        statusIds: samplesToActOnFilter,
      });

      if (fetchedSamples instanceof Error) {
        dispatch(openSnackbar('warning', 'somethingWentWrong'));
      } else {
        setSamplesToActOn(fetchedSamples);
      }
      setLoadingSamplesToActOn(false);
    }
    setLoadingSamplesToActOn(true);
    fetchSamplesToActOn();
  }, [dispatch]);

  useEffect(() => {
    setPage(1);
    if (selectedStatus === '' || selectedStatus === 'all') {
      setStatusIds([]);
    } else {
      setStatusIds(selectedStatus);
    }
  }, [selectedStatus]);

  useEffect(() => {
    setQueryParams({
      page: page,
      cowNumber: cowNumber,
      statusIds: statusIds,
      orderBy: orderBy,
      order: order,
    });
  }, [page, cowNumber, statusIds, orderBy, order]);

  useEffect(() => {
    setPage(1);
    setCowNumber(searchText);
  }, [searchText]);

  useEffect(() => {
    const statusFilters = getSampleStatuses();
    setStatuses(statusFilters);
  }, []);

  useEffect(() => {
    setDisplayAdmin(true);
  }, [modalOpen]);

  function onOrderOptionUpdated(orderBy, order) {
    setPage(1);
    setOrderBy(orderBy);
    setOrder(order ? 'descending' : 'ascending');
  }

  function handleOnSubmit(e) {
    e.preventDefault();
    if (query === '') {
      setSearchText('');
    } else {
      setSearchText(query);
    }
  }

  async function updateSample(component, sampleId) {
    if (component === 'classificationCard') {
      setIsLoadingUpdateSample(true);
    } else if (component === 'sendToLab') {
      setIsLoadingUpdateAfterSendInForVerification(true);
    } else if (component === 'sendToBacteriologist') {
      setIsLoadingUpdateAfterCheckSendToBacteriologist(true);
    } else if (component === 'labformDialog') {
      setIsLoadingClassifyAsLaboratory(true);
    } else if (component === 'sendToFinal') {
      setIsLoadingUpdateAfterSendToFinal(true);
    }

    const indexOfSampleInSamplesToActOn = samplesToActOn.findIndex(
      (sample) => sample.sampleId === sampleId,
    );
    const fetchSample = await SamplesClient.GetSample(sampleId);

    if (fetchSample instanceof Error) {
      dispatch(openSnackbar('warning', 'somethingWentWrong'));
    } else if (fetchSample) {
      if (!allSamplesDisplayed) {
        const samplesToActOnCopy = [].concat(samplesToActOn);
        samplesToActOnCopy.splice(indexOfSampleInSamplesToActOn, 1, fetchSample);
        setSamplesToActOn(samplesToActOnCopy);

        const indexOfSampleInSlideShow = slideShowSamples.findIndex(
          (sample) => sample.sampleId === sampleId,
        );
        const slideShowSamplesCopy = [].concat(slideShowSamples);
        slideShowSamplesCopy.splice(indexOfSampleInSlideShow, 1, fetchSample);
        setSlideShowSamples(slideShowSamplesCopy);
        dispatch(
          openModal(fetchSample, 'bacteriologist', startButtonIsClicked, slideShowSamplesCopy),
        );
      } else {
        dispatch(openModal(fetchSample, 'bacteriologist'));
      }
    }
    setIsLoadingUpdateSample(false);
    setIsLoadingUpdateAfterSendInForVerification(false);
    setIsLoadingUpdateAfterCheckSendToBacteriologist(false);
    setIsLoadingClassifyAsLaboratory(false);
    setIsLoadingUpdateAfterSendToFinal(false);

    refreshSamples();
  }

  function getStatusesList() {
    return statuses.map((status) => {
      return (
        <option key={status.key} value={status.value}>
          {status.title}
        </option>
      );
    });
  }

  function getListViewPicker() {
    return (
      <div className={classes.buttonWrapper}>
        <div className={classes.switchButtonContainer}>
          <Button
            variant='contained'
            size='small'
            disableElevation={true}
            color={!allSamplesDisplayed ? 'primary' : 'secondary'}
            className={
              allSamplesDisplayed
                ? isMobile
                  ? `${classes.switchButtonOff} ${classes.switchButtonMobile}`
                  : classes.switchButtonOff
                : isMobile
                ? `${classes.switchButtonOn} ${classes.switchButtonMobile}`
                : classes.switchButtonOn
            }
            onClick={() => {
              setAllSamplesDisplayed(false);
            }}
          >
            {t('mainButton:toActOn')}
          </Button>
          <Button
            variant='contained'
            size='small'
            disableElevation={true}
            color={allSamplesDisplayed ? 'primary' : 'secondary'}
            className={
              !allSamplesDisplayed
                ? isMobile
                  ? `${classes.switchButtonOff} ${classes.switchButtonMobile}`
                  : classes.switchButtonOff
                : isMobile
                ? `${classes.switchButtonOn} ${classes.switchButtonMobile}`
                : classes.switchButtonOn
            }
            onClick={() => {
              setPage(1);
              setAllSamplesDisplayed(true);
              setSelectedStatus('all');
            }}
          >
            {t('mainButton:allSamples')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        isMobile
          ? `${classes.bactSampleView} ${classes.bactSampleViewMobile}`
          : classes.bactSampleView
      }
    >
      <div
        className={
          isMobile
            ? `${classes.topWrapperStyle} ${classes.topWrapperStyleMobile}`
            : classes.topWrapperStyle
        }
      >
        <div
          className={
            isMobile
              ? `${classes.startButtonWrapper} ${classes.startButtonWrapperMobile}`
              : classes.startButtonWrapper
          }
        >
          <Typography variant='subtitle1'>
            {allSamplesDisplayed
              ? t('mainHeader:allSamples')
              : samplesToActOn &&
                t('mainHeader:samplesToActOnCount', { count: samplesToActOn.length })}
          </Typography>
          {!allSamplesDisplayed && (
            <Link to={`/samples/${samplesToActOn[0]?.sampleId}`}>
              <Button
                variant='contained'
                color='primary'
                size='large'
                className={classes.startButtonStyle}
                disabled={samplesToActOn.length === 0}
                onClick={() => {
                  setDisplayAdmin(true);
                  setSlideShowSamples(samplesToActOn);
                  dispatch(openModal(samplesToActOn[0], 'bacteriologist', true, samplesToActOn));
                }}
              >
                {t('mainButton:start')}
              </Button>
            </Link>
          )}
        </div>
        {getListViewPicker()}
      </div>

      {allSamplesDisplayed && (
        <Paper>
          <div className={classes.wrapperStyle}>
            <div
              className={
                isMobile ? `${classes.divStyle} ${classes.mobileDivStyle}` : classes.divStyle
              }
              onClick={() => {
                setSortIdentifierDesc((prevVal) => !prevVal);
                onOrderOptionUpdated('cowNumber', sortIdentifierDesc);
              }}
            >
              <Typography className={classes.pointer}>
                {t('tableHeader:sortIdentifierLabel')}
              </Typography>
              <Icon className={classes.sortingIcon}>
                <ExpandMoreIcon
                  className={sortIdentifierDesc ? classes.sortUp : classes.sortDown}
                />
              </Icon>
            </div>
            <div
              onClick={() => {
                setSortDateDesc((prevVal) => !prevVal);
                onOrderOptionUpdated('createdAt', sortDateDesc);
              }}
              className={
                isMobile ? `${classes.divStyle} ${classes.mobileDivStyle}` : classes.divStyle
              }
            >
              <Typography className={classes.pointer}>{t('tableHeader:sortDateLabel')}</Typography>
              <Icon className={classes.sortingIcon}>
                <ExpandMoreIcon className={sortDateDesc ? classes.sortUp : classes.sortDown} />
              </Icon>
            </div>
            <div className={classes.formStyle}>
              <div className={classes.formDiv}>
                <FormControl>
                  <Select
                    native
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    inputProps={{
                      name: 'status',
                      id: 'status-native-simple',
                    }}
                  >
                    {getStatusesList()}
                  </Select>
                </FormControl>
              </div>
              <div className={classes.searchWrapper}>
                <Paper
                  className={classes.searchBar}
                  elevation={0}
                  component='form'
                  onSubmit={handleOnSubmit}
                >
                  <InputBase
                    placeholder={t('tableHeader:filterSearchLabel')}
                    onChange={(e) =>
                      e.target.value === '' ? setSearchText('') : setQuery(e.target.value)
                    }
                    disabled={isLoadingSamples}
                    fullWidth
                  />
                  <IconButton type='submit'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
            </div>
          </div>
        </Paper>
      )}

      <BactSampleView
        samples={allSamplesDisplayed ? samples : samplesToActOn}
        onPageUpdated={onPageUpdated}
        loadingAllSamples={isLoadingSamples}
        loadingSamplesToActOn={loadingSamplesToActOn}
        page={page}
        allSamplesDisplayed={allSamplesDisplayed}
      />

      {modalOpen && view === 'bacteriologist' && (
        <Modal>
          <>
            <SelectViewBar selectAdmin={(e) => setDisplayAdmin(e)} displayAdmin={displayAdmin} />
            {displayAdmin ? (
              <BactDetails
                updateSample={updateSample}
                isLoadingUpdateSample={isLoadingUpdateSample}
                isLoadingUpdateAfterSendInForVerification={
                  isLoadingUpdateAfterSendInForVerification
                }
                isLoadingUpdateAfterCheckSendToBacteriologist={
                  isLoadingUpdateAfterCheckSendToBacteriologist
                }
                isLoadingUpdateAfterSendToFinal={isLoadingUpdateAfterSendToFinal}
              />
            ) : (
              <FarmerDetails />
            )}
          </>
          <LabFormDialog
            updateSample={updateSample}
            isUpdatingSample={isLoadingClassifyAsLaboratory}
          />
        </Modal>
      )}
    </div>
  );
}
