import React, { useEffect, useState } from 'react';
import { Card, makeStyles, Typography, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  shouldPreliminaryResultBeRejected,
  getFarmerSampleOutcomeInfo,
  whoSetThisResultName,
  getBacteriaAmount,
  getCultureTypeLabel,
} from '../../../../../Services/Utility/SampleInformation';
import { enums } from '../../../../../Services/Utility/Enumerations';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import brownCow from '../../../../../icons/cow-br.png';
import blackWhiteCow from '../../../../../icons/cow-bw.png';
import ResultIcon from '../ResultIcon';

const useStyles = makeStyles((theme) => ({
  resultCard: {
    border: '1px solid #009D58',
  },
  resultContainer: {
    padding: '1rem',
  },
  resultHeader: {
    display: 'flex',
  },
  result: {
    display: 'flex',
  },
  divider: {
    margin: '0 1rem',
  },
  waitingForResult: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    maxHeight: '14rem',
  },
  cowImage: {
    width: '25%',
    height: '25%',
    maxHeight: '10rem',
    maxWidth: '10rem',
  },
  waitForResultTextContainer: {
    marginLeft: '1rem',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function ResultCard(props) {
  const { sample } = props;
  const classes = useStyles();
  const { t } = useTranslation(['samplesFarmer, samplesDetails', 'bacterias']);
  const dispatch = useDispatch();
  const [earlyResult, setEarlyResult] = useState(null);
  const [prelResult, setPrelResult] = useState(null);
  const [finalResult, setFinalResult] = useState(null);
  const [cow, setCow] = useState(null);

  useEffect(() => {
    // Randomize which one of two cows should be displayed
    if (Math.floor(Math.random() * 2) + 1 < 2) {
      setCow(brownCow);
    } else {
      setCow(blackWhiteCow);
    }
  }, []);

  useEffect(() => {
    function getResultInfo(result) {
      if (result && result !== 'preliminaryResultMissing') {
        if (result.bacteriaResultOutcome === enums.bacteriaResultOutcome.success) {
          if (
            [enums.cultureType.notSet, enums.cultureType.pure].includes(
              result.cultureType.cultureType,
            )
          ) {
            return `${t(`bacterias:${result.bacterias[0]?.name}`)}${getBacteriaAmount(
              result.cultureType.bacteriaAmount,
            )}`;
          } else {
            return `${getCultureTypeLabel(result)}${getBacteriaAmount(
              result.cultureType.bacteriaAmount,
            )}`;
          }
        } else {
          return getFarmerSampleOutcomeInfo(result);
        }
      } else if (result === 'preliminaryResultMissing') {
        return t('samplesFarmer:farmerSampleOutcomes:preliminaryResultMissing');
      }

      return '';
    }

    setPrelResult(
      shouldPreliminaryResultBeRejected(sample)
        ? getResultInfo('preliminaryResultMissing')
        : getResultInfo(sample.preliminaryResult),
    );
    setFinalResult(getResultInfo(sample.finalResult));
    setEarlyResult(getResultInfo(sample.earlyResult));
  }, [t, sample, dispatch]);

  const waitingForResult = (
    <div className={classes.waitingForResult}>
      <img src={cow} alt='cow' className={classes.cowImage}></img>
      <div className={classes.waitForResultTextContainer}>
        <div>
          <Typography variant='h5' gutterBottom>
            {t('samplesDetails:resultCard:waitForResultHeader')}
          </Typography>
          <Typography>{t('samplesDetails:resultCard:waitForResultText')}</Typography>
        </div>
      </div>
    </div>
  );

  return (
    <Card elevation={0} className={!prelResult && !finalResult ? '' : classes.resultCard}>
      {earlyResult && (
        <div className={classes.resultContainer}>
          <div className={classes.resultHeader}>
            <Typography variant='subtitle1' gutterBottom>
              {t('samplesDetails:resultCard:earlyResult')}
            </Typography>
          </div>
          <div className={classes.result}>
            <ResultIcon result={sample.earlyResult} />
            <div>
              <Typography variant='h5' gutterBottom>
                {earlyResult}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {t('samplesDetails:informationCard:createdBy', {
                  name: whoSetThisResultName(sample.earlyResult),
                })}
              </Typography>
            </div>
          </div>
        </div>
      )}
      {earlyResult && (prelResult || finalResult) && <Divider className={classes.divider} />}
      {prelResult && (
        <div className={classes.resultContainer}>
          <div className={classes.resultHeader}>
            <Typography variant='subtitle1' gutterBottom>
              {t('samplesDetails:resultCard:preliminaryResult')}
            </Typography>
          </div>
          <div className={classes.result}>
            <ResultIcon result={sample.preliminaryResult} />
            <div>
              <Typography variant='h5' gutterBottom>
                {prelResult}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {t('samplesDetails:informationCard:createdBy', {
                  name: whoSetThisResultName(sample.preliminaryResult),
                })}
              </Typography>
            </div>
          </div>
        </div>
      )}
      {prelResult && finalResult && <Divider className={classes.divider} />}
      {finalResult && (
        <div className={classes.resultContainer}>
          <div className={classes.resultHeader}>
            <Typography variant='subtitle1' gutterBottom>
              {t('samplesDetails:resultCard:finalResult')}
            </Typography>
          </div>
          <div className={classes.result}>
            <ResultIcon result={sample.finalResult} />
            <div>
              <Typography variant='h5' gutterBottom>
                {finalResult}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {t('samplesDetails:informationCard:createdBy', {
                  name: whoSetThisResultName(sample.finalResult),
                })}
              </Typography>
            </div>
          </div>
        </div>
      )}
      {!prelResult && !finalResult && waitingForResult}
    </Card>
  );
}

ResultCard.propTypes = {
  sample: PropTypes.object.isRequired,
};
