import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  activeTag: {
    padding: '0.2rem',
    maxHeight: '1.5rem',
    borderRadius: '1rem',
    display: 'flex',
    alignItems: 'center',
    '&>div': {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      padding: '0 0.5rem',
      '&>p': {
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
      },
    },
  },
  greenBackground: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function ActiveTag() {
  const classes = useStyles();
  const { t } = useTranslation(['samplesFarmer']);

  return (
    <div className={`${classes.activeTag} ${classes.greenBackground}`}>
      <div>
        <Typography variant='body2'>{t('cowCard:active')}</Typography>
      </div>
    </div>
  );
}
