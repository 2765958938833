/**
 * Translations and interpretations of database-values
 */
export const enums = {
  System: {
    Bacticam: '5baeb51e-e642-491d-a6f2-3925cc6a8e2d',
  },
  reason: {
    clinical: 1,
    subClinical: 2,
  },
  udderPart: {
    frontLeft: 0,
    frontRight: 1,
    backLeft: 2,
    backRight: 3,
  },
  penicillin: {
    notTreated: 1,
    treated: 2,
  },
  cowImportance: {
    low: 1,
    medium: 2,
    high: 3,
  },
  symptom: {
    cellCountChanges: 1,
    milkChanges: 2,
    swollenUdder: 3,
    affectedGeneralCondition: 4,
    stronglyAffectedGeneralCondition: 5,
  },
  steps: {
    registered: 1,
    earlyPhotos: 2,
    earlyResult: 3,
    preliminaryPhotos: 4,
    preliminaryResult: 5,
    finalPhotos: 6,
    finalResult: 7,
  },
  lightMode: {
    over: 1,
    under: 2,
  },
  bacteriaResultOutcome: {
    notSet: 0,
    success: 1,
    humanEvaluationRequired: 2,
    poorImages: 3,
    unknownBacteria: 4,
    tooFewBacteriasIgnore: 5,
    tooFewBacteriasAdditionalEvaluationRequired: 6,
    incorrectSampleApplication: 7,
    tooLongCultivationTime: 8,
    tooShortCultivationTime: 9,
    badCulturePlate: 10,
    otherProblemFailed: 11,
    missingImages: 12,
    otherProblemInconclusive: 13,
    undersideMarkingOnCulturePlate: 14,
    additionalImagesRequired: 15,
  },
  status: {
    registered: 'b8ae2542-8312-43d3-980f-176d19b799d7',
    finalResultAvailable: '948d1dd2-1528-4a26-9539-d3077b6f2ae9',
    awaitingBac: '1e3008f1-c4a1-46c9-bf94-54a0dddea7d4',
    awaitingSeniorBac: 'd75ac6ce-a75b-4ab2-aaa2-f596d0354e79',
  },
  sourceKind: {
    notSet: 0,
    bdh: 1,
    ai: 2,
    juniorBacteriologist: 3,
    seniorBacteriologist: 4,
    laboratory: 5,
  },
  resultType: {
    preliminary: 1,
    final: 2,
    early: 3,
  },
  bacteriaAmount: {
    notSet: 0,
    few: 1,
    sparse: 2,
    moderate: 3,
    rich: 4,
  },
  cultureType: {
    notSet: 0,
    negative: 1,
    mixedFlora: 2,
    mixedInfection: 3,
    pure: 4,
  },
  otherClassificationOptions: {
    mixedFloraContagiousBacteriaSuspected: 22,
  },
  labFlag: {
    notSet: 0,
    doNotSend: 1,
    send: 2,
    hasBeenSent: 3,
    requestedResultAvailable: 4,
    notRequestedResultAvailable: 5,
    sendLocked: 6,
    notificationScheduled: 7,
  },
  labFlagTrigger: {
    bacteriaResultFromLaboratory: 0,
    bacUnsetsSendToLaboratory: 1,
    bacSetsSendToLaboratory: 2,
    farmerUnsetsSentToLaboratory: 3,
    farmerSetsSentToLaboratory: 4,
  },
  sampleStateTrigger: {
    addPhotoEvent: 0,
    addBacteriaResult: 1,
    sendToBak: 2,
    sendToSeniorBak: 3,
    complete: 4,
  },
  confidenceLevel: {
    notSet: 0,
    undecidable: 1,
    likely: 2,
    certain: 3,
    suspicion: 4,
  },
};
