import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  getSampleHistory,
  getClassificationText,
  getSourceLabel,
} from '../../../../../../Services/Utility/SampleInformation';
import { yyyymmddhhmm } from '../../../../../../Services/Utility/DateUtils';
import { enums } from '../../../../../../Services/Utility/Enumerations';

const timelineContainerMaxHeight = 320;
const useStyles = makeStyles((theme) => ({
  sampleHistoryContainer: {
    width: '100%',
    padding: theme.spacing(1),
    position: 'relative',
  },
  timelineContainer: {
    maxHeight: theme.spacing(timelineContainerMaxHeight / 16),
    position: 'relative',
    padding: theme.spacing(0, 0.5),
    display: 'flex',
    flexDirection: 'column-reverse',
    '&>div': {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
    '&>div:first-child::before': {
      content: '""',
      display: 'inline-block',
      height: '100%',
      width: '8px',
      backgroundColor: 'white',
      position: 'absolute',
      left: '-0.34rem',
      top: '14px',
    },
    '&>div:last-child::after': {
      content: '""',
      display: 'inline-block',
      width: '8px',
      height: '25px',
      backgroundColor: 'white',
      position: 'absolute',
      left: '-0.34rem',
      top: '-0.1rem',
    },
  },
  overflow: {
    overflowY: 'auto',
  },
  padding: {
    padding: theme.spacing(0, 0.5, 1, 0.5),
  },
  fadeBottom: {
    width: 'calc(100% - 50px)',
    position: 'absolute',
    padding: '20px 0 20px 0',
    backgroundImage: '-webkit-linear-gradient(top, rgba(255,255,255,0.001) 0%, #fff0 0%, #fff 70%)',
    bottom: 0,
    zIndex: 3,
  },
  fadeTop: {
    position: 'absolute',
    width: 'calc(100% - 50px)',
    padding: '19px 0 19px 0',
    backgroundImage:
      '-webkit-linear-gradient(top, rgba(255,255,255,0.001) 0%, #fff 70%, #fff0 100%)',
    top: '54px',
    zIndex: 3,
  },
  eventContainer: {
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    paddingLeft: '1rem',
    position: 'relative',
    '&>p': {
      color: theme.palette.darkGrays.gray80,
    },
  },
  noTimeline: {
    border: 'none',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  eventTitle: {
    // timeline dot
    '&::before': {
      content: '""',
      display: 'inline-block',
      borderRadius: '50%',
      height: '7px',
      width: '7px',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      left: '-0.23rem',
      marginTop: '0.46rem',
      zIndex: 2,
    },
  },
  bold: {
    fontWeight: 600,
  },
  resultContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    color: theme.palette.darkGrays.gray80,
    paddingTop: theme.spacing(0.75),
  },
  createdByContainer: {
    color: theme.palette.darkGrays.gray80,
    paddingTop: theme.spacing(0.75),
  },
}));

export default function SampleHistory() {
  const classes = useStyles();
  const { t } = useTranslation(['samplesDetails']);
  const sample = useSelector((state) => state.modalReducer.sample);
  const events = getSampleHistory(sample);
  const [fadeTimeline, setFadeTimeline] = useState(false);

  useEffect(() => {
    const container = document.getElementById('timeline');
    const containerActualHeight = container.scrollHeight;

    if (containerActualHeight > timelineContainerMaxHeight) {
      setFadeTimeline(true);
    } else {
      setFadeTimeline(false);
    }
  }, []);

  return (
    <div className={classes.sampleHistoryContainer}>
      <Typography>{t('historyCard:sampleHistoryHeader')}</Typography>
      <Typography variant='body2' color='textSecondary' style={{ paddingBottom: '1.5rem' }}>
        {t('historyCard:sampleHistorySubHeader')}
      </Typography>
      <div
        className={
          fadeTimeline
            ? `${classes.timelineContainer} ${classes.padding} ${classes.overflow}`
            : classes.timelineContainer
        }
        id='timeline'
      >
        {events.map((event) => {
          return <Event key={event.eventDate} event={event} singleEvent={events.length === 1} />;
        })}
      </div>
      {fadeTimeline && <div className={classes.fadeTop}></div>}
      {fadeTimeline && <div className={classes.fadeBottom}></div>}
    </div>
  );
}

export function Event(props) {
  const classes = useStyles();
  const { t } = useTranslation(['samplesDetails']);
  const { event, singleEvent } = props;

  return (
    <div
      className={
        singleEvent ? `${classes.eventContainer} ${classes.noTimeline}` : classes.eventContainer
      }
    >
      <Typography className={classes.eventTitle} variant='h5' gutterBottom>
        {t(`historyCard:${event.eventType}Timeline`)}
      </Typography>
      <Typography variant='body2'>{yyyymmddhhmm(new Date(event.eventDate))}</Typography>
      {event.eventType === 'classification' && (
        <Classification classification={event.classification} />
      )}
    </div>
  );
}

export function Classification(props) {
  const { classification } = props;
  const classes = useStyles();
  const { t } = useTranslation(['samplesDetails']);

  return (
    <>
      <div className={classes.resultContainer}>
        <Typography variant='body2' className={classes.bold}>
          {classification.resultType === enums.resultType.early
            ? `${t('historyCard:earlyResult')}:`
            : classification.resultType === enums.resultType.preliminary
            ? `${t('historyCard:preliminaryResult')}:`
            : `${t('historyCard:finalResult')}:`}
          {'\u00A0'}
        </Typography>
        <Typography variant='body2'>{getClassificationText(classification)}</Typography>
      </div>
      <div className={classes.createdByContainer}>
        <Typography display='inline' variant='body2' className={classes.bold}>{`${t(
          'historyCard:createdBy',
        )}: `}</Typography>
        <Typography display='inline' variant='body2'>
          {getSourceLabel(classification.sourceKind)}
        </Typography>
      </div>
    </>
  );
}

Event.propTypes = {
  event: PropTypes.object.isRequired,
  singleEvent: PropTypes.bool.isRequired,
};

Classification.propTypes = {
  classification: PropTypes.object.isRequired,
};
