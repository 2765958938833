import React from 'react';
import mobileAgricamLogo from '../../../icons/agricam_color.svg';
import logo from '../agricamlogga.png';
import { NavLink } from 'react-router-dom';
import { makeStyles, useMediaQuery, Toolbar, IconButton, AppBar } from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import SelectLanguageFlag from '../SelectLanguageFlag';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
    color: '#757575',
    boxShadow: '0px -3px 10px #888888',
    zIndex: 1250,
  },
  zIndex: {
    zIndex: 1400,
  },
  mobileToolbar: {
    display: 'flex',
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minHeight: 'var(--topbar-height-mobile)',
    justifyContent: 'space-between',
  },
  signedOutToolbar: {
    justifyContent: 'flex-end',
  },
  menuButton: {
    marginRight: 0,
    outline: 'none !important',
  },
  mobileAgricamLogo: {
    height: '1.5rem',
  },
  logoStyle: {
    height: '45px',
    width: '180px',
  },
}));

export default function MobileTopBar(props) {
  const { handleDrawerToggle, open, signedInState } = props;
  const isMobile = useMediaQuery('(max-width:1160px)');

  const classes = useStyles();
  return (
    <AppBar
      elevation={0}
      classes={{ root: classes.appBar }}
      className={signedInState === 'signedIn' ? classes.zIndex : ''}
    >
      <Toolbar
        className={
          signedInState === 'signedIn'
            ? classes.mobileToolbar
            : `${classes.mobileToolbar} ${classes.signedOutToolbar}`
        }
      >
        {signedInState === 'signedIn' && (
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            {open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        )}
        {signedInState === 'signedIn' && (
          <NavLink to={'/samples'}>
            <img src={mobileAgricamLogo} alt={'Agricam'} className={classes.mobileAgricamLogo} />
          </NavLink>
        )}
        {signedInState !== 'signedIn' && <SelectLanguageFlag />}
        <img src={logo} alt={'Agricam'} className={classes.logoStyle} hidden={!!isMobile} />
      </Toolbar>
    </AppBar>
  );
}

MobileTopBar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  open: PropTypes.bool,
  signedInState: PropTypes.string.isRequired,
};
