export const OPEN_FEEDBACK_DIALOG = 'OPEN_FEEDBACK_DIALOG';
export const CLOSE_FEEDBACK_DIALOG = 'CLOSE_FEEDBACK_DIALOG';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SELECT_SITE = 'SELECT_SITE';
export const SELECT_VIEW = 'SELECT_VIEW';
export const SET_AVAILABLE_SITES = 'SET_AVAILABLE_SITES';
export const OPEN_PDF_DIALOG = 'OPEN_PDF_DIALOG';
export const CLOSE_PDF_DIALOG = 'CLOSE_PDF_DIALOG';
export const OPEN_LABFORM_DIALOG = 'OPEN_LABFORM_DIALOG';
export const CLOSE_LABFORM_DIALOG = 'CLOSE_LABFORM_DIALOG';
export const OPEN_EXPORT_DIALOG = 'OPEN_EXPORT_DIALOG';
export const CLOSE_EXPORT_DIALOG = 'CLOSE_EXPORT_DIALOG';
