import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Card,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CowHistory from './CowHistory';
import SampleHistory from './SampleHistory';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  card: {
    border: '1px solid #979797',
    padding: '1rem',
  },
  mobileCard: {
    border: '1px solid #E8E8E8',
  },
  expanCard: {
    border: '1px solid #979797',
  },
  expanPanel: {
    padding: '0 1rem 0 1rem',
  },
  expanHistoryHeaderText: {
    float: 'left',
    marginRight: '0.4rem',
  },
  historyText: {
    lineHeight: '1',
  },
  expanPanelContent: {
    padding: 0,
    borderTop: '1px solid #E0E0E0',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
  },
}));

export default function HistoryCard(props) {
  const { t } = useTranslation(['samplesDetails']);
  const { subjectId, expansionPanel } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const view = useSelector((state) => state.modalReducer.view);

  const Title = () => {
    return (
      <Typography
        variant='subtitle1'
        gutterBottom={!expansionPanel}
        className={expansionPanel ? classes.expanHistoryHeaderText : classes.historyHeaderText}
      >
        {t('historyCard:history')}
      </Typography>
    );
  };

  const CardContent = () => {
    if (view === 'farmer') {
      return <CowHistory subjectId={subjectId} />;
    } else {
      return (
        <div style={{ width: '100%' }}>
          <SampleHistory />
          <Divider />
          <CowHistory subjectId={subjectId} />
        </div>
      );
    }
  };

  if (expansionPanel) {
    return (
      <Card className={isMobile ? `${classes.expanCard} ${classes.mobileCard}` : classes.expanCard}>
        <Accordion>
          <AccordionSummary classes={{ root: classes.expanPanel }} expandIcon={<ExpandMoreIcon />}>
            <Title />
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.expanPanelContent }}>
            <CardContent />
          </AccordionDetails>
        </Accordion>
      </Card>
    );
  } else {
    return (
      <Card className={isMobile ? `${classes.card} ${classes.mobileCard}` : classes.card}>
        <Title />
        <CardContent />
      </Card>
    );
  }
}

HistoryCard.propTypes = {
  subjectId: PropTypes.string.isRequired,
  expansionPanel: PropTypes.bool,
};
